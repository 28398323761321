import * as api from "@bakkt/api";
import { BusinessPortalGateway } from "@bakkt/marketplace-client/gen/marketplace/api/business/portal/gateway/v1/business_portal_gateway_connect";
import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import axios from "axios";
import application from "../app/Application";
import Alert from "./components/Alert";

export const SERVER_URL =
  (window as any)["SERVER_URL"] == "{{SERVER_URL}}"
    ? import.meta.env.VITE_REACT_APP_SERVER_URL
    : (window as any)["SERVER_URL"];
export const LOGIN_URL =
  (window as any)["LOGIN_URL"] == "{{LOGIN_URL}}" ? import.meta.env.VITE_LOGIN_URL : (window as any)["LOGIN_URL"];

export const redirectToLogin = () => {
  axios.get(`${SERVER_URL}/web/login-redirect?client=admin`).then((response) => {
    if (response.request.responseURL) window.location = response.request.responseURL;
  });
};

const globalErrorHandler = (error: any) => {
  application.respenseReceived();
  if (error.response && error.response.status === 401) {
    if (LOGIN_URL && LOGIN_URL != "" && !localStorage.getItem("disableOkta")) {
      window.location.assign(LOGIN_URL);
    } else {
      redirectToLogin();
    }
  }
  return Promise.reject({ ...error });
};

export const logout = () => {
  axios.post(`${SERVER_URL}/web/logout`).then((response) => {
    window.location.reload();
  });
};

/*
 * Takes an array of fusionAuthRefs to destroy the sessions for on a permissions change
 */
export const destroyUserSessions = async (userRefArray) => {
  return axios.post(`${SERVER_URL}/web/destroy-sessions`, {
    fusionAuthRefs: userRefArray,
  });
};

axios.interceptors.request.use((req) => {
  application.requestSent();
  return req;
});

const globalSuccessHandler = (response: any) => {
  application.respenseReceived();

  return response;
};
const baseUrl = SERVER_URL;

axios.interceptors.response.use(globalSuccessHandler, globalErrorHandler);
// send session cookie
axios.defaults.withCredentials = true;

const configuration: api.Configuration = {
  baseOptions: {},
  isJsonMime: (mime) => true,
};

export const importAllowList = (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  axios
    .post(`${baseUrl}/aof/allow-list/import`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .catch((reason) => {
      console.log(reason);
      Alert.error("Something went wrong while uploading Allow List CSV File.");
    });
};

export const retrieveAttachmentsDataList = (attachment: api.AttachmentDetails) => {
  return axios
    .post(`${baseUrl}/business-portal/ticket/retrieve-attachment`, attachment, {
      responseType: "arraybuffer",
    })
    .catch((reason) => {
      console.log("reason    ", reason);
    });
};

export const allowListApi = api.AllowListManagementApiFactory(configuration, baseUrl);

export const accountActivityApi = api.BusinessPortalAccountActivityControllerApiFactory(configuration, baseUrl);

export const disputeApi = api.BusinessPortalDisputeControllerApiFactory(configuration, baseUrl);

export const ticketApi = api.BusinessPortalTicketControllerApiFactory(configuration, baseUrl);

export const usersApi = api.UserRoleManagementControllerApiFactory(configuration, baseUrl);

export const transactionApi = api.TransactionControllerApiFactory(configuration, baseUrl);

export const partyApi = api.BusinessPortalPartyControllerApiFactory(configuration, baseUrl);

export const incentiveApi = api.BusinessPortalIncentiveControllerApiFactory(configuration, baseUrl);

export const activityAuditApi = api.BusinessPortalActivityAuditControllerApiFactory(configuration, baseUrl);

export const ofacApi = api.OfficeOfForeignAssetsControlOFACApiFactory(configuration, baseUrl);

export const reportApi = api.BusinessPortalReportControllerApiFactory(configuration, baseUrl);

export const achApi = api.BusinessPortalAchControllerApiFactory(configuration, baseUrl);

export const blackListedApi = api.BusinessPortalBankAccountControllerApiFactory(configuration, baseUrl);

export const loyaltyApi = api.BusinessPortalLoyaltyControllerApiFactory(configuration, baseUrl);

export const ledgerAdjustmentApi = api.BusinessPortalLedgerAdjustmentControllerApiFactory(configuration, baseUrl);

export const riskApi = api.BusinessPortalRiskControllerApiFactory(configuration, baseUrl);

export const multiOwnerApi = api.BusinessPortalMultiownerControllerApiFactory(configuration, baseUrl);

export const institutionApi = api.BusinessPortalInstitutionControllerApiFactory(configuration, baseUrl);

const transport = createConnectTransport({
  baseUrl: `${baseUrl}/grpc-adapter`,
  credentials: "include",
});

export const businessPortalGateway = createPromiseClient(BusinessPortalGateway, transport);
