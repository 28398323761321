import { ApiPortalUser, ApiPortalRolePermissionsEnum as PortalPermission } from "@bakkt/api";
import { WIDGET_ID } from "../manifest";
export { PortalPermission };

export interface User {
  email: string;
  roles: PortalRole[];
  permissions: PortalPermission[];
}

export enum PortalRole {
  ADMIN = "portal_admin",
  COMPLIANCE_FULL = "portal_compliance_fullaccess",
  COMPLIANCE_VIEWONLY = "portal_compliance_viewonly",
  CUSTOMERSUPPORT_FULL = "portal_customer_support_fullaccess",
  CUSTOMERSUPPORT_VIEWONLY = "portal_customer_support_viewonly",
  CUSTOMERSUPPORT_MANAGER = "portal_customer_support_manager",
  FINANCE_FULL = "portal_finance_fullaccess",
  FINANCE_VIEWONLY = "portal_finance_viewonly",
  PORTAL_ALLOW_LIST_FULL_ACCESS = "portal_allow_list_full_access",
  PORTAL_ALLOW_LIST_VIEW_ONLY = "portal_allow_list_view_only",
  PORTAL_GIFTCARD_ADMIN_FULL_ACCESS = "portal_giftcard_admin_full_access",
  PORTAL_GIFTCARD_ADMIN_VIEW_ONLY = "portal_giftcard_admin_view_only",
}

export interface Widget {
  id?: string;
  title: string;
  type: WIDGET_ID;
  options?: any;
  newTab?: boolean;
  isClosable?: boolean;
}

export interface Workspace {
  widgets: Widget[];
  activeWidgetId?: string | null;
}
export default interface AppState {
  currentUser: User;
  reasonCodeMappings: { [key: string]: string[] };
  workspace: Workspace;
  activeRequests: number;
  zendeskUsers: ApiPortalUser[];
}
