import { Button, Dialog } from "@bakkt/components";
import * as ReactDom from "react-dom";

export class CloseEvent {
  constructor(public detail: number) {}
}

class Alert {
  public readonly YES: number = 1;
  public readonly NO: number = 2;
  public readonly OK: number = 4;
  public readonly CANCEL: number = 8;

  private readonly dialogId: string = "alertDialog";

  private resolve: Function = () => {};
  public static Type = Alert;

  public info(
    message: string,
    title: string = "Info",
    okLabel: string = "OK",
    iconStyleName: string = "fa fa-info-circle fa-lg",
  ): Promise<CloseEvent> {
    return this.showDialog(
      this.createDialog(message, title, iconStyleName, this.OK, this.OK, undefined, undefined, okLabel, undefined),
    );
  }

  public error(
    message: string,
    title: string = "Error",
    okLabel: string = "OK",
    iconStyleName: string = "fa fa-exclamation-triangle fa-lg",
  ): Promise<CloseEvent> {
    return this.showDialog(
      this.createDialog(message, title, iconStyleName, this.OK, this.OK, undefined, undefined, okLabel, undefined),
    );
  }

  public prompt(
    message: string,
    title: string = "Attention",
    yesLabel: string = "Yes",
    noLabel: string = "No",
    iconStyleName: string = "fa fa-question-circle fa-lg",
  ): Promise<CloseEvent> {
    return this.showDialog(
      this.createDialog(
        message,
        title,
        iconStyleName,
        this.YES | this.NO,
        this.YES,
        yesLabel,
        noLabel,
        undefined,
        undefined,
      ),
    );
  }

  public confirm(
    message: string,
    title: string = "Attention",
    okLabel: string = "OK",
    cancelLabel: string = "Cancel",
    iconStyleName: string = "fa fa-question-circle fa-lg",
  ): Promise<CloseEvent> {
    return this.showDialog(
      this.createDialog(
        message,
        title,
        iconStyleName,
        this.OK | this.CANCEL,
        this.OK,
        undefined,
        undefined,
        okLabel,
        cancelLabel,
      ),
    );
  }

  public hideDialog = (): void => {
    const dialog = document.getElementById(this.dialogId);
    dialog && ReactDom.unmountComponentAtNode(dialog);
  };

  private handleClick = (flag: number) => (evt: { stopPropagation: () => void }) => {
    evt.stopPropagation(); //either stop event propagation or make the modal backdrop static, otherwise the event
    //will hit the overlay and get the modal closed again which causes exception
    // setTimeout(this.hideDialog, 100); // or hide dialog after next refresh, effectively letting overlay close it first
    this.hideDialog();
    this.resolve(new CloseEvent(flag));
  };

  private createDialog(
    message: string,
    title: string,
    iconStyleName: string,
    flags: number,
    primaryBtns: number,
    yesLabel: string | undefined,
    noLabel: string | undefined,
    okLabel: string | undefined,
    cancelLabel: string | undefined,
  ): any {
    return (
      <Dialog title={title} open={true} hideCancel={true} hideSave={true} fullWidth={true} size={"sm"}>
        <div>
          {message.split("\n").map((s, index) => {
            return <span>{s}</span>;
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "8px" }}>
          {this.createBtn(flags, this.CANCEL, cancelLabel || "Cancel", (primaryBtns & this.CANCEL) > 0)}
          {this.createBtn(flags, this.OK, okLabel || "OK", (primaryBtns & this.OK) > 0)}
          {this.createBtn(flags, this.NO, noLabel || "NO", (primaryBtns & this.NO) > 0)}
          {this.createBtn(flags, this.YES, yesLabel || "YES", (primaryBtns & this.YES) > 0)}
        </div>
      </Dialog>
    );
  }

  private createBtn(flags: number, target: number, label: string, primary: boolean) {
    if (flags & target) {
      return (
        <Button onClick={this.handleClick(target)} outline={!primary}>
          {label}
        </Button>
      );
    }
    return <span />;
  }

  private showDialog(dialog: any): Promise<CloseEvent> {
    let dialogElement: any = document.getElementById(this.dialogId);
    if (!dialogElement) {
      const popupArea = document.getElementById("popupArea");
      if (popupArea) {
        dialogElement = popupArea.appendChild(document.createElement("div"));
      }
      dialogElement.id = this.dialogId;
    }
    ReactDom.render(dialog, dialogElement);

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }
}
export default new Alert();
