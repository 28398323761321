/* tslint:disable */
/* eslint-disable */
/**
 * Bakkt Business Portal Service APIs
 * Bakkt Business Portal Service APIs
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
/**
    * @export
    * @enum {string}
    */
export var AccountActivitySearchRequestAccountActivityReasonsEnum;
(function (AccountActivitySearchRequestAccountActivityReasonsEnum) {
    AccountActivitySearchRequestAccountActivityReasonsEnum["ACCOUNT_CLOSURE_REQUESTED"] = "ACCOUNT_CLOSURE_REQUESTED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["ACH_MAX_RETURNS"] = "ACH_MAX_RETURNS";
    AccountActivitySearchRequestAccountActivityReasonsEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    AccountActivitySearchRequestAccountActivityReasonsEnum["ADMIN_UPDATE_IDENTIFIER"] = "ADMIN_UPDATE_IDENTIFIER";
    AccountActivitySearchRequestAccountActivityReasonsEnum["COMPLIANCE_INVESTIGATION"] = "COMPLIANCE_INVESTIGATION";
    AccountActivitySearchRequestAccountActivityReasonsEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["COMPLIANCE_VERIFICATION"] = "COMPLIANCE_VERIFICATION";
    AccountActivitySearchRequestAccountActivityReasonsEnum["CUSTOMER_GOOD_STANDING"] = "CUSTOMER_GOOD_STANDING";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_TRANSACTION_FRAUD_HOLD"] = "DEBIT_CARD_TRANSACTION_FRAUD_HOLD";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED"] = "DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS";
    AccountActivitySearchRequestAccountActivityReasonsEnum["LIQUIDATE_ACCOUNT"] = "LIQUIDATE_ACCOUNT";
    AccountActivitySearchRequestAccountActivityReasonsEnum["MARQETA_KYC_VERIFICATION"] = "MARQETA_KYC_VERIFICATION";
    AccountActivitySearchRequestAccountActivityReasonsEnum["NSF_BLOCKING"] = "NSF_BLOCKING";
    AccountActivitySearchRequestAccountActivityReasonsEnum["OLD_OFAC_CHECK"] = "OLD_OFAC_CHECK";
    AccountActivitySearchRequestAccountActivityReasonsEnum["OTHER"] = "OTHER";
    AccountActivitySearchRequestAccountActivityReasonsEnum["PLAID_IDENTITY_CHECK"] = "PLAID_IDENTITY_CHECK";
    AccountActivitySearchRequestAccountActivityReasonsEnum["PLAID_MISSING_NAMES"] = "PLAID_MISSING_NAMES";
    AccountActivitySearchRequestAccountActivityReasonsEnum["RELINK"] = "RELINK";
    AccountActivitySearchRequestAccountActivityReasonsEnum["RISK_DECISION"] = "RISK_DECISION";
    AccountActivitySearchRequestAccountActivityReasonsEnum["RISK_KYC"] = "RISK_KYC";
    AccountActivitySearchRequestAccountActivityReasonsEnum["RISK_OFAC"] = "RISK_OFAC";
    AccountActivitySearchRequestAccountActivityReasonsEnum["RISK_SCREENING"] = "RISK_SCREENING";
    AccountActivitySearchRequestAccountActivityReasonsEnum["TAX_ID_MAX_RETRIES"] = "TAX_ID_MAX_RETRIES";
    AccountActivitySearchRequestAccountActivityReasonsEnum["CARD_TERMINATION_ERROR"] = "CARD_TERMINATION_ERROR";
    AccountActivitySearchRequestAccountActivityReasonsEnum["CLOSED"] = "CLOSED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    AccountActivitySearchRequestAccountActivityReasonsEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    AccountActivitySearchRequestAccountActivityReasonsEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    AccountActivitySearchRequestAccountActivityReasonsEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    AccountActivitySearchRequestAccountActivityReasonsEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
})(AccountActivitySearchRequestAccountActivityReasonsEnum || (AccountActivitySearchRequestAccountActivityReasonsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AccountActivitySearchRequestAccountActivityTypesEnum;
(function (AccountActivitySearchRequestAccountActivityTypesEnum) {
    AccountActivitySearchRequestAccountActivityTypesEnum["BANK_IDENTITY_CHECK"] = "BANK_IDENTITY_CHECK";
    AccountActivitySearchRequestAccountActivityTypesEnum["BAKKT_CARD_KYC_FAIL"] = "BAKKT_CARD_KYC_FAIL";
    AccountActivitySearchRequestAccountActivityTypesEnum["BAKKT_CARD_KYC_PENDING"] = "BAKKT_CARD_KYC_PENDING";
    AccountActivitySearchRequestAccountActivityTypesEnum["BAKKT_CARD_KYC_SUCCESS"] = "BAKKT_CARD_KYC_SUCCESS";
    AccountActivitySearchRequestAccountActivityTypesEnum["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    AccountActivitySearchRequestAccountActivityTypesEnum["BANK_ACCOUNT_BLACKLIST"] = "BANK_ACCOUNT_BLACKLIST";
    AccountActivitySearchRequestAccountActivityTypesEnum["BANK_ACCOUNT_REMOVE_BLACKLIST"] = "BANK_ACCOUNT_REMOVE_BLACKLIST";
    AccountActivitySearchRequestAccountActivityTypesEnum["DEBIT_CARD_BLACKLIST"] = "DEBIT_CARD_BLACKLIST";
    AccountActivitySearchRequestAccountActivityTypesEnum["DEBIT_CARD_REMOVE_BLACKLIST"] = "DEBIT_CARD_REMOVE_BLACKLIST";
    AccountActivitySearchRequestAccountActivityTypesEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    AccountActivitySearchRequestAccountActivityTypesEnum["CREDIT"] = "CREDIT";
    AccountActivitySearchRequestAccountActivityTypesEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    AccountActivitySearchRequestAccountActivityTypesEnum["KYC_FAIL"] = "KYC_FAIL";
    AccountActivitySearchRequestAccountActivityTypesEnum["KYC_SUCCESS"] = "KYC_SUCCESS";
    AccountActivitySearchRequestAccountActivityTypesEnum["KYC_UPDATE"] = "KYC_UPDATE";
    AccountActivitySearchRequestAccountActivityTypesEnum["LIQUIDATE"] = "LIQUIDATE";
    AccountActivitySearchRequestAccountActivityTypesEnum["PARTY_REMOVE_WHITELIST"] = "PARTY_REMOVE_WHITELIST";
    AccountActivitySearchRequestAccountActivityTypesEnum["PARTY_WHITELIST"] = "PARTY_WHITELIST";
    AccountActivitySearchRequestAccountActivityTypesEnum["PREFUND_BLOCK"] = "PREFUND_BLOCK";
    AccountActivitySearchRequestAccountActivityTypesEnum["PREFUND_UNBLOCK"] = "PREFUND_UNBLOCK";
    AccountActivitySearchRequestAccountActivityTypesEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    AccountActivitySearchRequestAccountActivityTypesEnum["REPORT_REQUEST"] = "REPORT_REQUEST";
    AccountActivitySearchRequestAccountActivityTypesEnum["REPORT_SENT"] = "REPORT_SENT";
    AccountActivitySearchRequestAccountActivityTypesEnum["SUSPEND"] = "SUSPEND";
    AccountActivitySearchRequestAccountActivityTypesEnum["UNSUSPEND"] = "UNSUSPEND";
    AccountActivitySearchRequestAccountActivityTypesEnum["LIQUIDATE_REQUESTED"] = "LIQUIDATE_REQUESTED";
    AccountActivitySearchRequestAccountActivityTypesEnum["LIQUIDATE_REMOVE_REQUESTED"] = "LIQUIDATE_REMOVE_REQUESTED";
    AccountActivitySearchRequestAccountActivityTypesEnum["CLOSED"] = "CLOSED";
    AccountActivitySearchRequestAccountActivityTypesEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    AccountActivitySearchRequestAccountActivityTypesEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    AccountActivitySearchRequestAccountActivityTypesEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    AccountActivitySearchRequestAccountActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    AccountActivitySearchRequestAccountActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
})(AccountActivitySearchRequestAccountActivityTypesEnum || (AccountActivitySearchRequestAccountActivityTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AchFileFileTypeEnum;
(function (AchFileFileTypeEnum) {
    AchFileFileTypeEnum["OUTBOUND_CREDIT"] = "OUTBOUND_CREDIT";
    AchFileFileTypeEnum["OUTBOUND_DEBIT"] = "OUTBOUND_DEBIT";
    AchFileFileTypeEnum["OUTBOUND"] = "OUTBOUND";
    AchFileFileTypeEnum["RETURNS"] = "RETURNS";
})(AchFileFileTypeEnum || (AchFileFileTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AchFileRecordEnrollmentTypeEnum;
(function (AchFileRecordEnrollmentTypeEnum) {
    AchFileRecordEnrollmentTypeEnum["INDIVIDUAL"] = "INDIVIDUAL";
    AchFileRecordEnrollmentTypeEnum["ENTITY"] = "ENTITY";
    AchFileRecordEnrollmentTypeEnum["JOINT"] = "JOINT";
    AchFileRecordEnrollmentTypeEnum["CUSTODIAL"] = "CUSTODIAL";
})(AchFileRecordEnrollmentTypeEnum || (AchFileRecordEnrollmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ActivityAuditSearchRequestActivityTypesEnum;
(function (ActivityAuditSearchRequestActivityTypesEnum) {
    ActivityAuditSearchRequestActivityTypesEnum["PUBLISHED"] = "PUBLISHED";
    ActivityAuditSearchRequestActivityTypesEnum["CONFIGURE"] = "CONFIGURE";
    ActivityAuditSearchRequestActivityTypesEnum["PREFUND_BLOCK"] = "PREFUND_BLOCK";
    ActivityAuditSearchRequestActivityTypesEnum["PREFUND_UNBLOCK"] = "PREFUND_UNBLOCK";
    ActivityAuditSearchRequestActivityTypesEnum["SUSPEND"] = "SUSPEND";
    ActivityAuditSearchRequestActivityTypesEnum["UNSUSPEND"] = "UNSUSPEND";
    ActivityAuditSearchRequestActivityTypesEnum["REPORT_REQUEST"] = "REPORT_REQUEST";
    ActivityAuditSearchRequestActivityTypesEnum["REPORT_SENT"] = "REPORT_SENT";
    ActivityAuditSearchRequestActivityTypesEnum["KYC_SUCCESS"] = "KYC_SUCCESS";
    ActivityAuditSearchRequestActivityTypesEnum["KYC_FAIL"] = "KYC_FAIL";
    ActivityAuditSearchRequestActivityTypesEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ActivityAuditSearchRequestActivityTypesEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ActivityAuditSearchRequestActivityTypesEnum["CREDIT"] = "CREDIT";
    ActivityAuditSearchRequestActivityTypesEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ActivityAuditSearchRequestActivityTypesEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ActivityAuditSearchRequestActivityTypesEnum["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    ActivityAuditSearchRequestActivityTypesEnum["BANK_ACCOUNT_BLACKLIST"] = "BANK_ACCOUNT_BLACKLIST";
    ActivityAuditSearchRequestActivityTypesEnum["BANK_ACCOUNT_REMOVE_BLACKLIST"] = "BANK_ACCOUNT_REMOVE_BLACKLIST";
    ActivityAuditSearchRequestActivityTypesEnum["BAKKT_CARD_KYC_SUCCESS"] = "BAKKT_CARD_KYC_SUCCESS";
    ActivityAuditSearchRequestActivityTypesEnum["BAKKT_CARD_KYC_PENDING"] = "BAKKT_CARD_KYC_PENDING";
    ActivityAuditSearchRequestActivityTypesEnum["BAKKT_CARD_KYC_FAIL"] = "BAKKT_CARD_KYC_FAIL";
    ActivityAuditSearchRequestActivityTypesEnum["LINK_LOYALTY_ACCOUNT"] = "LINK_LOYALTY_ACCOUNT";
    ActivityAuditSearchRequestActivityTypesEnum["UNLINK_LOYALTY_ACCOUNT"] = "UNLINK_LOYALTY_ACCOUNT";
    ActivityAuditSearchRequestActivityTypesEnum["LINK_LOYALTY_ACCOUNT_FAILED"] = "LINK_LOYALTY_ACCOUNT_FAILED";
    ActivityAuditSearchRequestActivityTypesEnum["DEBIT_CARD_BLACKLIST"] = "DEBIT_CARD_BLACKLIST";
    ActivityAuditSearchRequestActivityTypesEnum["DEBIT_CARD_REMOVE_BLACKLIST"] = "DEBIT_CARD_REMOVE_BLACKLIST";
    ActivityAuditSearchRequestActivityTypesEnum["BANK_IDENTITY_CHECK"] = "BANK_IDENTITY_CHECK";
    ActivityAuditSearchRequestActivityTypesEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ActivityAuditSearchRequestActivityTypesEnum["CLOSED"] = "CLOSED";
    ActivityAuditSearchRequestActivityTypesEnum["LIQUIDATE_REQUESTED"] = "LIQUIDATE_REQUESTED";
    ActivityAuditSearchRequestActivityTypesEnum["LIQUIDATE_REMOVE_REQUESTED"] = "LIQUIDATE_REMOVE_REQUESTED";
    ActivityAuditSearchRequestActivityTypesEnum["PARTY_WHITELIST"] = "PARTY_WHITELIST";
    ActivityAuditSearchRequestActivityTypesEnum["PARTY_REMOVE_WHITELIST"] = "PARTY_REMOVE_WHITELIST";
    ActivityAuditSearchRequestActivityTypesEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ActivityAuditSearchRequestActivityTypesEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ActivityAuditSearchRequestActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ActivityAuditSearchRequestActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ActivityAuditSearchRequestActivityTypesEnum["PII_UPDATE"] = "PII_UPDATE";
})(ActivityAuditSearchRequestActivityTypesEnum || (ActivityAuditSearchRequestActivityTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ActivityAuditSearchRequestEntityTypeEnum;
(function (ActivityAuditSearchRequestEntityTypeEnum) {
    ActivityAuditSearchRequestEntityTypeEnum["INCENTIVE"] = "INCENTIVE";
    ActivityAuditSearchRequestEntityTypeEnum["ACCOUNT"] = "ACCOUNT";
    ActivityAuditSearchRequestEntityTypeEnum["GIFTCARD_BRANDS"] = "GIFTCARD_BRANDS";
    ActivityAuditSearchRequestEntityTypeEnum["LOYALTY"] = "LOYALTY";
    ActivityAuditSearchRequestEntityTypeEnum["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    ActivityAuditSearchRequestEntityTypeEnum["DEBIT_CARD"] = "DEBIT_CARD";
    ActivityAuditSearchRequestEntityTypeEnum["OTHER"] = "OTHER";
})(ActivityAuditSearchRequestEntityTypeEnum || (ActivityAuditSearchRequestEntityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum;
(function (AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum) {
    AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum["WIRE"] = "WIRE";
})(AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum || (AdjustFiatLedgerRequestLedgerAdjustmentReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AdjustFiatLedgerRequestLedgerAdjustmentTypeEnum;
(function (AdjustFiatLedgerRequestLedgerAdjustmentTypeEnum) {
    AdjustFiatLedgerRequestLedgerAdjustmentTypeEnum["CREDIT"] = "CREDIT";
    AdjustFiatLedgerRequestLedgerAdjustmentTypeEnum["DEBIT"] = "DEBIT";
})(AdjustFiatLedgerRequestLedgerAdjustmentTypeEnum || (AdjustFiatLedgerRequestLedgerAdjustmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AmountCurrencyEnum;
(function (AmountCurrencyEnum) {
    AmountCurrencyEnum["USD"] = "USD";
    AmountCurrencyEnum["BCH"] = "BCH";
    AmountCurrencyEnum["BTC"] = "BTC";
    AmountCurrencyEnum["DOGE"] = "DOGE";
    AmountCurrencyEnum["ETC"] = "ETC";
    AmountCurrencyEnum["ETH"] = "ETH";
    AmountCurrencyEnum["LTC"] = "LTC";
    AmountCurrencyEnum["SHIB"] = "SHIB";
    AmountCurrencyEnum["USDC"] = "USDC";
    AmountCurrencyEnum["CET"] = "CET";
    AmountCurrencyEnum["LXX"] = "LXX";
    AmountCurrencyEnum["LCP"] = "LCP";
    AmountCurrencyEnum["LWR"] = "LWR";
    AmountCurrencyEnum["LMO"] = "LMO";
    AmountCurrencyEnum["LKO"] = "LKO";
    AmountCurrencyEnum["LED"] = "LED";
})(AmountCurrencyEnum || (AmountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAccountAssetClassEnum;
(function (ApiAccountAssetClassEnum) {
    ApiAccountAssetClassEnum["CASH"] = "CASH";
    ApiAccountAssetClassEnum["CRYPTO"] = "CRYPTO";
    ApiAccountAssetClassEnum["REWARDS"] = "REWARDS";
})(ApiAccountAssetClassEnum || (ApiAccountAssetClassEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAccountCurrencyEnum;
(function (ApiAccountCurrencyEnum) {
    ApiAccountCurrencyEnum["USD"] = "USD";
    ApiAccountCurrencyEnum["BCH"] = "BCH";
    ApiAccountCurrencyEnum["BTC"] = "BTC";
    ApiAccountCurrencyEnum["DOGE"] = "DOGE";
    ApiAccountCurrencyEnum["ETC"] = "ETC";
    ApiAccountCurrencyEnum["ETH"] = "ETH";
    ApiAccountCurrencyEnum["LTC"] = "LTC";
    ApiAccountCurrencyEnum["SHIB"] = "SHIB";
    ApiAccountCurrencyEnum["USDC"] = "USDC";
    ApiAccountCurrencyEnum["CET"] = "CET";
    ApiAccountCurrencyEnum["LXX"] = "LXX";
    ApiAccountCurrencyEnum["LCP"] = "LCP";
    ApiAccountCurrencyEnum["LWR"] = "LWR";
    ApiAccountCurrencyEnum["LMO"] = "LMO";
    ApiAccountCurrencyEnum["LKO"] = "LKO";
    ApiAccountCurrencyEnum["LED"] = "LED";
})(ApiAccountCurrencyEnum || (ApiAccountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAccountGoalsInvestmentObjectiveEnum;
(function (ApiAccountGoalsInvestmentObjectiveEnum) {
    ApiAccountGoalsInvestmentObjectiveEnum["BALANCED"] = "BALANCED";
    ApiAccountGoalsInvestmentObjectiveEnum["CAPITAL_PRESERVATION"] = "CAPITAL_PRESERVATION";
    ApiAccountGoalsInvestmentObjectiveEnum["GROWTH"] = "GROWTH";
    ApiAccountGoalsInvestmentObjectiveEnum["INCOME"] = "INCOME";
    ApiAccountGoalsInvestmentObjectiveEnum["SPECULATION"] = "SPECULATION";
    ApiAccountGoalsInvestmentObjectiveEnum["OTHER"] = "OTHER";
})(ApiAccountGoalsInvestmentObjectiveEnum || (ApiAccountGoalsInvestmentObjectiveEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAccountGoalsLiquidityNeedsEnum;
(function (ApiAccountGoalsLiquidityNeedsEnum) {
    ApiAccountGoalsLiquidityNeedsEnum["VERY_IMPORTANT"] = "VERY_IMPORTANT";
    ApiAccountGoalsLiquidityNeedsEnum["SOMEWHAT_IMPORTANT"] = "SOMEWHAT_IMPORTANT";
    ApiAccountGoalsLiquidityNeedsEnum["NOT_IMPORTANT"] = "NOT_IMPORTANT";
})(ApiAccountGoalsLiquidityNeedsEnum || (ApiAccountGoalsLiquidityNeedsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAccountGoalsRiskToleranceEnum;
(function (ApiAccountGoalsRiskToleranceEnum) {
    ApiAccountGoalsRiskToleranceEnum["LOW"] = "LOW";
    ApiAccountGoalsRiskToleranceEnum["MEDIUM"] = "MEDIUM";
    ApiAccountGoalsRiskToleranceEnum["HIGH"] = "HIGH";
})(ApiAccountGoalsRiskToleranceEnum || (ApiAccountGoalsRiskToleranceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchFileDetailStatusEnum;
(function (ApiAchFileDetailStatusEnum) {
    ApiAchFileDetailStatusEnum["READY"] = "READY";
    ApiAchFileDetailStatusEnum["ERROR"] = "ERROR";
    ApiAchFileDetailStatusEnum["PROCESSED"] = "PROCESSED";
})(ApiAchFileDetailStatusEnum || (ApiAchFileDetailStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchFileDetailTypeEnum;
(function (ApiAchFileDetailTypeEnum) {
    ApiAchFileDetailTypeEnum["UNSPECIFIED"] = "UNSPECIFIED";
    ApiAchFileDetailTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiAchFileDetailTypeEnum["WITHDRAWAL"] = "WITHDRAWAL";
    ApiAchFileDetailTypeEnum["RETURN_DEPOSIT"] = "RETURN_DEPOSIT";
    ApiAchFileDetailTypeEnum["RETURN_WITHDRAWAL"] = "RETURN_WITHDRAWAL";
})(ApiAchFileDetailTypeEnum || (ApiAchFileDetailTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchSummaryViewTypeEnum;
(function (ApiAchSummaryViewTypeEnum) {
    ApiAchSummaryViewTypeEnum["OUTBOUND"] = "OUTBOUND";
    ApiAchSummaryViewTypeEnum["RETURNS"] = "RETURNS";
})(ApiAchSummaryViewTypeEnum || (ApiAchSummaryViewTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionAchTypeEnum;
(function (ApiAchTransactionAchTypeEnum) {
    ApiAchTransactionAchTypeEnum["WITHDRAWAL"] = "WITHDRAWAL";
    ApiAchTransactionAchTypeEnum["DEPOSIT"] = "DEPOSIT";
})(ApiAchTransactionAchTypeEnum || (ApiAchTransactionAchTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionCurrencyEnum;
(function (ApiAchTransactionCurrencyEnum) {
    ApiAchTransactionCurrencyEnum["USD"] = "USD";
    ApiAchTransactionCurrencyEnum["BCH"] = "BCH";
    ApiAchTransactionCurrencyEnum["BTC"] = "BTC";
    ApiAchTransactionCurrencyEnum["DOGE"] = "DOGE";
    ApiAchTransactionCurrencyEnum["ETC"] = "ETC";
    ApiAchTransactionCurrencyEnum["ETH"] = "ETH";
    ApiAchTransactionCurrencyEnum["LTC"] = "LTC";
    ApiAchTransactionCurrencyEnum["SHIB"] = "SHIB";
    ApiAchTransactionCurrencyEnum["USDC"] = "USDC";
    ApiAchTransactionCurrencyEnum["CET"] = "CET";
    ApiAchTransactionCurrencyEnum["LXX"] = "LXX";
    ApiAchTransactionCurrencyEnum["LCP"] = "LCP";
    ApiAchTransactionCurrencyEnum["LWR"] = "LWR";
    ApiAchTransactionCurrencyEnum["LMO"] = "LMO";
    ApiAchTransactionCurrencyEnum["LKO"] = "LKO";
    ApiAchTransactionCurrencyEnum["LED"] = "LED";
})(ApiAchTransactionCurrencyEnum || (ApiAchTransactionCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionPrefundingVerdictEnum;
(function (ApiAchTransactionPrefundingVerdictEnum) {
    ApiAchTransactionPrefundingVerdictEnum["ACCEPTED"] = "ACCEPTED";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_CAP_EXCEEDED"] = "DECLINED_CAP_EXCEEDED";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_NEGATIVE_BALANCE"] = "DECLINED_NEGATIVE_BALANCE";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_ALREADY_PREFUNDED"] = "DECLINED_ALREADY_PREFUNDED";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_RECENT_ACH_RETURNS"] = "DECLINED_RECENT_ACH_RETURNS";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_BANK_ACCOUNT_BALANCE_NOT_AVAILABLE"] = "DECLINED_BANK_ACCOUNT_BALANCE_NOT_AVAILABLE";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_PLAID_BALANCE_NOT_SUFFICIENT_FUNDS"] = "DECLINED_PLAID_BALANCE_NOT_SUFFICIENT_FUNDS";
    ApiAchTransactionPrefundingVerdictEnum["DISABLED"] = "DISABLED";
    ApiAchTransactionPrefundingVerdictEnum["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_SAME_DAY_MICRODEPOSIT_ACCOUNT"] = "DECLINED_SAME_DAY_MICRODEPOSIT_ACCOUNT";
    ApiAchTransactionPrefundingVerdictEnum["DECLINED_INSTITUTION_DENIED"] = "DECLINED_INSTITUTION_DENIED";
})(ApiAchTransactionPrefundingVerdictEnum || (ApiAchTransactionPrefundingVerdictEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionDetailsCurrencyEnum;
(function (ApiAchTransactionDetailsCurrencyEnum) {
    ApiAchTransactionDetailsCurrencyEnum["USD"] = "USD";
    ApiAchTransactionDetailsCurrencyEnum["BCH"] = "BCH";
    ApiAchTransactionDetailsCurrencyEnum["BTC"] = "BTC";
    ApiAchTransactionDetailsCurrencyEnum["DOGE"] = "DOGE";
    ApiAchTransactionDetailsCurrencyEnum["ETC"] = "ETC";
    ApiAchTransactionDetailsCurrencyEnum["ETH"] = "ETH";
    ApiAchTransactionDetailsCurrencyEnum["LTC"] = "LTC";
    ApiAchTransactionDetailsCurrencyEnum["SHIB"] = "SHIB";
    ApiAchTransactionDetailsCurrencyEnum["USDC"] = "USDC";
    ApiAchTransactionDetailsCurrencyEnum["CET"] = "CET";
    ApiAchTransactionDetailsCurrencyEnum["LXX"] = "LXX";
    ApiAchTransactionDetailsCurrencyEnum["LCP"] = "LCP";
    ApiAchTransactionDetailsCurrencyEnum["LWR"] = "LWR";
    ApiAchTransactionDetailsCurrencyEnum["LMO"] = "LMO";
    ApiAchTransactionDetailsCurrencyEnum["LKO"] = "LKO";
    ApiAchTransactionDetailsCurrencyEnum["LED"] = "LED";
})(ApiAchTransactionDetailsCurrencyEnum || (ApiAchTransactionDetailsCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionDetailsPrefundingVerdictEnum;
(function (ApiAchTransactionDetailsPrefundingVerdictEnum) {
    ApiAchTransactionDetailsPrefundingVerdictEnum["ACCEPTED"] = "ACCEPTED";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_CAP_EXCEEDED"] = "DECLINED_CAP_EXCEEDED";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_NEGATIVE_BALANCE"] = "DECLINED_NEGATIVE_BALANCE";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_ALREADY_PREFUNDED"] = "DECLINED_ALREADY_PREFUNDED";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_RECENT_ACH_RETURNS"] = "DECLINED_RECENT_ACH_RETURNS";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_BANK_ACCOUNT_BALANCE_NOT_AVAILABLE"] = "DECLINED_BANK_ACCOUNT_BALANCE_NOT_AVAILABLE";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_PLAID_BALANCE_NOT_SUFFICIENT_FUNDS"] = "DECLINED_PLAID_BALANCE_NOT_SUFFICIENT_FUNDS";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DISABLED"] = "DISABLED";
    ApiAchTransactionDetailsPrefundingVerdictEnum["NOT_APPLICABLE"] = "NOT_APPLICABLE";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_SAME_DAY_MICRODEPOSIT_ACCOUNT"] = "DECLINED_SAME_DAY_MICRODEPOSIT_ACCOUNT";
    ApiAchTransactionDetailsPrefundingVerdictEnum["DECLINED_INSTITUTION_DENIED"] = "DECLINED_INSTITUTION_DENIED";
})(ApiAchTransactionDetailsPrefundingVerdictEnum || (ApiAchTransactionDetailsPrefundingVerdictEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionResponseAchTypeEnum;
(function (ApiAchTransactionResponseAchTypeEnum) {
    ApiAchTransactionResponseAchTypeEnum["WITHDRAWAL"] = "WITHDRAWAL";
    ApiAchTransactionResponseAchTypeEnum["DEPOSIT"] = "DEPOSIT";
})(ApiAchTransactionResponseAchTypeEnum || (ApiAchTransactionResponseAchTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionResponseCurrencyEnum;
(function (ApiAchTransactionResponseCurrencyEnum) {
    ApiAchTransactionResponseCurrencyEnum["USD"] = "USD";
    ApiAchTransactionResponseCurrencyEnum["BCH"] = "BCH";
    ApiAchTransactionResponseCurrencyEnum["BTC"] = "BTC";
    ApiAchTransactionResponseCurrencyEnum["DOGE"] = "DOGE";
    ApiAchTransactionResponseCurrencyEnum["ETC"] = "ETC";
    ApiAchTransactionResponseCurrencyEnum["ETH"] = "ETH";
    ApiAchTransactionResponseCurrencyEnum["LTC"] = "LTC";
    ApiAchTransactionResponseCurrencyEnum["SHIB"] = "SHIB";
    ApiAchTransactionResponseCurrencyEnum["USDC"] = "USDC";
    ApiAchTransactionResponseCurrencyEnum["CET"] = "CET";
    ApiAchTransactionResponseCurrencyEnum["LXX"] = "LXX";
    ApiAchTransactionResponseCurrencyEnum["LCP"] = "LCP";
    ApiAchTransactionResponseCurrencyEnum["LWR"] = "LWR";
    ApiAchTransactionResponseCurrencyEnum["LMO"] = "LMO";
    ApiAchTransactionResponseCurrencyEnum["LKO"] = "LKO";
    ApiAchTransactionResponseCurrencyEnum["LED"] = "LED";
})(ApiAchTransactionResponseCurrencyEnum || (ApiAchTransactionResponseCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAchTransactionResponsePrefundingVerdictSummaryEnum;
(function (ApiAchTransactionResponsePrefundingVerdictSummaryEnum) {
    ApiAchTransactionResponsePrefundingVerdictSummaryEnum["ACCEPTED"] = "ACCEPTED";
    ApiAchTransactionResponsePrefundingVerdictSummaryEnum["NOT_ACCEPTED"] = "NOT_ACCEPTED";
})(ApiAchTransactionResponsePrefundingVerdictSummaryEnum || (ApiAchTransactionResponsePrefundingVerdictSummaryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiActivityAuditActivityTypeEnum;
(function (ApiActivityAuditActivityTypeEnum) {
    ApiActivityAuditActivityTypeEnum["PUBLISHED"] = "PUBLISHED";
    ApiActivityAuditActivityTypeEnum["CONFIGURE"] = "CONFIGURE";
    ApiActivityAuditActivityTypeEnum["PREFUND_BLOCK"] = "PREFUND_BLOCK";
    ApiActivityAuditActivityTypeEnum["PREFUND_UNBLOCK"] = "PREFUND_UNBLOCK";
    ApiActivityAuditActivityTypeEnum["SUSPEND"] = "SUSPEND";
    ApiActivityAuditActivityTypeEnum["UNSUSPEND"] = "UNSUSPEND";
    ApiActivityAuditActivityTypeEnum["REPORT_REQUEST"] = "REPORT_REQUEST";
    ApiActivityAuditActivityTypeEnum["REPORT_SENT"] = "REPORT_SENT";
    ApiActivityAuditActivityTypeEnum["KYC_SUCCESS"] = "KYC_SUCCESS";
    ApiActivityAuditActivityTypeEnum["KYC_FAIL"] = "KYC_FAIL";
    ApiActivityAuditActivityTypeEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ApiActivityAuditActivityTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiActivityAuditActivityTypeEnum["CREDIT"] = "CREDIT";
    ApiActivityAuditActivityTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiActivityAuditActivityTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiActivityAuditActivityTypeEnum["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    ApiActivityAuditActivityTypeEnum["BANK_ACCOUNT_BLACKLIST"] = "BANK_ACCOUNT_BLACKLIST";
    ApiActivityAuditActivityTypeEnum["BANK_ACCOUNT_REMOVE_BLACKLIST"] = "BANK_ACCOUNT_REMOVE_BLACKLIST";
    ApiActivityAuditActivityTypeEnum["BAKKT_CARD_KYC_SUCCESS"] = "BAKKT_CARD_KYC_SUCCESS";
    ApiActivityAuditActivityTypeEnum["BAKKT_CARD_KYC_PENDING"] = "BAKKT_CARD_KYC_PENDING";
    ApiActivityAuditActivityTypeEnum["BAKKT_CARD_KYC_FAIL"] = "BAKKT_CARD_KYC_FAIL";
    ApiActivityAuditActivityTypeEnum["LINK_LOYALTY_ACCOUNT"] = "LINK_LOYALTY_ACCOUNT";
    ApiActivityAuditActivityTypeEnum["UNLINK_LOYALTY_ACCOUNT"] = "UNLINK_LOYALTY_ACCOUNT";
    ApiActivityAuditActivityTypeEnum["LINK_LOYALTY_ACCOUNT_FAILED"] = "LINK_LOYALTY_ACCOUNT_FAILED";
    ApiActivityAuditActivityTypeEnum["DEBIT_CARD_BLACKLIST"] = "DEBIT_CARD_BLACKLIST";
    ApiActivityAuditActivityTypeEnum["DEBIT_CARD_REMOVE_BLACKLIST"] = "DEBIT_CARD_REMOVE_BLACKLIST";
    ApiActivityAuditActivityTypeEnum["BANK_IDENTITY_CHECK"] = "BANK_IDENTITY_CHECK";
    ApiActivityAuditActivityTypeEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiActivityAuditActivityTypeEnum["CLOSED"] = "CLOSED";
    ApiActivityAuditActivityTypeEnum["LIQUIDATE_REQUESTED"] = "LIQUIDATE_REQUESTED";
    ApiActivityAuditActivityTypeEnum["LIQUIDATE_REMOVE_REQUESTED"] = "LIQUIDATE_REMOVE_REQUESTED";
    ApiActivityAuditActivityTypeEnum["PARTY_WHITELIST"] = "PARTY_WHITELIST";
    ApiActivityAuditActivityTypeEnum["PARTY_REMOVE_WHITELIST"] = "PARTY_REMOVE_WHITELIST";
    ApiActivityAuditActivityTypeEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiActivityAuditActivityTypeEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiActivityAuditActivityTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiActivityAuditActivityTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiActivityAuditActivityTypeEnum["PII_UPDATE"] = "PII_UPDATE";
})(ApiActivityAuditActivityTypeEnum || (ApiActivityAuditActivityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiActivityAuditEntityTypeEnum;
(function (ApiActivityAuditEntityTypeEnum) {
    ApiActivityAuditEntityTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiActivityAuditEntityTypeEnum["ACCOUNT"] = "ACCOUNT";
    ApiActivityAuditEntityTypeEnum["GIFTCARD_BRANDS"] = "GIFTCARD_BRANDS";
    ApiActivityAuditEntityTypeEnum["LOYALTY"] = "LOYALTY";
    ApiActivityAuditEntityTypeEnum["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    ApiActivityAuditEntityTypeEnum["DEBIT_CARD"] = "DEBIT_CARD";
    ApiActivityAuditEntityTypeEnum["OTHER"] = "OTHER";
})(ApiActivityAuditEntityTypeEnum || (ApiActivityAuditEntityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAddressCountryEnum;
(function (ApiAddressCountryEnum) {
    ApiAddressCountryEnum["AND"] = "AND";
    ApiAddressCountryEnum["ARE"] = "ARE";
    ApiAddressCountryEnum["AFG"] = "AFG";
    ApiAddressCountryEnum["ATG"] = "ATG";
    ApiAddressCountryEnum["AIA"] = "AIA";
    ApiAddressCountryEnum["ALB"] = "ALB";
    ApiAddressCountryEnum["ARM"] = "ARM";
    ApiAddressCountryEnum["AGO"] = "AGO";
    ApiAddressCountryEnum["ATA"] = "ATA";
    ApiAddressCountryEnum["ARG"] = "ARG";
    ApiAddressCountryEnum["ASM"] = "ASM";
    ApiAddressCountryEnum["AUT"] = "AUT";
    ApiAddressCountryEnum["AUS"] = "AUS";
    ApiAddressCountryEnum["ABW"] = "ABW";
    ApiAddressCountryEnum["ALA"] = "ALA";
    ApiAddressCountryEnum["AZE"] = "AZE";
    ApiAddressCountryEnum["BIH"] = "BIH";
    ApiAddressCountryEnum["BRB"] = "BRB";
    ApiAddressCountryEnum["BGD"] = "BGD";
    ApiAddressCountryEnum["BEL"] = "BEL";
    ApiAddressCountryEnum["BFA"] = "BFA";
    ApiAddressCountryEnum["BGR"] = "BGR";
    ApiAddressCountryEnum["BHR"] = "BHR";
    ApiAddressCountryEnum["BDI"] = "BDI";
    ApiAddressCountryEnum["BEN"] = "BEN";
    ApiAddressCountryEnum["BLM"] = "BLM";
    ApiAddressCountryEnum["BMU"] = "BMU";
    ApiAddressCountryEnum["BRN"] = "BRN";
    ApiAddressCountryEnum["BOL"] = "BOL";
    ApiAddressCountryEnum["BES"] = "BES";
    ApiAddressCountryEnum["BRA"] = "BRA";
    ApiAddressCountryEnum["BHS"] = "BHS";
    ApiAddressCountryEnum["BTN"] = "BTN";
    ApiAddressCountryEnum["BVT"] = "BVT";
    ApiAddressCountryEnum["BWA"] = "BWA";
    ApiAddressCountryEnum["BLR"] = "BLR";
    ApiAddressCountryEnum["BLZ"] = "BLZ";
    ApiAddressCountryEnum["CAN"] = "CAN";
    ApiAddressCountryEnum["CCK"] = "CCK";
    ApiAddressCountryEnum["COD"] = "COD";
    ApiAddressCountryEnum["CAF"] = "CAF";
    ApiAddressCountryEnum["COG"] = "COG";
    ApiAddressCountryEnum["CHE"] = "CHE";
    ApiAddressCountryEnum["CIV"] = "CIV";
    ApiAddressCountryEnum["COK"] = "COK";
    ApiAddressCountryEnum["CHL"] = "CHL";
    ApiAddressCountryEnum["CMR"] = "CMR";
    ApiAddressCountryEnum["CHN"] = "CHN";
    ApiAddressCountryEnum["COL"] = "COL";
    ApiAddressCountryEnum["CRI"] = "CRI";
    ApiAddressCountryEnum["CUB"] = "CUB";
    ApiAddressCountryEnum["CPV"] = "CPV";
    ApiAddressCountryEnum["CUW"] = "CUW";
    ApiAddressCountryEnum["CXR"] = "CXR";
    ApiAddressCountryEnum["CYP"] = "CYP";
    ApiAddressCountryEnum["CZE"] = "CZE";
    ApiAddressCountryEnum["DEU"] = "DEU";
    ApiAddressCountryEnum["DJI"] = "DJI";
    ApiAddressCountryEnum["DNK"] = "DNK";
    ApiAddressCountryEnum["DMA"] = "DMA";
    ApiAddressCountryEnum["DOM"] = "DOM";
    ApiAddressCountryEnum["DZA"] = "DZA";
    ApiAddressCountryEnum["ECU"] = "ECU";
    ApiAddressCountryEnum["EST"] = "EST";
    ApiAddressCountryEnum["EGY"] = "EGY";
    ApiAddressCountryEnum["ESH"] = "ESH";
    ApiAddressCountryEnum["ERI"] = "ERI";
    ApiAddressCountryEnum["ESP"] = "ESP";
    ApiAddressCountryEnum["ETH"] = "ETH";
    ApiAddressCountryEnum["FIN"] = "FIN";
    ApiAddressCountryEnum["FJI"] = "FJI";
    ApiAddressCountryEnum["FLK"] = "FLK";
    ApiAddressCountryEnum["FSM"] = "FSM";
    ApiAddressCountryEnum["FRO"] = "FRO";
    ApiAddressCountryEnum["FRA"] = "FRA";
    ApiAddressCountryEnum["GAB"] = "GAB";
    ApiAddressCountryEnum["GBR"] = "GBR";
    ApiAddressCountryEnum["GRD"] = "GRD";
    ApiAddressCountryEnum["GEO"] = "GEO";
    ApiAddressCountryEnum["GUF"] = "GUF";
    ApiAddressCountryEnum["GGY"] = "GGY";
    ApiAddressCountryEnum["GHA"] = "GHA";
    ApiAddressCountryEnum["GIB"] = "GIB";
    ApiAddressCountryEnum["GRL"] = "GRL";
    ApiAddressCountryEnum["GMB"] = "GMB";
    ApiAddressCountryEnum["GIN"] = "GIN";
    ApiAddressCountryEnum["GLP"] = "GLP";
    ApiAddressCountryEnum["GNQ"] = "GNQ";
    ApiAddressCountryEnum["GRC"] = "GRC";
    ApiAddressCountryEnum["SGS"] = "SGS";
    ApiAddressCountryEnum["GTM"] = "GTM";
    ApiAddressCountryEnum["GUM"] = "GUM";
    ApiAddressCountryEnum["GNB"] = "GNB";
    ApiAddressCountryEnum["GUY"] = "GUY";
    ApiAddressCountryEnum["HKG"] = "HKG";
    ApiAddressCountryEnum["HMD"] = "HMD";
    ApiAddressCountryEnum["HND"] = "HND";
    ApiAddressCountryEnum["HRV"] = "HRV";
    ApiAddressCountryEnum["HTI"] = "HTI";
    ApiAddressCountryEnum["HUN"] = "HUN";
    ApiAddressCountryEnum["IDN"] = "IDN";
    ApiAddressCountryEnum["IRL"] = "IRL";
    ApiAddressCountryEnum["ISR"] = "ISR";
    ApiAddressCountryEnum["IMN"] = "IMN";
    ApiAddressCountryEnum["IND"] = "IND";
    ApiAddressCountryEnum["IOT"] = "IOT";
    ApiAddressCountryEnum["IRQ"] = "IRQ";
    ApiAddressCountryEnum["IRN"] = "IRN";
    ApiAddressCountryEnum["ISL"] = "ISL";
    ApiAddressCountryEnum["ITA"] = "ITA";
    ApiAddressCountryEnum["JEY"] = "JEY";
    ApiAddressCountryEnum["JAM"] = "JAM";
    ApiAddressCountryEnum["JOR"] = "JOR";
    ApiAddressCountryEnum["JPN"] = "JPN";
    ApiAddressCountryEnum["KEN"] = "KEN";
    ApiAddressCountryEnum["KGZ"] = "KGZ";
    ApiAddressCountryEnum["KHM"] = "KHM";
    ApiAddressCountryEnum["KIR"] = "KIR";
    ApiAddressCountryEnum["COM"] = "COM";
    ApiAddressCountryEnum["KNA"] = "KNA";
    ApiAddressCountryEnum["PRK"] = "PRK";
    ApiAddressCountryEnum["KOR"] = "KOR";
    ApiAddressCountryEnum["KWT"] = "KWT";
    ApiAddressCountryEnum["CYM"] = "CYM";
    ApiAddressCountryEnum["KAZ"] = "KAZ";
    ApiAddressCountryEnum["LAO"] = "LAO";
    ApiAddressCountryEnum["LBN"] = "LBN";
    ApiAddressCountryEnum["LCA"] = "LCA";
    ApiAddressCountryEnum["LIE"] = "LIE";
    ApiAddressCountryEnum["LKA"] = "LKA";
    ApiAddressCountryEnum["LBR"] = "LBR";
    ApiAddressCountryEnum["LSO"] = "LSO";
    ApiAddressCountryEnum["LTU"] = "LTU";
    ApiAddressCountryEnum["LUX"] = "LUX";
    ApiAddressCountryEnum["LVA"] = "LVA";
    ApiAddressCountryEnum["LBY"] = "LBY";
    ApiAddressCountryEnum["MAR"] = "MAR";
    ApiAddressCountryEnum["MCO"] = "MCO";
    ApiAddressCountryEnum["MDA"] = "MDA";
    ApiAddressCountryEnum["MNE"] = "MNE";
    ApiAddressCountryEnum["MAF"] = "MAF";
    ApiAddressCountryEnum["MDG"] = "MDG";
    ApiAddressCountryEnum["MHL"] = "MHL";
    ApiAddressCountryEnum["MKD"] = "MKD";
    ApiAddressCountryEnum["MLI"] = "MLI";
    ApiAddressCountryEnum["MMR"] = "MMR";
    ApiAddressCountryEnum["MNG"] = "MNG";
    ApiAddressCountryEnum["MAC"] = "MAC";
    ApiAddressCountryEnum["MNP"] = "MNP";
    ApiAddressCountryEnum["MTQ"] = "MTQ";
    ApiAddressCountryEnum["MRT"] = "MRT";
    ApiAddressCountryEnum["MSR"] = "MSR";
    ApiAddressCountryEnum["MLT"] = "MLT";
    ApiAddressCountryEnum["MUS"] = "MUS";
    ApiAddressCountryEnum["MDV"] = "MDV";
    ApiAddressCountryEnum["MWI"] = "MWI";
    ApiAddressCountryEnum["MEX"] = "MEX";
    ApiAddressCountryEnum["MYS"] = "MYS";
    ApiAddressCountryEnum["MOZ"] = "MOZ";
    ApiAddressCountryEnum["NAM"] = "NAM";
    ApiAddressCountryEnum["NCL"] = "NCL";
    ApiAddressCountryEnum["NER"] = "NER";
    ApiAddressCountryEnum["NFK"] = "NFK";
    ApiAddressCountryEnum["NGA"] = "NGA";
    ApiAddressCountryEnum["NIC"] = "NIC";
    ApiAddressCountryEnum["NLD"] = "NLD";
    ApiAddressCountryEnum["NOR"] = "NOR";
    ApiAddressCountryEnum["NPL"] = "NPL";
    ApiAddressCountryEnum["NRU"] = "NRU";
    ApiAddressCountryEnum["NIU"] = "NIU";
    ApiAddressCountryEnum["NZL"] = "NZL";
    ApiAddressCountryEnum["OMN"] = "OMN";
    ApiAddressCountryEnum["PAN"] = "PAN";
    ApiAddressCountryEnum["PER"] = "PER";
    ApiAddressCountryEnum["PYF"] = "PYF";
    ApiAddressCountryEnum["PNG"] = "PNG";
    ApiAddressCountryEnum["PHL"] = "PHL";
    ApiAddressCountryEnum["PAK"] = "PAK";
    ApiAddressCountryEnum["POL"] = "POL";
    ApiAddressCountryEnum["SPM"] = "SPM";
    ApiAddressCountryEnum["PCN"] = "PCN";
    ApiAddressCountryEnum["PRI"] = "PRI";
    ApiAddressCountryEnum["PSE"] = "PSE";
    ApiAddressCountryEnum["PRT"] = "PRT";
    ApiAddressCountryEnum["PLW"] = "PLW";
    ApiAddressCountryEnum["PRY"] = "PRY";
    ApiAddressCountryEnum["QAT"] = "QAT";
    ApiAddressCountryEnum["REU"] = "REU";
    ApiAddressCountryEnum["ROU"] = "ROU";
    ApiAddressCountryEnum["SRB"] = "SRB";
    ApiAddressCountryEnum["RUS"] = "RUS";
    ApiAddressCountryEnum["RWA"] = "RWA";
    ApiAddressCountryEnum["SAU"] = "SAU";
    ApiAddressCountryEnum["SLB"] = "SLB";
    ApiAddressCountryEnum["SYC"] = "SYC";
    ApiAddressCountryEnum["SDN"] = "SDN";
    ApiAddressCountryEnum["SWE"] = "SWE";
    ApiAddressCountryEnum["SGP"] = "SGP";
    ApiAddressCountryEnum["SHN"] = "SHN";
    ApiAddressCountryEnum["SVN"] = "SVN";
    ApiAddressCountryEnum["SJM"] = "SJM";
    ApiAddressCountryEnum["SVK"] = "SVK";
    ApiAddressCountryEnum["SLE"] = "SLE";
    ApiAddressCountryEnum["SMR"] = "SMR";
    ApiAddressCountryEnum["SEN"] = "SEN";
    ApiAddressCountryEnum["SOM"] = "SOM";
    ApiAddressCountryEnum["SUR"] = "SUR";
    ApiAddressCountryEnum["SSD"] = "SSD";
    ApiAddressCountryEnum["STP"] = "STP";
    ApiAddressCountryEnum["SLV"] = "SLV";
    ApiAddressCountryEnum["SXM"] = "SXM";
    ApiAddressCountryEnum["SYR"] = "SYR";
    ApiAddressCountryEnum["SWZ"] = "SWZ";
    ApiAddressCountryEnum["TCA"] = "TCA";
    ApiAddressCountryEnum["TCD"] = "TCD";
    ApiAddressCountryEnum["ATF"] = "ATF";
    ApiAddressCountryEnum["TGO"] = "TGO";
    ApiAddressCountryEnum["THA"] = "THA";
    ApiAddressCountryEnum["TJK"] = "TJK";
    ApiAddressCountryEnum["TKL"] = "TKL";
    ApiAddressCountryEnum["TLS"] = "TLS";
    ApiAddressCountryEnum["TKM"] = "TKM";
    ApiAddressCountryEnum["TUN"] = "TUN";
    ApiAddressCountryEnum["TON"] = "TON";
    ApiAddressCountryEnum["TUR"] = "TUR";
    ApiAddressCountryEnum["TTO"] = "TTO";
    ApiAddressCountryEnum["TUV"] = "TUV";
    ApiAddressCountryEnum["TWN"] = "TWN";
    ApiAddressCountryEnum["TZA"] = "TZA";
    ApiAddressCountryEnum["UKR"] = "UKR";
    ApiAddressCountryEnum["UGA"] = "UGA";
    ApiAddressCountryEnum["UMI"] = "UMI";
    ApiAddressCountryEnum["USA"] = "USA";
    ApiAddressCountryEnum["URY"] = "URY";
    ApiAddressCountryEnum["UZB"] = "UZB";
    ApiAddressCountryEnum["VAT"] = "VAT";
    ApiAddressCountryEnum["VCT"] = "VCT";
    ApiAddressCountryEnum["VEN"] = "VEN";
    ApiAddressCountryEnum["VGB"] = "VGB";
    ApiAddressCountryEnum["VIR"] = "VIR";
    ApiAddressCountryEnum["VNM"] = "VNM";
    ApiAddressCountryEnum["VUT"] = "VUT";
    ApiAddressCountryEnum["WLF"] = "WLF";
    ApiAddressCountryEnum["WSM"] = "WSM";
    ApiAddressCountryEnum["YEM"] = "YEM";
    ApiAddressCountryEnum["MYT"] = "MYT";
    ApiAddressCountryEnum["ZAF"] = "ZAF";
    ApiAddressCountryEnum["ZMB"] = "ZMB";
    ApiAddressCountryEnum["ZWE"] = "ZWE";
})(ApiAddressCountryEnum || (ApiAddressCountryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAllowListItemAllowListItemSubtypeEnum;
(function (ApiAllowListItemAllowListItemSubtypeEnum) {
    ApiAllowListItemAllowListItemSubtypeEnum["EMAIL"] = "EMAIL";
    ApiAllowListItemAllowListItemSubtypeEnum["PHONE"] = "PHONE";
    ApiAllowListItemAllowListItemSubtypeEnum["PARTY_ID"] = "PARTY_ID";
    ApiAllowListItemAllowListItemSubtypeEnum["REGION"] = "REGION";
})(ApiAllowListItemAllowListItemSubtypeEnum || (ApiAllowListItemAllowListItemSubtypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAllowListItemAllowListItemTypeEnum;
(function (ApiAllowListItemAllowListItemTypeEnum) {
    ApiAllowListItemAllowListItemTypeEnum["IDENTIFIER"] = "IDENTIFIER";
    ApiAllowListItemAllowListItemTypeEnum["ADDRESS"] = "ADDRESS";
    ApiAllowListItemAllowListItemTypeEnum["BATCH_P2P"] = "BATCH_P2P";
})(ApiAllowListItemAllowListItemTypeEnum || (ApiAllowListItemAllowListItemTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAllowListItemCurrencyEnum;
(function (ApiAllowListItemCurrencyEnum) {
    ApiAllowListItemCurrencyEnum["USD"] = "USD";
    ApiAllowListItemCurrencyEnum["BCH"] = "BCH";
    ApiAllowListItemCurrencyEnum["BTC"] = "BTC";
    ApiAllowListItemCurrencyEnum["DOGE"] = "DOGE";
    ApiAllowListItemCurrencyEnum["ETC"] = "ETC";
    ApiAllowListItemCurrencyEnum["ETH"] = "ETH";
    ApiAllowListItemCurrencyEnum["LTC"] = "LTC";
    ApiAllowListItemCurrencyEnum["SHIB"] = "SHIB";
    ApiAllowListItemCurrencyEnum["USDC"] = "USDC";
    ApiAllowListItemCurrencyEnum["CET"] = "CET";
    ApiAllowListItemCurrencyEnum["LXX"] = "LXX";
    ApiAllowListItemCurrencyEnum["LCP"] = "LCP";
    ApiAllowListItemCurrencyEnum["LWR"] = "LWR";
    ApiAllowListItemCurrencyEnum["LMO"] = "LMO";
    ApiAllowListItemCurrencyEnum["LKO"] = "LKO";
    ApiAllowListItemCurrencyEnum["LED"] = "LED";
})(ApiAllowListItemCurrencyEnum || (ApiAllowListItemCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAmountCurrencyEnum;
(function (ApiAmountCurrencyEnum) {
    ApiAmountCurrencyEnum["USD"] = "USD";
    ApiAmountCurrencyEnum["BCH"] = "BCH";
    ApiAmountCurrencyEnum["BTC"] = "BTC";
    ApiAmountCurrencyEnum["DOGE"] = "DOGE";
    ApiAmountCurrencyEnum["ETC"] = "ETC";
    ApiAmountCurrencyEnum["ETH"] = "ETH";
    ApiAmountCurrencyEnum["LTC"] = "LTC";
    ApiAmountCurrencyEnum["SHIB"] = "SHIB";
    ApiAmountCurrencyEnum["USDC"] = "USDC";
    ApiAmountCurrencyEnum["CET"] = "CET";
    ApiAmountCurrencyEnum["LXX"] = "LXX";
    ApiAmountCurrencyEnum["LCP"] = "LCP";
    ApiAmountCurrencyEnum["LWR"] = "LWR";
    ApiAmountCurrencyEnum["LMO"] = "LMO";
    ApiAmountCurrencyEnum["LKO"] = "LKO";
    ApiAmountCurrencyEnum["LED"] = "LED";
})(ApiAmountCurrencyEnum || (ApiAmountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivityDetailsAssetTypeEnum;
(function (ApiAssetActivityDetailsAssetTypeEnum) {
    ApiAssetActivityDetailsAssetTypeEnum["CASH"] = "CASH";
    ApiAssetActivityDetailsAssetTypeEnum["CRYPTO"] = "CRYPTO";
    ApiAssetActivityDetailsAssetTypeEnum["REWARDS"] = "REWARDS";
    ApiAssetActivityDetailsAssetTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    ApiAssetActivityDetailsAssetTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    ApiAssetActivityDetailsAssetTypeEnum["DEBIT_CARD"] = "DEBIT_CARD";
})(ApiAssetActivityDetailsAssetTypeEnum || (ApiAssetActivityDetailsAssetTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivityDetailsCostBasisCurrencyEnum;
(function (ApiAssetActivityDetailsCostBasisCurrencyEnum) {
    ApiAssetActivityDetailsCostBasisCurrencyEnum["USD"] = "USD";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["BCH"] = "BCH";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["BTC"] = "BTC";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["DOGE"] = "DOGE";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["ETC"] = "ETC";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["ETH"] = "ETH";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LTC"] = "LTC";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["SHIB"] = "SHIB";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["USDC"] = "USDC";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["CET"] = "CET";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LXX"] = "LXX";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LCP"] = "LCP";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LWR"] = "LWR";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LMO"] = "LMO";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LKO"] = "LKO";
    ApiAssetActivityDetailsCostBasisCurrencyEnum["LED"] = "LED";
})(ApiAssetActivityDetailsCostBasisCurrencyEnum || (ApiAssetActivityDetailsCostBasisCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivityDetailsDestinationCurrencyEnum;
(function (ApiAssetActivityDetailsDestinationCurrencyEnum) {
    ApiAssetActivityDetailsDestinationCurrencyEnum["USD"] = "USD";
    ApiAssetActivityDetailsDestinationCurrencyEnum["BCH"] = "BCH";
    ApiAssetActivityDetailsDestinationCurrencyEnum["BTC"] = "BTC";
    ApiAssetActivityDetailsDestinationCurrencyEnum["DOGE"] = "DOGE";
    ApiAssetActivityDetailsDestinationCurrencyEnum["ETC"] = "ETC";
    ApiAssetActivityDetailsDestinationCurrencyEnum["ETH"] = "ETH";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LTC"] = "LTC";
    ApiAssetActivityDetailsDestinationCurrencyEnum["SHIB"] = "SHIB";
    ApiAssetActivityDetailsDestinationCurrencyEnum["USDC"] = "USDC";
    ApiAssetActivityDetailsDestinationCurrencyEnum["CET"] = "CET";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LXX"] = "LXX";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LCP"] = "LCP";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LWR"] = "LWR";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LMO"] = "LMO";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LKO"] = "LKO";
    ApiAssetActivityDetailsDestinationCurrencyEnum["LED"] = "LED";
})(ApiAssetActivityDetailsDestinationCurrencyEnum || (ApiAssetActivityDetailsDestinationCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivityDetailsSourceCurrencyEnum;
(function (ApiAssetActivityDetailsSourceCurrencyEnum) {
    ApiAssetActivityDetailsSourceCurrencyEnum["USD"] = "USD";
    ApiAssetActivityDetailsSourceCurrencyEnum["BCH"] = "BCH";
    ApiAssetActivityDetailsSourceCurrencyEnum["BTC"] = "BTC";
    ApiAssetActivityDetailsSourceCurrencyEnum["DOGE"] = "DOGE";
    ApiAssetActivityDetailsSourceCurrencyEnum["ETC"] = "ETC";
    ApiAssetActivityDetailsSourceCurrencyEnum["ETH"] = "ETH";
    ApiAssetActivityDetailsSourceCurrencyEnum["LTC"] = "LTC";
    ApiAssetActivityDetailsSourceCurrencyEnum["SHIB"] = "SHIB";
    ApiAssetActivityDetailsSourceCurrencyEnum["USDC"] = "USDC";
    ApiAssetActivityDetailsSourceCurrencyEnum["CET"] = "CET";
    ApiAssetActivityDetailsSourceCurrencyEnum["LXX"] = "LXX";
    ApiAssetActivityDetailsSourceCurrencyEnum["LCP"] = "LCP";
    ApiAssetActivityDetailsSourceCurrencyEnum["LWR"] = "LWR";
    ApiAssetActivityDetailsSourceCurrencyEnum["LMO"] = "LMO";
    ApiAssetActivityDetailsSourceCurrencyEnum["LKO"] = "LKO";
    ApiAssetActivityDetailsSourceCurrencyEnum["LED"] = "LED";
})(ApiAssetActivityDetailsSourceCurrencyEnum || (ApiAssetActivityDetailsSourceCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivityDetailsStatusEnum;
(function (ApiAssetActivityDetailsStatusEnum) {
    ApiAssetActivityDetailsStatusEnum["CANCELED"] = "CANCELED";
    ApiAssetActivityDetailsStatusEnum["COMPLETE"] = "COMPLETE";
    ApiAssetActivityDetailsStatusEnum["EXPIRED"] = "EXPIRED";
    ApiAssetActivityDetailsStatusEnum["PENDING"] = "PENDING";
    ApiAssetActivityDetailsStatusEnum["REJECTED"] = "REJECTED";
    ApiAssetActivityDetailsStatusEnum["REPLACED"] = "REPLACED";
    ApiAssetActivityDetailsStatusEnum["FAILED"] = "FAILED";
    ApiAssetActivityDetailsStatusEnum["PROVISIONAL"] = "PROVISIONAL";
})(ApiAssetActivityDetailsStatusEnum || (ApiAssetActivityDetailsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivityDetailsTypeEnum;
(function (ApiAssetActivityDetailsTypeEnum) {
    ApiAssetActivityDetailsTypeEnum["ADD"] = "ADD";
    ApiAssetActivityDetailsTypeEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    ApiAssetActivityDetailsTypeEnum["BUY"] = "BUY";
    ApiAssetActivityDetailsTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiAssetActivityDetailsTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiAssetActivityDetailsTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiAssetActivityDetailsTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiAssetActivityDetailsTypeEnum["CREDIT"] = "CREDIT";
    ApiAssetActivityDetailsTypeEnum["CONVERT"] = "CONVERT";
    ApiAssetActivityDetailsTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiAssetActivityDetailsTypeEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    ApiAssetActivityDetailsTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiAssetActivityDetailsTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiAssetActivityDetailsTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiAssetActivityDetailsTypeEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    ApiAssetActivityDetailsTypeEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    ApiAssetActivityDetailsTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiAssetActivityDetailsTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiAssetActivityDetailsTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiAssetActivityDetailsTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiAssetActivityDetailsTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiAssetActivityDetailsTypeEnum["FREEZE"] = "FREEZE";
    ApiAssetActivityDetailsTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiAssetActivityDetailsTypeEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    ApiAssetActivityDetailsTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiAssetActivityDetailsTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiAssetActivityDetailsTypeEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    ApiAssetActivityDetailsTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiAssetActivityDetailsTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiAssetActivityDetailsTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiAssetActivityDetailsTypeEnum["LINK"] = "LINK";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiAssetActivityDetailsTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiAssetActivityDetailsTypeEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    ApiAssetActivityDetailsTypeEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    ApiAssetActivityDetailsTypeEnum["PAYOUT"] = "PAYOUT";
    ApiAssetActivityDetailsTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiAssetActivityDetailsTypeEnum["RECEIVE"] = "RECEIVE";
    ApiAssetActivityDetailsTypeEnum["REDEEM"] = "REDEEM";
    ApiAssetActivityDetailsTypeEnum["REMOVE"] = "REMOVE";
    ApiAssetActivityDetailsTypeEnum["RESTORE"] = "RESTORE";
    ApiAssetActivityDetailsTypeEnum["REVERSE"] = "REVERSE";
    ApiAssetActivityDetailsTypeEnum["SELL"] = "SELL";
    ApiAssetActivityDetailsTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiAssetActivityDetailsTypeEnum["SEND"] = "SEND";
    ApiAssetActivityDetailsTypeEnum["TERMINATE"] = "TERMINATE";
    ApiAssetActivityDetailsTypeEnum["UNFREEZE"] = "UNFREEZE";
    ApiAssetActivityDetailsTypeEnum["UNLINK"] = "UNLINK";
    ApiAssetActivityDetailsTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    ApiAssetActivityDetailsTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    ApiAssetActivityDetailsTypeEnum["WIRE_LINK"] = "WIRE_LINK";
    ApiAssetActivityDetailsTypeEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    ApiAssetActivityDetailsTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiAssetActivityDetailsTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiAssetActivityDetailsTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiAssetActivityDetailsTypeEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    ApiAssetActivityDetailsTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiAssetActivityDetailsTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    ApiAssetActivityDetailsTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiAssetActivityDetailsTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiAssetActivityDetailsTypeEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    ApiAssetActivityDetailsTypeEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    ApiAssetActivityDetailsTypeEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(ApiAssetActivityDetailsTypeEnum || (ApiAssetActivityDetailsTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivitySummaryDeclineReasonEnum;
(function (ApiAssetActivitySummaryDeclineReasonEnum) {
    ApiAssetActivitySummaryDeclineReasonEnum["ACCOUNT_NOT_FOUND"] = "ACCOUNT_NOT_FOUND";
    ApiAssetActivitySummaryDeclineReasonEnum["FUNDING_PREFERENCE_NOT_FOUND"] = "FUNDING_PREFERENCE_NOT_FOUND";
    ApiAssetActivitySummaryDeclineReasonEnum["CARD_INVALID_STATUS"] = "CARD_INVALID_STATUS";
    ApiAssetActivitySummaryDeclineReasonEnum["CARD_NOT_FOUND"] = "CARD_NOT_FOUND";
    ApiAssetActivitySummaryDeclineReasonEnum["CARD_NOT_ACTIVATED"] = "CARD_NOT_ACTIVATED";
    ApiAssetActivitySummaryDeclineReasonEnum["DAILY_LIMIT_EXCEEDED"] = "DAILY_LIMIT_EXCEEDED";
    ApiAssetActivitySummaryDeclineReasonEnum["MONTHLY_LIMIT_EXCEEDED"] = "MONTHLY_LIMIT_EXCEEDED";
    ApiAssetActivitySummaryDeclineReasonEnum["OVER_LIMIT"] = "OVER_LIMIT";
    ApiAssetActivitySummaryDeclineReasonEnum["NOT_SUFFICIENT_FUNDS"] = "NOT_SUFFICIENT_FUNDS";
    ApiAssetActivitySummaryDeclineReasonEnum["SUSPECTED_FRAUD"] = "SUSPECTED_FRAUD";
    ApiAssetActivitySummaryDeclineReasonEnum["UNSUPPORTED_OPERATION"] = "UNSUPPORTED_OPERATION";
    ApiAssetActivitySummaryDeclineReasonEnum["CARD_ISSUER_PARTY_NOT_FOUND"] = "CARD_ISSUER_PARTY_NOT_FOUND";
    ApiAssetActivitySummaryDeclineReasonEnum["CARD_ISSUER_PARTY_INVALID_STATUS"] = "CARD_ISSUER_PARTY_INVALID_STATUS";
    ApiAssetActivitySummaryDeclineReasonEnum["PARTY_NOT_FOUND"] = "PARTY_NOT_FOUND";
    ApiAssetActivitySummaryDeclineReasonEnum["PARTY_INVALID_STATUS"] = "PARTY_INVALID_STATUS";
    ApiAssetActivitySummaryDeclineReasonEnum["CURRENCY_MISMATCH"] = "CURRENCY_MISMATCH";
    ApiAssetActivitySummaryDeclineReasonEnum["DATA_MISMATCH"] = "DATA_MISMATCH";
    ApiAssetActivitySummaryDeclineReasonEnum["GENERIC_PAYMENT_PROCESSING_ERROR"] = "GENERIC_PAYMENT_PROCESSING_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["PRECONDITION_NOT_MET"] = "PRECONDITION_NOT_MET";
    ApiAssetActivitySummaryDeclineReasonEnum["INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT"] = "INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT";
    ApiAssetActivitySummaryDeclineReasonEnum["BLACKLISTED_DEBIT_CARD"] = "BLACKLISTED_DEBIT_CARD";
    ApiAssetActivitySummaryDeclineReasonEnum["DEBIT_CARD_BIN_DENY_LISTED"] = "DEBIT_CARD_BIN_DENY_LISTED";
    ApiAssetActivitySummaryDeclineReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiAssetActivitySummaryDeclineReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiAssetActivitySummaryDeclineReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiAssetActivitySummaryDeclineReasonEnum["BLACKLISTED_MERCHANT_CATEGORY"] = "BLACKLISTED_MERCHANT_CATEGORY";
    ApiAssetActivitySummaryDeclineReasonEnum["BLACKLISTED_BANK_ACCOUNT"] = "BLACKLISTED_BANK_ACCOUNT";
    ApiAssetActivitySummaryDeclineReasonEnum["BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED"] = "BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED";
    ApiAssetActivitySummaryDeclineReasonEnum["TRANSACTION_FAILED"] = "TRANSACTION_FAILED";
    ApiAssetActivitySummaryDeclineReasonEnum["REAUTHENTICATION_REQUIRED"] = "REAUTHENTICATION_REQUIRED";
    ApiAssetActivitySummaryDeclineReasonEnum["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["PROCESSOR_DECLINED"] = "PROCESSOR_DECLINED";
    ApiAssetActivitySummaryDeclineReasonEnum["SETTLEMENT_DECLINED"] = "SETTLEMENT_DECLINED";
    ApiAssetActivitySummaryDeclineReasonEnum["GATEWAY_REJECTED"] = "GATEWAY_REJECTED";
    ApiAssetActivitySummaryDeclineReasonEnum["REWARDS_GATEWAY_REJECTED"] = "REWARDS_GATEWAY_REJECTED";
    ApiAssetActivitySummaryDeclineReasonEnum["AVS_ERROR"] = "AVS_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["CVV_ERROR"] = "CVV_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["AVS_GATEWAY_ERROR"] = "AVS_GATEWAY_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["AVS_VERIFICATION_FAILED"] = "AVS_VERIFICATION_FAILED";
    ApiAssetActivitySummaryDeclineReasonEnum["CVV_VERIFICATION_FAILED"] = "CVV_VERIFICATION_FAILED";
    ApiAssetActivitySummaryDeclineReasonEnum["AMOUNT_VERIFICATION_FAILED"] = "AMOUNT_VERIFICATION_FAILED";
    ApiAssetActivitySummaryDeclineReasonEnum["DEBIT_CARD_NOT_FOUND"] = "DEBIT_CARD_NOT_FOUND";
    ApiAssetActivitySummaryDeclineReasonEnum["SDK_ERROR"] = "SDK_ERROR";
    ApiAssetActivitySummaryDeclineReasonEnum["PARTY_TERMS_AND_CONDITIONS_EXPIRED"] = "PARTY_TERMS_AND_CONDITIONS_EXPIRED";
    ApiAssetActivitySummaryDeclineReasonEnum["DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH"] = "DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH";
    ApiAssetActivitySummaryDeclineReasonEnum["PARTY_INVALID_LEVEL"] = "PARTY_INVALID_LEVEL";
    ApiAssetActivitySummaryDeclineReasonEnum["MISSING_WIRE_WITHDRAW_DETAILS"] = "MISSING_WIRE_WITHDRAW_DETAILS";
    ApiAssetActivitySummaryDeclineReasonEnum["INVALID_WIRE_ROUTING_NUMBER"] = "INVALID_WIRE_ROUTING_NUMBER";
    ApiAssetActivitySummaryDeclineReasonEnum["INVALID_WIRE_ACCOUNT_NUMBER"] = "INVALID_WIRE_ACCOUNT_NUMBER";
    ApiAssetActivitySummaryDeclineReasonEnum["INVALID_WIRE_BANK_NAME"] = "INVALID_WIRE_BANK_NAME";
    ApiAssetActivitySummaryDeclineReasonEnum["INVALID_WIRE_BANK_ADDRESS"] = "INVALID_WIRE_BANK_ADDRESS";
})(ApiAssetActivitySummaryDeclineReasonEnum || (ApiAssetActivitySummaryDeclineReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivitySummaryDerivedTypeEnum;
(function (ApiAssetActivitySummaryDerivedTypeEnum) {
    ApiAssetActivitySummaryDerivedTypeEnum["ADD"] = "ADD";
    ApiAssetActivitySummaryDerivedTypeEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    ApiAssetActivitySummaryDerivedTypeEnum["BUY"] = "BUY";
    ApiAssetActivitySummaryDerivedTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiAssetActivitySummaryDerivedTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiAssetActivitySummaryDerivedTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiAssetActivitySummaryDerivedTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["CREDIT"] = "CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["CONVERT"] = "CONVERT";
    ApiAssetActivitySummaryDerivedTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiAssetActivitySummaryDerivedTypeEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    ApiAssetActivitySummaryDerivedTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiAssetActivitySummaryDerivedTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiAssetActivitySummaryDerivedTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiAssetActivitySummaryDerivedTypeEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    ApiAssetActivitySummaryDerivedTypeEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    ApiAssetActivitySummaryDerivedTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiAssetActivitySummaryDerivedTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiAssetActivitySummaryDerivedTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiAssetActivitySummaryDerivedTypeEnum["FREEZE"] = "FREEZE";
    ApiAssetActivitySummaryDerivedTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiAssetActivitySummaryDerivedTypeEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    ApiAssetActivitySummaryDerivedTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiAssetActivitySummaryDerivedTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiAssetActivitySummaryDerivedTypeEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    ApiAssetActivitySummaryDerivedTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiAssetActivitySummaryDerivedTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiAssetActivitySummaryDerivedTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiAssetActivitySummaryDerivedTypeEnum["LINK"] = "LINK";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiAssetActivitySummaryDerivedTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiAssetActivitySummaryDerivedTypeEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    ApiAssetActivitySummaryDerivedTypeEnum["PAYOUT"] = "PAYOUT";
    ApiAssetActivitySummaryDerivedTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["RECEIVE"] = "RECEIVE";
    ApiAssetActivitySummaryDerivedTypeEnum["REDEEM"] = "REDEEM";
    ApiAssetActivitySummaryDerivedTypeEnum["REMOVE"] = "REMOVE";
    ApiAssetActivitySummaryDerivedTypeEnum["RESTORE"] = "RESTORE";
    ApiAssetActivitySummaryDerivedTypeEnum["REVERSE"] = "REVERSE";
    ApiAssetActivitySummaryDerivedTypeEnum["SELL"] = "SELL";
    ApiAssetActivitySummaryDerivedTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiAssetActivitySummaryDerivedTypeEnum["SEND"] = "SEND";
    ApiAssetActivitySummaryDerivedTypeEnum["TERMINATE"] = "TERMINATE";
    ApiAssetActivitySummaryDerivedTypeEnum["UNFREEZE"] = "UNFREEZE";
    ApiAssetActivitySummaryDerivedTypeEnum["UNLINK"] = "UNLINK";
    ApiAssetActivitySummaryDerivedTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    ApiAssetActivitySummaryDerivedTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    ApiAssetActivitySummaryDerivedTypeEnum["WIRE_LINK"] = "WIRE_LINK";
    ApiAssetActivitySummaryDerivedTypeEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    ApiAssetActivitySummaryDerivedTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiAssetActivitySummaryDerivedTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiAssetActivitySummaryDerivedTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiAssetActivitySummaryDerivedTypeEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    ApiAssetActivitySummaryDerivedTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    ApiAssetActivitySummaryDerivedTypeEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(ApiAssetActivitySummaryDerivedTypeEnum || (ApiAssetActivitySummaryDerivedTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivitySummaryDestinationCurrencyEnum;
(function (ApiAssetActivitySummaryDestinationCurrencyEnum) {
    ApiAssetActivitySummaryDestinationCurrencyEnum["USD"] = "USD";
    ApiAssetActivitySummaryDestinationCurrencyEnum["BCH"] = "BCH";
    ApiAssetActivitySummaryDestinationCurrencyEnum["BTC"] = "BTC";
    ApiAssetActivitySummaryDestinationCurrencyEnum["DOGE"] = "DOGE";
    ApiAssetActivitySummaryDestinationCurrencyEnum["ETC"] = "ETC";
    ApiAssetActivitySummaryDestinationCurrencyEnum["ETH"] = "ETH";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LTC"] = "LTC";
    ApiAssetActivitySummaryDestinationCurrencyEnum["SHIB"] = "SHIB";
    ApiAssetActivitySummaryDestinationCurrencyEnum["USDC"] = "USDC";
    ApiAssetActivitySummaryDestinationCurrencyEnum["CET"] = "CET";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LXX"] = "LXX";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LCP"] = "LCP";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LWR"] = "LWR";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LMO"] = "LMO";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LKO"] = "LKO";
    ApiAssetActivitySummaryDestinationCurrencyEnum["LED"] = "LED";
})(ApiAssetActivitySummaryDestinationCurrencyEnum || (ApiAssetActivitySummaryDestinationCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivitySummarySourceCurrencyEnum;
(function (ApiAssetActivitySummarySourceCurrencyEnum) {
    ApiAssetActivitySummarySourceCurrencyEnum["USD"] = "USD";
    ApiAssetActivitySummarySourceCurrencyEnum["BCH"] = "BCH";
    ApiAssetActivitySummarySourceCurrencyEnum["BTC"] = "BTC";
    ApiAssetActivitySummarySourceCurrencyEnum["DOGE"] = "DOGE";
    ApiAssetActivitySummarySourceCurrencyEnum["ETC"] = "ETC";
    ApiAssetActivitySummarySourceCurrencyEnum["ETH"] = "ETH";
    ApiAssetActivitySummarySourceCurrencyEnum["LTC"] = "LTC";
    ApiAssetActivitySummarySourceCurrencyEnum["SHIB"] = "SHIB";
    ApiAssetActivitySummarySourceCurrencyEnum["USDC"] = "USDC";
    ApiAssetActivitySummarySourceCurrencyEnum["CET"] = "CET";
    ApiAssetActivitySummarySourceCurrencyEnum["LXX"] = "LXX";
    ApiAssetActivitySummarySourceCurrencyEnum["LCP"] = "LCP";
    ApiAssetActivitySummarySourceCurrencyEnum["LWR"] = "LWR";
    ApiAssetActivitySummarySourceCurrencyEnum["LMO"] = "LMO";
    ApiAssetActivitySummarySourceCurrencyEnum["LKO"] = "LKO";
    ApiAssetActivitySummarySourceCurrencyEnum["LED"] = "LED";
})(ApiAssetActivitySummarySourceCurrencyEnum || (ApiAssetActivitySummarySourceCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivitySummaryStatusEnum;
(function (ApiAssetActivitySummaryStatusEnum) {
    ApiAssetActivitySummaryStatusEnum["CANCELED"] = "CANCELED";
    ApiAssetActivitySummaryStatusEnum["COMPLETE"] = "COMPLETE";
    ApiAssetActivitySummaryStatusEnum["EXPIRED"] = "EXPIRED";
    ApiAssetActivitySummaryStatusEnum["PENDING"] = "PENDING";
    ApiAssetActivitySummaryStatusEnum["REJECTED"] = "REJECTED";
    ApiAssetActivitySummaryStatusEnum["REPLACED"] = "REPLACED";
    ApiAssetActivitySummaryStatusEnum["REFUND"] = "REFUND";
    ApiAssetActivitySummaryStatusEnum["FAILED"] = "FAILED";
    ApiAssetActivitySummaryStatusEnum["PROVISIONAL"] = "PROVISIONAL";
    ApiAssetActivitySummaryStatusEnum["CAPTURED"] = "CAPTURED";
    ApiAssetActivitySummaryStatusEnum["PARTIAL_CAPTURED"] = "PARTIAL_CAPTURED";
    ApiAssetActivitySummaryStatusEnum["REFUNDED"] = "REFUNDED";
    ApiAssetActivitySummaryStatusEnum["REVERSED"] = "REVERSED";
    ApiAssetActivitySummaryStatusEnum["PARTIAL_REVERSED"] = "PARTIAL_REVERSED";
    ApiAssetActivitySummaryStatusEnum["AUTHORIZED"] = "AUTHORIZED";
    ApiAssetActivitySummaryStatusEnum["SETTLED"] = "SETTLED";
    ApiAssetActivitySummaryStatusEnum["DISPUTED"] = "DISPUTED";
    ApiAssetActivitySummaryStatusEnum["DISPUTE_CLOSED"] = "DISPUTE_CLOSED";
    ApiAssetActivitySummaryStatusEnum["DISPUTE_OPEN"] = "DISPUTE_OPEN";
})(ApiAssetActivitySummaryStatusEnum || (ApiAssetActivitySummaryStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiAssetActivitySummaryTypeEnum;
(function (ApiAssetActivitySummaryTypeEnum) {
    ApiAssetActivitySummaryTypeEnum["ADD"] = "ADD";
    ApiAssetActivitySummaryTypeEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    ApiAssetActivitySummaryTypeEnum["BUY"] = "BUY";
    ApiAssetActivitySummaryTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiAssetActivitySummaryTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiAssetActivitySummaryTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiAssetActivitySummaryTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiAssetActivitySummaryTypeEnum["CREDIT"] = "CREDIT";
    ApiAssetActivitySummaryTypeEnum["CONVERT"] = "CONVERT";
    ApiAssetActivitySummaryTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiAssetActivitySummaryTypeEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    ApiAssetActivitySummaryTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiAssetActivitySummaryTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiAssetActivitySummaryTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiAssetActivitySummaryTypeEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    ApiAssetActivitySummaryTypeEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    ApiAssetActivitySummaryTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiAssetActivitySummaryTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiAssetActivitySummaryTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiAssetActivitySummaryTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiAssetActivitySummaryTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiAssetActivitySummaryTypeEnum["FREEZE"] = "FREEZE";
    ApiAssetActivitySummaryTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiAssetActivitySummaryTypeEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    ApiAssetActivitySummaryTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiAssetActivitySummaryTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiAssetActivitySummaryTypeEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    ApiAssetActivitySummaryTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiAssetActivitySummaryTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiAssetActivitySummaryTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiAssetActivitySummaryTypeEnum["LINK"] = "LINK";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiAssetActivitySummaryTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiAssetActivitySummaryTypeEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    ApiAssetActivitySummaryTypeEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    ApiAssetActivitySummaryTypeEnum["PAYOUT"] = "PAYOUT";
    ApiAssetActivitySummaryTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiAssetActivitySummaryTypeEnum["RECEIVE"] = "RECEIVE";
    ApiAssetActivitySummaryTypeEnum["REDEEM"] = "REDEEM";
    ApiAssetActivitySummaryTypeEnum["REMOVE"] = "REMOVE";
    ApiAssetActivitySummaryTypeEnum["RESTORE"] = "RESTORE";
    ApiAssetActivitySummaryTypeEnum["REVERSE"] = "REVERSE";
    ApiAssetActivitySummaryTypeEnum["SELL"] = "SELL";
    ApiAssetActivitySummaryTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiAssetActivitySummaryTypeEnum["SEND"] = "SEND";
    ApiAssetActivitySummaryTypeEnum["TERMINATE"] = "TERMINATE";
    ApiAssetActivitySummaryTypeEnum["UNFREEZE"] = "UNFREEZE";
    ApiAssetActivitySummaryTypeEnum["UNLINK"] = "UNLINK";
    ApiAssetActivitySummaryTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    ApiAssetActivitySummaryTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    ApiAssetActivitySummaryTypeEnum["WIRE_LINK"] = "WIRE_LINK";
    ApiAssetActivitySummaryTypeEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    ApiAssetActivitySummaryTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiAssetActivitySummaryTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiAssetActivitySummaryTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiAssetActivitySummaryTypeEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    ApiAssetActivitySummaryTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiAssetActivitySummaryTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    ApiAssetActivitySummaryTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiAssetActivitySummaryTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiAssetActivitySummaryTypeEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    ApiAssetActivitySummaryTypeEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    ApiAssetActivitySummaryTypeEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(ApiAssetActivitySummaryTypeEnum || (ApiAssetActivitySummaryTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBakktCardTransactionDetailsPreferredCurrencyEnum;
(function (ApiBakktCardTransactionDetailsPreferredCurrencyEnum) {
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["USD"] = "USD";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["BCH"] = "BCH";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["BTC"] = "BTC";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["DOGE"] = "DOGE";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["ETC"] = "ETC";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["ETH"] = "ETH";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LTC"] = "LTC";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["SHIB"] = "SHIB";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["USDC"] = "USDC";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["CET"] = "CET";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LXX"] = "LXX";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LCP"] = "LCP";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LWR"] = "LWR";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LMO"] = "LMO";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LKO"] = "LKO";
    ApiBakktCardTransactionDetailsPreferredCurrencyEnum["LED"] = "LED";
})(ApiBakktCardTransactionDetailsPreferredCurrencyEnum || (ApiBakktCardTransactionDetailsPreferredCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBakktCardTransactionDetailsTransactionStatusEnum;
(function (ApiBakktCardTransactionDetailsTransactionStatusEnum) {
    ApiBakktCardTransactionDetailsTransactionStatusEnum["PENDING"] = "PENDING";
    ApiBakktCardTransactionDetailsTransactionStatusEnum["SETTLED"] = "SETTLED";
    ApiBakktCardTransactionDetailsTransactionStatusEnum["REVERSED"] = "REVERSED";
    ApiBakktCardTransactionDetailsTransactionStatusEnum["REFUNDED"] = "REFUNDED";
    ApiBakktCardTransactionDetailsTransactionStatusEnum["CHARGEBACK"] = "CHARGEBACK";
    ApiBakktCardTransactionDetailsTransactionStatusEnum["CHARGEBACK_REVERSED"] = "CHARGEBACK_REVERSED";
    ApiBakktCardTransactionDetailsTransactionStatusEnum["DECLINED"] = "DECLINED";
})(ApiBakktCardTransactionDetailsTransactionStatusEnum || (ApiBakktCardTransactionDetailsTransactionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBakktTransactionDetailsDisputeStatusEnum;
(function (ApiBakktTransactionDetailsDisputeStatusEnum) {
    ApiBakktTransactionDetailsDisputeStatusEnum["NEW"] = "NEW";
    ApiBakktTransactionDetailsDisputeStatusEnum["ESCALATED"] = "ESCALATED";
    ApiBakktTransactionDetailsDisputeStatusEnum["REJECTED"] = "REJECTED";
    ApiBakktTransactionDetailsDisputeStatusEnum["MERCHANT_REJECTED"] = "MERCHANT_REJECTED";
    ApiBakktTransactionDetailsDisputeStatusEnum["ACCEPTED"] = "ACCEPTED";
    ApiBakktTransactionDetailsDisputeStatusEnum["CANCELLED"] = "CANCELLED";
})(ApiBakktTransactionDetailsDisputeStatusEnum || (ApiBakktTransactionDetailsDisputeStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBakktTransactionDetailsTransactionStatusEnum;
(function (ApiBakktTransactionDetailsTransactionStatusEnum) {
    ApiBakktTransactionDetailsTransactionStatusEnum["AUTHORIZED"] = "AUTHORIZED";
    ApiBakktTransactionDetailsTransactionStatusEnum["DECLINED"] = "DECLINED";
    ApiBakktTransactionDetailsTransactionStatusEnum["CAPTURED"] = "CAPTURED";
    ApiBakktTransactionDetailsTransactionStatusEnum["DISPUTE_OPEN"] = "DISPUTE_OPEN";
    ApiBakktTransactionDetailsTransactionStatusEnum["REVERSED"] = "REVERSED";
    ApiBakktTransactionDetailsTransactionStatusEnum["CANCELED"] = "CANCELED";
    ApiBakktTransactionDetailsTransactionStatusEnum["SETTLED"] = "SETTLED";
    ApiBakktTransactionDetailsTransactionStatusEnum["DISPUTE_CLOSED"] = "DISPUTE_CLOSED";
    ApiBakktTransactionDetailsTransactionStatusEnum["DISPUTED"] = "DISPUTED";
    ApiBakktTransactionDetailsTransactionStatusEnum["PARTIAL_CAPTURED"] = "PARTIAL_CAPTURED";
    ApiBakktTransactionDetailsTransactionStatusEnum["PARTIAL_REVERSED"] = "PARTIAL_REVERSED";
})(ApiBakktTransactionDetailsTransactionStatusEnum || (ApiBakktTransactionDetailsTransactionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBankAccountCurrencyEnum;
(function (ApiBankAccountCurrencyEnum) {
    ApiBankAccountCurrencyEnum["USD"] = "USD";
    ApiBankAccountCurrencyEnum["BCH"] = "BCH";
    ApiBankAccountCurrencyEnum["BTC"] = "BTC";
    ApiBankAccountCurrencyEnum["DOGE"] = "DOGE";
    ApiBankAccountCurrencyEnum["ETC"] = "ETC";
    ApiBankAccountCurrencyEnum["ETH"] = "ETH";
    ApiBankAccountCurrencyEnum["LTC"] = "LTC";
    ApiBankAccountCurrencyEnum["SHIB"] = "SHIB";
    ApiBankAccountCurrencyEnum["USDC"] = "USDC";
    ApiBankAccountCurrencyEnum["CET"] = "CET";
    ApiBankAccountCurrencyEnum["LXX"] = "LXX";
    ApiBankAccountCurrencyEnum["LCP"] = "LCP";
    ApiBankAccountCurrencyEnum["LWR"] = "LWR";
    ApiBankAccountCurrencyEnum["LMO"] = "LMO";
    ApiBankAccountCurrencyEnum["LKO"] = "LKO";
    ApiBankAccountCurrencyEnum["LED"] = "LED";
})(ApiBankAccountCurrencyEnum || (ApiBankAccountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBankAccountKycStatusEnum;
(function (ApiBankAccountKycStatusEnum) {
    ApiBankAccountKycStatusEnum["SUCCESS"] = "SUCCESS";
    ApiBankAccountKycStatusEnum["FAILED"] = "FAILED";
    ApiBankAccountKycStatusEnum["PENDING"] = "PENDING";
})(ApiBankAccountKycStatusEnum || (ApiBankAccountKycStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBankAccountLinkStatusEnum;
(function (ApiBankAccountLinkStatusEnum) {
    ApiBankAccountLinkStatusEnum["CURRENT"] = "CURRENT";
    ApiBankAccountLinkStatusEnum["REFRESH_REQUIRED"] = "REFRESH_REQUIRED";
    ApiBankAccountLinkStatusEnum["PENDING_EXPIRATION"] = "PENDING_EXPIRATION";
})(ApiBankAccountLinkStatusEnum || (ApiBankAccountLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBankAccountStatusEnum;
(function (ApiBankAccountStatusEnum) {
    ApiBankAccountStatusEnum["ACTIVE"] = "ACTIVE";
    ApiBankAccountStatusEnum["PENDING"] = "PENDING";
    ApiBankAccountStatusEnum["SUSPENDED"] = "SUSPENDED";
})(ApiBankAccountStatusEnum || (ApiBankAccountStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBankAccountVerificationTypeEnum;
(function (ApiBankAccountVerificationTypeEnum) {
    ApiBankAccountVerificationTypeEnum["AUTOMATED_MICRODEPOSIT"] = "AUTOMATED_MICRODEPOSIT";
    ApiBankAccountVerificationTypeEnum["SAME_DAY_MICRODEPOSIT"] = "SAME_DAY_MICRODEPOSIT";
    ApiBankAccountVerificationTypeEnum["PROCESSOR_AUTOMATIC_VERIFICATION"] = "PROCESSOR_AUTOMATIC_VERIFICATION";
    ApiBankAccountVerificationTypeEnum["PROCESSOR_MANUAL_VERIFICATION"] = "PROCESSOR_MANUAL_VERIFICATION";
    ApiBankAccountVerificationTypeEnum["INSTANT"] = "INSTANT";
    ApiBankAccountVerificationTypeEnum["MANUAL"] = "MANUAL";
})(ApiBankAccountVerificationTypeEnum || (ApiBankAccountVerificationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBankAccountLinkStatusLinkStatusEnum;
(function (ApiBankAccountLinkStatusLinkStatusEnum) {
    ApiBankAccountLinkStatusLinkStatusEnum["CURRENT"] = "CURRENT";
    ApiBankAccountLinkStatusLinkStatusEnum["REFRESH_REQUIRED"] = "REFRESH_REQUIRED";
    ApiBankAccountLinkStatusLinkStatusEnum["PENDING_EXPIRATION"] = "PENDING_EXPIRATION";
})(ApiBankAccountLinkStatusLinkStatusEnum || (ApiBankAccountLinkStatusLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBitcoinTransactionDetailsFundingTypeEnum;
(function (ApiBitcoinTransactionDetailsFundingTypeEnum) {
    ApiBitcoinTransactionDetailsFundingTypeEnum["PARTNER"] = "PARTNER";
    ApiBitcoinTransactionDetailsFundingTypeEnum["PARTY"] = "PARTY";
})(ApiBitcoinTransactionDetailsFundingTypeEnum || (ApiBitcoinTransactionDetailsFundingTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBlacklistAccountRemovalRequestReasonEnum;
(function (ApiBlacklistAccountRemovalRequestReasonEnum) {
    ApiBlacklistAccountRemovalRequestReasonEnum["ACCOUNT_CLOSURE_REQUESTED"] = "ACCOUNT_CLOSURE_REQUESTED";
    ApiBlacklistAccountRemovalRequestReasonEnum["ACH_MAX_RETURNS"] = "ACH_MAX_RETURNS";
    ApiBlacklistAccountRemovalRequestReasonEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    ApiBlacklistAccountRemovalRequestReasonEnum["ADMIN_UPDATE_IDENTIFIER"] = "ADMIN_UPDATE_IDENTIFIER";
    ApiBlacklistAccountRemovalRequestReasonEnum["COMPLIANCE_INVESTIGATION"] = "COMPLIANCE_INVESTIGATION";
    ApiBlacklistAccountRemovalRequestReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiBlacklistAccountRemovalRequestReasonEnum["COMPLIANCE_VERIFICATION"] = "COMPLIANCE_VERIFICATION";
    ApiBlacklistAccountRemovalRequestReasonEnum["CUSTOMER_GOOD_STANDING"] = "CUSTOMER_GOOD_STANDING";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_TRANSACTION_FRAUD_HOLD"] = "DEBIT_CARD_TRANSACTION_FRAUD_HOLD";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED"] = "DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD";
    ApiBlacklistAccountRemovalRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS";
    ApiBlacklistAccountRemovalRequestReasonEnum["LIQUIDATE_ACCOUNT"] = "LIQUIDATE_ACCOUNT";
    ApiBlacklistAccountRemovalRequestReasonEnum["MARQETA_KYC_VERIFICATION"] = "MARQETA_KYC_VERIFICATION";
    ApiBlacklistAccountRemovalRequestReasonEnum["NSF_BLOCKING"] = "NSF_BLOCKING";
    ApiBlacklistAccountRemovalRequestReasonEnum["OLD_OFAC_CHECK"] = "OLD_OFAC_CHECK";
    ApiBlacklistAccountRemovalRequestReasonEnum["OTHER"] = "OTHER";
    ApiBlacklistAccountRemovalRequestReasonEnum["PLAID_IDENTITY_CHECK"] = "PLAID_IDENTITY_CHECK";
    ApiBlacklistAccountRemovalRequestReasonEnum["PLAID_MISSING_NAMES"] = "PLAID_MISSING_NAMES";
    ApiBlacklistAccountRemovalRequestReasonEnum["RELINK"] = "RELINK";
    ApiBlacklistAccountRemovalRequestReasonEnum["RISK_DECISION"] = "RISK_DECISION";
    ApiBlacklistAccountRemovalRequestReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiBlacklistAccountRemovalRequestReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiBlacklistAccountRemovalRequestReasonEnum["RISK_SCREENING"] = "RISK_SCREENING";
    ApiBlacklistAccountRemovalRequestReasonEnum["TAX_ID_MAX_RETRIES"] = "TAX_ID_MAX_RETRIES";
    ApiBlacklistAccountRemovalRequestReasonEnum["CARD_TERMINATION_ERROR"] = "CARD_TERMINATION_ERROR";
    ApiBlacklistAccountRemovalRequestReasonEnum["CLOSED"] = "CLOSED";
    ApiBlacklistAccountRemovalRequestReasonEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiBlacklistAccountRemovalRequestReasonEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiBlacklistAccountRemovalRequestReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiBlacklistAccountRemovalRequestReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiBlacklistAccountRemovalRequestReasonEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiBlacklistAccountRemovalRequestReasonEnum["PARTNER_UPDATE"] = "PARTNER_UPDATE";
    ApiBlacklistAccountRemovalRequestReasonEnum["ENROLLMENT"] = "ENROLLMENT";
    ApiBlacklistAccountRemovalRequestReasonEnum["OPEN"] = "OPEN";
    ApiBlacklistAccountRemovalRequestReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
})(ApiBlacklistAccountRemovalRequestReasonEnum || (ApiBlacklistAccountRemovalRequestReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBlacklistAccountRequestReasonEnum;
(function (ApiBlacklistAccountRequestReasonEnum) {
    ApiBlacklistAccountRequestReasonEnum["ACCOUNT_CLOSURE_REQUESTED"] = "ACCOUNT_CLOSURE_REQUESTED";
    ApiBlacklistAccountRequestReasonEnum["ACH_MAX_RETURNS"] = "ACH_MAX_RETURNS";
    ApiBlacklistAccountRequestReasonEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    ApiBlacklistAccountRequestReasonEnum["ADMIN_UPDATE_IDENTIFIER"] = "ADMIN_UPDATE_IDENTIFIER";
    ApiBlacklistAccountRequestReasonEnum["COMPLIANCE_INVESTIGATION"] = "COMPLIANCE_INVESTIGATION";
    ApiBlacklistAccountRequestReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiBlacklistAccountRequestReasonEnum["COMPLIANCE_VERIFICATION"] = "COMPLIANCE_VERIFICATION";
    ApiBlacklistAccountRequestReasonEnum["CUSTOMER_GOOD_STANDING"] = "CUSTOMER_GOOD_STANDING";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_TRANSACTION_FRAUD_HOLD"] = "DEBIT_CARD_TRANSACTION_FRAUD_HOLD";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED"] = "DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD";
    ApiBlacklistAccountRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS";
    ApiBlacklistAccountRequestReasonEnum["LIQUIDATE_ACCOUNT"] = "LIQUIDATE_ACCOUNT";
    ApiBlacklistAccountRequestReasonEnum["MARQETA_KYC_VERIFICATION"] = "MARQETA_KYC_VERIFICATION";
    ApiBlacklistAccountRequestReasonEnum["NSF_BLOCKING"] = "NSF_BLOCKING";
    ApiBlacklistAccountRequestReasonEnum["OLD_OFAC_CHECK"] = "OLD_OFAC_CHECK";
    ApiBlacklistAccountRequestReasonEnum["OTHER"] = "OTHER";
    ApiBlacklistAccountRequestReasonEnum["PLAID_IDENTITY_CHECK"] = "PLAID_IDENTITY_CHECK";
    ApiBlacklistAccountRequestReasonEnum["PLAID_MISSING_NAMES"] = "PLAID_MISSING_NAMES";
    ApiBlacklistAccountRequestReasonEnum["RELINK"] = "RELINK";
    ApiBlacklistAccountRequestReasonEnum["RISK_DECISION"] = "RISK_DECISION";
    ApiBlacklistAccountRequestReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiBlacklistAccountRequestReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiBlacklistAccountRequestReasonEnum["RISK_SCREENING"] = "RISK_SCREENING";
    ApiBlacklistAccountRequestReasonEnum["TAX_ID_MAX_RETRIES"] = "TAX_ID_MAX_RETRIES";
    ApiBlacklistAccountRequestReasonEnum["CARD_TERMINATION_ERROR"] = "CARD_TERMINATION_ERROR";
    ApiBlacklistAccountRequestReasonEnum["CLOSED"] = "CLOSED";
    ApiBlacklistAccountRequestReasonEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiBlacklistAccountRequestReasonEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiBlacklistAccountRequestReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiBlacklistAccountRequestReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiBlacklistAccountRequestReasonEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiBlacklistAccountRequestReasonEnum["PARTNER_UPDATE"] = "PARTNER_UPDATE";
    ApiBlacklistAccountRequestReasonEnum["ENROLLMENT"] = "ENROLLMENT";
    ApiBlacklistAccountRequestReasonEnum["OPEN"] = "OPEN";
    ApiBlacklistAccountRequestReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
})(ApiBlacklistAccountRequestReasonEnum || (ApiBlacklistAccountRequestReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBlacklistDebitCardRequestReasonEnum;
(function (ApiBlacklistDebitCardRequestReasonEnum) {
    ApiBlacklistDebitCardRequestReasonEnum["ACCOUNT_CLOSURE_REQUESTED"] = "ACCOUNT_CLOSURE_REQUESTED";
    ApiBlacklistDebitCardRequestReasonEnum["ACH_MAX_RETURNS"] = "ACH_MAX_RETURNS";
    ApiBlacklistDebitCardRequestReasonEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    ApiBlacklistDebitCardRequestReasonEnum["ADMIN_UPDATE_IDENTIFIER"] = "ADMIN_UPDATE_IDENTIFIER";
    ApiBlacklistDebitCardRequestReasonEnum["COMPLIANCE_INVESTIGATION"] = "COMPLIANCE_INVESTIGATION";
    ApiBlacklistDebitCardRequestReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiBlacklistDebitCardRequestReasonEnum["COMPLIANCE_VERIFICATION"] = "COMPLIANCE_VERIFICATION";
    ApiBlacklistDebitCardRequestReasonEnum["CUSTOMER_GOOD_STANDING"] = "CUSTOMER_GOOD_STANDING";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_TRANSACTION_FRAUD_HOLD"] = "DEBIT_CARD_TRANSACTION_FRAUD_HOLD";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED"] = "DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD";
    ApiBlacklistDebitCardRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS";
    ApiBlacklistDebitCardRequestReasonEnum["LIQUIDATE_ACCOUNT"] = "LIQUIDATE_ACCOUNT";
    ApiBlacklistDebitCardRequestReasonEnum["MARQETA_KYC_VERIFICATION"] = "MARQETA_KYC_VERIFICATION";
    ApiBlacklistDebitCardRequestReasonEnum["NSF_BLOCKING"] = "NSF_BLOCKING";
    ApiBlacklistDebitCardRequestReasonEnum["OLD_OFAC_CHECK"] = "OLD_OFAC_CHECK";
    ApiBlacklistDebitCardRequestReasonEnum["OTHER"] = "OTHER";
    ApiBlacklistDebitCardRequestReasonEnum["PLAID_IDENTITY_CHECK"] = "PLAID_IDENTITY_CHECK";
    ApiBlacklistDebitCardRequestReasonEnum["PLAID_MISSING_NAMES"] = "PLAID_MISSING_NAMES";
    ApiBlacklistDebitCardRequestReasonEnum["RELINK"] = "RELINK";
    ApiBlacklistDebitCardRequestReasonEnum["RISK_DECISION"] = "RISK_DECISION";
    ApiBlacklistDebitCardRequestReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiBlacklistDebitCardRequestReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiBlacklistDebitCardRequestReasonEnum["RISK_SCREENING"] = "RISK_SCREENING";
    ApiBlacklistDebitCardRequestReasonEnum["TAX_ID_MAX_RETRIES"] = "TAX_ID_MAX_RETRIES";
    ApiBlacklistDebitCardRequestReasonEnum["CARD_TERMINATION_ERROR"] = "CARD_TERMINATION_ERROR";
    ApiBlacklistDebitCardRequestReasonEnum["CLOSED"] = "CLOSED";
    ApiBlacklistDebitCardRequestReasonEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiBlacklistDebitCardRequestReasonEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiBlacklistDebitCardRequestReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiBlacklistDebitCardRequestReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiBlacklistDebitCardRequestReasonEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiBlacklistDebitCardRequestReasonEnum["PARTNER_UPDATE"] = "PARTNER_UPDATE";
    ApiBlacklistDebitCardRequestReasonEnum["ENROLLMENT"] = "ENROLLMENT";
    ApiBlacklistDebitCardRequestReasonEnum["OPEN"] = "OPEN";
    ApiBlacklistDebitCardRequestReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
})(ApiBlacklistDebitCardRequestReasonEnum || (ApiBlacklistDebitCardRequestReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiBlacklistedBankAccountOffenderReasonEnum;
(function (ApiBlacklistedBankAccountOffenderReasonEnum) {
    ApiBlacklistedBankAccountOffenderReasonEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    ApiBlacklistedBankAccountOffenderReasonEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    ApiBlacklistedBankAccountOffenderReasonEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistedBankAccountOffenderReasonEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    ApiBlacklistedBankAccountOffenderReasonEnum["CSR_BLACKLIST"] = "CSR_BLACKLIST";
})(ApiBlacklistedBankAccountOffenderReasonEnum || (ApiBlacklistedBankAccountOffenderReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCommentOwnerEnum;
(function (ApiCommentOwnerEnum) {
    ApiCommentOwnerEnum["BAKKT"] = "BAKKT";
    ApiCommentOwnerEnum["MERCHANT"] = "MERCHANT";
})(ApiCommentOwnerEnum || (ApiCommentOwnerEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCreateBankAccountRequestVerificationStatusEnum;
(function (ApiCreateBankAccountRequestVerificationStatusEnum) {
    ApiCreateBankAccountRequestVerificationStatusEnum["PENDING_AUTOMATIC_VERIFICATION"] = "PENDING_AUTOMATIC_VERIFICATION";
    ApiCreateBankAccountRequestVerificationStatusEnum["PENDING_MANUAL_VERIFICATION"] = "PENDING_MANUAL_VERIFICATION";
    ApiCreateBankAccountRequestVerificationStatusEnum["AUTOMATICALLY_VERIFIED"] = "AUTOMATICALLY_VERIFIED";
    ApiCreateBankAccountRequestVerificationStatusEnum["MANUALLY_VERIFIED"] = "MANUALLY_VERIFIED";
    ApiCreateBankAccountRequestVerificationStatusEnum["VERIFICATION_EXPIRED"] = "VERIFICATION_EXPIRED";
    ApiCreateBankAccountRequestVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
})(ApiCreateBankAccountRequestVerificationStatusEnum || (ApiCreateBankAccountRequestVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCreatePartnerRequestCryptoCurrenciesEnum;
(function (ApiCreatePartnerRequestCryptoCurrenciesEnum) {
    ApiCreatePartnerRequestCryptoCurrenciesEnum["BCH"] = "BCH";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["BTC"] = "BTC";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["DOGE"] = "DOGE";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["ETC"] = "ETC";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["ETH"] = "ETH";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["LTC"] = "LTC";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["SHIB"] = "SHIB";
    ApiCreatePartnerRequestCryptoCurrenciesEnum["USDC"] = "USDC";
})(ApiCreatePartnerRequestCryptoCurrenciesEnum || (ApiCreatePartnerRequestCryptoCurrenciesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCreatePartnerRequestPrimaryCurrencyEnum;
(function (ApiCreatePartnerRequestPrimaryCurrencyEnum) {
    ApiCreatePartnerRequestPrimaryCurrencyEnum["USD"] = "USD";
})(ApiCreatePartnerRequestPrimaryCurrencyEnum || (ApiCreatePartnerRequestPrimaryCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCreatePartnerRequestServiceTypesEnum;
(function (ApiCreatePartnerRequestServiceTypesEnum) {
    ApiCreatePartnerRequestServiceTypesEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiCreatePartnerRequestServiceTypesEnum["REWARD"] = "REWARD";
    ApiCreatePartnerRequestServiceTypesEnum["INCENTIVE"] = "INCENTIVE";
    ApiCreatePartnerRequestServiceTypesEnum["PARTY"] = "PARTY";
    ApiCreatePartnerRequestServiceTypesEnum["PAYOUT"] = "PAYOUT";
    ApiCreatePartnerRequestServiceTypesEnum["LOYALTY"] = "LOYALTY";
    ApiCreatePartnerRequestServiceTypesEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiCreatePartnerRequestServiceTypesEnum || (ApiCreatePartnerRequestServiceTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCreateRolePermissionsEnum;
(function (ApiCreateRolePermissionsEnum) {
    ApiCreateRolePermissionsEnum["ALLOWLIST_CREATE"] = "ALLOWLIST_CREATE";
    ApiCreateRolePermissionsEnum["ALLOWLIST_DELETE"] = "ALLOWLIST_DELETE";
    ApiCreateRolePermissionsEnum["ALLOWLIST_UPDATE"] = "ALLOWLIST_UPDATE";
    ApiCreateRolePermissionsEnum["ALLOWLIST_VIEW"] = "ALLOWLIST_VIEW";
    ApiCreateRolePermissionsEnum["DISPUTE_DELETE_REASON"] = "DISPUTE_DELETE_REASON";
    ApiCreateRolePermissionsEnum["DISPUTE_EXPORT_CSV"] = "DISPUTE_EXPORT_CSV";
    ApiCreateRolePermissionsEnum["DISPUTE_UPDATE_ACCEPT"] = "DISPUTE_UPDATE_ACCEPT";
    ApiCreateRolePermissionsEnum["DISPUTE_UPDATE_AUTO_APPROVE"] = "DISPUTE_UPDATE_AUTO_APPROVE";
    ApiCreateRolePermissionsEnum["DISPUTE_UPDATE_CREDIT_REJECT"] = "DISPUTE_UPDATE_CREDIT_REJECT";
    ApiCreateRolePermissionsEnum["DISPUTE_UPDATE_PROVISIONAL"] = "DISPUTE_UPDATE_PROVISIONAL";
    ApiCreateRolePermissionsEnum["DISPUTE_CREATE"] = "DISPUTE_CREATE";
    ApiCreateRolePermissionsEnum["DISPUTE_VIEW"] = "DISPUTE_VIEW";
    ApiCreateRolePermissionsEnum["DEVICE_SESSION_VIEW"] = "DEVICE_SESSION_VIEW";
    ApiCreateRolePermissionsEnum["FINANCE_VIEW"] = "FINANCE_VIEW";
    ApiCreateRolePermissionsEnum["FIAT_LEDGER_ADJUSTMENT"] = "FIAT_LEDGER_ADJUSTMENT";
    ApiCreateRolePermissionsEnum["GIFTCARD_UPDATE_REACTIVATE"] = "GIFTCARD_UPDATE_REACTIVATE";
    ApiCreateRolePermissionsEnum["GIFTCARD_BRAND_UPDATE"] = "GIFTCARD_BRAND_UPDATE";
    ApiCreateRolePermissionsEnum["GIFTCARD_BRAND_VIEW_ACTIVITY"] = "GIFTCARD_BRAND_VIEW_ACTIVITY";
    ApiCreateRolePermissionsEnum["GIFTCARD_VIEW"] = "GIFTCARD_VIEW";
    ApiCreateRolePermissionsEnum["GIFTCARD_VIEW_BALANCE"] = "GIFTCARD_VIEW_BALANCE";
    ApiCreateRolePermissionsEnum["GIFTCARD_VIEW_ACTIVITY"] = "GIFTCARD_VIEW_ACTIVITY";
    ApiCreateRolePermissionsEnum["KYC_ACTIVITY_VIEW"] = "KYC_ACTIVITY_VIEW";
    ApiCreateRolePermissionsEnum["OFAC_CREATE"] = "OFAC_CREATE";
    ApiCreateRolePermissionsEnum["OFAC_DELETE"] = "OFAC_DELETE";
    ApiCreateRolePermissionsEnum["OFAC_UPDATE"] = "OFAC_UPDATE";
    ApiCreateRolePermissionsEnum["OFAC_VIEW"] = "OFAC_VIEW";
    ApiCreateRolePermissionsEnum["PARTY_VIEW"] = "PARTY_VIEW";
    ApiCreateRolePermissionsEnum["PORTAL_VIEW"] = "PORTAL_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_CREATE"] = "REPORT_CREATE";
    ApiCreateRolePermissionsEnum["TICKET_CREATE"] = "TICKET_CREATE";
    ApiCreateRolePermissionsEnum["TICKET_UPDATE_ASSIGN"] = "TICKET_UPDATE_ASSIGN";
    ApiCreateRolePermissionsEnum["TICKET_UPDATE_CANCEL"] = "TICKET_UPDATE_CANCEL";
    ApiCreateRolePermissionsEnum["TICKET_UPDATE_ESCALATE"] = "TICKET_UPDATE_ESCALATE";
    ApiCreateRolePermissionsEnum["TICKET_UPDATE_COMMENT"] = "TICKET_UPDATE_COMMENT";
    ApiCreateRolePermissionsEnum["TICKET_VIEW"] = "TICKET_VIEW";
    ApiCreateRolePermissionsEnum["TRANSACTIONS_VIEW"] = "TRANSACTIONS_VIEW";
    ApiCreateRolePermissionsEnum["USERS_CREATE"] = "USERS_CREATE";
    ApiCreateRolePermissionsEnum["USERS_UPDATE"] = "USERS_UPDATE";
    ApiCreateRolePermissionsEnum["USERS_UPDATE_SYNC"] = "USERS_UPDATE_SYNC";
    ApiCreateRolePermissionsEnum["USERS_VIEW"] = "USERS_VIEW";
    ApiCreateRolePermissionsEnum["USERS_VIEW_DETAIL"] = "USERS_VIEW_DETAIL";
    ApiCreateRolePermissionsEnum["ACCOUNT_PREFUND_BLOCK"] = "ACCOUNT_PREFUND_BLOCK";
    ApiCreateRolePermissionsEnum["ACCOUNT_PREFUND_UNBLOCK"] = "ACCOUNT_PREFUND_UNBLOCK";
    ApiCreateRolePermissionsEnum["ACCOUNT_ACTIVITY_VIEW"] = "ACCOUNT_ACTIVITY_VIEW";
    ApiCreateRolePermissionsEnum["BAKKT_CARD_VIEW"] = "BAKKT_CARD_VIEW";
    ApiCreateRolePermissionsEnum["BAKKT_CARD_UPDATE"] = "BAKKT_CARD_UPDATE";
    ApiCreateRolePermissionsEnum["PARTY_CREDIT_ADD"] = "PARTY_CREDIT_ADD";
    ApiCreateRolePermissionsEnum["PARTY_PII_VIEW"] = "PARTY_PII_VIEW";
    ApiCreateRolePermissionsEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ApiCreateRolePermissionsEnum["CARD_ISSUER_KYC_UPDATE"] = "CARD_ISSUER_KYC_UPDATE";
    ApiCreateRolePermissionsEnum["BANKACCOUNT_BLACKLIST_VIEW"] = "BANKACCOUNT_BLACKLIST_VIEW";
    ApiCreateRolePermissionsEnum["BANKACCOUNT_BLACKLIST_UPDATE"] = "BANKACCOUNT_BLACKLIST_UPDATE";
    ApiCreateRolePermissionsEnum["DEBITCARD_BLACKLIST_UPDATE"] = "DEBITCARD_BLACKLIST_UPDATE";
    ApiCreateRolePermissionsEnum["PARTY_WHITELIST_UPDATE"] = "PARTY_WHITELIST_UPDATE";
    ApiCreateRolePermissionsEnum["INCENTIVE_OFFER_VIEW"] = "INCENTIVE_OFFER_VIEW";
    ApiCreateRolePermissionsEnum["LOYALTY_REDEEM_CANCEL"] = "LOYALTY_REDEEM_CANCEL";
    ApiCreateRolePermissionsEnum["LOYALTY_ACCOUNT_VIEW"] = "LOYALTY_ACCOUNT_VIEW";
    ApiCreateRolePermissionsEnum["LOYALTY_ACCOUNT_UPDATE"] = "LOYALTY_ACCOUNT_UPDATE";
    ApiCreateRolePermissionsEnum["INCENTIVE_OFFER_CREATE"] = "INCENTIVE_OFFER_CREATE";
    ApiCreateRolePermissionsEnum["INCENTIVE_OFFER_COMPLETION"] = "INCENTIVE_OFFER_COMPLETION";
    ApiCreateRolePermissionsEnum["ACTIVITY_AUDIT_VIEW"] = "ACTIVITY_AUDIT_VIEW";
    ApiCreateRolePermissionsEnum["DEBIT_CARD_VIEW"] = "DEBIT_CARD_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_ASSET_ACTIVTY_VIEW"] = "REPORT_ASSET_ACTIVTY_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_PREFUNDING_VIEW"] = "REPORT_PREFUNDING_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_VIRTUAL_CURRENCY_VIEW"] = "REPORT_VIRTUAL_CURRENCY_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_MTL_VIEW"] = "REPORT_MTL_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_ADOTMTL_VIEW"] = "REPORT_ADOTMTL_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_SIFT_VIEW"] = "REPORT_SIFT_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_DISPUTE_CREDIT_VIEW"] = "REPORT_DISPUTE_CREDIT_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_GIFT_CARD_RECON_VIEW"] = "REPORT_GIFT_CARD_RECON_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_ACH_PROCESSING_VIEW"] = "REPORT_ACH_PROCESSING_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_SUSPEND_ACCOUNT_VIEW"] = "REPORT_SUSPEND_ACCOUNT_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_P2P_TRANSFER_VIEW"] = "REPORT_P2P_TRANSFER_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_ASSET_ACTIVTY_CREATE"] = "REPORT_ASSET_ACTIVTY_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_PREFUNDING_CREATE"] = "REPORT_PREFUNDING_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_VIRTUAL_CURRENCY_CREATE"] = "REPORT_VIRTUAL_CURRENCY_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_MTL_CREATE"] = "REPORT_MTL_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_ADOTMTL_CREATE"] = "REPORT_ADOTMTL_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_SIFT_CREATE"] = "REPORT_SIFT_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_DISPUTE_CREDIT_CREATE"] = "REPORT_DISPUTE_CREDIT_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_GIFT_CARD_RECON_CREATE"] = "REPORT_GIFT_CARD_RECON_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_ACH_PROCESSING_CREATE"] = "REPORT_ACH_PROCESSING_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_SUSPEND_ACCOUNT_CREATE"] = "REPORT_SUSPEND_ACCOUNT_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_P2P_TRANSFERS_CREATE"] = "REPORT_P2P_TRANSFERS_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_QUIZNOS_VIEW"] = "REPORT_QUIZNOS_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_QUIZNOS_CREATE"] = "REPORT_QUIZNOS_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_CHOICE_VIEW"] = "REPORT_CHOICE_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_CHOICE_CREATE"] = "REPORT_CHOICE_CREATE";
    ApiCreateRolePermissionsEnum["RISK_AUDIT_VIEW"] = "RISK_AUDIT_VIEW";
    ApiCreateRolePermissionsEnum["RISK_AUDIT_VIEW_DETAIL"] = "RISK_AUDIT_VIEW_DETAIL";
    ApiCreateRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_VIEW"] = "REPORT_ACCOUNT_BALANCE_V2_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_CREATE"] = "REPORT_ACCOUNT_BALANCE_V2_CREATE";
    ApiCreateRolePermissionsEnum["PLAID_ACCOUNT_DETAIL_VIEW"] = "PLAID_ACCOUNT_DETAIL_VIEW";
    ApiCreateRolePermissionsEnum["UNMASK_PLAID_ACCOUNT_NUMBERS"] = "UNMASK_PLAID_ACCOUNT_NUMBERS";
    ApiCreateRolePermissionsEnum["UNMASK_DEBIT_CARD_FIELDS"] = "UNMASK_DEBIT_CARD_FIELDS";
    ApiCreateRolePermissionsEnum["TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW"] = "TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_BTC_TRANSACTION_REPORT_CREATE"] = "REPORT_BTC_TRANSACTION_REPORT_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_CRYPTO_TRANSACTION_REPORT_CREATE"] = "REPORT_CRYPTO_TRANSACTION_REPORT_CREATE";
    ApiCreateRolePermissionsEnum["PARTNER_DETAIL_VIEW"] = "PARTNER_DETAIL_VIEW";
    ApiCreateRolePermissionsEnum["PARTNER_LINK_UNLINK"] = "PARTNER_LINK_UNLINK";
    ApiCreateRolePermissionsEnum["PARTY_NAME_UPDATE"] = "PARTY_NAME_UPDATE";
    ApiCreateRolePermissionsEnum["PARTY_SUSPEND"] = "PARTY_SUSPEND";
    ApiCreateRolePermissionsEnum["PARTY_UNSUSPEND"] = "PARTY_UNSUSPEND";
    ApiCreateRolePermissionsEnum["PARTY_LIQUIDATE"] = "PARTY_LIQUIDATE";
    ApiCreateRolePermissionsEnum["PARTY_ACTIVATE"] = "PARTY_ACTIVATE";
    ApiCreateRolePermissionsEnum["PARTY_CLOSE"] = "PARTY_CLOSE";
    ApiCreateRolePermissionsEnum["PARTY_STATUS_UPDATE"] = "PARTY_STATUS_UPDATE";
    ApiCreateRolePermissionsEnum["REPORT_MERCHANT_INCENTIVE_CREATE"] = "REPORT_MERCHANT_INCENTIVE_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_MERCHANT_INCENTIVE_VIEW"] = "REPORT_MERCHANT_INCENTIVE_VIEW";
    ApiCreateRolePermissionsEnum["REPORT_PARTNER_INCENTIVE_CREATE"] = "REPORT_PARTNER_INCENTIVE_CREATE";
    ApiCreateRolePermissionsEnum["REPORT_PARTNER_INCENTIVE_VIEW"] = "REPORT_PARTNER_INCENTIVE_VIEW";
    ApiCreateRolePermissionsEnum["TAXID_LAST_4_DIGIT_VIEW"] = "TAXID_LAST_4_DIGIT_VIEW";
    ApiCreateRolePermissionsEnum["TAXID_FULL_VIEW"] = "TAXID_FULL_VIEW";
})(ApiCreateRolePermissionsEnum || (ApiCreateRolePermissionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCreditActionRequestAssetTypeEnum;
(function (ApiCreditActionRequestAssetTypeEnum) {
    ApiCreditActionRequestAssetTypeEnum["CASH"] = "CASH";
    ApiCreditActionRequestAssetTypeEnum["CRYPTO"] = "CRYPTO";
    ApiCreditActionRequestAssetTypeEnum["REWARDS"] = "REWARDS";
    ApiCreditActionRequestAssetTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    ApiCreditActionRequestAssetTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    ApiCreditActionRequestAssetTypeEnum["DEBIT_CARD"] = "DEBIT_CARD";
})(ApiCreditActionRequestAssetTypeEnum || (ApiCreditActionRequestAssetTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCustomerProfileAnnualIncomeUsdRangeEnum;
(function (ApiCustomerProfileAnnualIncomeUsdRangeEnum) {
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiCustomerProfileAnnualIncomeUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiCustomerProfileAnnualIncomeUsdRangeEnum || (ApiCustomerProfileAnnualIncomeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCustomerProfileInvestmentExperienceEnum;
(function (ApiCustomerProfileInvestmentExperienceEnum) {
    ApiCustomerProfileInvestmentExperienceEnum["NONE"] = "NONE";
    ApiCustomerProfileInvestmentExperienceEnum["LIMITED"] = "LIMITED";
    ApiCustomerProfileInvestmentExperienceEnum["GOOD"] = "GOOD";
    ApiCustomerProfileInvestmentExperienceEnum["EXTENSIVE"] = "EXTENSIVE";
})(ApiCustomerProfileInvestmentExperienceEnum || (ApiCustomerProfileInvestmentExperienceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCustomerProfileLiquidNetWorthUsdRangeEnum;
(function (ApiCustomerProfileLiquidNetWorthUsdRangeEnum) {
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiCustomerProfileLiquidNetWorthUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiCustomerProfileLiquidNetWorthUsdRangeEnum || (ApiCustomerProfileLiquidNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiCustomerProfileTotalNetWorthUsdRangeEnum;
(function (ApiCustomerProfileTotalNetWorthUsdRangeEnum) {
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiCustomerProfileTotalNetWorthUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiCustomerProfileTotalNetWorthUsdRangeEnum || (ApiCustomerProfileTotalNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDateRangeReportRequestReportTypeEnum;
(function (ApiDateRangeReportRequestReportTypeEnum) {
    ApiDateRangeReportRequestReportTypeEnum["ASSET_ACTIVTY"] = "ASSET_ACTIVTY";
    ApiDateRangeReportRequestReportTypeEnum["CAESARS_ACTIVITY"] = "CAESARS_ACTIVITY";
    ApiDateRangeReportRequestReportTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    ApiDateRangeReportRequestReportTypeEnum["PREFUNDING"] = "PREFUNDING";
    ApiDateRangeReportRequestReportTypeEnum["VIRTUAL_CURRENCY"] = "VIRTUAL_CURRENCY";
    ApiDateRangeReportRequestReportTypeEnum["SIFT"] = "SIFT";
    ApiDateRangeReportRequestReportTypeEnum["IIM_RECONCILATION_REPORT"] = "IIM_RECONCILATION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["MERCHANT_RECONCILIATION_REPORT"] = "MERCHANT_RECONCILIATION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["STARBUCKS_RECONCILIATION_REPORT"] = "STARBUCKS_RECONCILIATION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_SETTLEMENT_REPORT"] = "PARTNER_SETTLEMENT_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiDateRangeReportRequestReportTypeEnum["ACCOUNT_BALANCE"] = "ACCOUNT_BALANCE";
    ApiDateRangeReportRequestReportTypeEnum["ACCOUNT_BALANCE_V2"] = "ACCOUNT_BALANCE_V2";
    ApiDateRangeReportRequestReportTypeEnum["ACCOUNT_BALANCE_SUMMARY"] = "ACCOUNT_BALANCE_SUMMARY";
    ApiDateRangeReportRequestReportTypeEnum["ALL_CURRENCY_MTL"] = "ALL_CURRENCY_MTL";
    ApiDateRangeReportRequestReportTypeEnum["MTL"] = "MTL";
    ApiDateRangeReportRequestReportTypeEnum["ADOTMTL"] = "ADOTMTL";
    ApiDateRangeReportRequestReportTypeEnum["BTC_TRANSACTION_REPORT"] = "BTC_TRANSACTION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["CRYPTO_TRANSACTION_REPORT"] = "CRYPTO_TRANSACTION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT"] = "PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["SUSPEND_ACCOUNT"] = "SUSPEND_ACCOUNT";
    ApiDateRangeReportRequestReportTypeEnum["GIFT_CARD_RECON"] = "GIFT_CARD_RECON";
    ApiDateRangeReportRequestReportTypeEnum["GIFT_CARD_REDEEM_RECON"] = "GIFT_CARD_REDEEM_RECON";
    ApiDateRangeReportRequestReportTypeEnum["GIFT_CARD_RECON_MONTH"] = "GIFT_CARD_RECON_MONTH";
    ApiDateRangeReportRequestReportTypeEnum["GIFT_CARD_REDEEM_RECON_MONTH"] = "GIFT_CARD_REDEEM_RECON_MONTH";
    ApiDateRangeReportRequestReportTypeEnum["ACH_FILE_RETURNS"] = "ACH_FILE_RETURNS";
    ApiDateRangeReportRequestReportTypeEnum["ACH_FILE_OUTBOUND"] = "ACH_FILE_OUTBOUND";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_RECONCILIATION_REPORT"] = "PARTNER_RECONCILIATION_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["CHOICE"] = "CHOICE";
    ApiDateRangeReportRequestReportTypeEnum["QUIZNOS"] = "QUIZNOS";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_INCENTIVE"] = "PARTNER_INCENTIVE";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_CASH_ACTIVITY"] = "PARTNER_CASH_ACTIVITY";
    ApiDateRangeReportRequestReportTypeEnum["WIRE_TRANSFER_ACTIVITY_REVIEW"] = "WIRE_TRANSFER_ACTIVITY_REVIEW";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_CASH_ACTIVITY_APEX"] = "PARTNER_CASH_ACTIVITY_APEX";
    ApiDateRangeReportRequestReportTypeEnum["PARTNER_CASH_BALANCE"] = "PARTNER_CASH_BALANCE";
    ApiDateRangeReportRequestReportTypeEnum["PARTY_CSI_OFAC_CHECK"] = "PARTY_CSI_OFAC_CHECK";
    ApiDateRangeReportRequestReportTypeEnum["FISERV_PAYMENT_STATUS"] = "FISERV_PAYMENT_STATUS";
    ApiDateRangeReportRequestReportTypeEnum["DEPOSITE_BALANCE_CHECK_REPORT"] = "DEPOSITE_BALANCE_CHECK_REPORT";
    ApiDateRangeReportRequestReportTypeEnum["NYDFS_CUSTOMER_REPORT"] = "NYDFS_CUSTOMER_REPORT";
})(ApiDateRangeReportRequestReportTypeEnum || (ApiDateRangeReportRequestReportTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDebitCardCardTypeEnum;
(function (ApiDebitCardCardTypeEnum) {
    ApiDebitCardCardTypeEnum["MASTERCARD"] = "MASTERCARD";
    ApiDebitCardCardTypeEnum["VISA"] = "VISA";
    ApiDebitCardCardTypeEnum["UNSUPPORTED"] = "UNSUPPORTED";
})(ApiDebitCardCardTypeEnum || (ApiDebitCardCardTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDebitCardStatusEnum;
(function (ApiDebitCardStatusEnum) {
    ApiDebitCardStatusEnum["ACTIVE"] = "ACTIVE";
    ApiDebitCardStatusEnum["INACTIVE"] = "INACTIVE";
    ApiDebitCardStatusEnum["PENDING_VERIFICATION"] = "PENDING_VERIFICATION";
    ApiDebitCardStatusEnum["VERIFICATION_EXPIRED"] = "VERIFICATION_EXPIRED";
})(ApiDebitCardStatusEnum || (ApiDebitCardStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDebitCardActivityDeclineReasonEnum;
(function (ApiDebitCardActivityDeclineReasonEnum) {
    ApiDebitCardActivityDeclineReasonEnum["ACCOUNT_NOT_FOUND"] = "ACCOUNT_NOT_FOUND";
    ApiDebitCardActivityDeclineReasonEnum["FUNDING_PREFERENCE_NOT_FOUND"] = "FUNDING_PREFERENCE_NOT_FOUND";
    ApiDebitCardActivityDeclineReasonEnum["CARD_INVALID_STATUS"] = "CARD_INVALID_STATUS";
    ApiDebitCardActivityDeclineReasonEnum["CARD_NOT_FOUND"] = "CARD_NOT_FOUND";
    ApiDebitCardActivityDeclineReasonEnum["CARD_NOT_ACTIVATED"] = "CARD_NOT_ACTIVATED";
    ApiDebitCardActivityDeclineReasonEnum["DAILY_LIMIT_EXCEEDED"] = "DAILY_LIMIT_EXCEEDED";
    ApiDebitCardActivityDeclineReasonEnum["MONTHLY_LIMIT_EXCEEDED"] = "MONTHLY_LIMIT_EXCEEDED";
    ApiDebitCardActivityDeclineReasonEnum["OVER_LIMIT"] = "OVER_LIMIT";
    ApiDebitCardActivityDeclineReasonEnum["NOT_SUFFICIENT_FUNDS"] = "NOT_SUFFICIENT_FUNDS";
    ApiDebitCardActivityDeclineReasonEnum["SUSPECTED_FRAUD"] = "SUSPECTED_FRAUD";
    ApiDebitCardActivityDeclineReasonEnum["UNSUPPORTED_OPERATION"] = "UNSUPPORTED_OPERATION";
    ApiDebitCardActivityDeclineReasonEnum["CARD_ISSUER_PARTY_NOT_FOUND"] = "CARD_ISSUER_PARTY_NOT_FOUND";
    ApiDebitCardActivityDeclineReasonEnum["CARD_ISSUER_PARTY_INVALID_STATUS"] = "CARD_ISSUER_PARTY_INVALID_STATUS";
    ApiDebitCardActivityDeclineReasonEnum["PARTY_NOT_FOUND"] = "PARTY_NOT_FOUND";
    ApiDebitCardActivityDeclineReasonEnum["PARTY_INVALID_STATUS"] = "PARTY_INVALID_STATUS";
    ApiDebitCardActivityDeclineReasonEnum["CURRENCY_MISMATCH"] = "CURRENCY_MISMATCH";
    ApiDebitCardActivityDeclineReasonEnum["DATA_MISMATCH"] = "DATA_MISMATCH";
    ApiDebitCardActivityDeclineReasonEnum["GENERIC_PAYMENT_PROCESSING_ERROR"] = "GENERIC_PAYMENT_PROCESSING_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["PRECONDITION_NOT_MET"] = "PRECONDITION_NOT_MET";
    ApiDebitCardActivityDeclineReasonEnum["INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT"] = "INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT";
    ApiDebitCardActivityDeclineReasonEnum["BLACKLISTED_DEBIT_CARD"] = "BLACKLISTED_DEBIT_CARD";
    ApiDebitCardActivityDeclineReasonEnum["DEBIT_CARD_BIN_DENY_LISTED"] = "DEBIT_CARD_BIN_DENY_LISTED";
    ApiDebitCardActivityDeclineReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiDebitCardActivityDeclineReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiDebitCardActivityDeclineReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiDebitCardActivityDeclineReasonEnum["BLACKLISTED_MERCHANT_CATEGORY"] = "BLACKLISTED_MERCHANT_CATEGORY";
    ApiDebitCardActivityDeclineReasonEnum["BLACKLISTED_BANK_ACCOUNT"] = "BLACKLISTED_BANK_ACCOUNT";
    ApiDebitCardActivityDeclineReasonEnum["BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED"] = "BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED";
    ApiDebitCardActivityDeclineReasonEnum["TRANSACTION_FAILED"] = "TRANSACTION_FAILED";
    ApiDebitCardActivityDeclineReasonEnum["REAUTHENTICATION_REQUIRED"] = "REAUTHENTICATION_REQUIRED";
    ApiDebitCardActivityDeclineReasonEnum["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["PROCESSOR_DECLINED"] = "PROCESSOR_DECLINED";
    ApiDebitCardActivityDeclineReasonEnum["SETTLEMENT_DECLINED"] = "SETTLEMENT_DECLINED";
    ApiDebitCardActivityDeclineReasonEnum["GATEWAY_REJECTED"] = "GATEWAY_REJECTED";
    ApiDebitCardActivityDeclineReasonEnum["REWARDS_GATEWAY_REJECTED"] = "REWARDS_GATEWAY_REJECTED";
    ApiDebitCardActivityDeclineReasonEnum["AVS_ERROR"] = "AVS_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["CVV_ERROR"] = "CVV_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["AVS_GATEWAY_ERROR"] = "AVS_GATEWAY_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["AVS_VERIFICATION_FAILED"] = "AVS_VERIFICATION_FAILED";
    ApiDebitCardActivityDeclineReasonEnum["CVV_VERIFICATION_FAILED"] = "CVV_VERIFICATION_FAILED";
    ApiDebitCardActivityDeclineReasonEnum["AMOUNT_VERIFICATION_FAILED"] = "AMOUNT_VERIFICATION_FAILED";
    ApiDebitCardActivityDeclineReasonEnum["DEBIT_CARD_NOT_FOUND"] = "DEBIT_CARD_NOT_FOUND";
    ApiDebitCardActivityDeclineReasonEnum["SDK_ERROR"] = "SDK_ERROR";
    ApiDebitCardActivityDeclineReasonEnum["PARTY_TERMS_AND_CONDITIONS_EXPIRED"] = "PARTY_TERMS_AND_CONDITIONS_EXPIRED";
    ApiDebitCardActivityDeclineReasonEnum["DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH"] = "DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH";
    ApiDebitCardActivityDeclineReasonEnum["PARTY_INVALID_LEVEL"] = "PARTY_INVALID_LEVEL";
    ApiDebitCardActivityDeclineReasonEnum["MISSING_WIRE_WITHDRAW_DETAILS"] = "MISSING_WIRE_WITHDRAW_DETAILS";
    ApiDebitCardActivityDeclineReasonEnum["INVALID_WIRE_ROUTING_NUMBER"] = "INVALID_WIRE_ROUTING_NUMBER";
    ApiDebitCardActivityDeclineReasonEnum["INVALID_WIRE_ACCOUNT_NUMBER"] = "INVALID_WIRE_ACCOUNT_NUMBER";
    ApiDebitCardActivityDeclineReasonEnum["INVALID_WIRE_BANK_NAME"] = "INVALID_WIRE_BANK_NAME";
    ApiDebitCardActivityDeclineReasonEnum["INVALID_WIRE_BANK_ADDRESS"] = "INVALID_WIRE_BANK_ADDRESS";
})(ApiDebitCardActivityDeclineReasonEnum || (ApiDebitCardActivityDeclineReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDebitCardActivityStatusEnum;
(function (ApiDebitCardActivityStatusEnum) {
    ApiDebitCardActivityStatusEnum["CANCELED"] = "CANCELED";
    ApiDebitCardActivityStatusEnum["COMPLETE"] = "COMPLETE";
    ApiDebitCardActivityStatusEnum["EXPIRED"] = "EXPIRED";
    ApiDebitCardActivityStatusEnum["PENDING"] = "PENDING";
    ApiDebitCardActivityStatusEnum["REJECTED"] = "REJECTED";
    ApiDebitCardActivityStatusEnum["REPLACED"] = "REPLACED";
    ApiDebitCardActivityStatusEnum["FAILED"] = "FAILED";
    ApiDebitCardActivityStatusEnum["PROVISIONAL"] = "PROVISIONAL";
})(ApiDebitCardActivityStatusEnum || (ApiDebitCardActivityStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDebitCardDetailsStatusEnum;
(function (ApiDebitCardDetailsStatusEnum) {
    ApiDebitCardDetailsStatusEnum["ACTIVE"] = "ACTIVE";
    ApiDebitCardDetailsStatusEnum["INACTIVE"] = "INACTIVE";
    ApiDebitCardDetailsStatusEnum["PENDING_VERIFICATION"] = "PENDING_VERIFICATION";
    ApiDebitCardDetailsStatusEnum["VERIFICATION_EXPIRED"] = "VERIFICATION_EXPIRED";
})(ApiDebitCardDetailsStatusEnum || (ApiDebitCardDetailsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDebitCardExpiryDateMonthEnum;
(function (ApiDebitCardExpiryDateMonthEnum) {
    ApiDebitCardExpiryDateMonthEnum["JANUARY"] = "JANUARY";
    ApiDebitCardExpiryDateMonthEnum["FEBRUARY"] = "FEBRUARY";
    ApiDebitCardExpiryDateMonthEnum["MARCH"] = "MARCH";
    ApiDebitCardExpiryDateMonthEnum["APRIL"] = "APRIL";
    ApiDebitCardExpiryDateMonthEnum["MAY"] = "MAY";
    ApiDebitCardExpiryDateMonthEnum["JUNE"] = "JUNE";
    ApiDebitCardExpiryDateMonthEnum["JULY"] = "JULY";
    ApiDebitCardExpiryDateMonthEnum["AUGUST"] = "AUGUST";
    ApiDebitCardExpiryDateMonthEnum["SEPTEMBER"] = "SEPTEMBER";
    ApiDebitCardExpiryDateMonthEnum["OCTOBER"] = "OCTOBER";
    ApiDebitCardExpiryDateMonthEnum["NOVEMBER"] = "NOVEMBER";
    ApiDebitCardExpiryDateMonthEnum["DECEMBER"] = "DECEMBER";
})(ApiDebitCardExpiryDateMonthEnum || (ApiDebitCardExpiryDateMonthEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDeviceStatusEnum;
(function (ApiDeviceStatusEnum) {
    ApiDeviceStatusEnum["SUCCESS"] = "SUCCESS";
    ApiDeviceStatusEnum["FAILURE"] = "FAILURE";
})(ApiDeviceStatusEnum || (ApiDeviceStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDeviceSessionResponseFlowEnum;
(function (ApiDeviceSessionResponseFlowEnum) {
    ApiDeviceSessionResponseFlowEnum["L1_IDENTITY_CHECK_FINALIZE"] = "L1_IDENTITY_CHECK_FINALIZE";
    ApiDeviceSessionResponseFlowEnum["L2A_IDENTITY_CHECK_FINALIZE"] = "L2A_IDENTITY_CHECK_FINALIZE";
    ApiDeviceSessionResponseFlowEnum["L2A_IDENTITY_CHECK_ADMIN"] = "L2A_IDENTITY_CHECK_ADMIN";
    ApiDeviceSessionResponseFlowEnum["L2A_IDENTITY_CHECK_UPDATE_ADDRESS"] = "L2A_IDENTITY_CHECK_UPDATE_ADDRESS";
    ApiDeviceSessionResponseFlowEnum["L2B_IDENTITY_CHECK"] = "L2B_IDENTITY_CHECK";
    ApiDeviceSessionResponseFlowEnum["L2B_IDENTITY_CHECK_ADMIN"] = "L2B_IDENTITY_CHECK_ADMIN";
    ApiDeviceSessionResponseFlowEnum["L2C_IDENTITY_CHECK"] = "L2C_IDENTITY_CHECK";
    ApiDeviceSessionResponseFlowEnum["L3_IDENTITY_CHECK_ID_DOC_VERIFICATION"] = "L3_IDENTITY_CHECK_ID_DOC_VERIFICATION";
    ApiDeviceSessionResponseFlowEnum["PROFILE_UPDATE_ADDRESS"] = "PROFILE_UPDATE_ADDRESS";
    ApiDeviceSessionResponseFlowEnum["PROFILE_UPDATE_IDENTIFIER"] = "PROFILE_UPDATE_IDENTIFIER";
    ApiDeviceSessionResponseFlowEnum["BATCH_UPLOAD"] = "BATCH_UPLOAD";
    ApiDeviceSessionResponseFlowEnum["CSI_OFAC_BATCH"] = "CSI_OFAC_BATCH";
    ApiDeviceSessionResponseFlowEnum["CSI_OFAC"] = "CSI_OFAC";
    ApiDeviceSessionResponseFlowEnum["LOGIN"] = "LOGIN";
    ApiDeviceSessionResponseFlowEnum["LOGOUT"] = "LOGOUT";
    ApiDeviceSessionResponseFlowEnum["REGISTRATION"] = "REGISTRATION";
    ApiDeviceSessionResponseFlowEnum["TRANSACTION"] = "TRANSACTION";
    ApiDeviceSessionResponseFlowEnum["BANK_VERIFICATION"] = "BANK_VERIFICATION";
    ApiDeviceSessionResponseFlowEnum["PARTNER_PARTY_ENROLLMENT"] = "PARTNER_PARTY_ENROLLMENT";
    ApiDeviceSessionResponseFlowEnum["ACH_DEPOSIT"] = "ACH_DEPOSIT";
    ApiDeviceSessionResponseFlowEnum["ACH_WITHDRAW"] = "ACH_WITHDRAW";
    ApiDeviceSessionResponseFlowEnum["BUY_CRYPTO"] = "BUY_CRYPTO";
    ApiDeviceSessionResponseFlowEnum["SELL_CRYPTO"] = "SELL_CRYPTO";
    ApiDeviceSessionResponseFlowEnum["SEND_CRYPTO"] = "SEND_CRYPTO";
    ApiDeviceSessionResponseFlowEnum["CONVERT_REWARD"] = "CONVERT_REWARD";
    ApiDeviceSessionResponseFlowEnum["SEND_CASH"] = "SEND_CASH";
    ApiDeviceSessionResponseFlowEnum["BUY_GIFT_CARD"] = "BUY_GIFT_CARD";
    ApiDeviceSessionResponseFlowEnum["LINKING_BANK"] = "LINKING_BANK";
    ApiDeviceSessionResponseFlowEnum["LINKING_DEBIT_CARD"] = "LINKING_DEBIT_CARD";
    ApiDeviceSessionResponseFlowEnum["DEBIT_CARD_DEPOSIT_TO_WALLET"] = "DEBIT_CARD_DEPOSIT_TO_WALLET";
    ApiDeviceSessionResponseFlowEnum["DEBIT_CARD_WITHDRAWAL_FROM_WALLET"] = "DEBIT_CARD_WITHDRAWAL_FROM_WALLET";
    ApiDeviceSessionResponseFlowEnum["PAYMENT"] = "PAYMENT";
    ApiDeviceSessionResponseFlowEnum["DEBITCARD_VERIFICATION"] = "DEBITCARD_VERIFICATION";
    ApiDeviceSessionResponseFlowEnum["LOGIN_PIN"] = "LOGIN_PIN";
    ApiDeviceSessionResponseFlowEnum["LOGIN_BIOMETRIC"] = "LOGIN_BIOMETRIC";
    ApiDeviceSessionResponseFlowEnum["CARD_ADD"] = "CARD_ADD";
    ApiDeviceSessionResponseFlowEnum["L1_TO_L4_LEVEL_UP"] = "L1_TO_L4_LEVEL_UP";
    ApiDeviceSessionResponseFlowEnum["L2_TO_L4_LEVEL_UP"] = "L2_TO_L4_LEVEL_UP";
    ApiDeviceSessionResponseFlowEnum["L3_TO_L4_LEVEL_UP"] = "L3_TO_L4_LEVEL_UP";
})(ApiDeviceSessionResponseFlowEnum || (ApiDeviceSessionResponseFlowEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDeviceSessionResponseSourceEnum;
(function (ApiDeviceSessionResponseSourceEnum) {
    ApiDeviceSessionResponseSourceEnum["ARKOSE"] = "ARKOSE";
    ApiDeviceSessionResponseSourceEnum["SARDINE"] = "SARDINE";
    ApiDeviceSessionResponseSourceEnum["SIFT"] = "SIFT";
})(ApiDeviceSessionResponseSourceEnum || (ApiDeviceSessionResponseSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDisputeCreateRequestCurrencyEnum;
(function (ApiDisputeCreateRequestCurrencyEnum) {
    ApiDisputeCreateRequestCurrencyEnum["USD"] = "USD";
    ApiDisputeCreateRequestCurrencyEnum["BCH"] = "BCH";
    ApiDisputeCreateRequestCurrencyEnum["BTC"] = "BTC";
    ApiDisputeCreateRequestCurrencyEnum["DOGE"] = "DOGE";
    ApiDisputeCreateRequestCurrencyEnum["ETC"] = "ETC";
    ApiDisputeCreateRequestCurrencyEnum["ETH"] = "ETH";
    ApiDisputeCreateRequestCurrencyEnum["LTC"] = "LTC";
    ApiDisputeCreateRequestCurrencyEnum["SHIB"] = "SHIB";
    ApiDisputeCreateRequestCurrencyEnum["USDC"] = "USDC";
    ApiDisputeCreateRequestCurrencyEnum["CET"] = "CET";
    ApiDisputeCreateRequestCurrencyEnum["LXX"] = "LXX";
    ApiDisputeCreateRequestCurrencyEnum["LCP"] = "LCP";
    ApiDisputeCreateRequestCurrencyEnum["LWR"] = "LWR";
    ApiDisputeCreateRequestCurrencyEnum["LMO"] = "LMO";
    ApiDisputeCreateRequestCurrencyEnum["LKO"] = "LKO";
    ApiDisputeCreateRequestCurrencyEnum["LED"] = "LED";
})(ApiDisputeCreateRequestCurrencyEnum || (ApiDisputeCreateRequestCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDisputeCreateRequestDisputeTypeEnum;
(function (ApiDisputeCreateRequestDisputeTypeEnum) {
    ApiDisputeCreateRequestDisputeTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiDisputeCreateRequestDisputeTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiDisputeCreateRequestDisputeTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiDisputeCreateRequestDisputeTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiDisputeCreateRequestDisputeTypeEnum["MERCHANT_PAYMENT_V1"] = "MERCHANT_PAYMENT_V1";
    ApiDisputeCreateRequestDisputeTypeEnum["PARTNER_TRANSACTION"] = "PARTNER_TRANSACTION";
})(ApiDisputeCreateRequestDisputeTypeEnum || (ApiDisputeCreateRequestDisputeTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDisputeCreateRequestReasonCodeEnum;
(function (ApiDisputeCreateRequestReasonCodeEnum) {
    ApiDisputeCreateRequestReasonCodeEnum["DAC01"] = "DAC01";
    ApiDisputeCreateRequestReasonCodeEnum["DAC02"] = "DAC02";
    ApiDisputeCreateRequestReasonCodeEnum["DAC03"] = "DAC03";
    ApiDisputeCreateRequestReasonCodeEnum["DAC04"] = "DAC04";
    ApiDisputeCreateRequestReasonCodeEnum["DAC05"] = "DAC05";
    ApiDisputeCreateRequestReasonCodeEnum["DAC06"] = "DAC06";
    ApiDisputeCreateRequestReasonCodeEnum["DAC07"] = "DAC07";
    ApiDisputeCreateRequestReasonCodeEnum["DAC08"] = "DAC08";
    ApiDisputeCreateRequestReasonCodeEnum["DAC09"] = "DAC09";
    ApiDisputeCreateRequestReasonCodeEnum["DAC10"] = "DAC10";
})(ApiDisputeCreateRequestReasonCodeEnum || (ApiDisputeCreateRequestReasonCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiDisputeRegulationERequestRegulationEFlagEnum;
(function (ApiDisputeRegulationERequestRegulationEFlagEnum) {
    ApiDisputeRegulationERequestRegulationEFlagEnum["YES"] = "YES";
    ApiDisputeRegulationERequestRegulationEFlagEnum["NO"] = "NO";
    ApiDisputeRegulationERequestRegulationEFlagEnum["NO_DISPOSITION"] = "NO_DISPOSITION";
})(ApiDisputeRegulationERequestRegulationEFlagEnum || (ApiDisputeRegulationERequestRegulationEFlagEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEmbeddedPartnerConfigurationMissingFieldsEnum;
(function (ApiEmbeddedPartnerConfigurationMissingFieldsEnum) {
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["TAX_ID_NUMBER"] = "TAX_ID_NUMBER";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["OCCUPATION"] = "OCCUPATION";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["FIRST_NAME"] = "FIRST_NAME";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["LAST_NAME"] = "LAST_NAME";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["ADDRESS"] = "ADDRESS";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["PHONE"] = "PHONE";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["DATE_OF_BIRTH"] = "DATE_OF_BIRTH";
    ApiEmbeddedPartnerConfigurationMissingFieldsEnum["EMAIL"] = "EMAIL";
})(ApiEmbeddedPartnerConfigurationMissingFieldsEnum || (ApiEmbeddedPartnerConfigurationMissingFieldsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrichedAchRecordStatusEnum;
(function (ApiEnrichedAchRecordStatusEnum) {
    ApiEnrichedAchRecordStatusEnum["READY"] = "READY";
    ApiEnrichedAchRecordStatusEnum["ERROR"] = "ERROR";
    ApiEnrichedAchRecordStatusEnum["PROCESSED"] = "PROCESSED";
})(ApiEnrichedAchRecordStatusEnum || (ApiEnrichedAchRecordStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrichedAchRecordTypeEnum;
(function (ApiEnrichedAchRecordTypeEnum) {
    ApiEnrichedAchRecordTypeEnum["UNSPECIFIED"] = "UNSPECIFIED";
    ApiEnrichedAchRecordTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiEnrichedAchRecordTypeEnum["WITHDRAWAL"] = "WITHDRAWAL";
    ApiEnrichedAchRecordTypeEnum["RETURN_DEPOSIT"] = "RETURN_DEPOSIT";
    ApiEnrichedAchRecordTypeEnum["RETURN_WITHDRAWAL"] = "RETURN_WITHDRAWAL";
})(ApiEnrichedAchRecordTypeEnum || (ApiEnrichedAchRecordTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrolleeCitizenshipCountryEnum;
(function (ApiEnrolleeCitizenshipCountryEnum) {
    ApiEnrolleeCitizenshipCountryEnum["AND"] = "AND";
    ApiEnrolleeCitizenshipCountryEnum["ARE"] = "ARE";
    ApiEnrolleeCitizenshipCountryEnum["AFG"] = "AFG";
    ApiEnrolleeCitizenshipCountryEnum["ATG"] = "ATG";
    ApiEnrolleeCitizenshipCountryEnum["AIA"] = "AIA";
    ApiEnrolleeCitizenshipCountryEnum["ALB"] = "ALB";
    ApiEnrolleeCitizenshipCountryEnum["ARM"] = "ARM";
    ApiEnrolleeCitizenshipCountryEnum["AGO"] = "AGO";
    ApiEnrolleeCitizenshipCountryEnum["ATA"] = "ATA";
    ApiEnrolleeCitizenshipCountryEnum["ARG"] = "ARG";
    ApiEnrolleeCitizenshipCountryEnum["ASM"] = "ASM";
    ApiEnrolleeCitizenshipCountryEnum["AUT"] = "AUT";
    ApiEnrolleeCitizenshipCountryEnum["AUS"] = "AUS";
    ApiEnrolleeCitizenshipCountryEnum["ABW"] = "ABW";
    ApiEnrolleeCitizenshipCountryEnum["ALA"] = "ALA";
    ApiEnrolleeCitizenshipCountryEnum["AZE"] = "AZE";
    ApiEnrolleeCitizenshipCountryEnum["BIH"] = "BIH";
    ApiEnrolleeCitizenshipCountryEnum["BRB"] = "BRB";
    ApiEnrolleeCitizenshipCountryEnum["BGD"] = "BGD";
    ApiEnrolleeCitizenshipCountryEnum["BEL"] = "BEL";
    ApiEnrolleeCitizenshipCountryEnum["BFA"] = "BFA";
    ApiEnrolleeCitizenshipCountryEnum["BGR"] = "BGR";
    ApiEnrolleeCitizenshipCountryEnum["BHR"] = "BHR";
    ApiEnrolleeCitizenshipCountryEnum["BDI"] = "BDI";
    ApiEnrolleeCitizenshipCountryEnum["BEN"] = "BEN";
    ApiEnrolleeCitizenshipCountryEnum["BLM"] = "BLM";
    ApiEnrolleeCitizenshipCountryEnum["BMU"] = "BMU";
    ApiEnrolleeCitizenshipCountryEnum["BRN"] = "BRN";
    ApiEnrolleeCitizenshipCountryEnum["BOL"] = "BOL";
    ApiEnrolleeCitizenshipCountryEnum["BES"] = "BES";
    ApiEnrolleeCitizenshipCountryEnum["BRA"] = "BRA";
    ApiEnrolleeCitizenshipCountryEnum["BHS"] = "BHS";
    ApiEnrolleeCitizenshipCountryEnum["BTN"] = "BTN";
    ApiEnrolleeCitizenshipCountryEnum["BVT"] = "BVT";
    ApiEnrolleeCitizenshipCountryEnum["BWA"] = "BWA";
    ApiEnrolleeCitizenshipCountryEnum["BLR"] = "BLR";
    ApiEnrolleeCitizenshipCountryEnum["BLZ"] = "BLZ";
    ApiEnrolleeCitizenshipCountryEnum["CAN"] = "CAN";
    ApiEnrolleeCitizenshipCountryEnum["CCK"] = "CCK";
    ApiEnrolleeCitizenshipCountryEnum["COD"] = "COD";
    ApiEnrolleeCitizenshipCountryEnum["CAF"] = "CAF";
    ApiEnrolleeCitizenshipCountryEnum["COG"] = "COG";
    ApiEnrolleeCitizenshipCountryEnum["CHE"] = "CHE";
    ApiEnrolleeCitizenshipCountryEnum["CIV"] = "CIV";
    ApiEnrolleeCitizenshipCountryEnum["COK"] = "COK";
    ApiEnrolleeCitizenshipCountryEnum["CHL"] = "CHL";
    ApiEnrolleeCitizenshipCountryEnum["CMR"] = "CMR";
    ApiEnrolleeCitizenshipCountryEnum["CHN"] = "CHN";
    ApiEnrolleeCitizenshipCountryEnum["COL"] = "COL";
    ApiEnrolleeCitizenshipCountryEnum["CRI"] = "CRI";
    ApiEnrolleeCitizenshipCountryEnum["CUB"] = "CUB";
    ApiEnrolleeCitizenshipCountryEnum["CPV"] = "CPV";
    ApiEnrolleeCitizenshipCountryEnum["CUW"] = "CUW";
    ApiEnrolleeCitizenshipCountryEnum["CXR"] = "CXR";
    ApiEnrolleeCitizenshipCountryEnum["CYP"] = "CYP";
    ApiEnrolleeCitizenshipCountryEnum["CZE"] = "CZE";
    ApiEnrolleeCitizenshipCountryEnum["DEU"] = "DEU";
    ApiEnrolleeCitizenshipCountryEnum["DJI"] = "DJI";
    ApiEnrolleeCitizenshipCountryEnum["DNK"] = "DNK";
    ApiEnrolleeCitizenshipCountryEnum["DMA"] = "DMA";
    ApiEnrolleeCitizenshipCountryEnum["DOM"] = "DOM";
    ApiEnrolleeCitizenshipCountryEnum["DZA"] = "DZA";
    ApiEnrolleeCitizenshipCountryEnum["ECU"] = "ECU";
    ApiEnrolleeCitizenshipCountryEnum["EST"] = "EST";
    ApiEnrolleeCitizenshipCountryEnum["EGY"] = "EGY";
    ApiEnrolleeCitizenshipCountryEnum["ESH"] = "ESH";
    ApiEnrolleeCitizenshipCountryEnum["ERI"] = "ERI";
    ApiEnrolleeCitizenshipCountryEnum["ESP"] = "ESP";
    ApiEnrolleeCitizenshipCountryEnum["ETH"] = "ETH";
    ApiEnrolleeCitizenshipCountryEnum["FIN"] = "FIN";
    ApiEnrolleeCitizenshipCountryEnum["FJI"] = "FJI";
    ApiEnrolleeCitizenshipCountryEnum["FLK"] = "FLK";
    ApiEnrolleeCitizenshipCountryEnum["FSM"] = "FSM";
    ApiEnrolleeCitizenshipCountryEnum["FRO"] = "FRO";
    ApiEnrolleeCitizenshipCountryEnum["FRA"] = "FRA";
    ApiEnrolleeCitizenshipCountryEnum["GAB"] = "GAB";
    ApiEnrolleeCitizenshipCountryEnum["GBR"] = "GBR";
    ApiEnrolleeCitizenshipCountryEnum["GRD"] = "GRD";
    ApiEnrolleeCitizenshipCountryEnum["GEO"] = "GEO";
    ApiEnrolleeCitizenshipCountryEnum["GUF"] = "GUF";
    ApiEnrolleeCitizenshipCountryEnum["GGY"] = "GGY";
    ApiEnrolleeCitizenshipCountryEnum["GHA"] = "GHA";
    ApiEnrolleeCitizenshipCountryEnum["GIB"] = "GIB";
    ApiEnrolleeCitizenshipCountryEnum["GRL"] = "GRL";
    ApiEnrolleeCitizenshipCountryEnum["GMB"] = "GMB";
    ApiEnrolleeCitizenshipCountryEnum["GIN"] = "GIN";
    ApiEnrolleeCitizenshipCountryEnum["GLP"] = "GLP";
    ApiEnrolleeCitizenshipCountryEnum["GNQ"] = "GNQ";
    ApiEnrolleeCitizenshipCountryEnum["GRC"] = "GRC";
    ApiEnrolleeCitizenshipCountryEnum["SGS"] = "SGS";
    ApiEnrolleeCitizenshipCountryEnum["GTM"] = "GTM";
    ApiEnrolleeCitizenshipCountryEnum["GUM"] = "GUM";
    ApiEnrolleeCitizenshipCountryEnum["GNB"] = "GNB";
    ApiEnrolleeCitizenshipCountryEnum["GUY"] = "GUY";
    ApiEnrolleeCitizenshipCountryEnum["HKG"] = "HKG";
    ApiEnrolleeCitizenshipCountryEnum["HMD"] = "HMD";
    ApiEnrolleeCitizenshipCountryEnum["HND"] = "HND";
    ApiEnrolleeCitizenshipCountryEnum["HRV"] = "HRV";
    ApiEnrolleeCitizenshipCountryEnum["HTI"] = "HTI";
    ApiEnrolleeCitizenshipCountryEnum["HUN"] = "HUN";
    ApiEnrolleeCitizenshipCountryEnum["IDN"] = "IDN";
    ApiEnrolleeCitizenshipCountryEnum["IRL"] = "IRL";
    ApiEnrolleeCitizenshipCountryEnum["ISR"] = "ISR";
    ApiEnrolleeCitizenshipCountryEnum["IMN"] = "IMN";
    ApiEnrolleeCitizenshipCountryEnum["IND"] = "IND";
    ApiEnrolleeCitizenshipCountryEnum["IOT"] = "IOT";
    ApiEnrolleeCitizenshipCountryEnum["IRQ"] = "IRQ";
    ApiEnrolleeCitizenshipCountryEnum["IRN"] = "IRN";
    ApiEnrolleeCitizenshipCountryEnum["ISL"] = "ISL";
    ApiEnrolleeCitizenshipCountryEnum["ITA"] = "ITA";
    ApiEnrolleeCitizenshipCountryEnum["JEY"] = "JEY";
    ApiEnrolleeCitizenshipCountryEnum["JAM"] = "JAM";
    ApiEnrolleeCitizenshipCountryEnum["JOR"] = "JOR";
    ApiEnrolleeCitizenshipCountryEnum["JPN"] = "JPN";
    ApiEnrolleeCitizenshipCountryEnum["KEN"] = "KEN";
    ApiEnrolleeCitizenshipCountryEnum["KGZ"] = "KGZ";
    ApiEnrolleeCitizenshipCountryEnum["KHM"] = "KHM";
    ApiEnrolleeCitizenshipCountryEnum["KIR"] = "KIR";
    ApiEnrolleeCitizenshipCountryEnum["COM"] = "COM";
    ApiEnrolleeCitizenshipCountryEnum["KNA"] = "KNA";
    ApiEnrolleeCitizenshipCountryEnum["PRK"] = "PRK";
    ApiEnrolleeCitizenshipCountryEnum["KOR"] = "KOR";
    ApiEnrolleeCitizenshipCountryEnum["KWT"] = "KWT";
    ApiEnrolleeCitizenshipCountryEnum["CYM"] = "CYM";
    ApiEnrolleeCitizenshipCountryEnum["KAZ"] = "KAZ";
    ApiEnrolleeCitizenshipCountryEnum["LAO"] = "LAO";
    ApiEnrolleeCitizenshipCountryEnum["LBN"] = "LBN";
    ApiEnrolleeCitizenshipCountryEnum["LCA"] = "LCA";
    ApiEnrolleeCitizenshipCountryEnum["LIE"] = "LIE";
    ApiEnrolleeCitizenshipCountryEnum["LKA"] = "LKA";
    ApiEnrolleeCitizenshipCountryEnum["LBR"] = "LBR";
    ApiEnrolleeCitizenshipCountryEnum["LSO"] = "LSO";
    ApiEnrolleeCitizenshipCountryEnum["LTU"] = "LTU";
    ApiEnrolleeCitizenshipCountryEnum["LUX"] = "LUX";
    ApiEnrolleeCitizenshipCountryEnum["LVA"] = "LVA";
    ApiEnrolleeCitizenshipCountryEnum["LBY"] = "LBY";
    ApiEnrolleeCitizenshipCountryEnum["MAR"] = "MAR";
    ApiEnrolleeCitizenshipCountryEnum["MCO"] = "MCO";
    ApiEnrolleeCitizenshipCountryEnum["MDA"] = "MDA";
    ApiEnrolleeCitizenshipCountryEnum["MNE"] = "MNE";
    ApiEnrolleeCitizenshipCountryEnum["MAF"] = "MAF";
    ApiEnrolleeCitizenshipCountryEnum["MDG"] = "MDG";
    ApiEnrolleeCitizenshipCountryEnum["MHL"] = "MHL";
    ApiEnrolleeCitizenshipCountryEnum["MKD"] = "MKD";
    ApiEnrolleeCitizenshipCountryEnum["MLI"] = "MLI";
    ApiEnrolleeCitizenshipCountryEnum["MMR"] = "MMR";
    ApiEnrolleeCitizenshipCountryEnum["MNG"] = "MNG";
    ApiEnrolleeCitizenshipCountryEnum["MAC"] = "MAC";
    ApiEnrolleeCitizenshipCountryEnum["MNP"] = "MNP";
    ApiEnrolleeCitizenshipCountryEnum["MTQ"] = "MTQ";
    ApiEnrolleeCitizenshipCountryEnum["MRT"] = "MRT";
    ApiEnrolleeCitizenshipCountryEnum["MSR"] = "MSR";
    ApiEnrolleeCitizenshipCountryEnum["MLT"] = "MLT";
    ApiEnrolleeCitizenshipCountryEnum["MUS"] = "MUS";
    ApiEnrolleeCitizenshipCountryEnum["MDV"] = "MDV";
    ApiEnrolleeCitizenshipCountryEnum["MWI"] = "MWI";
    ApiEnrolleeCitizenshipCountryEnum["MEX"] = "MEX";
    ApiEnrolleeCitizenshipCountryEnum["MYS"] = "MYS";
    ApiEnrolleeCitizenshipCountryEnum["MOZ"] = "MOZ";
    ApiEnrolleeCitizenshipCountryEnum["NAM"] = "NAM";
    ApiEnrolleeCitizenshipCountryEnum["NCL"] = "NCL";
    ApiEnrolleeCitizenshipCountryEnum["NER"] = "NER";
    ApiEnrolleeCitizenshipCountryEnum["NFK"] = "NFK";
    ApiEnrolleeCitizenshipCountryEnum["NGA"] = "NGA";
    ApiEnrolleeCitizenshipCountryEnum["NIC"] = "NIC";
    ApiEnrolleeCitizenshipCountryEnum["NLD"] = "NLD";
    ApiEnrolleeCitizenshipCountryEnum["NOR"] = "NOR";
    ApiEnrolleeCitizenshipCountryEnum["NPL"] = "NPL";
    ApiEnrolleeCitizenshipCountryEnum["NRU"] = "NRU";
    ApiEnrolleeCitizenshipCountryEnum["NIU"] = "NIU";
    ApiEnrolleeCitizenshipCountryEnum["NZL"] = "NZL";
    ApiEnrolleeCitizenshipCountryEnum["OMN"] = "OMN";
    ApiEnrolleeCitizenshipCountryEnum["PAN"] = "PAN";
    ApiEnrolleeCitizenshipCountryEnum["PER"] = "PER";
    ApiEnrolleeCitizenshipCountryEnum["PYF"] = "PYF";
    ApiEnrolleeCitizenshipCountryEnum["PNG"] = "PNG";
    ApiEnrolleeCitizenshipCountryEnum["PHL"] = "PHL";
    ApiEnrolleeCitizenshipCountryEnum["PAK"] = "PAK";
    ApiEnrolleeCitizenshipCountryEnum["POL"] = "POL";
    ApiEnrolleeCitizenshipCountryEnum["SPM"] = "SPM";
    ApiEnrolleeCitizenshipCountryEnum["PCN"] = "PCN";
    ApiEnrolleeCitizenshipCountryEnum["PRI"] = "PRI";
    ApiEnrolleeCitizenshipCountryEnum["PSE"] = "PSE";
    ApiEnrolleeCitizenshipCountryEnum["PRT"] = "PRT";
    ApiEnrolleeCitizenshipCountryEnum["PLW"] = "PLW";
    ApiEnrolleeCitizenshipCountryEnum["PRY"] = "PRY";
    ApiEnrolleeCitizenshipCountryEnum["QAT"] = "QAT";
    ApiEnrolleeCitizenshipCountryEnum["REU"] = "REU";
    ApiEnrolleeCitizenshipCountryEnum["ROU"] = "ROU";
    ApiEnrolleeCitizenshipCountryEnum["SRB"] = "SRB";
    ApiEnrolleeCitizenshipCountryEnum["RUS"] = "RUS";
    ApiEnrolleeCitizenshipCountryEnum["RWA"] = "RWA";
    ApiEnrolleeCitizenshipCountryEnum["SAU"] = "SAU";
    ApiEnrolleeCitizenshipCountryEnum["SLB"] = "SLB";
    ApiEnrolleeCitizenshipCountryEnum["SYC"] = "SYC";
    ApiEnrolleeCitizenshipCountryEnum["SDN"] = "SDN";
    ApiEnrolleeCitizenshipCountryEnum["SWE"] = "SWE";
    ApiEnrolleeCitizenshipCountryEnum["SGP"] = "SGP";
    ApiEnrolleeCitizenshipCountryEnum["SHN"] = "SHN";
    ApiEnrolleeCitizenshipCountryEnum["SVN"] = "SVN";
    ApiEnrolleeCitizenshipCountryEnum["SJM"] = "SJM";
    ApiEnrolleeCitizenshipCountryEnum["SVK"] = "SVK";
    ApiEnrolleeCitizenshipCountryEnum["SLE"] = "SLE";
    ApiEnrolleeCitizenshipCountryEnum["SMR"] = "SMR";
    ApiEnrolleeCitizenshipCountryEnum["SEN"] = "SEN";
    ApiEnrolleeCitizenshipCountryEnum["SOM"] = "SOM";
    ApiEnrolleeCitizenshipCountryEnum["SUR"] = "SUR";
    ApiEnrolleeCitizenshipCountryEnum["SSD"] = "SSD";
    ApiEnrolleeCitizenshipCountryEnum["STP"] = "STP";
    ApiEnrolleeCitizenshipCountryEnum["SLV"] = "SLV";
    ApiEnrolleeCitizenshipCountryEnum["SXM"] = "SXM";
    ApiEnrolleeCitizenshipCountryEnum["SYR"] = "SYR";
    ApiEnrolleeCitizenshipCountryEnum["SWZ"] = "SWZ";
    ApiEnrolleeCitizenshipCountryEnum["TCA"] = "TCA";
    ApiEnrolleeCitizenshipCountryEnum["TCD"] = "TCD";
    ApiEnrolleeCitizenshipCountryEnum["ATF"] = "ATF";
    ApiEnrolleeCitizenshipCountryEnum["TGO"] = "TGO";
    ApiEnrolleeCitizenshipCountryEnum["THA"] = "THA";
    ApiEnrolleeCitizenshipCountryEnum["TJK"] = "TJK";
    ApiEnrolleeCitizenshipCountryEnum["TKL"] = "TKL";
    ApiEnrolleeCitizenshipCountryEnum["TLS"] = "TLS";
    ApiEnrolleeCitizenshipCountryEnum["TKM"] = "TKM";
    ApiEnrolleeCitizenshipCountryEnum["TUN"] = "TUN";
    ApiEnrolleeCitizenshipCountryEnum["TON"] = "TON";
    ApiEnrolleeCitizenshipCountryEnum["TUR"] = "TUR";
    ApiEnrolleeCitizenshipCountryEnum["TTO"] = "TTO";
    ApiEnrolleeCitizenshipCountryEnum["TUV"] = "TUV";
    ApiEnrolleeCitizenshipCountryEnum["TWN"] = "TWN";
    ApiEnrolleeCitizenshipCountryEnum["TZA"] = "TZA";
    ApiEnrolleeCitizenshipCountryEnum["UKR"] = "UKR";
    ApiEnrolleeCitizenshipCountryEnum["UGA"] = "UGA";
    ApiEnrolleeCitizenshipCountryEnum["UMI"] = "UMI";
    ApiEnrolleeCitizenshipCountryEnum["USA"] = "USA";
    ApiEnrolleeCitizenshipCountryEnum["URY"] = "URY";
    ApiEnrolleeCitizenshipCountryEnum["UZB"] = "UZB";
    ApiEnrolleeCitizenshipCountryEnum["VAT"] = "VAT";
    ApiEnrolleeCitizenshipCountryEnum["VCT"] = "VCT";
    ApiEnrolleeCitizenshipCountryEnum["VEN"] = "VEN";
    ApiEnrolleeCitizenshipCountryEnum["VGB"] = "VGB";
    ApiEnrolleeCitizenshipCountryEnum["VIR"] = "VIR";
    ApiEnrolleeCitizenshipCountryEnum["VNM"] = "VNM";
    ApiEnrolleeCitizenshipCountryEnum["VUT"] = "VUT";
    ApiEnrolleeCitizenshipCountryEnum["WLF"] = "WLF";
    ApiEnrolleeCitizenshipCountryEnum["WSM"] = "WSM";
    ApiEnrolleeCitizenshipCountryEnum["YEM"] = "YEM";
    ApiEnrolleeCitizenshipCountryEnum["MYT"] = "MYT";
    ApiEnrolleeCitizenshipCountryEnum["ZAF"] = "ZAF";
    ApiEnrolleeCitizenshipCountryEnum["ZMB"] = "ZMB";
    ApiEnrolleeCitizenshipCountryEnum["ZWE"] = "ZWE";
})(ApiEnrolleeCitizenshipCountryEnum || (ApiEnrolleeCitizenshipCountryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrolleeRoleEnum;
(function (ApiEnrolleeRoleEnum) {
    ApiEnrolleeRoleEnum["PRIMARY_OWNER"] = "PRIMARY_OWNER";
    ApiEnrolleeRoleEnum["BENEFICIAL_OWNER"] = "BENEFICIAL_OWNER";
    ApiEnrolleeRoleEnum["JOINT_OWNER"] = "JOINT_OWNER";
    ApiEnrolleeRoleEnum["CUSTODIAL_OWNER"] = "CUSTODIAL_OWNER";
})(ApiEnrolleeRoleEnum || (ApiEnrolleeRoleEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrolleeTaxIdTypeEnum;
(function (ApiEnrolleeTaxIdTypeEnum) {
    ApiEnrolleeTaxIdTypeEnum["SSN"] = "SSN";
    ApiEnrolleeTaxIdTypeEnum["ITIN"] = "ITIN";
    ApiEnrolleeTaxIdTypeEnum["EIN"] = "EIN";
    ApiEnrolleeTaxIdTypeEnum["TIN"] = "TIN";
    ApiEnrolleeTaxIdTypeEnum["NATIONAL_ID"] = "NATIONAL_ID";
})(ApiEnrolleeTaxIdTypeEnum || (ApiEnrolleeTaxIdTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrolleeUpdateRequestCitizenshipCountryEnum;
(function (ApiEnrolleeUpdateRequestCitizenshipCountryEnum) {
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AND"] = "AND";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ARE"] = "ARE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AFG"] = "AFG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ATG"] = "ATG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AIA"] = "AIA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ALB"] = "ALB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ARM"] = "ARM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AGO"] = "AGO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ATA"] = "ATA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ARG"] = "ARG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ASM"] = "ASM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AUT"] = "AUT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AUS"] = "AUS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ABW"] = "ABW";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ALA"] = "ALA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["AZE"] = "AZE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BIH"] = "BIH";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BRB"] = "BRB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BGD"] = "BGD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BEL"] = "BEL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BFA"] = "BFA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BGR"] = "BGR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BHR"] = "BHR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BDI"] = "BDI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BEN"] = "BEN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BLM"] = "BLM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BMU"] = "BMU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BRN"] = "BRN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BOL"] = "BOL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BES"] = "BES";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BRA"] = "BRA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BHS"] = "BHS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BTN"] = "BTN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BVT"] = "BVT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BWA"] = "BWA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BLR"] = "BLR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["BLZ"] = "BLZ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CAN"] = "CAN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CCK"] = "CCK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["COD"] = "COD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CAF"] = "CAF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["COG"] = "COG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CHE"] = "CHE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CIV"] = "CIV";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["COK"] = "COK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CHL"] = "CHL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CMR"] = "CMR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CHN"] = "CHN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["COL"] = "COL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CRI"] = "CRI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CUB"] = "CUB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CPV"] = "CPV";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CUW"] = "CUW";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CXR"] = "CXR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CYP"] = "CYP";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CZE"] = "CZE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["DEU"] = "DEU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["DJI"] = "DJI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["DNK"] = "DNK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["DMA"] = "DMA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["DOM"] = "DOM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["DZA"] = "DZA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ECU"] = "ECU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["EST"] = "EST";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["EGY"] = "EGY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ESH"] = "ESH";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ERI"] = "ERI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ESP"] = "ESP";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ETH"] = "ETH";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["FIN"] = "FIN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["FJI"] = "FJI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["FLK"] = "FLK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["FSM"] = "FSM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["FRO"] = "FRO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["FRA"] = "FRA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GAB"] = "GAB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GBR"] = "GBR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GRD"] = "GRD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GEO"] = "GEO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GUF"] = "GUF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GGY"] = "GGY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GHA"] = "GHA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GIB"] = "GIB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GRL"] = "GRL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GMB"] = "GMB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GIN"] = "GIN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GLP"] = "GLP";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GNQ"] = "GNQ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GRC"] = "GRC";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SGS"] = "SGS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GTM"] = "GTM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GUM"] = "GUM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GNB"] = "GNB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["GUY"] = "GUY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["HKG"] = "HKG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["HMD"] = "HMD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["HND"] = "HND";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["HRV"] = "HRV";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["HTI"] = "HTI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["HUN"] = "HUN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IDN"] = "IDN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IRL"] = "IRL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ISR"] = "ISR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IMN"] = "IMN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IND"] = "IND";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IOT"] = "IOT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IRQ"] = "IRQ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["IRN"] = "IRN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ISL"] = "ISL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ITA"] = "ITA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["JEY"] = "JEY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["JAM"] = "JAM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["JOR"] = "JOR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["JPN"] = "JPN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KEN"] = "KEN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KGZ"] = "KGZ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KHM"] = "KHM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KIR"] = "KIR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["COM"] = "COM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KNA"] = "KNA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PRK"] = "PRK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KOR"] = "KOR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KWT"] = "KWT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["CYM"] = "CYM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["KAZ"] = "KAZ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LAO"] = "LAO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LBN"] = "LBN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LCA"] = "LCA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LIE"] = "LIE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LKA"] = "LKA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LBR"] = "LBR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LSO"] = "LSO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LTU"] = "LTU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LUX"] = "LUX";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LVA"] = "LVA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["LBY"] = "LBY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MAR"] = "MAR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MCO"] = "MCO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MDA"] = "MDA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MNE"] = "MNE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MAF"] = "MAF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MDG"] = "MDG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MHL"] = "MHL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MKD"] = "MKD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MLI"] = "MLI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MMR"] = "MMR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MNG"] = "MNG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MAC"] = "MAC";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MNP"] = "MNP";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MTQ"] = "MTQ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MRT"] = "MRT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MSR"] = "MSR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MLT"] = "MLT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MUS"] = "MUS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MDV"] = "MDV";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MWI"] = "MWI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MEX"] = "MEX";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MYS"] = "MYS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MOZ"] = "MOZ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NAM"] = "NAM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NCL"] = "NCL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NER"] = "NER";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NFK"] = "NFK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NGA"] = "NGA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NIC"] = "NIC";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NLD"] = "NLD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NOR"] = "NOR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NPL"] = "NPL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NRU"] = "NRU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NIU"] = "NIU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["NZL"] = "NZL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["OMN"] = "OMN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PAN"] = "PAN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PER"] = "PER";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PYF"] = "PYF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PNG"] = "PNG";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PHL"] = "PHL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PAK"] = "PAK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["POL"] = "POL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SPM"] = "SPM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PCN"] = "PCN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PRI"] = "PRI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PSE"] = "PSE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PRT"] = "PRT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PLW"] = "PLW";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["PRY"] = "PRY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["QAT"] = "QAT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["REU"] = "REU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ROU"] = "ROU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SRB"] = "SRB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["RUS"] = "RUS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["RWA"] = "RWA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SAU"] = "SAU";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SLB"] = "SLB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SYC"] = "SYC";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SDN"] = "SDN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SWE"] = "SWE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SGP"] = "SGP";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SHN"] = "SHN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SVN"] = "SVN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SJM"] = "SJM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SVK"] = "SVK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SLE"] = "SLE";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SMR"] = "SMR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SEN"] = "SEN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SOM"] = "SOM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SUR"] = "SUR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SSD"] = "SSD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["STP"] = "STP";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SLV"] = "SLV";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SXM"] = "SXM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SYR"] = "SYR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["SWZ"] = "SWZ";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TCA"] = "TCA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TCD"] = "TCD";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ATF"] = "ATF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TGO"] = "TGO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["THA"] = "THA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TJK"] = "TJK";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TKL"] = "TKL";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TLS"] = "TLS";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TKM"] = "TKM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TUN"] = "TUN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TON"] = "TON";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TUR"] = "TUR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TTO"] = "TTO";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TUV"] = "TUV";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TWN"] = "TWN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["TZA"] = "TZA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["UKR"] = "UKR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["UGA"] = "UGA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["UMI"] = "UMI";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["USA"] = "USA";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["URY"] = "URY";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["UZB"] = "UZB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VAT"] = "VAT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VCT"] = "VCT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VEN"] = "VEN";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VGB"] = "VGB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VIR"] = "VIR";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VNM"] = "VNM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["VUT"] = "VUT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["WLF"] = "WLF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["WSM"] = "WSM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["YEM"] = "YEM";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["MYT"] = "MYT";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ZAF"] = "ZAF";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ZMB"] = "ZMB";
    ApiEnrolleeUpdateRequestCitizenshipCountryEnum["ZWE"] = "ZWE";
})(ApiEnrolleeUpdateRequestCitizenshipCountryEnum || (ApiEnrolleeUpdateRequestCitizenshipCountryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEnrolleeUpdateRequestRoleEnum;
(function (ApiEnrolleeUpdateRequestRoleEnum) {
    ApiEnrolleeUpdateRequestRoleEnum["PRIMARY_OWNER"] = "PRIMARY_OWNER";
    ApiEnrolleeUpdateRequestRoleEnum["BENEFICIAL_OWNER"] = "BENEFICIAL_OWNER";
    ApiEnrolleeUpdateRequestRoleEnum["JOINT_OWNER"] = "JOINT_OWNER";
    ApiEnrolleeUpdateRequestRoleEnum["OWNER"] = "OWNER";
})(ApiEnrolleeUpdateRequestRoleEnum || (ApiEnrolleeUpdateRequestRoleEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityDailyNotionalTradingVolumeUsdRangeEnum;
(function (ApiEntityDailyNotionalTradingVolumeUsdRangeEnum) {
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityDailyNotionalTradingVolumeUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityDailyNotionalTradingVolumeUsdRangeEnum || (ApiEntityDailyNotionalTradingVolumeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityDailyTransactionVolumeUsdRangeEnum;
(function (ApiEntityDailyTransactionVolumeUsdRangeEnum) {
    ApiEntityDailyTransactionVolumeUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityDailyTransactionVolumeUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityDailyTransactionVolumeUsdRangeEnum || (ApiEntityDailyTransactionVolumeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityEntityRegistrationStatusEnum;
(function (ApiEntityEntityRegistrationStatusEnum) {
    ApiEntityEntityRegistrationStatusEnum["CURRENTLY_REGISTERED"] = "CURRENTLY_REGISTERED";
    ApiEntityEntityRegistrationStatusEnum["NOT_REGISTERED"] = "NOT_REGISTERED";
    ApiEntityEntityRegistrationStatusEnum["REGISTRATION_IN_PROGRESS"] = "REGISTRATION_IN_PROGRESS";
    ApiEntityEntityRegistrationStatusEnum["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(ApiEntityEntityRegistrationStatusEnum || (ApiEntityEntityRegistrationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityEntityTypeEnum;
(function (ApiEntityEntityTypeEnum) {
    ApiEntityEntityTypeEnum["CORPORATE"] = "CORPORATE";
    ApiEntityEntityTypeEnum["PARTNERSHIP"] = "PARTNERSHIP";
    ApiEntityEntityTypeEnum["LLC"] = "LLC";
    ApiEntityEntityTypeEnum["LIMITED_PARTNERSHIP"] = "LIMITED_PARTNERSHIP";
    ApiEntityEntityTypeEnum["TRUST"] = "TRUST";
})(ApiEntityEntityTypeEnum || (ApiEntityEntityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityFirmAnnualNetIncomeEnum;
(function (ApiEntityFirmAnnualNetIncomeEnum) {
    ApiEntityFirmAnnualNetIncomeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityFirmAnnualNetIncomeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityFirmAnnualNetIncomeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityFirmAnnualNetIncomeEnum || (ApiEntityFirmAnnualNetIncomeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityFirmLiquidNetWorthUsdRangeEnum;
(function (ApiEntityFirmLiquidNetWorthUsdRangeEnum) {
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityFirmLiquidNetWorthUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityFirmLiquidNetWorthUsdRangeEnum || (ApiEntityFirmLiquidNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityFirmTotalNetWorthUsdRangeEnum;
(function (ApiEntityFirmTotalNetWorthUsdRangeEnum) {
    ApiEntityFirmTotalNetWorthUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityFirmTotalNetWorthUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityFirmTotalNetWorthUsdRangeEnum || (ApiEntityFirmTotalNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityInvestmentExperienceEnum;
(function (ApiEntityInvestmentExperienceEnum) {
    ApiEntityInvestmentExperienceEnum["NONE"] = "NONE";
    ApiEntityInvestmentExperienceEnum["LIMITED"] = "LIMITED";
    ApiEntityInvestmentExperienceEnum["GOOD"] = "GOOD";
    ApiEntityInvestmentExperienceEnum["EXTENSIVE"] = "EXTENSIVE";
})(ApiEntityInvestmentExperienceEnum || (ApiEntityInvestmentExperienceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityInvestmentObjectiveEnum;
(function (ApiEntityInvestmentObjectiveEnum) {
    ApiEntityInvestmentObjectiveEnum["BALANCED"] = "BALANCED";
    ApiEntityInvestmentObjectiveEnum["CAPITAL_PRESERVATION"] = "CAPITAL_PRESERVATION";
    ApiEntityInvestmentObjectiveEnum["GROWTH"] = "GROWTH";
    ApiEntityInvestmentObjectiveEnum["INCOME"] = "INCOME";
    ApiEntityInvestmentObjectiveEnum["SPECULATION"] = "SPECULATION";
    ApiEntityInvestmentObjectiveEnum["OTHER"] = "OTHER";
})(ApiEntityInvestmentObjectiveEnum || (ApiEntityInvestmentObjectiveEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityOperatingCountriesEnum;
(function (ApiEntityOperatingCountriesEnum) {
    ApiEntityOperatingCountriesEnum["AND"] = "AND";
    ApiEntityOperatingCountriesEnum["ARE"] = "ARE";
    ApiEntityOperatingCountriesEnum["AFG"] = "AFG";
    ApiEntityOperatingCountriesEnum["ATG"] = "ATG";
    ApiEntityOperatingCountriesEnum["AIA"] = "AIA";
    ApiEntityOperatingCountriesEnum["ALB"] = "ALB";
    ApiEntityOperatingCountriesEnum["ARM"] = "ARM";
    ApiEntityOperatingCountriesEnum["AGO"] = "AGO";
    ApiEntityOperatingCountriesEnum["ATA"] = "ATA";
    ApiEntityOperatingCountriesEnum["ARG"] = "ARG";
    ApiEntityOperatingCountriesEnum["ASM"] = "ASM";
    ApiEntityOperatingCountriesEnum["AUT"] = "AUT";
    ApiEntityOperatingCountriesEnum["AUS"] = "AUS";
    ApiEntityOperatingCountriesEnum["ABW"] = "ABW";
    ApiEntityOperatingCountriesEnum["ALA"] = "ALA";
    ApiEntityOperatingCountriesEnum["AZE"] = "AZE";
    ApiEntityOperatingCountriesEnum["BIH"] = "BIH";
    ApiEntityOperatingCountriesEnum["BRB"] = "BRB";
    ApiEntityOperatingCountriesEnum["BGD"] = "BGD";
    ApiEntityOperatingCountriesEnum["BEL"] = "BEL";
    ApiEntityOperatingCountriesEnum["BFA"] = "BFA";
    ApiEntityOperatingCountriesEnum["BGR"] = "BGR";
    ApiEntityOperatingCountriesEnum["BHR"] = "BHR";
    ApiEntityOperatingCountriesEnum["BDI"] = "BDI";
    ApiEntityOperatingCountriesEnum["BEN"] = "BEN";
    ApiEntityOperatingCountriesEnum["BLM"] = "BLM";
    ApiEntityOperatingCountriesEnum["BMU"] = "BMU";
    ApiEntityOperatingCountriesEnum["BRN"] = "BRN";
    ApiEntityOperatingCountriesEnum["BOL"] = "BOL";
    ApiEntityOperatingCountriesEnum["BES"] = "BES";
    ApiEntityOperatingCountriesEnum["BRA"] = "BRA";
    ApiEntityOperatingCountriesEnum["BHS"] = "BHS";
    ApiEntityOperatingCountriesEnum["BTN"] = "BTN";
    ApiEntityOperatingCountriesEnum["BVT"] = "BVT";
    ApiEntityOperatingCountriesEnum["BWA"] = "BWA";
    ApiEntityOperatingCountriesEnum["BLR"] = "BLR";
    ApiEntityOperatingCountriesEnum["BLZ"] = "BLZ";
    ApiEntityOperatingCountriesEnum["CAN"] = "CAN";
    ApiEntityOperatingCountriesEnum["CCK"] = "CCK";
    ApiEntityOperatingCountriesEnum["COD"] = "COD";
    ApiEntityOperatingCountriesEnum["CAF"] = "CAF";
    ApiEntityOperatingCountriesEnum["COG"] = "COG";
    ApiEntityOperatingCountriesEnum["CHE"] = "CHE";
    ApiEntityOperatingCountriesEnum["CIV"] = "CIV";
    ApiEntityOperatingCountriesEnum["COK"] = "COK";
    ApiEntityOperatingCountriesEnum["CHL"] = "CHL";
    ApiEntityOperatingCountriesEnum["CMR"] = "CMR";
    ApiEntityOperatingCountriesEnum["CHN"] = "CHN";
    ApiEntityOperatingCountriesEnum["COL"] = "COL";
    ApiEntityOperatingCountriesEnum["CRI"] = "CRI";
    ApiEntityOperatingCountriesEnum["CUB"] = "CUB";
    ApiEntityOperatingCountriesEnum["CPV"] = "CPV";
    ApiEntityOperatingCountriesEnum["CUW"] = "CUW";
    ApiEntityOperatingCountriesEnum["CXR"] = "CXR";
    ApiEntityOperatingCountriesEnum["CYP"] = "CYP";
    ApiEntityOperatingCountriesEnum["CZE"] = "CZE";
    ApiEntityOperatingCountriesEnum["DEU"] = "DEU";
    ApiEntityOperatingCountriesEnum["DJI"] = "DJI";
    ApiEntityOperatingCountriesEnum["DNK"] = "DNK";
    ApiEntityOperatingCountriesEnum["DMA"] = "DMA";
    ApiEntityOperatingCountriesEnum["DOM"] = "DOM";
    ApiEntityOperatingCountriesEnum["DZA"] = "DZA";
    ApiEntityOperatingCountriesEnum["ECU"] = "ECU";
    ApiEntityOperatingCountriesEnum["EST"] = "EST";
    ApiEntityOperatingCountriesEnum["EGY"] = "EGY";
    ApiEntityOperatingCountriesEnum["ESH"] = "ESH";
    ApiEntityOperatingCountriesEnum["ERI"] = "ERI";
    ApiEntityOperatingCountriesEnum["ESP"] = "ESP";
    ApiEntityOperatingCountriesEnum["ETH"] = "ETH";
    ApiEntityOperatingCountriesEnum["FIN"] = "FIN";
    ApiEntityOperatingCountriesEnum["FJI"] = "FJI";
    ApiEntityOperatingCountriesEnum["FLK"] = "FLK";
    ApiEntityOperatingCountriesEnum["FSM"] = "FSM";
    ApiEntityOperatingCountriesEnum["FRO"] = "FRO";
    ApiEntityOperatingCountriesEnum["FRA"] = "FRA";
    ApiEntityOperatingCountriesEnum["GAB"] = "GAB";
    ApiEntityOperatingCountriesEnum["GBR"] = "GBR";
    ApiEntityOperatingCountriesEnum["GRD"] = "GRD";
    ApiEntityOperatingCountriesEnum["GEO"] = "GEO";
    ApiEntityOperatingCountriesEnum["GUF"] = "GUF";
    ApiEntityOperatingCountriesEnum["GGY"] = "GGY";
    ApiEntityOperatingCountriesEnum["GHA"] = "GHA";
    ApiEntityOperatingCountriesEnum["GIB"] = "GIB";
    ApiEntityOperatingCountriesEnum["GRL"] = "GRL";
    ApiEntityOperatingCountriesEnum["GMB"] = "GMB";
    ApiEntityOperatingCountriesEnum["GIN"] = "GIN";
    ApiEntityOperatingCountriesEnum["GLP"] = "GLP";
    ApiEntityOperatingCountriesEnum["GNQ"] = "GNQ";
    ApiEntityOperatingCountriesEnum["GRC"] = "GRC";
    ApiEntityOperatingCountriesEnum["SGS"] = "SGS";
    ApiEntityOperatingCountriesEnum["GTM"] = "GTM";
    ApiEntityOperatingCountriesEnum["GUM"] = "GUM";
    ApiEntityOperatingCountriesEnum["GNB"] = "GNB";
    ApiEntityOperatingCountriesEnum["GUY"] = "GUY";
    ApiEntityOperatingCountriesEnum["HKG"] = "HKG";
    ApiEntityOperatingCountriesEnum["HMD"] = "HMD";
    ApiEntityOperatingCountriesEnum["HND"] = "HND";
    ApiEntityOperatingCountriesEnum["HRV"] = "HRV";
    ApiEntityOperatingCountriesEnum["HTI"] = "HTI";
    ApiEntityOperatingCountriesEnum["HUN"] = "HUN";
    ApiEntityOperatingCountriesEnum["IDN"] = "IDN";
    ApiEntityOperatingCountriesEnum["IRL"] = "IRL";
    ApiEntityOperatingCountriesEnum["ISR"] = "ISR";
    ApiEntityOperatingCountriesEnum["IMN"] = "IMN";
    ApiEntityOperatingCountriesEnum["IND"] = "IND";
    ApiEntityOperatingCountriesEnum["IOT"] = "IOT";
    ApiEntityOperatingCountriesEnum["IRQ"] = "IRQ";
    ApiEntityOperatingCountriesEnum["IRN"] = "IRN";
    ApiEntityOperatingCountriesEnum["ISL"] = "ISL";
    ApiEntityOperatingCountriesEnum["ITA"] = "ITA";
    ApiEntityOperatingCountriesEnum["JEY"] = "JEY";
    ApiEntityOperatingCountriesEnum["JAM"] = "JAM";
    ApiEntityOperatingCountriesEnum["JOR"] = "JOR";
    ApiEntityOperatingCountriesEnum["JPN"] = "JPN";
    ApiEntityOperatingCountriesEnum["KEN"] = "KEN";
    ApiEntityOperatingCountriesEnum["KGZ"] = "KGZ";
    ApiEntityOperatingCountriesEnum["KHM"] = "KHM";
    ApiEntityOperatingCountriesEnum["KIR"] = "KIR";
    ApiEntityOperatingCountriesEnum["COM"] = "COM";
    ApiEntityOperatingCountriesEnum["KNA"] = "KNA";
    ApiEntityOperatingCountriesEnum["PRK"] = "PRK";
    ApiEntityOperatingCountriesEnum["KOR"] = "KOR";
    ApiEntityOperatingCountriesEnum["KWT"] = "KWT";
    ApiEntityOperatingCountriesEnum["CYM"] = "CYM";
    ApiEntityOperatingCountriesEnum["KAZ"] = "KAZ";
    ApiEntityOperatingCountriesEnum["LAO"] = "LAO";
    ApiEntityOperatingCountriesEnum["LBN"] = "LBN";
    ApiEntityOperatingCountriesEnum["LCA"] = "LCA";
    ApiEntityOperatingCountriesEnum["LIE"] = "LIE";
    ApiEntityOperatingCountriesEnum["LKA"] = "LKA";
    ApiEntityOperatingCountriesEnum["LBR"] = "LBR";
    ApiEntityOperatingCountriesEnum["LSO"] = "LSO";
    ApiEntityOperatingCountriesEnum["LTU"] = "LTU";
    ApiEntityOperatingCountriesEnum["LUX"] = "LUX";
    ApiEntityOperatingCountriesEnum["LVA"] = "LVA";
    ApiEntityOperatingCountriesEnum["LBY"] = "LBY";
    ApiEntityOperatingCountriesEnum["MAR"] = "MAR";
    ApiEntityOperatingCountriesEnum["MCO"] = "MCO";
    ApiEntityOperatingCountriesEnum["MDA"] = "MDA";
    ApiEntityOperatingCountriesEnum["MNE"] = "MNE";
    ApiEntityOperatingCountriesEnum["MAF"] = "MAF";
    ApiEntityOperatingCountriesEnum["MDG"] = "MDG";
    ApiEntityOperatingCountriesEnum["MHL"] = "MHL";
    ApiEntityOperatingCountriesEnum["MKD"] = "MKD";
    ApiEntityOperatingCountriesEnum["MLI"] = "MLI";
    ApiEntityOperatingCountriesEnum["MMR"] = "MMR";
    ApiEntityOperatingCountriesEnum["MNG"] = "MNG";
    ApiEntityOperatingCountriesEnum["MAC"] = "MAC";
    ApiEntityOperatingCountriesEnum["MNP"] = "MNP";
    ApiEntityOperatingCountriesEnum["MTQ"] = "MTQ";
    ApiEntityOperatingCountriesEnum["MRT"] = "MRT";
    ApiEntityOperatingCountriesEnum["MSR"] = "MSR";
    ApiEntityOperatingCountriesEnum["MLT"] = "MLT";
    ApiEntityOperatingCountriesEnum["MUS"] = "MUS";
    ApiEntityOperatingCountriesEnum["MDV"] = "MDV";
    ApiEntityOperatingCountriesEnum["MWI"] = "MWI";
    ApiEntityOperatingCountriesEnum["MEX"] = "MEX";
    ApiEntityOperatingCountriesEnum["MYS"] = "MYS";
    ApiEntityOperatingCountriesEnum["MOZ"] = "MOZ";
    ApiEntityOperatingCountriesEnum["NAM"] = "NAM";
    ApiEntityOperatingCountriesEnum["NCL"] = "NCL";
    ApiEntityOperatingCountriesEnum["NER"] = "NER";
    ApiEntityOperatingCountriesEnum["NFK"] = "NFK";
    ApiEntityOperatingCountriesEnum["NGA"] = "NGA";
    ApiEntityOperatingCountriesEnum["NIC"] = "NIC";
    ApiEntityOperatingCountriesEnum["NLD"] = "NLD";
    ApiEntityOperatingCountriesEnum["NOR"] = "NOR";
    ApiEntityOperatingCountriesEnum["NPL"] = "NPL";
    ApiEntityOperatingCountriesEnum["NRU"] = "NRU";
    ApiEntityOperatingCountriesEnum["NIU"] = "NIU";
    ApiEntityOperatingCountriesEnum["NZL"] = "NZL";
    ApiEntityOperatingCountriesEnum["OMN"] = "OMN";
    ApiEntityOperatingCountriesEnum["PAN"] = "PAN";
    ApiEntityOperatingCountriesEnum["PER"] = "PER";
    ApiEntityOperatingCountriesEnum["PYF"] = "PYF";
    ApiEntityOperatingCountriesEnum["PNG"] = "PNG";
    ApiEntityOperatingCountriesEnum["PHL"] = "PHL";
    ApiEntityOperatingCountriesEnum["PAK"] = "PAK";
    ApiEntityOperatingCountriesEnum["POL"] = "POL";
    ApiEntityOperatingCountriesEnum["SPM"] = "SPM";
    ApiEntityOperatingCountriesEnum["PCN"] = "PCN";
    ApiEntityOperatingCountriesEnum["PRI"] = "PRI";
    ApiEntityOperatingCountriesEnum["PSE"] = "PSE";
    ApiEntityOperatingCountriesEnum["PRT"] = "PRT";
    ApiEntityOperatingCountriesEnum["PLW"] = "PLW";
    ApiEntityOperatingCountriesEnum["PRY"] = "PRY";
    ApiEntityOperatingCountriesEnum["QAT"] = "QAT";
    ApiEntityOperatingCountriesEnum["REU"] = "REU";
    ApiEntityOperatingCountriesEnum["ROU"] = "ROU";
    ApiEntityOperatingCountriesEnum["SRB"] = "SRB";
    ApiEntityOperatingCountriesEnum["RUS"] = "RUS";
    ApiEntityOperatingCountriesEnum["RWA"] = "RWA";
    ApiEntityOperatingCountriesEnum["SAU"] = "SAU";
    ApiEntityOperatingCountriesEnum["SLB"] = "SLB";
    ApiEntityOperatingCountriesEnum["SYC"] = "SYC";
    ApiEntityOperatingCountriesEnum["SDN"] = "SDN";
    ApiEntityOperatingCountriesEnum["SWE"] = "SWE";
    ApiEntityOperatingCountriesEnum["SGP"] = "SGP";
    ApiEntityOperatingCountriesEnum["SHN"] = "SHN";
    ApiEntityOperatingCountriesEnum["SVN"] = "SVN";
    ApiEntityOperatingCountriesEnum["SJM"] = "SJM";
    ApiEntityOperatingCountriesEnum["SVK"] = "SVK";
    ApiEntityOperatingCountriesEnum["SLE"] = "SLE";
    ApiEntityOperatingCountriesEnum["SMR"] = "SMR";
    ApiEntityOperatingCountriesEnum["SEN"] = "SEN";
    ApiEntityOperatingCountriesEnum["SOM"] = "SOM";
    ApiEntityOperatingCountriesEnum["SUR"] = "SUR";
    ApiEntityOperatingCountriesEnum["SSD"] = "SSD";
    ApiEntityOperatingCountriesEnum["STP"] = "STP";
    ApiEntityOperatingCountriesEnum["SLV"] = "SLV";
    ApiEntityOperatingCountriesEnum["SXM"] = "SXM";
    ApiEntityOperatingCountriesEnum["SYR"] = "SYR";
    ApiEntityOperatingCountriesEnum["SWZ"] = "SWZ";
    ApiEntityOperatingCountriesEnum["TCA"] = "TCA";
    ApiEntityOperatingCountriesEnum["TCD"] = "TCD";
    ApiEntityOperatingCountriesEnum["ATF"] = "ATF";
    ApiEntityOperatingCountriesEnum["TGO"] = "TGO";
    ApiEntityOperatingCountriesEnum["THA"] = "THA";
    ApiEntityOperatingCountriesEnum["TJK"] = "TJK";
    ApiEntityOperatingCountriesEnum["TKL"] = "TKL";
    ApiEntityOperatingCountriesEnum["TLS"] = "TLS";
    ApiEntityOperatingCountriesEnum["TKM"] = "TKM";
    ApiEntityOperatingCountriesEnum["TUN"] = "TUN";
    ApiEntityOperatingCountriesEnum["TON"] = "TON";
    ApiEntityOperatingCountriesEnum["TUR"] = "TUR";
    ApiEntityOperatingCountriesEnum["TTO"] = "TTO";
    ApiEntityOperatingCountriesEnum["TUV"] = "TUV";
    ApiEntityOperatingCountriesEnum["TWN"] = "TWN";
    ApiEntityOperatingCountriesEnum["TZA"] = "TZA";
    ApiEntityOperatingCountriesEnum["UKR"] = "UKR";
    ApiEntityOperatingCountriesEnum["UGA"] = "UGA";
    ApiEntityOperatingCountriesEnum["UMI"] = "UMI";
    ApiEntityOperatingCountriesEnum["USA"] = "USA";
    ApiEntityOperatingCountriesEnum["URY"] = "URY";
    ApiEntityOperatingCountriesEnum["UZB"] = "UZB";
    ApiEntityOperatingCountriesEnum["VAT"] = "VAT";
    ApiEntityOperatingCountriesEnum["VCT"] = "VCT";
    ApiEntityOperatingCountriesEnum["VEN"] = "VEN";
    ApiEntityOperatingCountriesEnum["VGB"] = "VGB";
    ApiEntityOperatingCountriesEnum["VIR"] = "VIR";
    ApiEntityOperatingCountriesEnum["VNM"] = "VNM";
    ApiEntityOperatingCountriesEnum["VUT"] = "VUT";
    ApiEntityOperatingCountriesEnum["WLF"] = "WLF";
    ApiEntityOperatingCountriesEnum["WSM"] = "WSM";
    ApiEntityOperatingCountriesEnum["YEM"] = "YEM";
    ApiEntityOperatingCountriesEnum["MYT"] = "MYT";
    ApiEntityOperatingCountriesEnum["ZAF"] = "ZAF";
    ApiEntityOperatingCountriesEnum["ZMB"] = "ZMB";
    ApiEntityOperatingCountriesEnum["ZWE"] = "ZWE";
})(ApiEntityOperatingCountriesEnum || (ApiEntityOperatingCountriesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum;
(function (ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum) {
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum || (ApiEntityUpdateRequestDailyNotionalTradingVolumeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum;
(function (ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum) {
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum || (ApiEntityUpdateRequestDailyTransactionVolumeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestEntityRegistrationStatusEnum;
(function (ApiEntityUpdateRequestEntityRegistrationStatusEnum) {
    ApiEntityUpdateRequestEntityRegistrationStatusEnum["CURRENTLY_REGISTERED"] = "CURRENTLY_REGISTERED";
    ApiEntityUpdateRequestEntityRegistrationStatusEnum["NOT_REGISTERED"] = "NOT_REGISTERED";
    ApiEntityUpdateRequestEntityRegistrationStatusEnum["REGISTRATION_IN_PROGRESS"] = "REGISTRATION_IN_PROGRESS";
    ApiEntityUpdateRequestEntityRegistrationStatusEnum["NOT_APPLICABLE"] = "NOT_APPLICABLE";
})(ApiEntityUpdateRequestEntityRegistrationStatusEnum || (ApiEntityUpdateRequestEntityRegistrationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestFirmAnnualNetIncomeEnum;
(function (ApiEntityUpdateRequestFirmAnnualNetIncomeEnum) {
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityUpdateRequestFirmAnnualNetIncomeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityUpdateRequestFirmAnnualNetIncomeEnum || (ApiEntityUpdateRequestFirmAnnualNetIncomeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum;
(function (ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum) {
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum || (ApiEntityUpdateRequestFirmLiquidNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum;
(function (ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum) {
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["UNDER_250K"] = "UNDER_250K";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_250K_TO_500K"] = "FROM_250K_TO_500K";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_1M_TO_2M"] = "FROM_1M_TO_2M";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_2M_TO_5M"] = "FROM_2M_TO_5M";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_5M_TO_10M"] = "FROM_5M_TO_10M";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_10M_TO_20M"] = "FROM_10M_TO_20M";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["FROM_20M_TO_50M"] = "FROM_20M_TO_50M";
    ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum["OVER_50M"] = "OVER_50M";
})(ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum || (ApiEntityUpdateRequestFirmTotalNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestInvestmentExperienceEnum;
(function (ApiEntityUpdateRequestInvestmentExperienceEnum) {
    ApiEntityUpdateRequestInvestmentExperienceEnum["NONE"] = "NONE";
    ApiEntityUpdateRequestInvestmentExperienceEnum["LIMITED"] = "LIMITED";
    ApiEntityUpdateRequestInvestmentExperienceEnum["GOOD"] = "GOOD";
    ApiEntityUpdateRequestInvestmentExperienceEnum["EXTENSIVE"] = "EXTENSIVE";
})(ApiEntityUpdateRequestInvestmentExperienceEnum || (ApiEntityUpdateRequestInvestmentExperienceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiEntityUpdateRequestOperatingCountriesEnum;
(function (ApiEntityUpdateRequestOperatingCountriesEnum) {
    ApiEntityUpdateRequestOperatingCountriesEnum["AND"] = "AND";
    ApiEntityUpdateRequestOperatingCountriesEnum["ARE"] = "ARE";
    ApiEntityUpdateRequestOperatingCountriesEnum["AFG"] = "AFG";
    ApiEntityUpdateRequestOperatingCountriesEnum["ATG"] = "ATG";
    ApiEntityUpdateRequestOperatingCountriesEnum["AIA"] = "AIA";
    ApiEntityUpdateRequestOperatingCountriesEnum["ALB"] = "ALB";
    ApiEntityUpdateRequestOperatingCountriesEnum["ARM"] = "ARM";
    ApiEntityUpdateRequestOperatingCountriesEnum["AGO"] = "AGO";
    ApiEntityUpdateRequestOperatingCountriesEnum["ATA"] = "ATA";
    ApiEntityUpdateRequestOperatingCountriesEnum["ARG"] = "ARG";
    ApiEntityUpdateRequestOperatingCountriesEnum["ASM"] = "ASM";
    ApiEntityUpdateRequestOperatingCountriesEnum["AUT"] = "AUT";
    ApiEntityUpdateRequestOperatingCountriesEnum["AUS"] = "AUS";
    ApiEntityUpdateRequestOperatingCountriesEnum["ABW"] = "ABW";
    ApiEntityUpdateRequestOperatingCountriesEnum["ALA"] = "ALA";
    ApiEntityUpdateRequestOperatingCountriesEnum["AZE"] = "AZE";
    ApiEntityUpdateRequestOperatingCountriesEnum["BIH"] = "BIH";
    ApiEntityUpdateRequestOperatingCountriesEnum["BRB"] = "BRB";
    ApiEntityUpdateRequestOperatingCountriesEnum["BGD"] = "BGD";
    ApiEntityUpdateRequestOperatingCountriesEnum["BEL"] = "BEL";
    ApiEntityUpdateRequestOperatingCountriesEnum["BFA"] = "BFA";
    ApiEntityUpdateRequestOperatingCountriesEnum["BGR"] = "BGR";
    ApiEntityUpdateRequestOperatingCountriesEnum["BHR"] = "BHR";
    ApiEntityUpdateRequestOperatingCountriesEnum["BDI"] = "BDI";
    ApiEntityUpdateRequestOperatingCountriesEnum["BEN"] = "BEN";
    ApiEntityUpdateRequestOperatingCountriesEnum["BLM"] = "BLM";
    ApiEntityUpdateRequestOperatingCountriesEnum["BMU"] = "BMU";
    ApiEntityUpdateRequestOperatingCountriesEnum["BRN"] = "BRN";
    ApiEntityUpdateRequestOperatingCountriesEnum["BOL"] = "BOL";
    ApiEntityUpdateRequestOperatingCountriesEnum["BES"] = "BES";
    ApiEntityUpdateRequestOperatingCountriesEnum["BRA"] = "BRA";
    ApiEntityUpdateRequestOperatingCountriesEnum["BHS"] = "BHS";
    ApiEntityUpdateRequestOperatingCountriesEnum["BTN"] = "BTN";
    ApiEntityUpdateRequestOperatingCountriesEnum["BVT"] = "BVT";
    ApiEntityUpdateRequestOperatingCountriesEnum["BWA"] = "BWA";
    ApiEntityUpdateRequestOperatingCountriesEnum["BLR"] = "BLR";
    ApiEntityUpdateRequestOperatingCountriesEnum["BLZ"] = "BLZ";
    ApiEntityUpdateRequestOperatingCountriesEnum["CAN"] = "CAN";
    ApiEntityUpdateRequestOperatingCountriesEnum["CCK"] = "CCK";
    ApiEntityUpdateRequestOperatingCountriesEnum["COD"] = "COD";
    ApiEntityUpdateRequestOperatingCountriesEnum["CAF"] = "CAF";
    ApiEntityUpdateRequestOperatingCountriesEnum["COG"] = "COG";
    ApiEntityUpdateRequestOperatingCountriesEnum["CHE"] = "CHE";
    ApiEntityUpdateRequestOperatingCountriesEnum["CIV"] = "CIV";
    ApiEntityUpdateRequestOperatingCountriesEnum["COK"] = "COK";
    ApiEntityUpdateRequestOperatingCountriesEnum["CHL"] = "CHL";
    ApiEntityUpdateRequestOperatingCountriesEnum["CMR"] = "CMR";
    ApiEntityUpdateRequestOperatingCountriesEnum["CHN"] = "CHN";
    ApiEntityUpdateRequestOperatingCountriesEnum["COL"] = "COL";
    ApiEntityUpdateRequestOperatingCountriesEnum["CRI"] = "CRI";
    ApiEntityUpdateRequestOperatingCountriesEnum["CUB"] = "CUB";
    ApiEntityUpdateRequestOperatingCountriesEnum["CPV"] = "CPV";
    ApiEntityUpdateRequestOperatingCountriesEnum["CUW"] = "CUW";
    ApiEntityUpdateRequestOperatingCountriesEnum["CXR"] = "CXR";
    ApiEntityUpdateRequestOperatingCountriesEnum["CYP"] = "CYP";
    ApiEntityUpdateRequestOperatingCountriesEnum["CZE"] = "CZE";
    ApiEntityUpdateRequestOperatingCountriesEnum["DEU"] = "DEU";
    ApiEntityUpdateRequestOperatingCountriesEnum["DJI"] = "DJI";
    ApiEntityUpdateRequestOperatingCountriesEnum["DNK"] = "DNK";
    ApiEntityUpdateRequestOperatingCountriesEnum["DMA"] = "DMA";
    ApiEntityUpdateRequestOperatingCountriesEnum["DOM"] = "DOM";
    ApiEntityUpdateRequestOperatingCountriesEnum["DZA"] = "DZA";
    ApiEntityUpdateRequestOperatingCountriesEnum["ECU"] = "ECU";
    ApiEntityUpdateRequestOperatingCountriesEnum["EST"] = "EST";
    ApiEntityUpdateRequestOperatingCountriesEnum["EGY"] = "EGY";
    ApiEntityUpdateRequestOperatingCountriesEnum["ESH"] = "ESH";
    ApiEntityUpdateRequestOperatingCountriesEnum["ERI"] = "ERI";
    ApiEntityUpdateRequestOperatingCountriesEnum["ESP"] = "ESP";
    ApiEntityUpdateRequestOperatingCountriesEnum["ETH"] = "ETH";
    ApiEntityUpdateRequestOperatingCountriesEnum["FIN"] = "FIN";
    ApiEntityUpdateRequestOperatingCountriesEnum["FJI"] = "FJI";
    ApiEntityUpdateRequestOperatingCountriesEnum["FLK"] = "FLK";
    ApiEntityUpdateRequestOperatingCountriesEnum["FSM"] = "FSM";
    ApiEntityUpdateRequestOperatingCountriesEnum["FRO"] = "FRO";
    ApiEntityUpdateRequestOperatingCountriesEnum["FRA"] = "FRA";
    ApiEntityUpdateRequestOperatingCountriesEnum["GAB"] = "GAB";
    ApiEntityUpdateRequestOperatingCountriesEnum["GBR"] = "GBR";
    ApiEntityUpdateRequestOperatingCountriesEnum["GRD"] = "GRD";
    ApiEntityUpdateRequestOperatingCountriesEnum["GEO"] = "GEO";
    ApiEntityUpdateRequestOperatingCountriesEnum["GUF"] = "GUF";
    ApiEntityUpdateRequestOperatingCountriesEnum["GGY"] = "GGY";
    ApiEntityUpdateRequestOperatingCountriesEnum["GHA"] = "GHA";
    ApiEntityUpdateRequestOperatingCountriesEnum["GIB"] = "GIB";
    ApiEntityUpdateRequestOperatingCountriesEnum["GRL"] = "GRL";
    ApiEntityUpdateRequestOperatingCountriesEnum["GMB"] = "GMB";
    ApiEntityUpdateRequestOperatingCountriesEnum["GIN"] = "GIN";
    ApiEntityUpdateRequestOperatingCountriesEnum["GLP"] = "GLP";
    ApiEntityUpdateRequestOperatingCountriesEnum["GNQ"] = "GNQ";
    ApiEntityUpdateRequestOperatingCountriesEnum["GRC"] = "GRC";
    ApiEntityUpdateRequestOperatingCountriesEnum["SGS"] = "SGS";
    ApiEntityUpdateRequestOperatingCountriesEnum["GTM"] = "GTM";
    ApiEntityUpdateRequestOperatingCountriesEnum["GUM"] = "GUM";
    ApiEntityUpdateRequestOperatingCountriesEnum["GNB"] = "GNB";
    ApiEntityUpdateRequestOperatingCountriesEnum["GUY"] = "GUY";
    ApiEntityUpdateRequestOperatingCountriesEnum["HKG"] = "HKG";
    ApiEntityUpdateRequestOperatingCountriesEnum["HMD"] = "HMD";
    ApiEntityUpdateRequestOperatingCountriesEnum["HND"] = "HND";
    ApiEntityUpdateRequestOperatingCountriesEnum["HRV"] = "HRV";
    ApiEntityUpdateRequestOperatingCountriesEnum["HTI"] = "HTI";
    ApiEntityUpdateRequestOperatingCountriesEnum["HUN"] = "HUN";
    ApiEntityUpdateRequestOperatingCountriesEnum["IDN"] = "IDN";
    ApiEntityUpdateRequestOperatingCountriesEnum["IRL"] = "IRL";
    ApiEntityUpdateRequestOperatingCountriesEnum["ISR"] = "ISR";
    ApiEntityUpdateRequestOperatingCountriesEnum["IMN"] = "IMN";
    ApiEntityUpdateRequestOperatingCountriesEnum["IND"] = "IND";
    ApiEntityUpdateRequestOperatingCountriesEnum["IOT"] = "IOT";
    ApiEntityUpdateRequestOperatingCountriesEnum["IRQ"] = "IRQ";
    ApiEntityUpdateRequestOperatingCountriesEnum["IRN"] = "IRN";
    ApiEntityUpdateRequestOperatingCountriesEnum["ISL"] = "ISL";
    ApiEntityUpdateRequestOperatingCountriesEnum["ITA"] = "ITA";
    ApiEntityUpdateRequestOperatingCountriesEnum["JEY"] = "JEY";
    ApiEntityUpdateRequestOperatingCountriesEnum["JAM"] = "JAM";
    ApiEntityUpdateRequestOperatingCountriesEnum["JOR"] = "JOR";
    ApiEntityUpdateRequestOperatingCountriesEnum["JPN"] = "JPN";
    ApiEntityUpdateRequestOperatingCountriesEnum["KEN"] = "KEN";
    ApiEntityUpdateRequestOperatingCountriesEnum["KGZ"] = "KGZ";
    ApiEntityUpdateRequestOperatingCountriesEnum["KHM"] = "KHM";
    ApiEntityUpdateRequestOperatingCountriesEnum["KIR"] = "KIR";
    ApiEntityUpdateRequestOperatingCountriesEnum["COM"] = "COM";
    ApiEntityUpdateRequestOperatingCountriesEnum["KNA"] = "KNA";
    ApiEntityUpdateRequestOperatingCountriesEnum["PRK"] = "PRK";
    ApiEntityUpdateRequestOperatingCountriesEnum["KOR"] = "KOR";
    ApiEntityUpdateRequestOperatingCountriesEnum["KWT"] = "KWT";
    ApiEntityUpdateRequestOperatingCountriesEnum["CYM"] = "CYM";
    ApiEntityUpdateRequestOperatingCountriesEnum["KAZ"] = "KAZ";
    ApiEntityUpdateRequestOperatingCountriesEnum["LAO"] = "LAO";
    ApiEntityUpdateRequestOperatingCountriesEnum["LBN"] = "LBN";
    ApiEntityUpdateRequestOperatingCountriesEnum["LCA"] = "LCA";
    ApiEntityUpdateRequestOperatingCountriesEnum["LIE"] = "LIE";
    ApiEntityUpdateRequestOperatingCountriesEnum["LKA"] = "LKA";
    ApiEntityUpdateRequestOperatingCountriesEnum["LBR"] = "LBR";
    ApiEntityUpdateRequestOperatingCountriesEnum["LSO"] = "LSO";
    ApiEntityUpdateRequestOperatingCountriesEnum["LTU"] = "LTU";
    ApiEntityUpdateRequestOperatingCountriesEnum["LUX"] = "LUX";
    ApiEntityUpdateRequestOperatingCountriesEnum["LVA"] = "LVA";
    ApiEntityUpdateRequestOperatingCountriesEnum["LBY"] = "LBY";
    ApiEntityUpdateRequestOperatingCountriesEnum["MAR"] = "MAR";
    ApiEntityUpdateRequestOperatingCountriesEnum["MCO"] = "MCO";
    ApiEntityUpdateRequestOperatingCountriesEnum["MDA"] = "MDA";
    ApiEntityUpdateRequestOperatingCountriesEnum["MNE"] = "MNE";
    ApiEntityUpdateRequestOperatingCountriesEnum["MAF"] = "MAF";
    ApiEntityUpdateRequestOperatingCountriesEnum["MDG"] = "MDG";
    ApiEntityUpdateRequestOperatingCountriesEnum["MHL"] = "MHL";
    ApiEntityUpdateRequestOperatingCountriesEnum["MKD"] = "MKD";
    ApiEntityUpdateRequestOperatingCountriesEnum["MLI"] = "MLI";
    ApiEntityUpdateRequestOperatingCountriesEnum["MMR"] = "MMR";
    ApiEntityUpdateRequestOperatingCountriesEnum["MNG"] = "MNG";
    ApiEntityUpdateRequestOperatingCountriesEnum["MAC"] = "MAC";
    ApiEntityUpdateRequestOperatingCountriesEnum["MNP"] = "MNP";
    ApiEntityUpdateRequestOperatingCountriesEnum["MTQ"] = "MTQ";
    ApiEntityUpdateRequestOperatingCountriesEnum["MRT"] = "MRT";
    ApiEntityUpdateRequestOperatingCountriesEnum["MSR"] = "MSR";
    ApiEntityUpdateRequestOperatingCountriesEnum["MLT"] = "MLT";
    ApiEntityUpdateRequestOperatingCountriesEnum["MUS"] = "MUS";
    ApiEntityUpdateRequestOperatingCountriesEnum["MDV"] = "MDV";
    ApiEntityUpdateRequestOperatingCountriesEnum["MWI"] = "MWI";
    ApiEntityUpdateRequestOperatingCountriesEnum["MEX"] = "MEX";
    ApiEntityUpdateRequestOperatingCountriesEnum["MYS"] = "MYS";
    ApiEntityUpdateRequestOperatingCountriesEnum["MOZ"] = "MOZ";
    ApiEntityUpdateRequestOperatingCountriesEnum["NAM"] = "NAM";
    ApiEntityUpdateRequestOperatingCountriesEnum["NCL"] = "NCL";
    ApiEntityUpdateRequestOperatingCountriesEnum["NER"] = "NER";
    ApiEntityUpdateRequestOperatingCountriesEnum["NFK"] = "NFK";
    ApiEntityUpdateRequestOperatingCountriesEnum["NGA"] = "NGA";
    ApiEntityUpdateRequestOperatingCountriesEnum["NIC"] = "NIC";
    ApiEntityUpdateRequestOperatingCountriesEnum["NLD"] = "NLD";
    ApiEntityUpdateRequestOperatingCountriesEnum["NOR"] = "NOR";
    ApiEntityUpdateRequestOperatingCountriesEnum["NPL"] = "NPL";
    ApiEntityUpdateRequestOperatingCountriesEnum["NRU"] = "NRU";
    ApiEntityUpdateRequestOperatingCountriesEnum["NIU"] = "NIU";
    ApiEntityUpdateRequestOperatingCountriesEnum["NZL"] = "NZL";
    ApiEntityUpdateRequestOperatingCountriesEnum["OMN"] = "OMN";
    ApiEntityUpdateRequestOperatingCountriesEnum["PAN"] = "PAN";
    ApiEntityUpdateRequestOperatingCountriesEnum["PER"] = "PER";
    ApiEntityUpdateRequestOperatingCountriesEnum["PYF"] = "PYF";
    ApiEntityUpdateRequestOperatingCountriesEnum["PNG"] = "PNG";
    ApiEntityUpdateRequestOperatingCountriesEnum["PHL"] = "PHL";
    ApiEntityUpdateRequestOperatingCountriesEnum["PAK"] = "PAK";
    ApiEntityUpdateRequestOperatingCountriesEnum["POL"] = "POL";
    ApiEntityUpdateRequestOperatingCountriesEnum["SPM"] = "SPM";
    ApiEntityUpdateRequestOperatingCountriesEnum["PCN"] = "PCN";
    ApiEntityUpdateRequestOperatingCountriesEnum["PRI"] = "PRI";
    ApiEntityUpdateRequestOperatingCountriesEnum["PSE"] = "PSE";
    ApiEntityUpdateRequestOperatingCountriesEnum["PRT"] = "PRT";
    ApiEntityUpdateRequestOperatingCountriesEnum["PLW"] = "PLW";
    ApiEntityUpdateRequestOperatingCountriesEnum["PRY"] = "PRY";
    ApiEntityUpdateRequestOperatingCountriesEnum["QAT"] = "QAT";
    ApiEntityUpdateRequestOperatingCountriesEnum["REU"] = "REU";
    ApiEntityUpdateRequestOperatingCountriesEnum["ROU"] = "ROU";
    ApiEntityUpdateRequestOperatingCountriesEnum["SRB"] = "SRB";
    ApiEntityUpdateRequestOperatingCountriesEnum["RUS"] = "RUS";
    ApiEntityUpdateRequestOperatingCountriesEnum["RWA"] = "RWA";
    ApiEntityUpdateRequestOperatingCountriesEnum["SAU"] = "SAU";
    ApiEntityUpdateRequestOperatingCountriesEnum["SLB"] = "SLB";
    ApiEntityUpdateRequestOperatingCountriesEnum["SYC"] = "SYC";
    ApiEntityUpdateRequestOperatingCountriesEnum["SDN"] = "SDN";
    ApiEntityUpdateRequestOperatingCountriesEnum["SWE"] = "SWE";
    ApiEntityUpdateRequestOperatingCountriesEnum["SGP"] = "SGP";
    ApiEntityUpdateRequestOperatingCountriesEnum["SHN"] = "SHN";
    ApiEntityUpdateRequestOperatingCountriesEnum["SVN"] = "SVN";
    ApiEntityUpdateRequestOperatingCountriesEnum["SJM"] = "SJM";
    ApiEntityUpdateRequestOperatingCountriesEnum["SVK"] = "SVK";
    ApiEntityUpdateRequestOperatingCountriesEnum["SLE"] = "SLE";
    ApiEntityUpdateRequestOperatingCountriesEnum["SMR"] = "SMR";
    ApiEntityUpdateRequestOperatingCountriesEnum["SEN"] = "SEN";
    ApiEntityUpdateRequestOperatingCountriesEnum["SOM"] = "SOM";
    ApiEntityUpdateRequestOperatingCountriesEnum["SUR"] = "SUR";
    ApiEntityUpdateRequestOperatingCountriesEnum["SSD"] = "SSD";
    ApiEntityUpdateRequestOperatingCountriesEnum["STP"] = "STP";
    ApiEntityUpdateRequestOperatingCountriesEnum["SLV"] = "SLV";
    ApiEntityUpdateRequestOperatingCountriesEnum["SXM"] = "SXM";
    ApiEntityUpdateRequestOperatingCountriesEnum["SYR"] = "SYR";
    ApiEntityUpdateRequestOperatingCountriesEnum["SWZ"] = "SWZ";
    ApiEntityUpdateRequestOperatingCountriesEnum["TCA"] = "TCA";
    ApiEntityUpdateRequestOperatingCountriesEnum["TCD"] = "TCD";
    ApiEntityUpdateRequestOperatingCountriesEnum["ATF"] = "ATF";
    ApiEntityUpdateRequestOperatingCountriesEnum["TGO"] = "TGO";
    ApiEntityUpdateRequestOperatingCountriesEnum["THA"] = "THA";
    ApiEntityUpdateRequestOperatingCountriesEnum["TJK"] = "TJK";
    ApiEntityUpdateRequestOperatingCountriesEnum["TKL"] = "TKL";
    ApiEntityUpdateRequestOperatingCountriesEnum["TLS"] = "TLS";
    ApiEntityUpdateRequestOperatingCountriesEnum["TKM"] = "TKM";
    ApiEntityUpdateRequestOperatingCountriesEnum["TUN"] = "TUN";
    ApiEntityUpdateRequestOperatingCountriesEnum["TON"] = "TON";
    ApiEntityUpdateRequestOperatingCountriesEnum["TUR"] = "TUR";
    ApiEntityUpdateRequestOperatingCountriesEnum["TTO"] = "TTO";
    ApiEntityUpdateRequestOperatingCountriesEnum["TUV"] = "TUV";
    ApiEntityUpdateRequestOperatingCountriesEnum["TWN"] = "TWN";
    ApiEntityUpdateRequestOperatingCountriesEnum["TZA"] = "TZA";
    ApiEntityUpdateRequestOperatingCountriesEnum["UKR"] = "UKR";
    ApiEntityUpdateRequestOperatingCountriesEnum["UGA"] = "UGA";
    ApiEntityUpdateRequestOperatingCountriesEnum["UMI"] = "UMI";
    ApiEntityUpdateRequestOperatingCountriesEnum["USA"] = "USA";
    ApiEntityUpdateRequestOperatingCountriesEnum["URY"] = "URY";
    ApiEntityUpdateRequestOperatingCountriesEnum["UZB"] = "UZB";
    ApiEntityUpdateRequestOperatingCountriesEnum["VAT"] = "VAT";
    ApiEntityUpdateRequestOperatingCountriesEnum["VCT"] = "VCT";
    ApiEntityUpdateRequestOperatingCountriesEnum["VEN"] = "VEN";
    ApiEntityUpdateRequestOperatingCountriesEnum["VGB"] = "VGB";
    ApiEntityUpdateRequestOperatingCountriesEnum["VIR"] = "VIR";
    ApiEntityUpdateRequestOperatingCountriesEnum["VNM"] = "VNM";
    ApiEntityUpdateRequestOperatingCountriesEnum["VUT"] = "VUT";
    ApiEntityUpdateRequestOperatingCountriesEnum["WLF"] = "WLF";
    ApiEntityUpdateRequestOperatingCountriesEnum["WSM"] = "WSM";
    ApiEntityUpdateRequestOperatingCountriesEnum["YEM"] = "YEM";
    ApiEntityUpdateRequestOperatingCountriesEnum["MYT"] = "MYT";
    ApiEntityUpdateRequestOperatingCountriesEnum["ZAF"] = "ZAF";
    ApiEntityUpdateRequestOperatingCountriesEnum["ZMB"] = "ZMB";
    ApiEntityUpdateRequestOperatingCountriesEnum["ZWE"] = "ZWE";
})(ApiEntityUpdateRequestOperatingCountriesEnum || (ApiEntityUpdateRequestOperatingCountriesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiFailureActivityTransactionTypeEnum;
(function (ApiFailureActivityTransactionTypeEnum) {
    ApiFailureActivityTransactionTypeEnum["SIFT_SCORE"] = "SIFT_SCORE";
    ApiFailureActivityTransactionTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    ApiFailureActivityTransactionTypeEnum["DEBIT_CARD_LINK"] = "DEBIT_CARD_LINK";
    ApiFailureActivityTransactionTypeEnum["ACH_LINK"] = "ACH_LINK";
    ApiFailureActivityTransactionTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiFailureActivityTransactionTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiFailureActivityTransactionTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiFailureActivityTransactionTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiFailureActivityTransactionTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiFailureActivityTransactionTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiFailureActivityTransactionTypeEnum["REVERSE"] = "REVERSE";
    ApiFailureActivityTransactionTypeEnum["SELL"] = "SELL";
    ApiFailureActivityTransactionTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiFailureActivityTransactionTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiFailureActivityTransactionTypeEnum["REDEEM"] = "REDEEM";
    ApiFailureActivityTransactionTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiFailureActivityTransactionTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiFailureActivityTransactionTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiFailureActivityTransactionTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiFailureActivityTransactionTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiFailureActivityTransactionTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiFailureActivityTransactionTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiFailureActivityTransactionTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiFailureActivityTransactionTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiFailureActivityTransactionTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiFailureActivityTransactionTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiFailureActivityTransactionTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiFailureActivityTransactionTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiFailureActivityTransactionTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiFailureActivityTransactionTypeEnum["BUY"] = "BUY";
    ApiFailureActivityTransactionTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiFailureActivityTransactionTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiFailureActivityTransactionTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiFailureActivityTransactionTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiFailureActivityTransactionTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiFailureActivityTransactionTypeEnum["OTHER"] = "OTHER";
})(ApiFailureActivityTransactionTypeEnum || (ApiFailureActivityTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiFundingSourceDetailAssetTypeEnum;
(function (ApiFundingSourceDetailAssetTypeEnum) {
    ApiFundingSourceDetailAssetTypeEnum["CASH"] = "CASH";
    ApiFundingSourceDetailAssetTypeEnum["CRYPTO"] = "CRYPTO";
    ApiFundingSourceDetailAssetTypeEnum["REWARDS"] = "REWARDS";
})(ApiFundingSourceDetailAssetTypeEnum || (ApiFundingSourceDetailAssetTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiFundingSourceDetailCurrencyEnum;
(function (ApiFundingSourceDetailCurrencyEnum) {
    ApiFundingSourceDetailCurrencyEnum["USD"] = "USD";
    ApiFundingSourceDetailCurrencyEnum["BCH"] = "BCH";
    ApiFundingSourceDetailCurrencyEnum["BTC"] = "BTC";
    ApiFundingSourceDetailCurrencyEnum["DOGE"] = "DOGE";
    ApiFundingSourceDetailCurrencyEnum["ETC"] = "ETC";
    ApiFundingSourceDetailCurrencyEnum["ETH"] = "ETH";
    ApiFundingSourceDetailCurrencyEnum["LTC"] = "LTC";
    ApiFundingSourceDetailCurrencyEnum["SHIB"] = "SHIB";
    ApiFundingSourceDetailCurrencyEnum["USDC"] = "USDC";
    ApiFundingSourceDetailCurrencyEnum["CET"] = "CET";
    ApiFundingSourceDetailCurrencyEnum["LXX"] = "LXX";
    ApiFundingSourceDetailCurrencyEnum["LCP"] = "LCP";
    ApiFundingSourceDetailCurrencyEnum["LWR"] = "LWR";
    ApiFundingSourceDetailCurrencyEnum["LMO"] = "LMO";
    ApiFundingSourceDetailCurrencyEnum["LKO"] = "LKO";
    ApiFundingSourceDetailCurrencyEnum["LED"] = "LED";
})(ApiFundingSourceDetailCurrencyEnum || (ApiFundingSourceDetailCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiFundingSourceDetailResponseTypeEnum;
(function (ApiFundingSourceDetailResponseTypeEnum) {
    ApiFundingSourceDetailResponseTypeEnum["BUY_GIFTCARD"] = "BUY_GIFTCARD";
    ApiFundingSourceDetailResponseTypeEnum["BUY_CRYPTO"] = "BUY_CRYPTO";
    ApiFundingSourceDetailResponseTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    ApiFundingSourceDetailResponseTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
})(ApiFundingSourceDetailResponseTypeEnum || (ApiFundingSourceDetailResponseTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIdentityCheckResponseFlowEnum;
(function (ApiIdentityCheckResponseFlowEnum) {
    ApiIdentityCheckResponseFlowEnum["L1_IDENTITY_CHECK_FINALIZE"] = "L1_IDENTITY_CHECK_FINALIZE";
    ApiIdentityCheckResponseFlowEnum["L2A_IDENTITY_CHECK_FINALIZE"] = "L2A_IDENTITY_CHECK_FINALIZE";
    ApiIdentityCheckResponseFlowEnum["L2A_IDENTITY_CHECK_ADMIN"] = "L2A_IDENTITY_CHECK_ADMIN";
    ApiIdentityCheckResponseFlowEnum["L2A_IDENTITY_CHECK_UPDATE_ADDRESS"] = "L2A_IDENTITY_CHECK_UPDATE_ADDRESS";
    ApiIdentityCheckResponseFlowEnum["L2B_IDENTITY_CHECK"] = "L2B_IDENTITY_CHECK";
    ApiIdentityCheckResponseFlowEnum["L2B_IDENTITY_CHECK_ADMIN"] = "L2B_IDENTITY_CHECK_ADMIN";
    ApiIdentityCheckResponseFlowEnum["L2C_IDENTITY_CHECK"] = "L2C_IDENTITY_CHECK";
    ApiIdentityCheckResponseFlowEnum["L3_IDENTITY_CHECK_ID_DOC_VERIFICATION"] = "L3_IDENTITY_CHECK_ID_DOC_VERIFICATION";
    ApiIdentityCheckResponseFlowEnum["PROFILE_UPDATE_ADDRESS"] = "PROFILE_UPDATE_ADDRESS";
    ApiIdentityCheckResponseFlowEnum["PROFILE_UPDATE_IDENTIFIER"] = "PROFILE_UPDATE_IDENTIFIER";
    ApiIdentityCheckResponseFlowEnum["BATCH_UPLOAD"] = "BATCH_UPLOAD";
    ApiIdentityCheckResponseFlowEnum["CSI_OFAC_BATCH"] = "CSI_OFAC_BATCH";
    ApiIdentityCheckResponseFlowEnum["CSI_OFAC"] = "CSI_OFAC";
    ApiIdentityCheckResponseFlowEnum["LOGIN"] = "LOGIN";
    ApiIdentityCheckResponseFlowEnum["LOGOUT"] = "LOGOUT";
    ApiIdentityCheckResponseFlowEnum["REGISTRATION"] = "REGISTRATION";
    ApiIdentityCheckResponseFlowEnum["TRANSACTION"] = "TRANSACTION";
    ApiIdentityCheckResponseFlowEnum["BANK_VERIFICATION"] = "BANK_VERIFICATION";
    ApiIdentityCheckResponseFlowEnum["PARTNER_PARTY_ENROLLMENT"] = "PARTNER_PARTY_ENROLLMENT";
    ApiIdentityCheckResponseFlowEnum["ACH_DEPOSIT"] = "ACH_DEPOSIT";
    ApiIdentityCheckResponseFlowEnum["ACH_WITHDRAW"] = "ACH_WITHDRAW";
    ApiIdentityCheckResponseFlowEnum["BUY_CRYPTO"] = "BUY_CRYPTO";
    ApiIdentityCheckResponseFlowEnum["SELL_CRYPTO"] = "SELL_CRYPTO";
    ApiIdentityCheckResponseFlowEnum["SEND_CRYPTO"] = "SEND_CRYPTO";
    ApiIdentityCheckResponseFlowEnum["CONVERT_REWARD"] = "CONVERT_REWARD";
    ApiIdentityCheckResponseFlowEnum["SEND_CASH"] = "SEND_CASH";
    ApiIdentityCheckResponseFlowEnum["BUY_GIFT_CARD"] = "BUY_GIFT_CARD";
    ApiIdentityCheckResponseFlowEnum["LINKING_BANK"] = "LINKING_BANK";
    ApiIdentityCheckResponseFlowEnum["LINKING_DEBIT_CARD"] = "LINKING_DEBIT_CARD";
    ApiIdentityCheckResponseFlowEnum["DEBIT_CARD_DEPOSIT_TO_WALLET"] = "DEBIT_CARD_DEPOSIT_TO_WALLET";
    ApiIdentityCheckResponseFlowEnum["DEBIT_CARD_WITHDRAWAL_FROM_WALLET"] = "DEBIT_CARD_WITHDRAWAL_FROM_WALLET";
    ApiIdentityCheckResponseFlowEnum["PAYMENT"] = "PAYMENT";
    ApiIdentityCheckResponseFlowEnum["DEBITCARD_VERIFICATION"] = "DEBITCARD_VERIFICATION";
    ApiIdentityCheckResponseFlowEnum["LOGIN_PIN"] = "LOGIN_PIN";
    ApiIdentityCheckResponseFlowEnum["LOGIN_BIOMETRIC"] = "LOGIN_BIOMETRIC";
    ApiIdentityCheckResponseFlowEnum["CARD_ADD"] = "CARD_ADD";
    ApiIdentityCheckResponseFlowEnum["L1_TO_L4_LEVEL_UP"] = "L1_TO_L4_LEVEL_UP";
    ApiIdentityCheckResponseFlowEnum["L2_TO_L4_LEVEL_UP"] = "L2_TO_L4_LEVEL_UP";
    ApiIdentityCheckResponseFlowEnum["L3_TO_L4_LEVEL_UP"] = "L3_TO_L4_LEVEL_UP";
})(ApiIdentityCheckResponseFlowEnum || (ApiIdentityCheckResponseFlowEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIdentityCheckResponseRiskLevelEnum;
(function (ApiIdentityCheckResponseRiskLevelEnum) {
    ApiIdentityCheckResponseRiskLevelEnum["VERY_HIGH"] = "VERY_HIGH";
    ApiIdentityCheckResponseRiskLevelEnum["HIGH"] = "HIGH";
    ApiIdentityCheckResponseRiskLevelEnum["MEDIUM"] = "MEDIUM";
    ApiIdentityCheckResponseRiskLevelEnum["LOW"] = "LOW";
})(ApiIdentityCheckResponseRiskLevelEnum || (ApiIdentityCheckResponseRiskLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveAwardDetailAwardExecutionCurrencyEnum;
(function (ApiIncentiveAwardDetailAwardExecutionCurrencyEnum) {
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["USD"] = "USD";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["BCH"] = "BCH";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["BTC"] = "BTC";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["DOGE"] = "DOGE";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["ETC"] = "ETC";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["ETH"] = "ETH";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LTC"] = "LTC";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["SHIB"] = "SHIB";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["USDC"] = "USDC";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["CET"] = "CET";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LXX"] = "LXX";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LCP"] = "LCP";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LWR"] = "LWR";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LMO"] = "LMO";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LKO"] = "LKO";
    ApiIncentiveAwardDetailAwardExecutionCurrencyEnum["LED"] = "LED";
})(ApiIncentiveAwardDetailAwardExecutionCurrencyEnum || (ApiIncentiveAwardDetailAwardExecutionCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveAwardDetailTypeEnum;
(function (ApiIncentiveAwardDetailTypeEnum) {
    ApiIncentiveAwardDetailTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    ApiIncentiveAwardDetailTypeEnum["CRYPTO"] = "CRYPTO";
    ApiIncentiveAwardDetailTypeEnum["CASH"] = "CASH";
    ApiIncentiveAwardDetailTypeEnum["FEATURE_WHITELIST"] = "FEATURE_WHITELIST";
    ApiIncentiveAwardDetailTypeEnum["LOYALTY"] = "LOYALTY";
})(ApiIncentiveAwardDetailTypeEnum || (ApiIncentiveAwardDetailTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveAwardDetailValueTypeEnum;
(function (ApiIncentiveAwardDetailValueTypeEnum) {
    ApiIncentiveAwardDetailValueTypeEnum["FIXED_AWARD"] = "FIXED_AWARD";
    ApiIncentiveAwardDetailValueTypeEnum["PERCENT_AWARD"] = "PERCENT_AWARD";
})(ApiIncentiveAwardDetailValueTypeEnum || (ApiIncentiveAwardDetailValueTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveCriterionTypeEnum;
(function (ApiIncentiveCriterionTypeEnum) {
    ApiIncentiveCriterionTypeEnum["REGISTRATION_DATE"] = "REGISTRATION_DATE";
    ApiIncentiveCriterionTypeEnum["FIXED_IDENTIFIERS"] = "FIXED_IDENTIFIERS";
    ApiIncentiveCriterionTypeEnum["OPEN_TO_ALL"] = "OPEN_TO_ALL";
    ApiIncentiveCriterionTypeEnum["FIRST_OFFER"] = "FIRST_OFFER";
    ApiIncentiveCriterionTypeEnum["FIRST_OFFER_EXCLUDING_OTHER_OFFER"] = "FIRST_OFFER_EXCLUDING_OTHER_OFFER";
    ApiIncentiveCriterionTypeEnum["INELIGIBLE_IF_OTHER_OFFER"] = "INELIGIBLE_IF_OTHER_OFFER";
    ApiIncentiveCriterionTypeEnum["CAMPAIGN_NAME_MATCH"] = "CAMPAIGN_NAME_MATCH";
    ApiIncentiveCriterionTypeEnum["FIRST_TIME_CRYPTO_BUY"] = "FIRST_TIME_CRYPTO_BUY";
    ApiIncentiveCriterionTypeEnum["SEGMENT_AUDIENCE"] = "SEGMENT_AUDIENCE";
    ApiIncentiveCriterionTypeEnum["PARTNER_PARTY_LINK_DATE"] = "PARTNER_PARTY_LINK_DATE";
    ApiIncentiveCriterionTypeEnum["PARTNER"] = "PARTNER";
})(ApiIncentiveCriterionTypeEnum || (ApiIncentiveCriterionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveDetailResponsePartyAwardStatusEnum;
(function (ApiIncentiveDetailResponsePartyAwardStatusEnum) {
    ApiIncentiveDetailResponsePartyAwardStatusEnum["COMPLETE"] = "COMPLETE";
    ApiIncentiveDetailResponsePartyAwardStatusEnum["PENDING"] = "PENDING";
    ApiIncentiveDetailResponsePartyAwardStatusEnum["EXPIRED"] = "EXPIRED";
    ApiIncentiveDetailResponsePartyAwardStatusEnum["FAILED"] = "FAILED";
})(ApiIncentiveDetailResponsePartyAwardStatusEnum || (ApiIncentiveDetailResponsePartyAwardStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveOfferSummaryAwardTypeEnum;
(function (ApiIncentiveOfferSummaryAwardTypeEnum) {
    ApiIncentiveOfferSummaryAwardTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    ApiIncentiveOfferSummaryAwardTypeEnum["CRYPTO"] = "CRYPTO";
    ApiIncentiveOfferSummaryAwardTypeEnum["CASH"] = "CASH";
    ApiIncentiveOfferSummaryAwardTypeEnum["FEATURE_WHITELIST"] = "FEATURE_WHITELIST";
    ApiIncentiveOfferSummaryAwardTypeEnum["LOYALTY"] = "LOYALTY";
})(ApiIncentiveOfferSummaryAwardTypeEnum || (ApiIncentiveOfferSummaryAwardTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveTriggerCriterionTypeEnum;
(function (ApiIncentiveTriggerCriterionTypeEnum) {
    ApiIncentiveTriggerCriterionTypeEnum["LOYALTY_PARTNER_BY_NAME"] = "LOYALTY_PARTNER_BY_NAME";
    ApiIncentiveTriggerCriterionTypeEnum["BAKKT_CARD_PAYMENT"] = "BAKKT_CARD_PAYMENT";
    ApiIncentiveTriggerCriterionTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiIncentiveTriggerCriterionTypeEnum["LOYALTY_REDEMPTION"] = "LOYALTY_REDEMPTION";
    ApiIncentiveTriggerCriterionTypeEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiIncentiveTriggerCriterionTypeEnum["PARTY_TO_PARTY_TRANSFER"] = "PARTY_TO_PARTY_TRANSFER";
})(ApiIncentiveTriggerCriterionTypeEnum || (ApiIncentiveTriggerCriterionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveTriggerDetailActionTypeEnum;
(function (ApiIncentiveTriggerDetailActionTypeEnum) {
    ApiIncentiveTriggerDetailActionTypeEnum["BUY"] = "BUY";
    ApiIncentiveTriggerDetailActionTypeEnum["SEND"] = "SEND";
    ApiIncentiveTriggerDetailActionTypeEnum["LINK"] = "LINK";
    ApiIncentiveTriggerDetailActionTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiIncentiveTriggerDetailActionTypeEnum["PAYMENT"] = "PAYMENT";
    ApiIncentiveTriggerDetailActionTypeEnum["PAYOUT"] = "PAYOUT";
    ApiIncentiveTriggerDetailActionTypeEnum["REDEEM"] = "REDEEM";
    ApiIncentiveTriggerDetailActionTypeEnum["USER_REGISTRATION"] = "USER_REGISTRATION";
    ApiIncentiveTriggerDetailActionTypeEnum["USER_REFEREE"] = "USER_REFEREE";
    ApiIncentiveTriggerDetailActionTypeEnum["USER_REFERRER"] = "USER_REFERRER";
})(ApiIncentiveTriggerDetailActionTypeEnum || (ApiIncentiveTriggerDetailActionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiIncentiveTriggerDetailAssetTypeEnum;
(function (ApiIncentiveTriggerDetailAssetTypeEnum) {
    ApiIncentiveTriggerDetailAssetTypeEnum["CASH"] = "CASH";
    ApiIncentiveTriggerDetailAssetTypeEnum["CRYPTO"] = "CRYPTO";
    ApiIncentiveTriggerDetailAssetTypeEnum["REWARDS"] = "REWARDS";
    ApiIncentiveTriggerDetailAssetTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    ApiIncentiveTriggerDetailAssetTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    ApiIncentiveTriggerDetailAssetTypeEnum["MERCHANT"] = "MERCHANT";
    ApiIncentiveTriggerDetailAssetTypeEnum["NONE"] = "NONE";
    ApiIncentiveTriggerDetailAssetTypeEnum["ANY"] = "ANY";
})(ApiIncentiveTriggerDetailAssetTypeEnum || (ApiIncentiveTriggerDetailAssetTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum;
(function (ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum) {
    ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum["UNKNOWN"] = "UNKNOWN";
    ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum["AWARD_WALLET"] = "AWARD_WALLET";
    ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum["REWARDS_GATEWAY"] = "REWARDS_GATEWAY";
})(ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum || (ApiLoyaltyAccountDetailLoyaltyIntegrationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyAccountDetailStatusEnum;
(function (ApiLoyaltyAccountDetailStatusEnum) {
    ApiLoyaltyAccountDetailStatusEnum["OPEN"] = "OPEN";
    ApiLoyaltyAccountDetailStatusEnum["PENDING"] = "PENDING";
    ApiLoyaltyAccountDetailStatusEnum["SUSPENDED"] = "SUSPENDED";
    ApiLoyaltyAccountDetailStatusEnum["UNLINKED"] = "UNLINKED";
    ApiLoyaltyAccountDetailStatusEnum["EXPIRED"] = "EXPIRED";
})(ApiLoyaltyAccountDetailStatusEnum || (ApiLoyaltyAccountDetailStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyTransactionLoyaltyCurrencyEnum;
(function (ApiLoyaltyTransactionLoyaltyCurrencyEnum) {
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["USD"] = "USD";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["BCH"] = "BCH";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["BTC"] = "BTC";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["DOGE"] = "DOGE";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["ETC"] = "ETC";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["ETH"] = "ETH";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LTC"] = "LTC";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["SHIB"] = "SHIB";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["USDC"] = "USDC";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["CET"] = "CET";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LXX"] = "LXX";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LCP"] = "LCP";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LWR"] = "LWR";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LMO"] = "LMO";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LKO"] = "LKO";
    ApiLoyaltyTransactionLoyaltyCurrencyEnum["LED"] = "LED";
})(ApiLoyaltyTransactionLoyaltyCurrencyEnum || (ApiLoyaltyTransactionLoyaltyCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyTransactionLoyaltyTransactionTypeEnum;
(function (ApiLoyaltyTransactionLoyaltyTransactionTypeEnum) {
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["REDEMPTION_CANCEL"] = "REDEMPTION_CANCEL";
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["REDEMPTION"] = "REDEMPTION";
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["CREDIT"] = "CREDIT";
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["CREDIT_CANCEL"] = "CREDIT_CANCEL";
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["BUY"] = "BUY";
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["BUY_CANCEL"] = "BUY_CANCEL";
    ApiLoyaltyTransactionLoyaltyTransactionTypeEnum["PAY_WITH_POINTS"] = "PAY_WITH_POINTS";
})(ApiLoyaltyTransactionLoyaltyTransactionTypeEnum || (ApiLoyaltyTransactionLoyaltyTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyTransactionStatusEnum;
(function (ApiLoyaltyTransactionStatusEnum) {
    ApiLoyaltyTransactionStatusEnum["COMPLETE"] = "COMPLETE";
    ApiLoyaltyTransactionStatusEnum["PENDING"] = "PENDING";
    ApiLoyaltyTransactionStatusEnum["TIMED_OUT"] = "TIMED_OUT";
    ApiLoyaltyTransactionStatusEnum["PERMANENT_FAILURE"] = "PERMANENT_FAILURE";
    ApiLoyaltyTransactionStatusEnum["REPLACED_NSF"] = "REPLACED_NSF";
    ApiLoyaltyTransactionStatusEnum["NO_AVAILABLE_BALANCE"] = "NO_AVAILABLE_BALANCE";
})(ApiLoyaltyTransactionStatusEnum || (ApiLoyaltyTransactionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyTransactionTransactionCurrencyEnum;
(function (ApiLoyaltyTransactionTransactionCurrencyEnum) {
    ApiLoyaltyTransactionTransactionCurrencyEnum["USD"] = "USD";
    ApiLoyaltyTransactionTransactionCurrencyEnum["BCH"] = "BCH";
    ApiLoyaltyTransactionTransactionCurrencyEnum["BTC"] = "BTC";
    ApiLoyaltyTransactionTransactionCurrencyEnum["DOGE"] = "DOGE";
    ApiLoyaltyTransactionTransactionCurrencyEnum["ETC"] = "ETC";
    ApiLoyaltyTransactionTransactionCurrencyEnum["ETH"] = "ETH";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LTC"] = "LTC";
    ApiLoyaltyTransactionTransactionCurrencyEnum["SHIB"] = "SHIB";
    ApiLoyaltyTransactionTransactionCurrencyEnum["USDC"] = "USDC";
    ApiLoyaltyTransactionTransactionCurrencyEnum["CET"] = "CET";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LXX"] = "LXX";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LCP"] = "LCP";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LWR"] = "LWR";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LMO"] = "LMO";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LKO"] = "LKO";
    ApiLoyaltyTransactionTransactionCurrencyEnum["LED"] = "LED";
})(ApiLoyaltyTransactionTransactionCurrencyEnum || (ApiLoyaltyTransactionTransactionCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiLoyaltyTransactionUnitPriceCurrencyEnum;
(function (ApiLoyaltyTransactionUnitPriceCurrencyEnum) {
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["USD"] = "USD";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["BCH"] = "BCH";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["BTC"] = "BTC";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["DOGE"] = "DOGE";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["ETC"] = "ETC";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["ETH"] = "ETH";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LTC"] = "LTC";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["SHIB"] = "SHIB";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["USDC"] = "USDC";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["CET"] = "CET";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LXX"] = "LXX";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LCP"] = "LCP";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LWR"] = "LWR";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LMO"] = "LMO";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LKO"] = "LKO";
    ApiLoyaltyTransactionUnitPriceCurrencyEnum["LED"] = "LED";
})(ApiLoyaltyTransactionUnitPriceCurrencyEnum || (ApiLoyaltyTransactionUnitPriceCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiMultiOwnerAccountTypeEnum;
(function (ApiMultiOwnerAccountTypeEnum) {
    ApiMultiOwnerAccountTypeEnum["ENTITY"] = "ENTITY";
    ApiMultiOwnerAccountTypeEnum["JOINT"] = "JOINT";
    ApiMultiOwnerAccountTypeEnum["CUSTODIAL"] = "CUSTODIAL";
})(ApiMultiOwnerAccountTypeEnum || (ApiMultiOwnerAccountTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiOfacRuleDefinitionRuleTypeEnum;
(function (ApiOfacRuleDefinitionRuleTypeEnum) {
    ApiOfacRuleDefinitionRuleTypeEnum["HARD"] = "HARD";
    ApiOfacRuleDefinitionRuleTypeEnum["SOFT"] = "SOFT";
})(ApiOfacRuleDefinitionRuleTypeEnum || (ApiOfacRuleDefinitionRuleTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerCryptoCurrenciesEnum;
(function (ApiPartnerCryptoCurrenciesEnum) {
    ApiPartnerCryptoCurrenciesEnum["BCH"] = "BCH";
    ApiPartnerCryptoCurrenciesEnum["BTC"] = "BTC";
    ApiPartnerCryptoCurrenciesEnum["DOGE"] = "DOGE";
    ApiPartnerCryptoCurrenciesEnum["ETC"] = "ETC";
    ApiPartnerCryptoCurrenciesEnum["ETH"] = "ETH";
    ApiPartnerCryptoCurrenciesEnum["LTC"] = "LTC";
    ApiPartnerCryptoCurrenciesEnum["SHIB"] = "SHIB";
    ApiPartnerCryptoCurrenciesEnum["USDC"] = "USDC";
})(ApiPartnerCryptoCurrenciesEnum || (ApiPartnerCryptoCurrenciesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPrimaryCurrencyEnum;
(function (ApiPartnerPrimaryCurrencyEnum) {
    ApiPartnerPrimaryCurrencyEnum["USD"] = "USD";
    ApiPartnerPrimaryCurrencyEnum["BCH"] = "BCH";
    ApiPartnerPrimaryCurrencyEnum["BTC"] = "BTC";
    ApiPartnerPrimaryCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerPrimaryCurrencyEnum["ETC"] = "ETC";
    ApiPartnerPrimaryCurrencyEnum["ETH"] = "ETH";
    ApiPartnerPrimaryCurrencyEnum["LTC"] = "LTC";
    ApiPartnerPrimaryCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerPrimaryCurrencyEnum["USDC"] = "USDC";
    ApiPartnerPrimaryCurrencyEnum["CET"] = "CET";
    ApiPartnerPrimaryCurrencyEnum["LXX"] = "LXX";
    ApiPartnerPrimaryCurrencyEnum["LCP"] = "LCP";
    ApiPartnerPrimaryCurrencyEnum["LWR"] = "LWR";
    ApiPartnerPrimaryCurrencyEnum["LMO"] = "LMO";
    ApiPartnerPrimaryCurrencyEnum["LKO"] = "LKO";
    ApiPartnerPrimaryCurrencyEnum["LED"] = "LED";
})(ApiPartnerPrimaryCurrencyEnum || (ApiPartnerPrimaryCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerServiceTypesEnum;
(function (ApiPartnerServiceTypesEnum) {
    ApiPartnerServiceTypesEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiPartnerServiceTypesEnum["REWARD"] = "REWARD";
    ApiPartnerServiceTypesEnum["INCENTIVE"] = "INCENTIVE";
    ApiPartnerServiceTypesEnum["PARTY"] = "PARTY";
    ApiPartnerServiceTypesEnum["PAYOUT"] = "PAYOUT";
    ApiPartnerServiceTypesEnum["LOYALTY"] = "LOYALTY";
    ApiPartnerServiceTypesEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiPartnerServiceTypesEnum || (ApiPartnerServiceTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerStatusEnum;
(function (ApiPartnerStatusEnum) {
    ApiPartnerStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerStatusEnum["SUSPENDED"] = "SUSPENDED";
    ApiPartnerStatusEnum["CLOSED"] = "CLOSED";
    ApiPartnerStatusEnum["PENDING"] = "PENDING";
})(ApiPartnerStatusEnum || (ApiPartnerStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerAccountCurrencyEnum;
(function (ApiPartnerAccountCurrencyEnum) {
    ApiPartnerAccountCurrencyEnum["USD"] = "USD";
    ApiPartnerAccountCurrencyEnum["BCH"] = "BCH";
    ApiPartnerAccountCurrencyEnum["BTC"] = "BTC";
    ApiPartnerAccountCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerAccountCurrencyEnum["ETC"] = "ETC";
    ApiPartnerAccountCurrencyEnum["ETH"] = "ETH";
    ApiPartnerAccountCurrencyEnum["LTC"] = "LTC";
    ApiPartnerAccountCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerAccountCurrencyEnum["USDC"] = "USDC";
    ApiPartnerAccountCurrencyEnum["CET"] = "CET";
    ApiPartnerAccountCurrencyEnum["LXX"] = "LXX";
    ApiPartnerAccountCurrencyEnum["LCP"] = "LCP";
    ApiPartnerAccountCurrencyEnum["LWR"] = "LWR";
    ApiPartnerAccountCurrencyEnum["LMO"] = "LMO";
    ApiPartnerAccountCurrencyEnum["LKO"] = "LKO";
    ApiPartnerAccountCurrencyEnum["LED"] = "LED";
})(ApiPartnerAccountCurrencyEnum || (ApiPartnerAccountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerAccountProductTypesEnum;
(function (ApiPartnerAccountProductTypesEnum) {
    ApiPartnerAccountProductTypesEnum["CRYPTO_CONNECT"] = "CRYPTO_CONNECT";
    ApiPartnerAccountProductTypesEnum["CRYPTO_REWARDS"] = "CRYPTO_REWARDS";
    ApiPartnerAccountProductTypesEnum["CRYPTO_PAYOUT"] = "CRYPTO_PAYOUT";
    ApiPartnerAccountProductTypesEnum["FIAT_FUNDING"] = "FIAT_FUNDING";
})(ApiPartnerAccountProductTypesEnum || (ApiPartnerAccountProductTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerAccountStatusEnum;
(function (ApiPartnerAccountStatusEnum) {
    ApiPartnerAccountStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerAccountStatusEnum["DELETED"] = "DELETED";
})(ApiPartnerAccountStatusEnum || (ApiPartnerAccountStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerConfigurationCryptoCurrencySourceEnum;
(function (ApiPartnerConfigurationCryptoCurrencySourceEnum) {
    ApiPartnerConfigurationCryptoCurrencySourceEnum["PARTNER"] = "PARTNER";
    ApiPartnerConfigurationCryptoCurrencySourceEnum["PARTY"] = "PARTY";
})(ApiPartnerConfigurationCryptoCurrencySourceEnum || (ApiPartnerConfigurationCryptoCurrencySourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum;
(function (ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum) {
    ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum["NONE"] = "NONE";
    ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum["VENDOR_SMS"] = "VENDOR_SMS";
    ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum["BAKKT_EMAIL"] = "BAKKT_EMAIL";
})(ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum || (ApiPartnerConfigurationKycDocumentVerificationLinkMethodEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerConfigurationSettlementTypeEnum;
(function (ApiPartnerConfigurationSettlementTypeEnum) {
    ApiPartnerConfigurationSettlementTypeEnum["GROSS"] = "GROSS";
    ApiPartnerConfigurationSettlementTypeEnum["NET"] = "NET";
})(ApiPartnerConfigurationSettlementTypeEnum || (ApiPartnerConfigurationSettlementTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerConfigurationSupportedOperationsEnum;
(function (ApiPartnerConfigurationSupportedOperationsEnum) {
    ApiPartnerConfigurationSupportedOperationsEnum["REVERSE"] = "REVERSE";
    ApiPartnerConfigurationSupportedOperationsEnum["CANCEL"] = "CANCEL";
    ApiPartnerConfigurationSupportedOperationsEnum["DISPUTE"] = "DISPUTE";
})(ApiPartnerConfigurationSupportedOperationsEnum || (ApiPartnerConfigurationSupportedOperationsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerConfigurationWalletTypeEnum;
(function (ApiPartnerConfigurationWalletTypeEnum) {
    ApiPartnerConfigurationWalletTypeEnum["PARTNER"] = "PARTNER";
    ApiPartnerConfigurationWalletTypeEnum["SHARED"] = "SHARED";
})(ApiPartnerConfigurationWalletTypeEnum || (ApiPartnerConfigurationWalletTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerDisputeDetailsCurrencyEnum;
(function (ApiPartnerDisputeDetailsCurrencyEnum) {
    ApiPartnerDisputeDetailsCurrencyEnum["USD"] = "USD";
    ApiPartnerDisputeDetailsCurrencyEnum["BCH"] = "BCH";
    ApiPartnerDisputeDetailsCurrencyEnum["BTC"] = "BTC";
    ApiPartnerDisputeDetailsCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerDisputeDetailsCurrencyEnum["ETC"] = "ETC";
    ApiPartnerDisputeDetailsCurrencyEnum["ETH"] = "ETH";
    ApiPartnerDisputeDetailsCurrencyEnum["LTC"] = "LTC";
    ApiPartnerDisputeDetailsCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerDisputeDetailsCurrencyEnum["USDC"] = "USDC";
    ApiPartnerDisputeDetailsCurrencyEnum["CET"] = "CET";
    ApiPartnerDisputeDetailsCurrencyEnum["LXX"] = "LXX";
    ApiPartnerDisputeDetailsCurrencyEnum["LCP"] = "LCP";
    ApiPartnerDisputeDetailsCurrencyEnum["LWR"] = "LWR";
    ApiPartnerDisputeDetailsCurrencyEnum["LMO"] = "LMO";
    ApiPartnerDisputeDetailsCurrencyEnum["LKO"] = "LKO";
    ApiPartnerDisputeDetailsCurrencyEnum["LED"] = "LED";
})(ApiPartnerDisputeDetailsCurrencyEnum || (ApiPartnerDisputeDetailsCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerDisputeDetailsDisputeTypeEnum;
(function (ApiPartnerDisputeDetailsDisputeTypeEnum) {
    ApiPartnerDisputeDetailsDisputeTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiPartnerDisputeDetailsDisputeTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiPartnerDisputeDetailsDisputeTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiPartnerDisputeDetailsDisputeTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiPartnerDisputeDetailsDisputeTypeEnum["MERCHANT_PAYMENT_V1"] = "MERCHANT_PAYMENT_V1";
    ApiPartnerDisputeDetailsDisputeTypeEnum["PARTNER_TRANSACTION"] = "PARTNER_TRANSACTION";
})(ApiPartnerDisputeDetailsDisputeTypeEnum || (ApiPartnerDisputeDetailsDisputeTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerDisputeDetailsReasonCodeEnum;
(function (ApiPartnerDisputeDetailsReasonCodeEnum) {
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC01"] = "DAC01";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC02"] = "DAC02";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC03"] = "DAC03";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC04"] = "DAC04";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC05"] = "DAC05";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC06"] = "DAC06";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC07"] = "DAC07";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC08"] = "DAC08";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC09"] = "DAC09";
    ApiPartnerDisputeDetailsReasonCodeEnum["DAC10"] = "DAC10";
})(ApiPartnerDisputeDetailsReasonCodeEnum || (ApiPartnerDisputeDetailsReasonCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerDisputeDetailsResolutionTypeEnum;
(function (ApiPartnerDisputeDetailsResolutionTypeEnum) {
    ApiPartnerDisputeDetailsResolutionTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    ApiPartnerDisputeDetailsResolutionTypeEnum["CHARGEBACK_BAKKT"] = "CHARGEBACK_BAKKT";
    ApiPartnerDisputeDetailsResolutionTypeEnum["CREDIT"] = "CREDIT";
    ApiPartnerDisputeDetailsResolutionTypeEnum["REFUND"] = "REFUND";
})(ApiPartnerDisputeDetailsResolutionTypeEnum || (ApiPartnerDisputeDetailsResolutionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerDisputeDetailsStatusEnum;
(function (ApiPartnerDisputeDetailsStatusEnum) {
    ApiPartnerDisputeDetailsStatusEnum["NEW"] = "NEW";
    ApiPartnerDisputeDetailsStatusEnum["ESCALATED"] = "ESCALATED";
    ApiPartnerDisputeDetailsStatusEnum["REJECTED"] = "REJECTED";
    ApiPartnerDisputeDetailsStatusEnum["MERCHANT_REJECTED"] = "MERCHANT_REJECTED";
    ApiPartnerDisputeDetailsStatusEnum["ACCEPTED"] = "ACCEPTED";
    ApiPartnerDisputeDetailsStatusEnum["CANCELLED"] = "CANCELLED";
})(ApiPartnerDisputeDetailsStatusEnum || (ApiPartnerDisputeDetailsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerImmConfigUpdateRequestCommissionTypeEnum;
(function (ApiPartnerImmConfigUpdateRequestCommissionTypeEnum) {
    ApiPartnerImmConfigUpdateRequestCommissionTypeEnum["FLAT"] = "FLAT";
    ApiPartnerImmConfigUpdateRequestCommissionTypeEnum["VARIABLE"] = "VARIABLE";
})(ApiPartnerImmConfigUpdateRequestCommissionTypeEnum || (ApiPartnerImmConfigUpdateRequestCommissionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum;
(function (ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum) {
    ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum["BUY"] = "BUY";
    ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum["SELL"] = "SELL";
    ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum["SELL_ALL"] = "SELL_ALL";
})(ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum || (ApiPartnerLedgerPostingCryptocurrencyTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerLedgerPostingSourceCurrencyEnum;
(function (ApiPartnerLedgerPostingSourceCurrencyEnum) {
    ApiPartnerLedgerPostingSourceCurrencyEnum["USD"] = "USD";
    ApiPartnerLedgerPostingSourceCurrencyEnum["BCH"] = "BCH";
    ApiPartnerLedgerPostingSourceCurrencyEnum["BTC"] = "BTC";
    ApiPartnerLedgerPostingSourceCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerLedgerPostingSourceCurrencyEnum["ETC"] = "ETC";
    ApiPartnerLedgerPostingSourceCurrencyEnum["ETH"] = "ETH";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LTC"] = "LTC";
    ApiPartnerLedgerPostingSourceCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerLedgerPostingSourceCurrencyEnum["USDC"] = "USDC";
    ApiPartnerLedgerPostingSourceCurrencyEnum["CET"] = "CET";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LXX"] = "LXX";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LCP"] = "LCP";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LWR"] = "LWR";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LMO"] = "LMO";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LKO"] = "LKO";
    ApiPartnerLedgerPostingSourceCurrencyEnum["LED"] = "LED";
})(ApiPartnerLedgerPostingSourceCurrencyEnum || (ApiPartnerLedgerPostingSourceCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerLedgerPostingTargetCurrencyEnum;
(function (ApiPartnerLedgerPostingTargetCurrencyEnum) {
    ApiPartnerLedgerPostingTargetCurrencyEnum["USD"] = "USD";
    ApiPartnerLedgerPostingTargetCurrencyEnum["BCH"] = "BCH";
    ApiPartnerLedgerPostingTargetCurrencyEnum["BTC"] = "BTC";
    ApiPartnerLedgerPostingTargetCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerLedgerPostingTargetCurrencyEnum["ETC"] = "ETC";
    ApiPartnerLedgerPostingTargetCurrencyEnum["ETH"] = "ETH";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LTC"] = "LTC";
    ApiPartnerLedgerPostingTargetCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerLedgerPostingTargetCurrencyEnum["USDC"] = "USDC";
    ApiPartnerLedgerPostingTargetCurrencyEnum["CET"] = "CET";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LXX"] = "LXX";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LCP"] = "LCP";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LWR"] = "LWR";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LMO"] = "LMO";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LKO"] = "LKO";
    ApiPartnerLedgerPostingTargetCurrencyEnum["LED"] = "LED";
})(ApiPartnerLedgerPostingTargetCurrencyEnum || (ApiPartnerLedgerPostingTargetCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerLedgerPostingTransactionTypeEnum;
(function (ApiPartnerLedgerPostingTransactionTypeEnum) {
    ApiPartnerLedgerPostingTransactionTypeEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiPartnerLedgerPostingTransactionTypeEnum["REWARD"] = "REWARD";
    ApiPartnerLedgerPostingTransactionTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiPartnerLedgerPostingTransactionTypeEnum["LOYALTY"] = "LOYALTY";
    ApiPartnerLedgerPostingTransactionTypeEnum["PAYOUT"] = "PAYOUT";
    ApiPartnerLedgerPostingTransactionTypeEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiPartnerLedgerPostingTransactionTypeEnum || (ApiPartnerLedgerPostingTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum;
(function (ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum) {
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum || (ApiPartnerPartyDetailAnnualIncomeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailInvestmentExperienceEnum;
(function (ApiPartnerPartyDetailInvestmentExperienceEnum) {
    ApiPartnerPartyDetailInvestmentExperienceEnum["NONE"] = "NONE";
    ApiPartnerPartyDetailInvestmentExperienceEnum["LIMITED"] = "LIMITED";
    ApiPartnerPartyDetailInvestmentExperienceEnum["GOOD"] = "GOOD";
    ApiPartnerPartyDetailInvestmentExperienceEnum["EXTENSIVE"] = "EXTENSIVE";
})(ApiPartnerPartyDetailInvestmentExperienceEnum || (ApiPartnerPartyDetailInvestmentExperienceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailInvestmentObjectiveEnum;
(function (ApiPartnerPartyDetailInvestmentObjectiveEnum) {
    ApiPartnerPartyDetailInvestmentObjectiveEnum["BALANCED"] = "BALANCED";
    ApiPartnerPartyDetailInvestmentObjectiveEnum["CAPITAL_PRESERVATION"] = "CAPITAL_PRESERVATION";
    ApiPartnerPartyDetailInvestmentObjectiveEnum["GROWTH"] = "GROWTH";
    ApiPartnerPartyDetailInvestmentObjectiveEnum["INCOME"] = "INCOME";
    ApiPartnerPartyDetailInvestmentObjectiveEnum["SPECULATION"] = "SPECULATION";
    ApiPartnerPartyDetailInvestmentObjectiveEnum["OTHER"] = "OTHER";
})(ApiPartnerPartyDetailInvestmentObjectiveEnum || (ApiPartnerPartyDetailInvestmentObjectiveEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum;
(function (ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum) {
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum || (ApiPartnerPartyDetailLiquidNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailLiquidityNeedsEnum;
(function (ApiPartnerPartyDetailLiquidityNeedsEnum) {
    ApiPartnerPartyDetailLiquidityNeedsEnum["VERY_IMPORTANT"] = "VERY_IMPORTANT";
    ApiPartnerPartyDetailLiquidityNeedsEnum["SOMEWHAT_IMPORTANT"] = "SOMEWHAT_IMPORTANT";
    ApiPartnerPartyDetailLiquidityNeedsEnum["NOT_IMPORTANT"] = "NOT_IMPORTANT";
})(ApiPartnerPartyDetailLiquidityNeedsEnum || (ApiPartnerPartyDetailLiquidityNeedsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailPartyLevelEnum;
(function (ApiPartnerPartyDetailPartyLevelEnum) {
    ApiPartnerPartyDetailPartyLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartnerPartyDetailPartyLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartnerPartyDetailPartyLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartnerPartyDetailPartyLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyDetailPartyLevelEnum || (ApiPartnerPartyDetailPartyLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailRiskToleranceEnum;
(function (ApiPartnerPartyDetailRiskToleranceEnum) {
    ApiPartnerPartyDetailRiskToleranceEnum["LOW"] = "LOW";
    ApiPartnerPartyDetailRiskToleranceEnum["MEDIUM"] = "MEDIUM";
    ApiPartnerPartyDetailRiskToleranceEnum["HIGH"] = "HIGH";
})(ApiPartnerPartyDetailRiskToleranceEnum || (ApiPartnerPartyDetailRiskToleranceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailServiceTypesEnum;
(function (ApiPartnerPartyDetailServiceTypesEnum) {
    ApiPartnerPartyDetailServiceTypesEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiPartnerPartyDetailServiceTypesEnum["REWARD"] = "REWARD";
    ApiPartnerPartyDetailServiceTypesEnum["INCENTIVE"] = "INCENTIVE";
    ApiPartnerPartyDetailServiceTypesEnum["PARTY"] = "PARTY";
    ApiPartnerPartyDetailServiceTypesEnum["PAYOUT"] = "PAYOUT";
    ApiPartnerPartyDetailServiceTypesEnum["LOYALTY"] = "LOYALTY";
    ApiPartnerPartyDetailServiceTypesEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiPartnerPartyDetailServiceTypesEnum || (ApiPartnerPartyDetailServiceTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailStatusEnum;
(function (ApiPartnerPartyDetailStatusEnum) {
    ApiPartnerPartyDetailStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerPartyDetailStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPartnerPartyDetailStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyDetailStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyDetailStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiPartnerPartyDetailStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiPartnerPartyDetailStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiPartnerPartyDetailStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyDetailStatusEnum || (ApiPartnerPartyDetailStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum;
(function (ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum) {
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum || (ApiPartnerPartyDetailTotalNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum;
(function (ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum) {
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum || (ApiPartnerPartyEnrollmentLogPartnerPartyLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyEnrollmentLogPartyEnrollmentDataValidationStatusEnum;
(function (ApiPartnerPartyEnrollmentLogPartyEnrollmentDataValidationStatusEnum) {
    ApiPartnerPartyEnrollmentLogPartyEnrollmentDataValidationStatusEnum["MATCHES"] = "DATA_MATCHES";
    ApiPartnerPartyEnrollmentLogPartyEnrollmentDataValidationStatusEnum["DOES_NOT_MATCH"] = "DATA_DOES_NOT_MATCH";
})(ApiPartnerPartyEnrollmentLogPartyEnrollmentDataValidationStatusEnum || (ApiPartnerPartyEnrollmentLogPartyEnrollmentDataValidationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum;
(function (ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum) {
    ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum["PARTY_SUSPENDED"] = "PARTY_SUSPENDED";
    ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum["TRANSUNION_TAX_ID_VERIFICATION"] = "TRANSUNION_TAX_ID_VERIFICATION";
    ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum["RISK"] = "RISK";
})(ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum || (ApiPartnerPartyEnrollmentValidationStatusValidationReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum;
(function (ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum) {
    ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum["SUCCESS"] = "SUCCESS";
    ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum["FAILED"] = "FAILED";
    ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum["ERROR"] = "ERROR";
    ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum["RISK_IDENTITY_SERVER_UNREACHABLE"] = "RISK_IDENTITY_SERVER_UNREACHABLE";
})(ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum || (ApiPartnerPartyEnrollmentValidationStatusValidationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum;
(function (ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum) {
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum || (ApiPartnerPartyLinkAnnualIncomeUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkEnrollmentSourceEnum;
(function (ApiPartnerPartyLinkEnrollmentSourceEnum) {
    ApiPartnerPartyLinkEnrollmentSourceEnum["CONSUMER"] = "CONSUMER";
    ApiPartnerPartyLinkEnrollmentSourceEnum["PARTNER"] = "PARTNER";
})(ApiPartnerPartyLinkEnrollmentSourceEnum || (ApiPartnerPartyLinkEnrollmentSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkEnrollmentTypeEnum;
(function (ApiPartnerPartyLinkEnrollmentTypeEnum) {
    ApiPartnerPartyLinkEnrollmentTypeEnum["INDIVIDUAL"] = "INDIVIDUAL";
    ApiPartnerPartyLinkEnrollmentTypeEnum["ENTITY"] = "ENTITY";
    ApiPartnerPartyLinkEnrollmentTypeEnum["JOINT"] = "JOINT";
    ApiPartnerPartyLinkEnrollmentTypeEnum["CUSTODIAL"] = "CUSTODIAL";
})(ApiPartnerPartyLinkEnrollmentTypeEnum || (ApiPartnerPartyLinkEnrollmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkInvestmentExperienceEnum;
(function (ApiPartnerPartyLinkInvestmentExperienceEnum) {
    ApiPartnerPartyLinkInvestmentExperienceEnum["NONE"] = "NONE";
    ApiPartnerPartyLinkInvestmentExperienceEnum["LIMITED"] = "LIMITED";
    ApiPartnerPartyLinkInvestmentExperienceEnum["GOOD"] = "GOOD";
    ApiPartnerPartyLinkInvestmentExperienceEnum["EXTENSIVE"] = "EXTENSIVE";
})(ApiPartnerPartyLinkInvestmentExperienceEnum || (ApiPartnerPartyLinkInvestmentExperienceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkInvestmentObjectiveEnum;
(function (ApiPartnerPartyLinkInvestmentObjectiveEnum) {
    ApiPartnerPartyLinkInvestmentObjectiveEnum["BALANCED"] = "BALANCED";
    ApiPartnerPartyLinkInvestmentObjectiveEnum["CAPITAL_PRESERVATION"] = "CAPITAL_PRESERVATION";
    ApiPartnerPartyLinkInvestmentObjectiveEnum["GROWTH"] = "GROWTH";
    ApiPartnerPartyLinkInvestmentObjectiveEnum["INCOME"] = "INCOME";
    ApiPartnerPartyLinkInvestmentObjectiveEnum["SPECULATION"] = "SPECULATION";
    ApiPartnerPartyLinkInvestmentObjectiveEnum["OTHER"] = "OTHER";
})(ApiPartnerPartyLinkInvestmentObjectiveEnum || (ApiPartnerPartyLinkInvestmentObjectiveEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkLevelEnum;
(function (ApiPartnerPartyLinkLevelEnum) {
    ApiPartnerPartyLinkLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartnerPartyLinkLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartnerPartyLinkLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartnerPartyLinkLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartnerPartyLinkLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartnerPartyLinkLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartnerPartyLinkLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartnerPartyLinkLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartnerPartyLinkLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartnerPartyLinkLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyLinkLevelEnum || (ApiPartnerPartyLinkLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum;
(function (ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum) {
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum || (ApiPartnerPartyLinkLiquidNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkLiquidityNeedsEnum;
(function (ApiPartnerPartyLinkLiquidityNeedsEnum) {
    ApiPartnerPartyLinkLiquidityNeedsEnum["VERY_IMPORTANT"] = "VERY_IMPORTANT";
    ApiPartnerPartyLinkLiquidityNeedsEnum["SOMEWHAT_IMPORTANT"] = "SOMEWHAT_IMPORTANT";
    ApiPartnerPartyLinkLiquidityNeedsEnum["NOT_IMPORTANT"] = "NOT_IMPORTANT";
})(ApiPartnerPartyLinkLiquidityNeedsEnum || (ApiPartnerPartyLinkLiquidityNeedsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkPhotoIdVerificationStatusEnum;
(function (ApiPartnerPartyLinkPhotoIdVerificationStatusEnum) {
    ApiPartnerPartyLinkPhotoIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    ApiPartnerPartyLinkPhotoIdVerificationStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyLinkPhotoIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartnerPartyLinkPhotoIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    ApiPartnerPartyLinkPhotoIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(ApiPartnerPartyLinkPhotoIdVerificationStatusEnum || (ApiPartnerPartyLinkPhotoIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkRiskToleranceEnum;
(function (ApiPartnerPartyLinkRiskToleranceEnum) {
    ApiPartnerPartyLinkRiskToleranceEnum["LOW"] = "LOW";
    ApiPartnerPartyLinkRiskToleranceEnum["MEDIUM"] = "MEDIUM";
    ApiPartnerPartyLinkRiskToleranceEnum["HIGH"] = "HIGH";
})(ApiPartnerPartyLinkRiskToleranceEnum || (ApiPartnerPartyLinkRiskToleranceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkServiceTypesEnum;
(function (ApiPartnerPartyLinkServiceTypesEnum) {
    ApiPartnerPartyLinkServiceTypesEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiPartnerPartyLinkServiceTypesEnum["REWARD"] = "REWARD";
    ApiPartnerPartyLinkServiceTypesEnum["INCENTIVE"] = "INCENTIVE";
    ApiPartnerPartyLinkServiceTypesEnum["PARTY"] = "PARTY";
    ApiPartnerPartyLinkServiceTypesEnum["PAYOUT"] = "PAYOUT";
    ApiPartnerPartyLinkServiceTypesEnum["LOYALTY"] = "LOYALTY";
    ApiPartnerPartyLinkServiceTypesEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiPartnerPartyLinkServiceTypesEnum || (ApiPartnerPartyLinkServiceTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkStatusEnum;
(function (ApiPartnerPartyLinkStatusEnum) {
    ApiPartnerPartyLinkStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerPartyLinkStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPartnerPartyLinkStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyLinkStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyLinkStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiPartnerPartyLinkStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiPartnerPartyLinkStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiPartnerPartyLinkStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyLinkStatusEnum || (ApiPartnerPartyLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkStatusReasonEnum;
(function (ApiPartnerPartyLinkStatusReasonEnum) {
    ApiPartnerPartyLinkStatusReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
    ApiPartnerPartyLinkStatusReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiPartnerPartyLinkStatusReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiPartnerPartyLinkStatusReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiPartnerPartyLinkStatusReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiPartnerPartyLinkStatusReasonEnum["OPEN"] = "OPEN";
    ApiPartnerPartyLinkStatusReasonEnum["CLOSED"] = "CLOSED";
    ApiPartnerPartyLinkStatusReasonEnum["CLOSING_ONLY"] = "CLOSING_ONLY";
    ApiPartnerPartyLinkStatusReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiPartnerPartyLinkStatusReasonEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyLinkStatusReasonEnum["KYC_SKIPPED"] = "KYC_SKIPPED";
})(ApiPartnerPartyLinkStatusReasonEnum || (ApiPartnerPartyLinkStatusReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkTaxIdTypeEnum;
(function (ApiPartnerPartyLinkTaxIdTypeEnum) {
    ApiPartnerPartyLinkTaxIdTypeEnum["SSN"] = "SSN";
    ApiPartnerPartyLinkTaxIdTypeEnum["ITIN"] = "ITIN";
    ApiPartnerPartyLinkTaxIdTypeEnum["EIN"] = "EIN";
    ApiPartnerPartyLinkTaxIdTypeEnum["TIN"] = "TIN";
    ApiPartnerPartyLinkTaxIdTypeEnum["NATIONAL_ID"] = "NATIONAL_ID";
})(ApiPartnerPartyLinkTaxIdTypeEnum || (ApiPartnerPartyLinkTaxIdTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkTaxIdVerificationStatusEnum;
(function (ApiPartnerPartyLinkTaxIdVerificationStatusEnum) {
    ApiPartnerPartyLinkTaxIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    ApiPartnerPartyLinkTaxIdVerificationStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyLinkTaxIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartnerPartyLinkTaxIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    ApiPartnerPartyLinkTaxIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(ApiPartnerPartyLinkTaxIdVerificationStatusEnum || (ApiPartnerPartyLinkTaxIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum;
(function (ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum) {
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["UNDER_25K"] = "UNDER_25K";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_25K_TO_50K"] = "FROM_25K_TO_50K";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_50K_TO_100K"] = "FROM_50K_TO_100K";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_100K_TO_200K"] = "FROM_100K_TO_200K";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_200K_TO_300K"] = "FROM_200K_TO_300K";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_300K_TO_500K"] = "FROM_300K_TO_500K";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_500K_TO_1M"] = "FROM_500K_TO_1M";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["FROM_1M_TO_5M"] = "FROM_1M_TO_5M";
    ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum["OVER_5M"] = "OVER_5M";
})(ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum || (ApiPartnerPartyLinkTotalNetWorthUsdRangeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum;
(function (ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum) {
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum || (ApiPartnerPartyLinkChangeStatusRequestChangeStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum;
(function (ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum) {
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["OPEN"] = "OPEN";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["CLOSED"] = "CLOSED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["CLOSING_ONLY"] = "CLOSING_ONLY";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum["KYC_SKIPPED"] = "KYC_SKIPPED";
})(ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum || (ApiPartnerPartyLinkChangeStatusRequestChangeStatusReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkChangeStatusRequestReasonEnum;
(function (ApiPartnerPartyLinkChangeStatusRequestReasonEnum) {
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ACCOUNT_CLOSURE_REQUESTED"] = "ACCOUNT_CLOSURE_REQUESTED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ACH_MAX_RETURNS"] = "ACH_MAX_RETURNS";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ADMIN_UPDATE_IDENTIFIER"] = "ADMIN_UPDATE_IDENTIFIER";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["COMPLIANCE_INVESTIGATION"] = "COMPLIANCE_INVESTIGATION";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["COMPLIANCE_VERIFICATION"] = "COMPLIANCE_VERIFICATION";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["CUSTOMER_GOOD_STANDING"] = "CUSTOMER_GOOD_STANDING";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_TRANSACTION_FRAUD_HOLD"] = "DEBIT_CARD_TRANSACTION_FRAUD_HOLD";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED"] = "DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["LIQUIDATE_ACCOUNT"] = "LIQUIDATE_ACCOUNT";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["MARQETA_KYC_VERIFICATION"] = "MARQETA_KYC_VERIFICATION";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["NSF_BLOCKING"] = "NSF_BLOCKING";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["OLD_OFAC_CHECK"] = "OLD_OFAC_CHECK";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["OTHER"] = "OTHER";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["PLAID_IDENTITY_CHECK"] = "PLAID_IDENTITY_CHECK";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["PLAID_MISSING_NAMES"] = "PLAID_MISSING_NAMES";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["RELINK"] = "RELINK";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["RISK_DECISION"] = "RISK_DECISION";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["RISK_SCREENING"] = "RISK_SCREENING";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["TAX_ID_MAX_RETRIES"] = "TAX_ID_MAX_RETRIES";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["CARD_TERMINATION_ERROR"] = "CARD_TERMINATION_ERROR";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["CLOSED"] = "CLOSED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["PARTNER_UPDATE"] = "PARTNER_UPDATE";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["ENROLLMENT"] = "ENROLLMENT";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["OPEN"] = "OPEN";
    ApiPartnerPartyLinkChangeStatusRequestReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
})(ApiPartnerPartyLinkChangeStatusRequestReasonEnum || (ApiPartnerPartyLinkChangeStatusRequestReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum;
(function (ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum) {
    ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum["INDIVIDUAL"] = "INDIVIDUAL";
    ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum["ENTITY"] = "ENTITY";
    ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum["JOINT"] = "JOINT";
    ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum["CUSTODIAL"] = "CUSTODIAL";
})(ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum || (ApiPartnerPartyLinkSearchResponseEnrollmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkSearchResponseLevelEnum;
(function (ApiPartnerPartyLinkSearchResponseLevelEnum) {
    ApiPartnerPartyLinkSearchResponseLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartnerPartyLinkSearchResponseLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyLinkSearchResponseLevelEnum || (ApiPartnerPartyLinkSearchResponseLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkSearchResponsePartyTypeEnum;
(function (ApiPartnerPartyLinkSearchResponsePartyTypeEnum) {
    ApiPartnerPartyLinkSearchResponsePartyTypeEnum["SERVICE"] = "SERVICE";
    ApiPartnerPartyLinkSearchResponsePartyTypeEnum["CONSUMER"] = "CONSUMER";
    ApiPartnerPartyLinkSearchResponsePartyTypeEnum["MERCHANT"] = "MERCHANT";
})(ApiPartnerPartyLinkSearchResponsePartyTypeEnum || (ApiPartnerPartyLinkSearchResponsePartyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerPartyLinkSearchResponseStatusEnum;
(function (ApiPartnerPartyLinkSearchResponseStatusEnum) {
    ApiPartnerPartyLinkSearchResponseStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartnerPartyLinkSearchResponseStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPartnerPartyLinkSearchResponseStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartnerPartyLinkSearchResponseStatusEnum["PENDING"] = "PENDING";
    ApiPartnerPartyLinkSearchResponseStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiPartnerPartyLinkSearchResponseStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiPartnerPartyLinkSearchResponseStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiPartnerPartyLinkSearchResponseStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartnerPartyLinkSearchResponseStatusEnum || (ApiPartnerPartyLinkSearchResponseStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerRequestRequestDataTypeEnum;
(function (ApiPartnerRequestRequestDataTypeEnum) {
    ApiPartnerRequestRequestDataTypeEnum["APEX"] = "APEX";
    ApiPartnerRequestRequestDataTypeEnum["ASYNCBUY"] = "ASYNCBUY";
    ApiPartnerRequestRequestDataTypeEnum["ASYNC_TRANSFER_FIAT_CURRENCY"] = "ASYNC_TRANSFER_FIAT_CURRENCY";
    ApiPartnerRequestRequestDataTypeEnum["ASYNCENROLL"] = "ASYNCENROLL";
    ApiPartnerRequestRequestDataTypeEnum["ASYNC_LINK_BANK_ACCOUNT"] = "ASYNC_LINK_BANK_ACCOUNT";
    ApiPartnerRequestRequestDataTypeEnum["ASYNC_LINK_BANK_ACCOUNT_ACH"] = "ASYNC_LINK_BANK_ACCOUNT_ACH";
    ApiPartnerRequestRequestDataTypeEnum["ASYNC_UNLINK_BANK_ACCOUNT"] = "ASYNC_UNLINK_BANK_ACCOUNT";
    ApiPartnerRequestRequestDataTypeEnum["ASYNC_LINK_BANK_ACCOUNT_WIRE"] = "ASYNC_LINK_BANK_ACCOUNT_WIRE";
})(ApiPartnerRequestRequestDataTypeEnum || (ApiPartnerRequestRequestDataTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerRequestStatusEnum;
(function (ApiPartnerRequestStatusEnum) {
    ApiPartnerRequestStatusEnum["RECEIVED"] = "RECEIVED";
    ApiPartnerRequestStatusEnum["PENDING"] = "PENDING";
    ApiPartnerRequestStatusEnum["COMPLETED"] = "COMPLETED";
    ApiPartnerRequestStatusEnum["FAILED"] = "FAILED";
    ApiPartnerRequestStatusEnum["EXPIRED"] = "EXPIRED";
})(ApiPartnerRequestStatusEnum || (ApiPartnerRequestStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerRiskConfigRequestProductEnum;
(function (ApiPartnerRiskConfigRequestProductEnum) {
    ApiPartnerRiskConfigRequestProductEnum["DEFAULT"] = "DEFAULT";
    ApiPartnerRiskConfigRequestProductEnum["BAKKT_APP"] = "BAKKT_APP";
    ApiPartnerRiskConfigRequestProductEnum["CRYPTO_AS_SERVICE"] = "CRYPTO_AS_SERVICE";
    ApiPartnerRiskConfigRequestProductEnum["EMBEDDED_CRYPTO_AS_SERVICE"] = "EMBEDDED_CRYPTO_AS_SERVICE";
    ApiPartnerRiskConfigRequestProductEnum["CRYPTO_CARD"] = "CRYPTO_CARD";
    ApiPartnerRiskConfigRequestProductEnum["CRYPTO_REWARDS"] = "CRYPTO_REWARDS";
    ApiPartnerRiskConfigRequestProductEnum["DISBURSEMENTS"] = "DISBURSEMENTS";
    ApiPartnerRiskConfigRequestProductEnum["PARTY"] = "PARTY";
    ApiPartnerRiskConfigRequestProductEnum["PAYOUTS"] = "PAYOUTS";
    ApiPartnerRiskConfigRequestProductEnum["FIAT_FUNDING"] = "FIAT_FUNDING";
})(ApiPartnerRiskConfigRequestProductEnum || (ApiPartnerRiskConfigRequestProductEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum;
(function (ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum) {
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum || (ApiPartnerRiskConfigRequestRequiredPartnerPartyLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerRiskConfigResponseProductEnum;
(function (ApiPartnerRiskConfigResponseProductEnum) {
    ApiPartnerRiskConfigResponseProductEnum["DEFAULT"] = "DEFAULT";
    ApiPartnerRiskConfigResponseProductEnum["BAKKT_APP"] = "BAKKT_APP";
    ApiPartnerRiskConfigResponseProductEnum["CRYPTO_AS_SERVICE"] = "CRYPTO_AS_SERVICE";
    ApiPartnerRiskConfigResponseProductEnum["EMBEDDED_CRYPTO_AS_SERVICE"] = "EMBEDDED_CRYPTO_AS_SERVICE";
    ApiPartnerRiskConfigResponseProductEnum["CRYPTO_CARD"] = "CRYPTO_CARD";
    ApiPartnerRiskConfigResponseProductEnum["CRYPTO_REWARDS"] = "CRYPTO_REWARDS";
    ApiPartnerRiskConfigResponseProductEnum["DISBURSEMENTS"] = "DISBURSEMENTS";
    ApiPartnerRiskConfigResponseProductEnum["PARTY"] = "PARTY";
    ApiPartnerRiskConfigResponseProductEnum["PAYOUTS"] = "PAYOUTS";
    ApiPartnerRiskConfigResponseProductEnum["FIAT_FUNDING"] = "FIAT_FUNDING";
})(ApiPartnerRiskConfigResponseProductEnum || (ApiPartnerRiskConfigResponseProductEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum;
(function (ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum) {
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum || (ApiPartnerRiskConfigResponseRequiredPartnerPartyLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum;
(function (ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum) {
    ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum["CASH_TRANSACTION"] = "CASH_TRANSACTION";
    ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum["CRYPTO_BUY"] = "CRYPTO_BUY";
    ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum["POINTS_TO_FIAT"] = "POINTS_TO_FIAT";
    ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum["PAYOUT"] = "PAYOUT";
})(ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum || (ApiPartnerTransactionAdjustmentTransactionAdjustmentTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailCryptoCurrencySourceEnum;
(function (ApiPartnerTransactionDetailCryptoCurrencySourceEnum) {
    ApiPartnerTransactionDetailCryptoCurrencySourceEnum["PARTNER"] = "PARTNER";
    ApiPartnerTransactionDetailCryptoCurrencySourceEnum["PARTY"] = "PARTY";
})(ApiPartnerTransactionDetailCryptoCurrencySourceEnum || (ApiPartnerTransactionDetailCryptoCurrencySourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum;
(function (ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum) {
    ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum["BUY"] = "BUY";
    ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum["SELL"] = "SELL";
    ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum["SELL_ALL"] = "SELL_ALL";
})(ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum || (ApiPartnerTransactionDetailCryptoCurrencyTransactTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailCurrencyEnum;
(function (ApiPartnerTransactionDetailCurrencyEnum) {
    ApiPartnerTransactionDetailCurrencyEnum["USD"] = "USD";
    ApiPartnerTransactionDetailCurrencyEnum["BCH"] = "BCH";
    ApiPartnerTransactionDetailCurrencyEnum["BTC"] = "BTC";
    ApiPartnerTransactionDetailCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerTransactionDetailCurrencyEnum["ETC"] = "ETC";
    ApiPartnerTransactionDetailCurrencyEnum["ETH"] = "ETH";
    ApiPartnerTransactionDetailCurrencyEnum["LTC"] = "LTC";
    ApiPartnerTransactionDetailCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerTransactionDetailCurrencyEnum["USDC"] = "USDC";
    ApiPartnerTransactionDetailCurrencyEnum["CET"] = "CET";
    ApiPartnerTransactionDetailCurrencyEnum["LXX"] = "LXX";
    ApiPartnerTransactionDetailCurrencyEnum["LCP"] = "LCP";
    ApiPartnerTransactionDetailCurrencyEnum["LWR"] = "LWR";
    ApiPartnerTransactionDetailCurrencyEnum["LMO"] = "LMO";
    ApiPartnerTransactionDetailCurrencyEnum["LKO"] = "LKO";
    ApiPartnerTransactionDetailCurrencyEnum["LED"] = "LED";
})(ApiPartnerTransactionDetailCurrencyEnum || (ApiPartnerTransactionDetailCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailSourceCurrencyEnum;
(function (ApiPartnerTransactionDetailSourceCurrencyEnum) {
    ApiPartnerTransactionDetailSourceCurrencyEnum["USD"] = "USD";
    ApiPartnerTransactionDetailSourceCurrencyEnum["BCH"] = "BCH";
    ApiPartnerTransactionDetailSourceCurrencyEnum["BTC"] = "BTC";
    ApiPartnerTransactionDetailSourceCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerTransactionDetailSourceCurrencyEnum["ETC"] = "ETC";
    ApiPartnerTransactionDetailSourceCurrencyEnum["ETH"] = "ETH";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LTC"] = "LTC";
    ApiPartnerTransactionDetailSourceCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerTransactionDetailSourceCurrencyEnum["USDC"] = "USDC";
    ApiPartnerTransactionDetailSourceCurrencyEnum["CET"] = "CET";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LXX"] = "LXX";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LCP"] = "LCP";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LWR"] = "LWR";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LMO"] = "LMO";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LKO"] = "LKO";
    ApiPartnerTransactionDetailSourceCurrencyEnum["LED"] = "LED";
})(ApiPartnerTransactionDetailSourceCurrencyEnum || (ApiPartnerTransactionDetailSourceCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailStatusEnum;
(function (ApiPartnerTransactionDetailStatusEnum) {
    ApiPartnerTransactionDetailStatusEnum["CAPTURED"] = "CAPTURED";
    ApiPartnerTransactionDetailStatusEnum["DECLINED"] = "DECLINED";
    ApiPartnerTransactionDetailStatusEnum["DISPUTED"] = "DISPUTED";
    ApiPartnerTransactionDetailStatusEnum["CANCELLED"] = "CANCELLED";
    ApiPartnerTransactionDetailStatusEnum["PENDING"] = "PENDING";
    ApiPartnerTransactionDetailStatusEnum["COMPLETED"] = "COMPLETED";
})(ApiPartnerTransactionDetailStatusEnum || (ApiPartnerTransactionDetailStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailTargetCurrencyEnum;
(function (ApiPartnerTransactionDetailTargetCurrencyEnum) {
    ApiPartnerTransactionDetailTargetCurrencyEnum["USD"] = "USD";
    ApiPartnerTransactionDetailTargetCurrencyEnum["BCH"] = "BCH";
    ApiPartnerTransactionDetailTargetCurrencyEnum["BTC"] = "BTC";
    ApiPartnerTransactionDetailTargetCurrencyEnum["DOGE"] = "DOGE";
    ApiPartnerTransactionDetailTargetCurrencyEnum["ETC"] = "ETC";
    ApiPartnerTransactionDetailTargetCurrencyEnum["ETH"] = "ETH";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LTC"] = "LTC";
    ApiPartnerTransactionDetailTargetCurrencyEnum["SHIB"] = "SHIB";
    ApiPartnerTransactionDetailTargetCurrencyEnum["USDC"] = "USDC";
    ApiPartnerTransactionDetailTargetCurrencyEnum["CET"] = "CET";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LXX"] = "LXX";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LCP"] = "LCP";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LWR"] = "LWR";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LMO"] = "LMO";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LKO"] = "LKO";
    ApiPartnerTransactionDetailTargetCurrencyEnum["LED"] = "LED";
})(ApiPartnerTransactionDetailTargetCurrencyEnum || (ApiPartnerTransactionDetailTargetCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailTransactionTypeEnum;
(function (ApiPartnerTransactionDetailTransactionTypeEnum) {
    ApiPartnerTransactionDetailTransactionTypeEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiPartnerTransactionDetailTransactionTypeEnum["REWARD"] = "REWARD";
    ApiPartnerTransactionDetailTransactionTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiPartnerTransactionDetailTransactionTypeEnum["LOYALTY"] = "LOYALTY";
    ApiPartnerTransactionDetailTransactionTypeEnum["PAYOUT"] = "PAYOUT";
    ApiPartnerTransactionDetailTransactionTypeEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiPartnerTransactionDetailTransactionTypeEnum || (ApiPartnerTransactionDetailTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailWalletTypeEnum;
(function (ApiPartnerTransactionDetailWalletTypeEnum) {
    ApiPartnerTransactionDetailWalletTypeEnum["PARTNER"] = "PARTNER";
    ApiPartnerTransactionDetailWalletTypeEnum["SHARED"] = "SHARED";
})(ApiPartnerTransactionDetailWalletTypeEnum || (ApiPartnerTransactionDetailWalletTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartnerTransactionDetailsStatusEnum;
(function (ApiPartnerTransactionDetailsStatusEnum) {
    ApiPartnerTransactionDetailsStatusEnum["CAPTURED"] = "CAPTURED";
    ApiPartnerTransactionDetailsStatusEnum["DECLINED"] = "DECLINED";
    ApiPartnerTransactionDetailsStatusEnum["DISPUTED"] = "DISPUTED";
    ApiPartnerTransactionDetailsStatusEnum["CANCELLED"] = "CANCELLED";
    ApiPartnerTransactionDetailsStatusEnum["PENDING"] = "PENDING";
    ApiPartnerTransactionDetailsStatusEnum["COMPLETED"] = "COMPLETED";
})(ApiPartnerTransactionDetailsStatusEnum || (ApiPartnerTransactionDetailsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyLevelEnum;
(function (ApiPartyLevelEnum) {
    ApiPartyLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartyLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartyLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartyLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartyLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartyLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartyLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartyLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartyLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartyLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartyLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartyLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartyLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartyLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartyLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartyLevelEnum || (ApiPartyLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPartyTypeEnum;
(function (ApiPartyPartyTypeEnum) {
    ApiPartyPartyTypeEnum["SERVICE"] = "SERVICE";
    ApiPartyPartyTypeEnum["CONSUMER"] = "CONSUMER";
    ApiPartyPartyTypeEnum["MERCHANT"] = "MERCHANT";
})(ApiPartyPartyTypeEnum || (ApiPartyPartyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPhotoIdVerificationStatusEnum;
(function (ApiPartyPhotoIdVerificationStatusEnum) {
    ApiPartyPhotoIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    ApiPartyPhotoIdVerificationStatusEnum["PENDING"] = "PENDING";
    ApiPartyPhotoIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartyPhotoIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    ApiPartyPhotoIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(ApiPartyPhotoIdVerificationStatusEnum || (ApiPartyPhotoIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyStatusEnum;
(function (ApiPartyStatusEnum) {
    ApiPartyStatusEnum["OPEN"] = "OPEN";
    ApiPartyStatusEnum["CLOSED"] = "CLOSED";
    ApiPartyStatusEnum["SUSPENDED"] = "SUSPENDED";
    ApiPartyStatusEnum["LIQUIDATED"] = "LIQUIDATED";
})(ApiPartyStatusEnum || (ApiPartyStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyTaxIdVerificationStatusEnum;
(function (ApiPartyTaxIdVerificationStatusEnum) {
    ApiPartyTaxIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    ApiPartyTaxIdVerificationStatusEnum["PENDING"] = "PENDING";
    ApiPartyTaxIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartyTaxIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    ApiPartyTaxIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(ApiPartyTaxIdVerificationStatusEnum || (ApiPartyTaxIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyBaseSearchResponseLevelEnum;
(function (ApiPartyBaseSearchResponseLevelEnum) {
    ApiPartyBaseSearchResponseLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartyBaseSearchResponseLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartyBaseSearchResponseLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartyBaseSearchResponseLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartyBaseSearchResponseLevelEnum || (ApiPartyBaseSearchResponseLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyBaseSearchResponsePartyTypeEnum;
(function (ApiPartyBaseSearchResponsePartyTypeEnum) {
    ApiPartyBaseSearchResponsePartyTypeEnum["SERVICE"] = "SERVICE";
    ApiPartyBaseSearchResponsePartyTypeEnum["CONSUMER"] = "CONSUMER";
    ApiPartyBaseSearchResponsePartyTypeEnum["MERCHANT"] = "MERCHANT";
})(ApiPartyBaseSearchResponsePartyTypeEnum || (ApiPartyBaseSearchResponsePartyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyBaseSearchResponseStatusEnum;
(function (ApiPartyBaseSearchResponseStatusEnum) {
    ApiPartyBaseSearchResponseStatusEnum["OPEN"] = "OPEN";
    ApiPartyBaseSearchResponseStatusEnum["CLOSED"] = "CLOSED";
    ApiPartyBaseSearchResponseStatusEnum["SUSPENDED"] = "SUSPENDED";
    ApiPartyBaseSearchResponseStatusEnum["LIQUIDATED"] = "LIQUIDATED";
})(ApiPartyBaseSearchResponseStatusEnum || (ApiPartyBaseSearchResponseStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyDashboardSearchSystemEnum;
(function (ApiPartyDashboardSearchSystemEnum) {
    ApiPartyDashboardSearchSystemEnum["TANGO"] = "TANGO";
    ApiPartyDashboardSearchSystemEnum["UNIT21"] = "UNIT21";
    ApiPartyDashboardSearchSystemEnum["SIFT"] = "SIFT";
    ApiPartyDashboardSearchSystemEnum["BRAZE"] = "BRAZE";
    ApiPartyDashboardSearchSystemEnum["SARDINE"] = "SARDINE";
    ApiPartyDashboardSearchSystemEnum["REWARD"] = "REWARD";
    ApiPartyDashboardSearchSystemEnum["FUSIONAUTH"] = "FUSIONAUTH";
    ApiPartyDashboardSearchSystemEnum["PARTNER_PARTY_LINK"] = "PARTNER_PARTY_LINK";
    ApiPartyDashboardSearchSystemEnum["WIRE_REFERENCE_CODE"] = "WIRE_REFERENCE_CODE";
})(ApiPartyDashboardSearchSystemEnum || (ApiPartyDashboardSearchSystemEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyKycDetailsKycStatusEnum;
(function (ApiPartyKycDetailsKycStatusEnum) {
    ApiPartyKycDetailsKycStatusEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartyKycDetailsKycStatusEnum["PASSED"] = "PASSED";
    ApiPartyKycDetailsKycStatusEnum["RELEASED"] = "RELEASED";
    ApiPartyKycDetailsKycStatusEnum["ON_HOLD"] = "ON_HOLD";
})(ApiPartyKycDetailsKycStatusEnum || (ApiPartyKycDetailsKycStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyOfacMatchMatchedRuleTypeEnum;
(function (ApiPartyOfacMatchMatchedRuleTypeEnum) {
    ApiPartyOfacMatchMatchedRuleTypeEnum["HARD"] = "HARD";
    ApiPartyOfacMatchMatchedRuleTypeEnum["SOFT"] = "SOFT";
})(ApiPartyOfacMatchMatchedRuleTypeEnum || (ApiPartyOfacMatchMatchedRuleTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPartnerLinkDetailsCryptoCurrencySourceEnum;
(function (ApiPartyPartnerLinkDetailsCryptoCurrencySourceEnum) {
    ApiPartyPartnerLinkDetailsCryptoCurrencySourceEnum["PARTNER"] = "PARTNER";
    ApiPartyPartnerLinkDetailsCryptoCurrencySourceEnum["PARTY"] = "PARTY";
})(ApiPartyPartnerLinkDetailsCryptoCurrencySourceEnum || (ApiPartyPartnerLinkDetailsCryptoCurrencySourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPartnerLinkDetailsServiceTypesEnum;
(function (ApiPartyPartnerLinkDetailsServiceTypesEnum) {
    ApiPartyPartnerLinkDetailsServiceTypesEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    ApiPartyPartnerLinkDetailsServiceTypesEnum["REWARD"] = "REWARD";
    ApiPartyPartnerLinkDetailsServiceTypesEnum["INCENTIVE"] = "INCENTIVE";
    ApiPartyPartnerLinkDetailsServiceTypesEnum["PARTY"] = "PARTY";
    ApiPartyPartnerLinkDetailsServiceTypesEnum["PAYOUT"] = "PAYOUT";
    ApiPartyPartnerLinkDetailsServiceTypesEnum["LOYALTY"] = "LOYALTY";
    ApiPartyPartnerLinkDetailsServiceTypesEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(ApiPartyPartnerLinkDetailsServiceTypesEnum || (ApiPartyPartnerLinkDetailsServiceTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPartnerLinkDetailsStatusEnum;
(function (ApiPartyPartnerLinkDetailsStatusEnum) {
    ApiPartyPartnerLinkDetailsStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPartyPartnerLinkDetailsStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPartyPartnerLinkDetailsStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiPartyPartnerLinkDetailsStatusEnum["PENDING"] = "PENDING";
    ApiPartyPartnerLinkDetailsStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiPartyPartnerLinkDetailsStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiPartyPartnerLinkDetailsStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiPartyPartnerLinkDetailsStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartyPartnerLinkDetailsStatusEnum || (ApiPartyPartnerLinkDetailsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPartnerLinkDetailsWalletTypeEnum;
(function (ApiPartyPartnerLinkDetailsWalletTypeEnum) {
    ApiPartyPartnerLinkDetailsWalletTypeEnum["PARTNER"] = "PARTNER";
    ApiPartyPartnerLinkDetailsWalletTypeEnum["SHARED"] = "SHARED";
})(ApiPartyPartnerLinkDetailsWalletTypeEnum || (ApiPartyPartnerLinkDetailsWalletTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyPhotoIdDetailsIdVerifiedEnum;
(function (ApiPartyPhotoIdDetailsIdVerifiedEnum) {
    ApiPartyPhotoIdDetailsIdVerifiedEnum["LOCKED"] = "LOCKED";
    ApiPartyPhotoIdDetailsIdVerifiedEnum["PENDING"] = "PENDING";
    ApiPartyPhotoIdDetailsIdVerifiedEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartyPhotoIdDetailsIdVerifiedEnum["VERIFIED"] = "VERIFIED";
    ApiPartyPhotoIdDetailsIdVerifiedEnum["SKIPPED"] = "SKIPPED";
})(ApiPartyPhotoIdDetailsIdVerifiedEnum || (ApiPartyPhotoIdDetailsIdVerifiedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyRiskDetailsRiskLevelEnum;
(function (ApiPartyRiskDetailsRiskLevelEnum) {
    ApiPartyRiskDetailsRiskLevelEnum["VERY_HIGH"] = "VERY_HIGH";
    ApiPartyRiskDetailsRiskLevelEnum["HIGH"] = "HIGH";
    ApiPartyRiskDetailsRiskLevelEnum["MEDIUM"] = "MEDIUM";
    ApiPartyRiskDetailsRiskLevelEnum["LOW"] = "LOW";
})(ApiPartyRiskDetailsRiskLevelEnum || (ApiPartyRiskDetailsRiskLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartySearchResponseOnBoardingStatusEnum;
(function (ApiPartySearchResponseOnBoardingStatusEnum) {
    ApiPartySearchResponseOnBoardingStatusEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartySearchResponseOnBoardingStatusEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartySearchResponseOnBoardingStatusEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartySearchResponseOnBoardingStatusEnum["CLOSED"] = "CLOSED";
})(ApiPartySearchResponseOnBoardingStatusEnum || (ApiPartySearchResponseOnBoardingStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyTaxIdDetailsResponseStatusEnum;
(function (ApiPartyTaxIdDetailsResponseStatusEnum) {
    ApiPartyTaxIdDetailsResponseStatusEnum["SUCCESS"] = "SUCCESS";
    ApiPartyTaxIdDetailsResponseStatusEnum["FAILURE"] = "FAILURE";
    ApiPartyTaxIdDetailsResponseStatusEnum["ACCESS_DENIED"] = "ACCESS_DENIED";
    ApiPartyTaxIdDetailsResponseStatusEnum["BAD_REQUEST"] = "BAD_REQUEST";
    ApiPartyTaxIdDetailsResponseStatusEnum["SERVICE_ERROR"] = "SERVICE_ERROR";
    ApiPartyTaxIdDetailsResponseStatusEnum["MULTIPLE_RECORDS"] = "MULTIPLE_RECORDS";
    ApiPartyTaxIdDetailsResponseStatusEnum["DOB_INVALID"] = "DOB_INVALID";
    ApiPartyTaxIdDetailsResponseStatusEnum["INVALID_TOKEN"] = "INVALID_TOKEN";
    ApiPartyTaxIdDetailsResponseStatusEnum["INVALID_MSG_CONTENT"] = "INVALID_MSG_CONTENT";
    ApiPartyTaxIdDetailsResponseStatusEnum["TIMEOUT"] = "TIMEOUT";
})(ApiPartyTaxIdDetailsResponseStatusEnum || (ApiPartyTaxIdDetailsResponseStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyTaxIdDetailsTaxIdVerifiedEnum;
(function (ApiPartyTaxIdDetailsTaxIdVerifiedEnum) {
    ApiPartyTaxIdDetailsTaxIdVerifiedEnum["LOCKED"] = "LOCKED";
    ApiPartyTaxIdDetailsTaxIdVerifiedEnum["PENDING"] = "PENDING";
    ApiPartyTaxIdDetailsTaxIdVerifiedEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartyTaxIdDetailsTaxIdVerifiedEnum["VERIFIED"] = "VERIFIED";
    ApiPartyTaxIdDetailsTaxIdVerifiedEnum["SKIPPED"] = "SKIPPED";
})(ApiPartyTaxIdDetailsTaxIdVerifiedEnum || (ApiPartyTaxIdDetailsTaxIdVerifiedEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyWithTermsAndConditionsLevelEnum;
(function (ApiPartyWithTermsAndConditionsLevelEnum) {
    ApiPartyWithTermsAndConditionsLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiPartyWithTermsAndConditionsLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiPartyWithTermsAndConditionsLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiPartyWithTermsAndConditionsLevelEnum["CLOSED"] = "CLOSED";
})(ApiPartyWithTermsAndConditionsLevelEnum || (ApiPartyWithTermsAndConditionsLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyWithTermsAndConditionsPartyTypeEnum;
(function (ApiPartyWithTermsAndConditionsPartyTypeEnum) {
    ApiPartyWithTermsAndConditionsPartyTypeEnum["SERVICE"] = "SERVICE";
    ApiPartyWithTermsAndConditionsPartyTypeEnum["CONSUMER"] = "CONSUMER";
    ApiPartyWithTermsAndConditionsPartyTypeEnum["MERCHANT"] = "MERCHANT";
})(ApiPartyWithTermsAndConditionsPartyTypeEnum || (ApiPartyWithTermsAndConditionsPartyTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum;
(function (ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum) {
    ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum["PENDING"] = "PENDING";
    ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum || (ApiPartyWithTermsAndConditionsPhotoIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyWithTermsAndConditionsStatusEnum;
(function (ApiPartyWithTermsAndConditionsStatusEnum) {
    ApiPartyWithTermsAndConditionsStatusEnum["OPEN"] = "OPEN";
    ApiPartyWithTermsAndConditionsStatusEnum["CLOSED"] = "CLOSED";
    ApiPartyWithTermsAndConditionsStatusEnum["SUSPENDED"] = "SUSPENDED";
    ApiPartyWithTermsAndConditionsStatusEnum["LIQUIDATED"] = "LIQUIDATED";
})(ApiPartyWithTermsAndConditionsStatusEnum || (ApiPartyWithTermsAndConditionsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum;
(function (ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum) {
    ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum["PENDING"] = "PENDING";
    ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum || (ApiPartyWithTermsAndConditionsTaxIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPendingBankAccountVerificationStatusEnum;
(function (ApiPendingBankAccountVerificationStatusEnum) {
    ApiPendingBankAccountVerificationStatusEnum["COMPLETE"] = "COMPLETE";
    ApiPendingBankAccountVerificationStatusEnum["EXPIRED"] = "EXPIRED";
    ApiPendingBankAccountVerificationStatusEnum["PENDING"] = "PENDING";
})(ApiPendingBankAccountVerificationStatusEnum || (ApiPendingBankAccountVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPendingBankAccountVerificationTypeEnum;
(function (ApiPendingBankAccountVerificationTypeEnum) {
    ApiPendingBankAccountVerificationTypeEnum["AUTOMATED_MICRODEPOSIT"] = "AUTOMATED_MICRODEPOSIT";
    ApiPendingBankAccountVerificationTypeEnum["SAME_DAY_MICRODEPOSIT"] = "SAME_DAY_MICRODEPOSIT";
    ApiPendingBankAccountVerificationTypeEnum["PROCESSOR_AUTOMATIC_VERIFICATION"] = "PROCESSOR_AUTOMATIC_VERIFICATION";
    ApiPendingBankAccountVerificationTypeEnum["PROCESSOR_MANUAL_VERIFICATION"] = "PROCESSOR_MANUAL_VERIFICATION";
    ApiPendingBankAccountVerificationTypeEnum["INSTANT"] = "INSTANT";
    ApiPendingBankAccountVerificationTypeEnum["MANUAL"] = "MANUAL";
})(ApiPendingBankAccountVerificationTypeEnum || (ApiPendingBankAccountVerificationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidAccountCurrencyEnum;
(function (ApiPlaidAccountCurrencyEnum) {
    ApiPlaidAccountCurrencyEnum["USD"] = "USD";
    ApiPlaidAccountCurrencyEnum["BCH"] = "BCH";
    ApiPlaidAccountCurrencyEnum["BTC"] = "BTC";
    ApiPlaidAccountCurrencyEnum["DOGE"] = "DOGE";
    ApiPlaidAccountCurrencyEnum["ETC"] = "ETC";
    ApiPlaidAccountCurrencyEnum["ETH"] = "ETH";
    ApiPlaidAccountCurrencyEnum["LTC"] = "LTC";
    ApiPlaidAccountCurrencyEnum["SHIB"] = "SHIB";
    ApiPlaidAccountCurrencyEnum["USDC"] = "USDC";
    ApiPlaidAccountCurrencyEnum["CET"] = "CET";
    ApiPlaidAccountCurrencyEnum["LXX"] = "LXX";
    ApiPlaidAccountCurrencyEnum["LCP"] = "LCP";
    ApiPlaidAccountCurrencyEnum["LWR"] = "LWR";
    ApiPlaidAccountCurrencyEnum["LMO"] = "LMO";
    ApiPlaidAccountCurrencyEnum["LKO"] = "LKO";
    ApiPlaidAccountCurrencyEnum["LED"] = "LED";
})(ApiPlaidAccountCurrencyEnum || (ApiPlaidAccountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidAccountKycBankTransactionsStatusEnum;
(function (ApiPlaidAccountKycBankTransactionsStatusEnum) {
    ApiPlaidAccountKycBankTransactionsStatusEnum["SUCCESS"] = "SUCCESS";
    ApiPlaidAccountKycBankTransactionsStatusEnum["FAILED"] = "FAILED";
    ApiPlaidAccountKycBankTransactionsStatusEnum["PENDING"] = "PENDING";
    ApiPlaidAccountKycBankTransactionsStatusEnum["PROCESSING"] = "PROCESSING";
})(ApiPlaidAccountKycBankTransactionsStatusEnum || (ApiPlaidAccountKycBankTransactionsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidAccountKycStatusEnum;
(function (ApiPlaidAccountKycStatusEnum) {
    ApiPlaidAccountKycStatusEnum["SUCCESS"] = "SUCCESS";
    ApiPlaidAccountKycStatusEnum["FAILED"] = "FAILED";
    ApiPlaidAccountKycStatusEnum["PENDING"] = "PENDING";
})(ApiPlaidAccountKycStatusEnum || (ApiPlaidAccountKycStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidAccountLinkStatusEnum;
(function (ApiPlaidAccountLinkStatusEnum) {
    ApiPlaidAccountLinkStatusEnum["CURRENT"] = "CURRENT";
    ApiPlaidAccountLinkStatusEnum["REFRESH_REQUIRED"] = "REFRESH_REQUIRED";
    ApiPlaidAccountLinkStatusEnum["PENDING_EXPIRATION"] = "PENDING_EXPIRATION";
})(ApiPlaidAccountLinkStatusEnum || (ApiPlaidAccountLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidAccountStatusEnum;
(function (ApiPlaidAccountStatusEnum) {
    ApiPlaidAccountStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPlaidAccountStatusEnum["PENDING"] = "PENDING";
    ApiPlaidAccountStatusEnum["SUSPENDED"] = "SUSPENDED";
})(ApiPlaidAccountStatusEnum || (ApiPlaidAccountStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidEventPlaidEventSourceEnum;
(function (ApiPlaidEventPlaidEventSourceEnum) {
    ApiPlaidEventPlaidEventSourceEnum["CLIENT"] = "CLIENT";
    ApiPlaidEventPlaidEventSourceEnum["SERVER"] = "SERVER";
})(ApiPlaidEventPlaidEventSourceEnum || (ApiPlaidEventPlaidEventSourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidInstitutionRestrictionRequestStatusEnum;
(function (ApiPlaidInstitutionRestrictionRequestStatusEnum) {
    ApiPlaidInstitutionRestrictionRequestStatusEnum["ALLOWED"] = "ALLOWED";
    ApiPlaidInstitutionRestrictionRequestStatusEnum["DENIED"] = "DENIED";
    ApiPlaidInstitutionRestrictionRequestStatusEnum["IGNORED"] = "IGNORED";
})(ApiPlaidInstitutionRestrictionRequestStatusEnum || (ApiPlaidInstitutionRestrictionRequestStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPlaidInstitutionRestrictionResponseStatusEnum;
(function (ApiPlaidInstitutionRestrictionResponseStatusEnum) {
    ApiPlaidInstitutionRestrictionResponseStatusEnum["ALLOWED"] = "ALLOWED";
    ApiPlaidInstitutionRestrictionResponseStatusEnum["DENIED"] = "DENIED";
    ApiPlaidInstitutionRestrictionResponseStatusEnum["IGNORED"] = "IGNORED";
})(ApiPlaidInstitutionRestrictionResponseStatusEnum || (ApiPlaidInstitutionRestrictionResponseStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPortalRolePermissionsEnum;
(function (ApiPortalRolePermissionsEnum) {
    ApiPortalRolePermissionsEnum["ALLOWLIST_CREATE"] = "ALLOWLIST_CREATE";
    ApiPortalRolePermissionsEnum["ALLOWLIST_DELETE"] = "ALLOWLIST_DELETE";
    ApiPortalRolePermissionsEnum["ALLOWLIST_UPDATE"] = "ALLOWLIST_UPDATE";
    ApiPortalRolePermissionsEnum["ALLOWLIST_VIEW"] = "ALLOWLIST_VIEW";
    ApiPortalRolePermissionsEnum["DISPUTE_DELETE_REASON"] = "DISPUTE_DELETE_REASON";
    ApiPortalRolePermissionsEnum["DISPUTE_EXPORT_CSV"] = "DISPUTE_EXPORT_CSV";
    ApiPortalRolePermissionsEnum["DISPUTE_UPDATE_ACCEPT"] = "DISPUTE_UPDATE_ACCEPT";
    ApiPortalRolePermissionsEnum["DISPUTE_UPDATE_AUTO_APPROVE"] = "DISPUTE_UPDATE_AUTO_APPROVE";
    ApiPortalRolePermissionsEnum["DISPUTE_UPDATE_CREDIT_REJECT"] = "DISPUTE_UPDATE_CREDIT_REJECT";
    ApiPortalRolePermissionsEnum["DISPUTE_UPDATE_PROVISIONAL"] = "DISPUTE_UPDATE_PROVISIONAL";
    ApiPortalRolePermissionsEnum["DISPUTE_CREATE"] = "DISPUTE_CREATE";
    ApiPortalRolePermissionsEnum["DISPUTE_VIEW"] = "DISPUTE_VIEW";
    ApiPortalRolePermissionsEnum["DEVICE_SESSION_VIEW"] = "DEVICE_SESSION_VIEW";
    ApiPortalRolePermissionsEnum["FINANCE_VIEW"] = "FINANCE_VIEW";
    ApiPortalRolePermissionsEnum["FIAT_LEDGER_ADJUSTMENT"] = "FIAT_LEDGER_ADJUSTMENT";
    ApiPortalRolePermissionsEnum["GIFTCARD_UPDATE_REACTIVATE"] = "GIFTCARD_UPDATE_REACTIVATE";
    ApiPortalRolePermissionsEnum["GIFTCARD_BRAND_UPDATE"] = "GIFTCARD_BRAND_UPDATE";
    ApiPortalRolePermissionsEnum["GIFTCARD_BRAND_VIEW_ACTIVITY"] = "GIFTCARD_BRAND_VIEW_ACTIVITY";
    ApiPortalRolePermissionsEnum["GIFTCARD_VIEW"] = "GIFTCARD_VIEW";
    ApiPortalRolePermissionsEnum["GIFTCARD_VIEW_BALANCE"] = "GIFTCARD_VIEW_BALANCE";
    ApiPortalRolePermissionsEnum["GIFTCARD_VIEW_ACTIVITY"] = "GIFTCARD_VIEW_ACTIVITY";
    ApiPortalRolePermissionsEnum["KYC_ACTIVITY_VIEW"] = "KYC_ACTIVITY_VIEW";
    ApiPortalRolePermissionsEnum["OFAC_CREATE"] = "OFAC_CREATE";
    ApiPortalRolePermissionsEnum["OFAC_DELETE"] = "OFAC_DELETE";
    ApiPortalRolePermissionsEnum["OFAC_UPDATE"] = "OFAC_UPDATE";
    ApiPortalRolePermissionsEnum["OFAC_VIEW"] = "OFAC_VIEW";
    ApiPortalRolePermissionsEnum["PARTY_VIEW"] = "PARTY_VIEW";
    ApiPortalRolePermissionsEnum["PORTAL_VIEW"] = "PORTAL_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_CREATE"] = "REPORT_CREATE";
    ApiPortalRolePermissionsEnum["TICKET_CREATE"] = "TICKET_CREATE";
    ApiPortalRolePermissionsEnum["TICKET_UPDATE_ASSIGN"] = "TICKET_UPDATE_ASSIGN";
    ApiPortalRolePermissionsEnum["TICKET_UPDATE_CANCEL"] = "TICKET_UPDATE_CANCEL";
    ApiPortalRolePermissionsEnum["TICKET_UPDATE_ESCALATE"] = "TICKET_UPDATE_ESCALATE";
    ApiPortalRolePermissionsEnum["TICKET_UPDATE_COMMENT"] = "TICKET_UPDATE_COMMENT";
    ApiPortalRolePermissionsEnum["TICKET_VIEW"] = "TICKET_VIEW";
    ApiPortalRolePermissionsEnum["TRANSACTIONS_VIEW"] = "TRANSACTIONS_VIEW";
    ApiPortalRolePermissionsEnum["USERS_CREATE"] = "USERS_CREATE";
    ApiPortalRolePermissionsEnum["USERS_UPDATE"] = "USERS_UPDATE";
    ApiPortalRolePermissionsEnum["USERS_UPDATE_SYNC"] = "USERS_UPDATE_SYNC";
    ApiPortalRolePermissionsEnum["USERS_VIEW"] = "USERS_VIEW";
    ApiPortalRolePermissionsEnum["USERS_VIEW_DETAIL"] = "USERS_VIEW_DETAIL";
    ApiPortalRolePermissionsEnum["ACCOUNT_PREFUND_BLOCK"] = "ACCOUNT_PREFUND_BLOCK";
    ApiPortalRolePermissionsEnum["ACCOUNT_PREFUND_UNBLOCK"] = "ACCOUNT_PREFUND_UNBLOCK";
    ApiPortalRolePermissionsEnum["ACCOUNT_ACTIVITY_VIEW"] = "ACCOUNT_ACTIVITY_VIEW";
    ApiPortalRolePermissionsEnum["BAKKT_CARD_VIEW"] = "BAKKT_CARD_VIEW";
    ApiPortalRolePermissionsEnum["BAKKT_CARD_UPDATE"] = "BAKKT_CARD_UPDATE";
    ApiPortalRolePermissionsEnum["PARTY_CREDIT_ADD"] = "PARTY_CREDIT_ADD";
    ApiPortalRolePermissionsEnum["PARTY_PII_VIEW"] = "PARTY_PII_VIEW";
    ApiPortalRolePermissionsEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ApiPortalRolePermissionsEnum["CARD_ISSUER_KYC_UPDATE"] = "CARD_ISSUER_KYC_UPDATE";
    ApiPortalRolePermissionsEnum["BANKACCOUNT_BLACKLIST_VIEW"] = "BANKACCOUNT_BLACKLIST_VIEW";
    ApiPortalRolePermissionsEnum["BANKACCOUNT_BLACKLIST_UPDATE"] = "BANKACCOUNT_BLACKLIST_UPDATE";
    ApiPortalRolePermissionsEnum["DEBITCARD_BLACKLIST_UPDATE"] = "DEBITCARD_BLACKLIST_UPDATE";
    ApiPortalRolePermissionsEnum["PARTY_WHITELIST_UPDATE"] = "PARTY_WHITELIST_UPDATE";
    ApiPortalRolePermissionsEnum["INCENTIVE_OFFER_VIEW"] = "INCENTIVE_OFFER_VIEW";
    ApiPortalRolePermissionsEnum["LOYALTY_REDEEM_CANCEL"] = "LOYALTY_REDEEM_CANCEL";
    ApiPortalRolePermissionsEnum["LOYALTY_ACCOUNT_VIEW"] = "LOYALTY_ACCOUNT_VIEW";
    ApiPortalRolePermissionsEnum["LOYALTY_ACCOUNT_UPDATE"] = "LOYALTY_ACCOUNT_UPDATE";
    ApiPortalRolePermissionsEnum["INCENTIVE_OFFER_CREATE"] = "INCENTIVE_OFFER_CREATE";
    ApiPortalRolePermissionsEnum["INCENTIVE_OFFER_COMPLETION"] = "INCENTIVE_OFFER_COMPLETION";
    ApiPortalRolePermissionsEnum["ACTIVITY_AUDIT_VIEW"] = "ACTIVITY_AUDIT_VIEW";
    ApiPortalRolePermissionsEnum["DEBIT_CARD_VIEW"] = "DEBIT_CARD_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_ASSET_ACTIVTY_VIEW"] = "REPORT_ASSET_ACTIVTY_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_PREFUNDING_VIEW"] = "REPORT_PREFUNDING_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_VIRTUAL_CURRENCY_VIEW"] = "REPORT_VIRTUAL_CURRENCY_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_MTL_VIEW"] = "REPORT_MTL_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_ADOTMTL_VIEW"] = "REPORT_ADOTMTL_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_SIFT_VIEW"] = "REPORT_SIFT_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_DISPUTE_CREDIT_VIEW"] = "REPORT_DISPUTE_CREDIT_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_GIFT_CARD_RECON_VIEW"] = "REPORT_GIFT_CARD_RECON_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_ACH_PROCESSING_VIEW"] = "REPORT_ACH_PROCESSING_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_SUSPEND_ACCOUNT_VIEW"] = "REPORT_SUSPEND_ACCOUNT_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_P2P_TRANSFER_VIEW"] = "REPORT_P2P_TRANSFER_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_ASSET_ACTIVTY_CREATE"] = "REPORT_ASSET_ACTIVTY_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_PREFUNDING_CREATE"] = "REPORT_PREFUNDING_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_VIRTUAL_CURRENCY_CREATE"] = "REPORT_VIRTUAL_CURRENCY_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_MTL_CREATE"] = "REPORT_MTL_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_ADOTMTL_CREATE"] = "REPORT_ADOTMTL_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_SIFT_CREATE"] = "REPORT_SIFT_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_DISPUTE_CREDIT_CREATE"] = "REPORT_DISPUTE_CREDIT_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_GIFT_CARD_RECON_CREATE"] = "REPORT_GIFT_CARD_RECON_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_ACH_PROCESSING_CREATE"] = "REPORT_ACH_PROCESSING_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_SUSPEND_ACCOUNT_CREATE"] = "REPORT_SUSPEND_ACCOUNT_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_P2P_TRANSFERS_CREATE"] = "REPORT_P2P_TRANSFERS_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_QUIZNOS_VIEW"] = "REPORT_QUIZNOS_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_QUIZNOS_CREATE"] = "REPORT_QUIZNOS_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_CHOICE_VIEW"] = "REPORT_CHOICE_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_CHOICE_CREATE"] = "REPORT_CHOICE_CREATE";
    ApiPortalRolePermissionsEnum["RISK_AUDIT_VIEW"] = "RISK_AUDIT_VIEW";
    ApiPortalRolePermissionsEnum["RISK_AUDIT_VIEW_DETAIL"] = "RISK_AUDIT_VIEW_DETAIL";
    ApiPortalRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_VIEW"] = "REPORT_ACCOUNT_BALANCE_V2_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_CREATE"] = "REPORT_ACCOUNT_BALANCE_V2_CREATE";
    ApiPortalRolePermissionsEnum["PLAID_ACCOUNT_DETAIL_VIEW"] = "PLAID_ACCOUNT_DETAIL_VIEW";
    ApiPortalRolePermissionsEnum["UNMASK_PLAID_ACCOUNT_NUMBERS"] = "UNMASK_PLAID_ACCOUNT_NUMBERS";
    ApiPortalRolePermissionsEnum["UNMASK_DEBIT_CARD_FIELDS"] = "UNMASK_DEBIT_CARD_FIELDS";
    ApiPortalRolePermissionsEnum["TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW"] = "TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_BTC_TRANSACTION_REPORT_CREATE"] = "REPORT_BTC_TRANSACTION_REPORT_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_CRYPTO_TRANSACTION_REPORT_CREATE"] = "REPORT_CRYPTO_TRANSACTION_REPORT_CREATE";
    ApiPortalRolePermissionsEnum["PARTNER_DETAIL_VIEW"] = "PARTNER_DETAIL_VIEW";
    ApiPortalRolePermissionsEnum["PARTNER_LINK_UNLINK"] = "PARTNER_LINK_UNLINK";
    ApiPortalRolePermissionsEnum["PARTY_NAME_UPDATE"] = "PARTY_NAME_UPDATE";
    ApiPortalRolePermissionsEnum["PARTY_SUSPEND"] = "PARTY_SUSPEND";
    ApiPortalRolePermissionsEnum["PARTY_UNSUSPEND"] = "PARTY_UNSUSPEND";
    ApiPortalRolePermissionsEnum["PARTY_LIQUIDATE"] = "PARTY_LIQUIDATE";
    ApiPortalRolePermissionsEnum["PARTY_ACTIVATE"] = "PARTY_ACTIVATE";
    ApiPortalRolePermissionsEnum["PARTY_CLOSE"] = "PARTY_CLOSE";
    ApiPortalRolePermissionsEnum["PARTY_STATUS_UPDATE"] = "PARTY_STATUS_UPDATE";
    ApiPortalRolePermissionsEnum["REPORT_MERCHANT_INCENTIVE_CREATE"] = "REPORT_MERCHANT_INCENTIVE_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_MERCHANT_INCENTIVE_VIEW"] = "REPORT_MERCHANT_INCENTIVE_VIEW";
    ApiPortalRolePermissionsEnum["REPORT_PARTNER_INCENTIVE_CREATE"] = "REPORT_PARTNER_INCENTIVE_CREATE";
    ApiPortalRolePermissionsEnum["REPORT_PARTNER_INCENTIVE_VIEW"] = "REPORT_PARTNER_INCENTIVE_VIEW";
    ApiPortalRolePermissionsEnum["TAXID_LAST_4_DIGIT_VIEW"] = "TAXID_LAST_4_DIGIT_VIEW";
    ApiPortalRolePermissionsEnum["TAXID_FULL_VIEW"] = "TAXID_FULL_VIEW";
})(ApiPortalRolePermissionsEnum || (ApiPortalRolePermissionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiPortalUserUserStatusEnum;
(function (ApiPortalUserUserStatusEnum) {
    ApiPortalUserUserStatusEnum["ACTIVE"] = "ACTIVE";
    ApiPortalUserUserStatusEnum["INACTIVE"] = "INACTIVE";
    ApiPortalUserUserStatusEnum["INVITED"] = "INVITED";
})(ApiPortalUserUserStatusEnum || (ApiPortalUserUserStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiProductAccountProductsEnum;
(function (ApiProductAccountProductsEnum) {
    ApiProductAccountProductsEnum["CRYPTO_CONNECT"] = "CRYPTO_CONNECT";
    ApiProductAccountProductsEnum["CRYPTO_REWARDS"] = "CRYPTO_REWARDS";
    ApiProductAccountProductsEnum["CRYPTO_PAYOUT"] = "CRYPTO_PAYOUT";
    ApiProductAccountProductsEnum["FIAT_FUNDING"] = "FIAT_FUNDING";
})(ApiProductAccountProductsEnum || (ApiProductAccountProductsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiRelatedTransactionTypeEnum;
(function (ApiRelatedTransactionTypeEnum) {
    ApiRelatedTransactionTypeEnum["ADD"] = "ADD";
    ApiRelatedTransactionTypeEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    ApiRelatedTransactionTypeEnum["BUY"] = "BUY";
    ApiRelatedTransactionTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiRelatedTransactionTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiRelatedTransactionTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiRelatedTransactionTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiRelatedTransactionTypeEnum["CREDIT"] = "CREDIT";
    ApiRelatedTransactionTypeEnum["CONVERT"] = "CONVERT";
    ApiRelatedTransactionTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiRelatedTransactionTypeEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    ApiRelatedTransactionTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiRelatedTransactionTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiRelatedTransactionTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiRelatedTransactionTypeEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    ApiRelatedTransactionTypeEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    ApiRelatedTransactionTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiRelatedTransactionTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiRelatedTransactionTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiRelatedTransactionTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiRelatedTransactionTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiRelatedTransactionTypeEnum["FREEZE"] = "FREEZE";
    ApiRelatedTransactionTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiRelatedTransactionTypeEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    ApiRelatedTransactionTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiRelatedTransactionTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiRelatedTransactionTypeEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    ApiRelatedTransactionTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiRelatedTransactionTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiRelatedTransactionTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiRelatedTransactionTypeEnum["LINK"] = "LINK";
    ApiRelatedTransactionTypeEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiRelatedTransactionTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiRelatedTransactionTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiRelatedTransactionTypeEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    ApiRelatedTransactionTypeEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    ApiRelatedTransactionTypeEnum["PAYOUT"] = "PAYOUT";
    ApiRelatedTransactionTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiRelatedTransactionTypeEnum["RECEIVE"] = "RECEIVE";
    ApiRelatedTransactionTypeEnum["REDEEM"] = "REDEEM";
    ApiRelatedTransactionTypeEnum["REMOVE"] = "REMOVE";
    ApiRelatedTransactionTypeEnum["RESTORE"] = "RESTORE";
    ApiRelatedTransactionTypeEnum["REVERSE"] = "REVERSE";
    ApiRelatedTransactionTypeEnum["SELL"] = "SELL";
    ApiRelatedTransactionTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiRelatedTransactionTypeEnum["SEND"] = "SEND";
    ApiRelatedTransactionTypeEnum["TERMINATE"] = "TERMINATE";
    ApiRelatedTransactionTypeEnum["UNFREEZE"] = "UNFREEZE";
    ApiRelatedTransactionTypeEnum["UNLINK"] = "UNLINK";
    ApiRelatedTransactionTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    ApiRelatedTransactionTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    ApiRelatedTransactionTypeEnum["WIRE_LINK"] = "WIRE_LINK";
    ApiRelatedTransactionTypeEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    ApiRelatedTransactionTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiRelatedTransactionTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiRelatedTransactionTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiRelatedTransactionTypeEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    ApiRelatedTransactionTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiRelatedTransactionTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    ApiRelatedTransactionTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiRelatedTransactionTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiRelatedTransactionTypeEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    ApiRelatedTransactionTypeEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    ApiRelatedTransactionTypeEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(ApiRelatedTransactionTypeEnum || (ApiRelatedTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiReplacePartnerAccountRequestProductsEnum;
(function (ApiReplacePartnerAccountRequestProductsEnum) {
    ApiReplacePartnerAccountRequestProductsEnum["CRYPTO_CONNECT"] = "CRYPTO_CONNECT";
    ApiReplacePartnerAccountRequestProductsEnum["CRYPTO_REWARDS"] = "CRYPTO_REWARDS";
    ApiReplacePartnerAccountRequestProductsEnum["CRYPTO_PAYOUT"] = "CRYPTO_PAYOUT";
    ApiReplacePartnerAccountRequestProductsEnum["FIAT_FUNDING"] = "FIAT_FUNDING";
})(ApiReplacePartnerAccountRequestProductsEnum || (ApiReplacePartnerAccountRequestProductsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiReportListRequestReportTypeEnum;
(function (ApiReportListRequestReportTypeEnum) {
    ApiReportListRequestReportTypeEnum["ASSET_ACTIVTY"] = "ASSET_ACTIVTY";
    ApiReportListRequestReportTypeEnum["CAESARS_ACTIVITY"] = "CAESARS_ACTIVITY";
    ApiReportListRequestReportTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    ApiReportListRequestReportTypeEnum["PREFUNDING"] = "PREFUNDING";
    ApiReportListRequestReportTypeEnum["VIRTUAL_CURRENCY"] = "VIRTUAL_CURRENCY";
    ApiReportListRequestReportTypeEnum["SIFT"] = "SIFT";
    ApiReportListRequestReportTypeEnum["IIM_RECONCILATION_REPORT"] = "IIM_RECONCILATION_REPORT";
    ApiReportListRequestReportTypeEnum["MERCHANT_RECONCILIATION_REPORT"] = "MERCHANT_RECONCILIATION_REPORT";
    ApiReportListRequestReportTypeEnum["STARBUCKS_RECONCILIATION_REPORT"] = "STARBUCKS_RECONCILIATION_REPORT";
    ApiReportListRequestReportTypeEnum["PARTNER_SETTLEMENT_REPORT"] = "PARTNER_SETTLEMENT_REPORT";
    ApiReportListRequestReportTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiReportListRequestReportTypeEnum["ACCOUNT_BALANCE"] = "ACCOUNT_BALANCE";
    ApiReportListRequestReportTypeEnum["ACCOUNT_BALANCE_V2"] = "ACCOUNT_BALANCE_V2";
    ApiReportListRequestReportTypeEnum["ACCOUNT_BALANCE_SUMMARY"] = "ACCOUNT_BALANCE_SUMMARY";
    ApiReportListRequestReportTypeEnum["ALL_CURRENCY_MTL"] = "ALL_CURRENCY_MTL";
    ApiReportListRequestReportTypeEnum["MTL"] = "MTL";
    ApiReportListRequestReportTypeEnum["ADOTMTL"] = "ADOTMTL";
    ApiReportListRequestReportTypeEnum["BTC_TRANSACTION_REPORT"] = "BTC_TRANSACTION_REPORT";
    ApiReportListRequestReportTypeEnum["CRYPTO_TRANSACTION_REPORT"] = "CRYPTO_TRANSACTION_REPORT";
    ApiReportListRequestReportTypeEnum["PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT"] = "PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT";
    ApiReportListRequestReportTypeEnum["SUSPEND_ACCOUNT"] = "SUSPEND_ACCOUNT";
    ApiReportListRequestReportTypeEnum["GIFT_CARD_RECON"] = "GIFT_CARD_RECON";
    ApiReportListRequestReportTypeEnum["GIFT_CARD_REDEEM_RECON"] = "GIFT_CARD_REDEEM_RECON";
    ApiReportListRequestReportTypeEnum["GIFT_CARD_RECON_MONTH"] = "GIFT_CARD_RECON_MONTH";
    ApiReportListRequestReportTypeEnum["GIFT_CARD_REDEEM_RECON_MONTH"] = "GIFT_CARD_REDEEM_RECON_MONTH";
    ApiReportListRequestReportTypeEnum["ACH_FILE_RETURNS"] = "ACH_FILE_RETURNS";
    ApiReportListRequestReportTypeEnum["ACH_FILE_OUTBOUND"] = "ACH_FILE_OUTBOUND";
    ApiReportListRequestReportTypeEnum["PARTNER_RECONCILIATION_REPORT"] = "PARTNER_RECONCILIATION_REPORT";
    ApiReportListRequestReportTypeEnum["CHOICE"] = "CHOICE";
    ApiReportListRequestReportTypeEnum["QUIZNOS"] = "QUIZNOS";
    ApiReportListRequestReportTypeEnum["PARTNER_INCENTIVE"] = "PARTNER_INCENTIVE";
    ApiReportListRequestReportTypeEnum["PARTNER_CASH_ACTIVITY"] = "PARTNER_CASH_ACTIVITY";
    ApiReportListRequestReportTypeEnum["WIRE_TRANSFER_ACTIVITY_REVIEW"] = "WIRE_TRANSFER_ACTIVITY_REVIEW";
    ApiReportListRequestReportTypeEnum["PARTNER_CASH_ACTIVITY_APEX"] = "PARTNER_CASH_ACTIVITY_APEX";
    ApiReportListRequestReportTypeEnum["PARTNER_CASH_BALANCE"] = "PARTNER_CASH_BALANCE";
    ApiReportListRequestReportTypeEnum["PARTY_CSI_OFAC_CHECK"] = "PARTY_CSI_OFAC_CHECK";
    ApiReportListRequestReportTypeEnum["FISERV_PAYMENT_STATUS"] = "FISERV_PAYMENT_STATUS";
    ApiReportListRequestReportTypeEnum["DEPOSITE_BALANCE_CHECK_REPORT"] = "DEPOSITE_BALANCE_CHECK_REPORT";
    ApiReportListRequestReportTypeEnum["NYDFS_CUSTOMER_REPORT"] = "NYDFS_CUSTOMER_REPORT";
})(ApiReportListRequestReportTypeEnum || (ApiReportListRequestReportTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum;
(function (ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum) {
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BANK_IDENTITY_CHECK"] = "BANK_IDENTITY_CHECK";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BAKKT_CARD_KYC_FAIL"] = "BAKKT_CARD_KYC_FAIL";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BAKKT_CARD_KYC_PENDING"] = "BAKKT_CARD_KYC_PENDING";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BAKKT_CARD_KYC_SUCCESS"] = "BAKKT_CARD_KYC_SUCCESS";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BANK_ACCOUNT"] = "BANK_ACCOUNT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BANK_ACCOUNT_BLACKLIST"] = "BANK_ACCOUNT_BLACKLIST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["BANK_ACCOUNT_REMOVE_BLACKLIST"] = "BANK_ACCOUNT_REMOVE_BLACKLIST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["DEBIT_CARD_BLACKLIST"] = "DEBIT_CARD_BLACKLIST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["DEBIT_CARD_REMOVE_BLACKLIST"] = "DEBIT_CARD_REMOVE_BLACKLIST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["CREDIT"] = "CREDIT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["KYC_FAIL"] = "KYC_FAIL";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["KYC_SUCCESS"] = "KYC_SUCCESS";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["LIQUIDATE"] = "LIQUIDATE";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["OPEN"] = "OPEN";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PARTY_REMOVE_WHITELIST"] = "PARTY_REMOVE_WHITELIST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PARTY_WHITELIST"] = "PARTY_WHITELIST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PREFUND_BLOCK"] = "PREFUND_BLOCK";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PREFUND_UNBLOCK"] = "PREFUND_UNBLOCK";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["REPORT_REQUEST"] = "REPORT_REQUEST";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["REPORT_SENT"] = "REPORT_SENT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["SUSPEND"] = "SUSPEND";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["UNSUSPEND"] = "UNSUSPEND";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["UNSPECIFIED"] = "UNSPECIFIED";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["LIQUIDATE_REQUESTED"] = "LIQUIDATE_REQUESTED";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["LIQUIDATE_REMOVE_REQUESTED"] = "LIQUIDATE_REMOVE_REQUESTED";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["CLOSED"] = "CLOSED";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum["PARTNER_UPDATE"] = "PARTNER_UPDATE";
})(ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum || (ApiResponseMapAccountActivityReasonListAccountActivityTypePayloadEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiResponseMapStringThirdPartyResponseStatusPayloadEnum;
(function (ApiResponseMapStringThirdPartyResponseStatusPayloadEnum) {
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["SUCCESS"] = "SUCCESS";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["FAILURE"] = "FAILURE";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["ACCESS_DENIED"] = "ACCESS_DENIED";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["BAD_REQUEST"] = "BAD_REQUEST";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["SERVICE_ERROR"] = "SERVICE_ERROR";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["MULTIPLE_RECORDS"] = "MULTIPLE_RECORDS";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["DOB_INVALID"] = "DOB_INVALID";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["INVALID_TOKEN"] = "INVALID_TOKEN";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["INVALID_MSG_CONTENT"] = "INVALID_MSG_CONTENT";
    ApiResponseMapStringThirdPartyResponseStatusPayloadEnum["TIMEOUT"] = "TIMEOUT";
})(ApiResponseMapStringThirdPartyResponseStatusPayloadEnum || (ApiResponseMapStringThirdPartyResponseStatusPayloadEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiRestrictionPeriodEnum;
(function (ApiRestrictionPeriodEnum) {
    ApiRestrictionPeriodEnum["SINGLE"] = "SINGLE";
    ApiRestrictionPeriodEnum["ONE_DAY"] = "ONE_DAY";
    ApiRestrictionPeriodEnum["SEVEN_DAYS"] = "SEVEN_DAYS";
    ApiRestrictionPeriodEnum["THIRTY_DAYS"] = "THIRTY_DAYS";
    ApiRestrictionPeriodEnum["LIFETIME"] = "LIFETIME";
})(ApiRestrictionPeriodEnum || (ApiRestrictionPeriodEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiRestrictionTypeEnum;
(function (ApiRestrictionTypeEnum) {
    ApiRestrictionTypeEnum["ACH_DEPOSIT"] = "ACH_DEPOSIT";
    ApiRestrictionTypeEnum["ACH_WITHDRAWAL"] = "ACH_WITHDRAWAL";
    ApiRestrictionTypeEnum["CASH_PAYMENT"] = "CASH_PAYMENT";
    ApiRestrictionTypeEnum["CASH_P2P"] = "CASH_P2P";
    ApiRestrictionTypeEnum["BITCOIN_ANY"] = "BITCOIN_ANY";
    ApiRestrictionTypeEnum["BITCOIN_BUY"] = "BITCOIN_BUY";
    ApiRestrictionTypeEnum["BITCOIN_P2P"] = "BITCOIN_P2P";
})(ApiRestrictionTypeEnum || (ApiRestrictionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiRestrictionUnitEnum;
(function (ApiRestrictionUnitEnum) {
    ApiRestrictionUnitEnum["COUNT"] = "COUNT";
    ApiRestrictionUnitEnum["AMOUNT"] = "AMOUNT";
})(ApiRestrictionUnitEnum || (ApiRestrictionUnitEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiRewardsTransactionDetailsStatusEnum;
(function (ApiRewardsTransactionDetailsStatusEnum) {
    ApiRewardsTransactionDetailsStatusEnum["COMPLETE"] = "COMPLETE";
    ApiRewardsTransactionDetailsStatusEnum["PENDING"] = "PENDING";
    ApiRewardsTransactionDetailsStatusEnum["CANCELED"] = "CANCELED";
})(ApiRewardsTransactionDetailsStatusEnum || (ApiRewardsTransactionDetailsStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiRewardsTransactionDetailsSupportedOperationsEnum;
(function (ApiRewardsTransactionDetailsSupportedOperationsEnum) {
    ApiRewardsTransactionDetailsSupportedOperationsEnum["BUY"] = "BUY";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["BUY_CANCEL"] = "BUY_CANCEL";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["CREDIT_CANCEL"] = "CREDIT_CANCEL";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["REDEEM_CANCEL"] = "REDEEM_CANCEL";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["CHECK_BALANCE"] = "CHECK_BALANCE";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["CREDIT"] = "CREDIT";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["ENROLL"] = "ENROLL";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["PAY_WITH_POINTS"] = "PAY_WITH_POINTS";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["QUOTE_BUY"] = "QUOTE_BUY";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["QUOTE_REDEEM"] = "QUOTE_REDEEM";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["REDEEM"] = "REDEEM";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["REDEEM_FOR_CRYPTO"] = "REDEEM_FOR_CRYPTO";
    ApiRewardsTransactionDetailsSupportedOperationsEnum["UNENROLL"] = "UNENROLL";
})(ApiRewardsTransactionDetailsSupportedOperationsEnum || (ApiRewardsTransactionDetailsSupportedOperationsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiSettlementAchTypeEnum;
(function (ApiSettlementAchTypeEnum) {
    ApiSettlementAchTypeEnum["WITHDRAWAL"] = "WITHDRAWAL";
    ApiSettlementAchTypeEnum["DEPOSIT"] = "DEPOSIT";
})(ApiSettlementAchTypeEnum || (ApiSettlementAchTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiSettlementSourceCurrencyEnum;
(function (ApiSettlementSourceCurrencyEnum) {
    ApiSettlementSourceCurrencyEnum["USD"] = "USD";
    ApiSettlementSourceCurrencyEnum["BCH"] = "BCH";
    ApiSettlementSourceCurrencyEnum["BTC"] = "BTC";
    ApiSettlementSourceCurrencyEnum["DOGE"] = "DOGE";
    ApiSettlementSourceCurrencyEnum["ETC"] = "ETC";
    ApiSettlementSourceCurrencyEnum["ETH"] = "ETH";
    ApiSettlementSourceCurrencyEnum["LTC"] = "LTC";
    ApiSettlementSourceCurrencyEnum["SHIB"] = "SHIB";
    ApiSettlementSourceCurrencyEnum["USDC"] = "USDC";
    ApiSettlementSourceCurrencyEnum["CET"] = "CET";
    ApiSettlementSourceCurrencyEnum["LXX"] = "LXX";
    ApiSettlementSourceCurrencyEnum["LCP"] = "LCP";
    ApiSettlementSourceCurrencyEnum["LWR"] = "LWR";
    ApiSettlementSourceCurrencyEnum["LMO"] = "LMO";
    ApiSettlementSourceCurrencyEnum["LKO"] = "LKO";
    ApiSettlementSourceCurrencyEnum["LED"] = "LED";
})(ApiSettlementSourceCurrencyEnum || (ApiSettlementSourceCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiThirdPartyVerificationLogThirdPartyServiceEnum;
(function (ApiThirdPartyVerificationLogThirdPartyServiceEnum) {
    ApiThirdPartyVerificationLogThirdPartyServiceEnum["TRANSUNION"] = "TRANSUNION";
    ApiThirdPartyVerificationLogThirdPartyServiceEnum["SOCURE"] = "SOCURE";
    ApiThirdPartyVerificationLogThirdPartyServiceEnum["CSI"] = "CSI";
})(ApiThirdPartyVerificationLogThirdPartyServiceEnum || (ApiThirdPartyVerificationLogThirdPartyServiceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiThirdPartyVerificationLogVerificationTypeEnum;
(function (ApiThirdPartyVerificationLogVerificationTypeEnum) {
    ApiThirdPartyVerificationLogVerificationTypeEnum["TAX_ID"] = "TAX_ID";
})(ApiThirdPartyVerificationLogVerificationTypeEnum || (ApiThirdPartyVerificationLogVerificationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTicketCategoryEnum;
(function (ApiTicketCategoryEnum) {
    ApiTicketCategoryEnum["TRANSACTIONS"] = "TRANSACTIONS";
    ApiTicketCategoryEnum["BILLING"] = "BILLING";
    ApiTicketCategoryEnum["SETTLEMENT"] = "SETTLEMENT";
    ApiTicketCategoryEnum["OTHERS"] = "OTHERS";
    ApiTicketCategoryEnum["CHARGEBACKS"] = "CHARGEBACKS";
})(ApiTicketCategoryEnum || (ApiTicketCategoryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTicketReasonCodeEnum;
(function (ApiTicketReasonCodeEnum) {
    ApiTicketReasonCodeEnum["DAC01"] = "DAC01";
    ApiTicketReasonCodeEnum["DAC02"] = "DAC02";
    ApiTicketReasonCodeEnum["DAC03"] = "DAC03";
    ApiTicketReasonCodeEnum["DAC04"] = "DAC04";
    ApiTicketReasonCodeEnum["DAC05"] = "DAC05";
    ApiTicketReasonCodeEnum["DAC06"] = "DAC06";
    ApiTicketReasonCodeEnum["DAC07"] = "DAC07";
    ApiTicketReasonCodeEnum["DAC08"] = "DAC08";
    ApiTicketReasonCodeEnum["DAC09"] = "DAC09";
    ApiTicketReasonCodeEnum["DAC10"] = "DAC10";
})(ApiTicketReasonCodeEnum || (ApiTicketReasonCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTicketRecipientTypeEnum;
(function (ApiTicketRecipientTypeEnum) {
    ApiTicketRecipientTypeEnum["CONSUMER"] = "CONSUMER";
    ApiTicketRecipientTypeEnum["MERCHANT"] = "MERCHANT";
    ApiTicketRecipientTypeEnum["INTERNAL"] = "INTERNAL";
})(ApiTicketRecipientTypeEnum || (ApiTicketRecipientTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTicketStatusEnum;
(function (ApiTicketStatusEnum) {
    ApiTicketStatusEnum["NEW"] = "NEW";
    ApiTicketStatusEnum["OPEN"] = "OPEN";
    ApiTicketStatusEnum["PENDING"] = "PENDING";
    ApiTicketStatusEnum["SOLVED"] = "SOLVED";
    ApiTicketStatusEnum["CLOSED"] = "CLOSED";
})(ApiTicketStatusEnum || (ApiTicketStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionActivityActivityTypeEnum;
(function (ApiTransactionActivityActivityTypeEnum) {
    ApiTransactionActivityActivityTypeEnum["INITIATED"] = "INITIATED";
    ApiTransactionActivityActivityTypeEnum["EXECUTED"] = "EXECUTED";
    ApiTransactionActivityActivityTypeEnum["AUTHORIZED"] = "AUTHORIZED";
    ApiTransactionActivityActivityTypeEnum["REAUTHORIZED"] = "REAUTHORIZED";
    ApiTransactionActivityActivityTypeEnum["AUTH_DECLINED"] = "AUTH_DECLINED";
    ApiTransactionActivityActivityTypeEnum["CAPTURED"] = "CAPTURED";
    ApiTransactionActivityActivityTypeEnum["REVERSED"] = "REVERSED";
    ApiTransactionActivityActivityTypeEnum["SETTLED"] = "SETTLED";
    ApiTransactionActivityActivityTypeEnum["DISPUTED"] = "DISPUTED";
    ApiTransactionActivityActivityTypeEnum["ESCALATED"] = "ESCALATED";
    ApiTransactionActivityActivityTypeEnum["ACCEPTED"] = "ACCEPTED";
    ApiTransactionActivityActivityTypeEnum["REJECTED"] = "REJECTED";
    ApiTransactionActivityActivityTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    ApiTransactionActivityActivityTypeEnum["FORCE_CHARGEBACK"] = "FORCE_CHARGEBACK";
    ApiTransactionActivityActivityTypeEnum["MERCHANT_REJECTED"] = "MERCHANT_REJECTED";
    ApiTransactionActivityActivityTypeEnum["MERCHANT_ACCEPTED"] = "MERCHANT_ACCEPTED";
    ApiTransactionActivityActivityTypeEnum["MERCHANT_RE_ESCALATED"] = "MERCHANT_RE_ESCALATED";
    ApiTransactionActivityActivityTypeEnum["CANCELLED"] = "CANCELLED";
    ApiTransactionActivityActivityTypeEnum["MERCHANT_ESCALATED"] = "MERCHANT_ESCALATED";
    ApiTransactionActivityActivityTypeEnum["DISPUTE_CANCELED"] = "DISPUTE_CANCELED";
    ApiTransactionActivityActivityTypeEnum["REGULATION_E"] = "REGULATION_E";
    ApiTransactionActivityActivityTypeEnum["PROVISIONAL_CREDIT"] = "PROVISIONAL_CREDIT";
    ApiTransactionActivityActivityTypeEnum["CANCELED"] = "CANCELED";
    ApiTransactionActivityActivityTypeEnum["COMPLETE"] = "COMPLETE";
    ApiTransactionActivityActivityTypeEnum["EXPIRED"] = "EXPIRED";
    ApiTransactionActivityActivityTypeEnum["REPLACED"] = "REPLACED";
    ApiTransactionActivityActivityTypeEnum["PENDING"] = "PENDING";
    ApiTransactionActivityActivityTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiTransactionActivityActivityTypeEnum["REFUNDED"] = "REFUNDED";
    ApiTransactionActivityActivityTypeEnum["CREDITED"] = "CREDITED";
})(ApiTransactionActivityActivityTypeEnum || (ApiTransactionActivityActivityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionActivityDeclineReasonEnum;
(function (ApiTransactionActivityDeclineReasonEnum) {
    ApiTransactionActivityDeclineReasonEnum["ACCOUNT_NOT_FOUND"] = "ACCOUNT_NOT_FOUND";
    ApiTransactionActivityDeclineReasonEnum["FUNDING_PREFERENCE_NOT_FOUND"] = "FUNDING_PREFERENCE_NOT_FOUND";
    ApiTransactionActivityDeclineReasonEnum["CARD_INVALID_STATUS"] = "CARD_INVALID_STATUS";
    ApiTransactionActivityDeclineReasonEnum["CARD_NOT_FOUND"] = "CARD_NOT_FOUND";
    ApiTransactionActivityDeclineReasonEnum["CARD_NOT_ACTIVATED"] = "CARD_NOT_ACTIVATED";
    ApiTransactionActivityDeclineReasonEnum["DAILY_LIMIT_EXCEEDED"] = "DAILY_LIMIT_EXCEEDED";
    ApiTransactionActivityDeclineReasonEnum["MONTHLY_LIMIT_EXCEEDED"] = "MONTHLY_LIMIT_EXCEEDED";
    ApiTransactionActivityDeclineReasonEnum["OVER_LIMIT"] = "OVER_LIMIT";
    ApiTransactionActivityDeclineReasonEnum["NOT_SUFFICIENT_FUNDS"] = "NOT_SUFFICIENT_FUNDS";
    ApiTransactionActivityDeclineReasonEnum["SUSPECTED_FRAUD"] = "SUSPECTED_FRAUD";
    ApiTransactionActivityDeclineReasonEnum["UNSUPPORTED_OPERATION"] = "UNSUPPORTED_OPERATION";
    ApiTransactionActivityDeclineReasonEnum["CARD_ISSUER_PARTY_NOT_FOUND"] = "CARD_ISSUER_PARTY_NOT_FOUND";
    ApiTransactionActivityDeclineReasonEnum["CARD_ISSUER_PARTY_INVALID_STATUS"] = "CARD_ISSUER_PARTY_INVALID_STATUS";
    ApiTransactionActivityDeclineReasonEnum["PARTY_NOT_FOUND"] = "PARTY_NOT_FOUND";
    ApiTransactionActivityDeclineReasonEnum["PARTY_INVALID_STATUS"] = "PARTY_INVALID_STATUS";
    ApiTransactionActivityDeclineReasonEnum["CURRENCY_MISMATCH"] = "CURRENCY_MISMATCH";
    ApiTransactionActivityDeclineReasonEnum["DATA_MISMATCH"] = "DATA_MISMATCH";
    ApiTransactionActivityDeclineReasonEnum["GENERIC_PAYMENT_PROCESSING_ERROR"] = "GENERIC_PAYMENT_PROCESSING_ERROR";
    ApiTransactionActivityDeclineReasonEnum["PRECONDITION_NOT_MET"] = "PRECONDITION_NOT_MET";
    ApiTransactionActivityDeclineReasonEnum["INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT"] = "INSUFFICIENT_FUNDS_FOR_BAKKT_ACCOUNT_DEPOSIT";
    ApiTransactionActivityDeclineReasonEnum["BLACKLISTED_DEBIT_CARD"] = "BLACKLISTED_DEBIT_CARD";
    ApiTransactionActivityDeclineReasonEnum["DEBIT_CARD_BIN_DENY_LISTED"] = "DEBIT_CARD_BIN_DENY_LISTED";
    ApiTransactionActivityDeclineReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiTransactionActivityDeclineReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiTransactionActivityDeclineReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiTransactionActivityDeclineReasonEnum["BLACKLISTED_MERCHANT_CATEGORY"] = "BLACKLISTED_MERCHANT_CATEGORY";
    ApiTransactionActivityDeclineReasonEnum["BLACKLISTED_BANK_ACCOUNT"] = "BLACKLISTED_BANK_ACCOUNT";
    ApiTransactionActivityDeclineReasonEnum["BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED"] = "BANK_ACCOUNT_BLACKLISTED_LINKING_FAILED";
    ApiTransactionActivityDeclineReasonEnum["TRANSACTION_FAILED"] = "TRANSACTION_FAILED";
    ApiTransactionActivityDeclineReasonEnum["REAUTHENTICATION_REQUIRED"] = "REAUTHENTICATION_REQUIRED";
    ApiTransactionActivityDeclineReasonEnum["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
    ApiTransactionActivityDeclineReasonEnum["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    ApiTransactionActivityDeclineReasonEnum["PROCESSOR_DECLINED"] = "PROCESSOR_DECLINED";
    ApiTransactionActivityDeclineReasonEnum["SETTLEMENT_DECLINED"] = "SETTLEMENT_DECLINED";
    ApiTransactionActivityDeclineReasonEnum["GATEWAY_REJECTED"] = "GATEWAY_REJECTED";
    ApiTransactionActivityDeclineReasonEnum["REWARDS_GATEWAY_REJECTED"] = "REWARDS_GATEWAY_REJECTED";
    ApiTransactionActivityDeclineReasonEnum["AVS_ERROR"] = "AVS_ERROR";
    ApiTransactionActivityDeclineReasonEnum["CVV_ERROR"] = "CVV_ERROR";
    ApiTransactionActivityDeclineReasonEnum["AVS_GATEWAY_ERROR"] = "AVS_GATEWAY_ERROR";
    ApiTransactionActivityDeclineReasonEnum["AVS_VERIFICATION_FAILED"] = "AVS_VERIFICATION_FAILED";
    ApiTransactionActivityDeclineReasonEnum["CVV_VERIFICATION_FAILED"] = "CVV_VERIFICATION_FAILED";
    ApiTransactionActivityDeclineReasonEnum["AMOUNT_VERIFICATION_FAILED"] = "AMOUNT_VERIFICATION_FAILED";
    ApiTransactionActivityDeclineReasonEnum["DEBIT_CARD_NOT_FOUND"] = "DEBIT_CARD_NOT_FOUND";
    ApiTransactionActivityDeclineReasonEnum["SDK_ERROR"] = "SDK_ERROR";
    ApiTransactionActivityDeclineReasonEnum["PARTY_TERMS_AND_CONDITIONS_EXPIRED"] = "PARTY_TERMS_AND_CONDITIONS_EXPIRED";
    ApiTransactionActivityDeclineReasonEnum["DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH"] = "DEBIT_CARD_GIVEN_DETAILS_DO_NOT_MATCH";
    ApiTransactionActivityDeclineReasonEnum["PARTY_INVALID_LEVEL"] = "PARTY_INVALID_LEVEL";
    ApiTransactionActivityDeclineReasonEnum["MISSING_WIRE_WITHDRAW_DETAILS"] = "MISSING_WIRE_WITHDRAW_DETAILS";
    ApiTransactionActivityDeclineReasonEnum["INVALID_WIRE_ROUTING_NUMBER"] = "INVALID_WIRE_ROUTING_NUMBER";
    ApiTransactionActivityDeclineReasonEnum["INVALID_WIRE_ACCOUNT_NUMBER"] = "INVALID_WIRE_ACCOUNT_NUMBER";
    ApiTransactionActivityDeclineReasonEnum["INVALID_WIRE_BANK_NAME"] = "INVALID_WIRE_BANK_NAME";
    ApiTransactionActivityDeclineReasonEnum["INVALID_WIRE_BANK_ADDRESS"] = "INVALID_WIRE_BANK_ADDRESS";
})(ApiTransactionActivityDeclineReasonEnum || (ApiTransactionActivityDeclineReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionDetailsActivityTypeEnum;
(function (ApiTransactionDetailsActivityTypeEnum) {
    ApiTransactionDetailsActivityTypeEnum["ADD"] = "ADD";
    ApiTransactionDetailsActivityTypeEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    ApiTransactionDetailsActivityTypeEnum["BUY"] = "BUY";
    ApiTransactionDetailsActivityTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiTransactionDetailsActivityTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiTransactionDetailsActivityTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiTransactionDetailsActivityTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["CREDIT"] = "CREDIT";
    ApiTransactionDetailsActivityTypeEnum["CONVERT"] = "CONVERT";
    ApiTransactionDetailsActivityTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiTransactionDetailsActivityTypeEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    ApiTransactionDetailsActivityTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiTransactionDetailsActivityTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiTransactionDetailsActivityTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiTransactionDetailsActivityTypeEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    ApiTransactionDetailsActivityTypeEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    ApiTransactionDetailsActivityTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiTransactionDetailsActivityTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiTransactionDetailsActivityTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiTransactionDetailsActivityTypeEnum["FREEZE"] = "FREEZE";
    ApiTransactionDetailsActivityTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiTransactionDetailsActivityTypeEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    ApiTransactionDetailsActivityTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiTransactionDetailsActivityTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiTransactionDetailsActivityTypeEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    ApiTransactionDetailsActivityTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiTransactionDetailsActivityTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiTransactionDetailsActivityTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiTransactionDetailsActivityTypeEnum["LINK"] = "LINK";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiTransactionDetailsActivityTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiTransactionDetailsActivityTypeEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    ApiTransactionDetailsActivityTypeEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    ApiTransactionDetailsActivityTypeEnum["PAYOUT"] = "PAYOUT";
    ApiTransactionDetailsActivityTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["RECEIVE"] = "RECEIVE";
    ApiTransactionDetailsActivityTypeEnum["REDEEM"] = "REDEEM";
    ApiTransactionDetailsActivityTypeEnum["REMOVE"] = "REMOVE";
    ApiTransactionDetailsActivityTypeEnum["RESTORE"] = "RESTORE";
    ApiTransactionDetailsActivityTypeEnum["REVERSE"] = "REVERSE";
    ApiTransactionDetailsActivityTypeEnum["SELL"] = "SELL";
    ApiTransactionDetailsActivityTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiTransactionDetailsActivityTypeEnum["SEND"] = "SEND";
    ApiTransactionDetailsActivityTypeEnum["TERMINATE"] = "TERMINATE";
    ApiTransactionDetailsActivityTypeEnum["UNFREEZE"] = "UNFREEZE";
    ApiTransactionDetailsActivityTypeEnum["UNLINK"] = "UNLINK";
    ApiTransactionDetailsActivityTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    ApiTransactionDetailsActivityTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    ApiTransactionDetailsActivityTypeEnum["WIRE_LINK"] = "WIRE_LINK";
    ApiTransactionDetailsActivityTypeEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    ApiTransactionDetailsActivityTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiTransactionDetailsActivityTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiTransactionDetailsActivityTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiTransactionDetailsActivityTypeEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    ApiTransactionDetailsActivityTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiTransactionDetailsActivityTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    ApiTransactionDetailsActivityTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiTransactionDetailsActivityTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiTransactionDetailsActivityTypeEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    ApiTransactionDetailsActivityTypeEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    ApiTransactionDetailsActivityTypeEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(ApiTransactionDetailsActivityTypeEnum || (ApiTransactionDetailsActivityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionDetailsAssetTypeEnum;
(function (ApiTransactionDetailsAssetTypeEnum) {
    ApiTransactionDetailsAssetTypeEnum["CASH"] = "CASH";
    ApiTransactionDetailsAssetTypeEnum["CRYPTO"] = "CRYPTO";
    ApiTransactionDetailsAssetTypeEnum["REWARDS"] = "REWARDS";
    ApiTransactionDetailsAssetTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    ApiTransactionDetailsAssetTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    ApiTransactionDetailsAssetTypeEnum["DEBIT_CARD"] = "DEBIT_CARD";
})(ApiTransactionDetailsAssetTypeEnum || (ApiTransactionDetailsAssetTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionDetailsRequestAssetActivityTypeEnum;
(function (ApiTransactionDetailsRequestAssetActivityTypeEnum) {
    ApiTransactionDetailsRequestAssetActivityTypeEnum["ADD"] = "ADD";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["BUY"] = "BUY";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CARD_REFUND"] = "CARD_REFUND";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CREDIT"] = "CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CONVERT"] = "CONVERT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DEPOSIT"] = "DEPOSIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["FREEZE"] = "FREEZE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["INCENTIVE"] = "INCENTIVE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["LINK"] = "LINK";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PAYOUT"] = "PAYOUT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["RECEIVE"] = "RECEIVE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["REDEEM"] = "REDEEM";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["REMOVE"] = "REMOVE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["RESTORE"] = "RESTORE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["REVERSE"] = "REVERSE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["SELL"] = "SELL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["SELL_ALL"] = "SELL_ALL";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["SEND"] = "SEND";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["TERMINATE"] = "TERMINATE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["UNFREEZE"] = "UNFREEZE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["UNLINK"] = "UNLINK";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WIRE_LINK"] = "WIRE_LINK";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WITHDRAW"] = "WITHDRAW";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    ApiTransactionDetailsRequestAssetActivityTypeEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(ApiTransactionDetailsRequestAssetActivityTypeEnum || (ApiTransactionDetailsRequestAssetActivityTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionTicketDetailsDisputeStatusEnum;
(function (ApiTransactionTicketDetailsDisputeStatusEnum) {
    ApiTransactionTicketDetailsDisputeStatusEnum["NEW"] = "NEW";
    ApiTransactionTicketDetailsDisputeStatusEnum["ESCALATED"] = "ESCALATED";
    ApiTransactionTicketDetailsDisputeStatusEnum["REJECTED"] = "REJECTED";
    ApiTransactionTicketDetailsDisputeStatusEnum["MERCHANT_REJECTED"] = "MERCHANT_REJECTED";
    ApiTransactionTicketDetailsDisputeStatusEnum["ACCEPTED"] = "ACCEPTED";
    ApiTransactionTicketDetailsDisputeStatusEnum["CANCELLED"] = "CANCELLED";
})(ApiTransactionTicketDetailsDisputeStatusEnum || (ApiTransactionTicketDetailsDisputeStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionTicketDetailsReasonCodeEnum;
(function (ApiTransactionTicketDetailsReasonCodeEnum) {
    ApiTransactionTicketDetailsReasonCodeEnum["DAC01"] = "DAC01";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC02"] = "DAC02";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC03"] = "DAC03";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC04"] = "DAC04";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC05"] = "DAC05";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC06"] = "DAC06";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC07"] = "DAC07";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC08"] = "DAC08";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC09"] = "DAC09";
    ApiTransactionTicketDetailsReasonCodeEnum["DAC10"] = "DAC10";
})(ApiTransactionTicketDetailsReasonCodeEnum || (ApiTransactionTicketDetailsReasonCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiTransactionTicketDetailsRegulationEFlagEnum;
(function (ApiTransactionTicketDetailsRegulationEFlagEnum) {
    ApiTransactionTicketDetailsRegulationEFlagEnum["YES"] = "YES";
    ApiTransactionTicketDetailsRegulationEFlagEnum["NO"] = "NO";
    ApiTransactionTicketDetailsRegulationEFlagEnum["NO_DISPOSITION"] = "NO_DISPOSITION";
})(ApiTransactionTicketDetailsRegulationEFlagEnum || (ApiTransactionTicketDetailsRegulationEFlagEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum;
(function (ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum) {
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["ACTIVE"] = "ACTIVE";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["INACTIVE"] = "INACTIVE";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["PENDING"] = "PENDING";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum["CLOSED"] = "CLOSED";
})(ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum || (ApiUpdatePartnerPartyEmailResultBakktPartnerPartyLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiUpdatePartnerPartyEmailResultPartyLevelEnum;
(function (ApiUpdatePartnerPartyEmailResultPartyLevelEnum) {
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["UNKNOWN"] = "UNKNOWN";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_1"] = "LEVEL_1";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_2"] = "LEVEL_2";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_3"] = "LEVEL_3";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_4"] = "LEVEL_4";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_5"] = "LEVEL_5";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_99"] = "LEVEL_99";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultPartyLevelEnum["CLOSED"] = "CLOSED";
})(ApiUpdatePartnerPartyEmailResultPartyLevelEnum || (ApiUpdatePartnerPartyEmailResultPartyLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiUpdatePartnerPartyEmailResultPartyStatusEnum;
(function (ApiUpdatePartnerPartyEmailResultPartyStatusEnum) {
    ApiUpdatePartnerPartyEmailResultPartyStatusEnum["OPEN"] = "OPEN";
    ApiUpdatePartnerPartyEmailResultPartyStatusEnum["CLOSED"] = "CLOSED";
    ApiUpdatePartnerPartyEmailResultPartyStatusEnum["SUSPENDED"] = "SUSPENDED";
    ApiUpdatePartnerPartyEmailResultPartyStatusEnum["LIQUIDATED"] = "LIQUIDATED";
})(ApiUpdatePartnerPartyEmailResultPartyStatusEnum || (ApiUpdatePartnerPartyEmailResultPartyStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum;
(function (ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum) {
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["ACTIVE"] = "ACTIVE";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["INACTIVE"] = "INACTIVE";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["PENDING"] = "PENDING";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum["CLOSED"] = "CLOSED";
})(ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum || (ApiUpdatePartnerPartyEmailResultWebullPartnerPartyLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiUpdateRoleAddPermissionsEnum;
(function (ApiUpdateRoleAddPermissionsEnum) {
    ApiUpdateRoleAddPermissionsEnum["ALLOWLIST_CREATE"] = "ALLOWLIST_CREATE";
    ApiUpdateRoleAddPermissionsEnum["ALLOWLIST_DELETE"] = "ALLOWLIST_DELETE";
    ApiUpdateRoleAddPermissionsEnum["ALLOWLIST_UPDATE"] = "ALLOWLIST_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["ALLOWLIST_VIEW"] = "ALLOWLIST_VIEW";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_DELETE_REASON"] = "DISPUTE_DELETE_REASON";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_EXPORT_CSV"] = "DISPUTE_EXPORT_CSV";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_UPDATE_ACCEPT"] = "DISPUTE_UPDATE_ACCEPT";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_UPDATE_AUTO_APPROVE"] = "DISPUTE_UPDATE_AUTO_APPROVE";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_UPDATE_CREDIT_REJECT"] = "DISPUTE_UPDATE_CREDIT_REJECT";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_UPDATE_PROVISIONAL"] = "DISPUTE_UPDATE_PROVISIONAL";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_CREATE"] = "DISPUTE_CREATE";
    ApiUpdateRoleAddPermissionsEnum["DISPUTE_VIEW"] = "DISPUTE_VIEW";
    ApiUpdateRoleAddPermissionsEnum["DEVICE_SESSION_VIEW"] = "DEVICE_SESSION_VIEW";
    ApiUpdateRoleAddPermissionsEnum["FINANCE_VIEW"] = "FINANCE_VIEW";
    ApiUpdateRoleAddPermissionsEnum["FIAT_LEDGER_ADJUSTMENT"] = "FIAT_LEDGER_ADJUSTMENT";
    ApiUpdateRoleAddPermissionsEnum["GIFTCARD_UPDATE_REACTIVATE"] = "GIFTCARD_UPDATE_REACTIVATE";
    ApiUpdateRoleAddPermissionsEnum["GIFTCARD_BRAND_UPDATE"] = "GIFTCARD_BRAND_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["GIFTCARD_BRAND_VIEW_ACTIVITY"] = "GIFTCARD_BRAND_VIEW_ACTIVITY";
    ApiUpdateRoleAddPermissionsEnum["GIFTCARD_VIEW"] = "GIFTCARD_VIEW";
    ApiUpdateRoleAddPermissionsEnum["GIFTCARD_VIEW_BALANCE"] = "GIFTCARD_VIEW_BALANCE";
    ApiUpdateRoleAddPermissionsEnum["GIFTCARD_VIEW_ACTIVITY"] = "GIFTCARD_VIEW_ACTIVITY";
    ApiUpdateRoleAddPermissionsEnum["KYC_ACTIVITY_VIEW"] = "KYC_ACTIVITY_VIEW";
    ApiUpdateRoleAddPermissionsEnum["OFAC_CREATE"] = "OFAC_CREATE";
    ApiUpdateRoleAddPermissionsEnum["OFAC_DELETE"] = "OFAC_DELETE";
    ApiUpdateRoleAddPermissionsEnum["OFAC_UPDATE"] = "OFAC_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["OFAC_VIEW"] = "OFAC_VIEW";
    ApiUpdateRoleAddPermissionsEnum["PARTY_VIEW"] = "PARTY_VIEW";
    ApiUpdateRoleAddPermissionsEnum["PORTAL_VIEW"] = "PORTAL_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_CREATE"] = "REPORT_CREATE";
    ApiUpdateRoleAddPermissionsEnum["TICKET_CREATE"] = "TICKET_CREATE";
    ApiUpdateRoleAddPermissionsEnum["TICKET_UPDATE_ASSIGN"] = "TICKET_UPDATE_ASSIGN";
    ApiUpdateRoleAddPermissionsEnum["TICKET_UPDATE_CANCEL"] = "TICKET_UPDATE_CANCEL";
    ApiUpdateRoleAddPermissionsEnum["TICKET_UPDATE_ESCALATE"] = "TICKET_UPDATE_ESCALATE";
    ApiUpdateRoleAddPermissionsEnum["TICKET_UPDATE_COMMENT"] = "TICKET_UPDATE_COMMENT";
    ApiUpdateRoleAddPermissionsEnum["TICKET_VIEW"] = "TICKET_VIEW";
    ApiUpdateRoleAddPermissionsEnum["TRANSACTIONS_VIEW"] = "TRANSACTIONS_VIEW";
    ApiUpdateRoleAddPermissionsEnum["USERS_CREATE"] = "USERS_CREATE";
    ApiUpdateRoleAddPermissionsEnum["USERS_UPDATE"] = "USERS_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["USERS_UPDATE_SYNC"] = "USERS_UPDATE_SYNC";
    ApiUpdateRoleAddPermissionsEnum["USERS_VIEW"] = "USERS_VIEW";
    ApiUpdateRoleAddPermissionsEnum["USERS_VIEW_DETAIL"] = "USERS_VIEW_DETAIL";
    ApiUpdateRoleAddPermissionsEnum["ACCOUNT_PREFUND_BLOCK"] = "ACCOUNT_PREFUND_BLOCK";
    ApiUpdateRoleAddPermissionsEnum["ACCOUNT_PREFUND_UNBLOCK"] = "ACCOUNT_PREFUND_UNBLOCK";
    ApiUpdateRoleAddPermissionsEnum["ACCOUNT_ACTIVITY_VIEW"] = "ACCOUNT_ACTIVITY_VIEW";
    ApiUpdateRoleAddPermissionsEnum["BAKKT_CARD_VIEW"] = "BAKKT_CARD_VIEW";
    ApiUpdateRoleAddPermissionsEnum["BAKKT_CARD_UPDATE"] = "BAKKT_CARD_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["PARTY_CREDIT_ADD"] = "PARTY_CREDIT_ADD";
    ApiUpdateRoleAddPermissionsEnum["PARTY_PII_VIEW"] = "PARTY_PII_VIEW";
    ApiUpdateRoleAddPermissionsEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["CARD_ISSUER_KYC_UPDATE"] = "CARD_ISSUER_KYC_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["BANKACCOUNT_BLACKLIST_VIEW"] = "BANKACCOUNT_BLACKLIST_VIEW";
    ApiUpdateRoleAddPermissionsEnum["BANKACCOUNT_BLACKLIST_UPDATE"] = "BANKACCOUNT_BLACKLIST_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["DEBITCARD_BLACKLIST_UPDATE"] = "DEBITCARD_BLACKLIST_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["PARTY_WHITELIST_UPDATE"] = "PARTY_WHITELIST_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["INCENTIVE_OFFER_VIEW"] = "INCENTIVE_OFFER_VIEW";
    ApiUpdateRoleAddPermissionsEnum["LOYALTY_REDEEM_CANCEL"] = "LOYALTY_REDEEM_CANCEL";
    ApiUpdateRoleAddPermissionsEnum["LOYALTY_ACCOUNT_VIEW"] = "LOYALTY_ACCOUNT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["LOYALTY_ACCOUNT_UPDATE"] = "LOYALTY_ACCOUNT_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["INCENTIVE_OFFER_CREATE"] = "INCENTIVE_OFFER_CREATE";
    ApiUpdateRoleAddPermissionsEnum["INCENTIVE_OFFER_COMPLETION"] = "INCENTIVE_OFFER_COMPLETION";
    ApiUpdateRoleAddPermissionsEnum["ACTIVITY_AUDIT_VIEW"] = "ACTIVITY_AUDIT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["DEBIT_CARD_VIEW"] = "DEBIT_CARD_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ASSET_ACTIVTY_VIEW"] = "REPORT_ASSET_ACTIVTY_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_PREFUNDING_VIEW"] = "REPORT_PREFUNDING_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_VIRTUAL_CURRENCY_VIEW"] = "REPORT_VIRTUAL_CURRENCY_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_MTL_VIEW"] = "REPORT_MTL_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ADOTMTL_VIEW"] = "REPORT_ADOTMTL_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_SIFT_VIEW"] = "REPORT_SIFT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_DISPUTE_CREDIT_VIEW"] = "REPORT_DISPUTE_CREDIT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_GIFT_CARD_RECON_VIEW"] = "REPORT_GIFT_CARD_RECON_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ACH_PROCESSING_VIEW"] = "REPORT_ACH_PROCESSING_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_SUSPEND_ACCOUNT_VIEW"] = "REPORT_SUSPEND_ACCOUNT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_P2P_TRANSFER_VIEW"] = "REPORT_P2P_TRANSFER_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ASSET_ACTIVTY_CREATE"] = "REPORT_ASSET_ACTIVTY_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_PREFUNDING_CREATE"] = "REPORT_PREFUNDING_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_VIRTUAL_CURRENCY_CREATE"] = "REPORT_VIRTUAL_CURRENCY_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_MTL_CREATE"] = "REPORT_MTL_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ADOTMTL_CREATE"] = "REPORT_ADOTMTL_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_SIFT_CREATE"] = "REPORT_SIFT_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_DISPUTE_CREDIT_CREATE"] = "REPORT_DISPUTE_CREDIT_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_GIFT_CARD_RECON_CREATE"] = "REPORT_GIFT_CARD_RECON_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ACH_PROCESSING_CREATE"] = "REPORT_ACH_PROCESSING_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_SUSPEND_ACCOUNT_CREATE"] = "REPORT_SUSPEND_ACCOUNT_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_P2P_TRANSFERS_CREATE"] = "REPORT_P2P_TRANSFERS_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_QUIZNOS_VIEW"] = "REPORT_QUIZNOS_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_QUIZNOS_CREATE"] = "REPORT_QUIZNOS_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_CHOICE_VIEW"] = "REPORT_CHOICE_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_CHOICE_CREATE"] = "REPORT_CHOICE_CREATE";
    ApiUpdateRoleAddPermissionsEnum["RISK_AUDIT_VIEW"] = "RISK_AUDIT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["RISK_AUDIT_VIEW_DETAIL"] = "RISK_AUDIT_VIEW_DETAIL";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_VIEW"] = "REPORT_ACCOUNT_BALANCE_V2_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_CREATE"] = "REPORT_ACCOUNT_BALANCE_V2_CREATE";
    ApiUpdateRoleAddPermissionsEnum["PLAID_ACCOUNT_DETAIL_VIEW"] = "PLAID_ACCOUNT_DETAIL_VIEW";
    ApiUpdateRoleAddPermissionsEnum["UNMASK_PLAID_ACCOUNT_NUMBERS"] = "UNMASK_PLAID_ACCOUNT_NUMBERS";
    ApiUpdateRoleAddPermissionsEnum["UNMASK_DEBIT_CARD_FIELDS"] = "UNMASK_DEBIT_CARD_FIELDS";
    ApiUpdateRoleAddPermissionsEnum["TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW"] = "TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_BTC_TRANSACTION_REPORT_CREATE"] = "REPORT_BTC_TRANSACTION_REPORT_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_CRYPTO_TRANSACTION_REPORT_CREATE"] = "REPORT_CRYPTO_TRANSACTION_REPORT_CREATE";
    ApiUpdateRoleAddPermissionsEnum["PARTNER_DETAIL_VIEW"] = "PARTNER_DETAIL_VIEW";
    ApiUpdateRoleAddPermissionsEnum["PARTNER_LINK_UNLINK"] = "PARTNER_LINK_UNLINK";
    ApiUpdateRoleAddPermissionsEnum["PARTY_NAME_UPDATE"] = "PARTY_NAME_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["PARTY_SUSPEND"] = "PARTY_SUSPEND";
    ApiUpdateRoleAddPermissionsEnum["PARTY_UNSUSPEND"] = "PARTY_UNSUSPEND";
    ApiUpdateRoleAddPermissionsEnum["PARTY_LIQUIDATE"] = "PARTY_LIQUIDATE";
    ApiUpdateRoleAddPermissionsEnum["PARTY_ACTIVATE"] = "PARTY_ACTIVATE";
    ApiUpdateRoleAddPermissionsEnum["PARTY_CLOSE"] = "PARTY_CLOSE";
    ApiUpdateRoleAddPermissionsEnum["PARTY_STATUS_UPDATE"] = "PARTY_STATUS_UPDATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_MERCHANT_INCENTIVE_CREATE"] = "REPORT_MERCHANT_INCENTIVE_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_MERCHANT_INCENTIVE_VIEW"] = "REPORT_MERCHANT_INCENTIVE_VIEW";
    ApiUpdateRoleAddPermissionsEnum["REPORT_PARTNER_INCENTIVE_CREATE"] = "REPORT_PARTNER_INCENTIVE_CREATE";
    ApiUpdateRoleAddPermissionsEnum["REPORT_PARTNER_INCENTIVE_VIEW"] = "REPORT_PARTNER_INCENTIVE_VIEW";
    ApiUpdateRoleAddPermissionsEnum["TAXID_LAST_4_DIGIT_VIEW"] = "TAXID_LAST_4_DIGIT_VIEW";
    ApiUpdateRoleAddPermissionsEnum["TAXID_FULL_VIEW"] = "TAXID_FULL_VIEW";
})(ApiUpdateRoleAddPermissionsEnum || (ApiUpdateRoleAddPermissionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiUpdateRoleRemovePermissionsEnum;
(function (ApiUpdateRoleRemovePermissionsEnum) {
    ApiUpdateRoleRemovePermissionsEnum["ALLOWLIST_CREATE"] = "ALLOWLIST_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["ALLOWLIST_DELETE"] = "ALLOWLIST_DELETE";
    ApiUpdateRoleRemovePermissionsEnum["ALLOWLIST_UPDATE"] = "ALLOWLIST_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["ALLOWLIST_VIEW"] = "ALLOWLIST_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_DELETE_REASON"] = "DISPUTE_DELETE_REASON";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_EXPORT_CSV"] = "DISPUTE_EXPORT_CSV";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_UPDATE_ACCEPT"] = "DISPUTE_UPDATE_ACCEPT";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_UPDATE_AUTO_APPROVE"] = "DISPUTE_UPDATE_AUTO_APPROVE";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_UPDATE_CREDIT_REJECT"] = "DISPUTE_UPDATE_CREDIT_REJECT";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_UPDATE_PROVISIONAL"] = "DISPUTE_UPDATE_PROVISIONAL";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_CREATE"] = "DISPUTE_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["DISPUTE_VIEW"] = "DISPUTE_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["DEVICE_SESSION_VIEW"] = "DEVICE_SESSION_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["FINANCE_VIEW"] = "FINANCE_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["FIAT_LEDGER_ADJUSTMENT"] = "FIAT_LEDGER_ADJUSTMENT";
    ApiUpdateRoleRemovePermissionsEnum["GIFTCARD_UPDATE_REACTIVATE"] = "GIFTCARD_UPDATE_REACTIVATE";
    ApiUpdateRoleRemovePermissionsEnum["GIFTCARD_BRAND_UPDATE"] = "GIFTCARD_BRAND_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["GIFTCARD_BRAND_VIEW_ACTIVITY"] = "GIFTCARD_BRAND_VIEW_ACTIVITY";
    ApiUpdateRoleRemovePermissionsEnum["GIFTCARD_VIEW"] = "GIFTCARD_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["GIFTCARD_VIEW_BALANCE"] = "GIFTCARD_VIEW_BALANCE";
    ApiUpdateRoleRemovePermissionsEnum["GIFTCARD_VIEW_ACTIVITY"] = "GIFTCARD_VIEW_ACTIVITY";
    ApiUpdateRoleRemovePermissionsEnum["KYC_ACTIVITY_VIEW"] = "KYC_ACTIVITY_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["OFAC_CREATE"] = "OFAC_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["OFAC_DELETE"] = "OFAC_DELETE";
    ApiUpdateRoleRemovePermissionsEnum["OFAC_UPDATE"] = "OFAC_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["OFAC_VIEW"] = "OFAC_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_VIEW"] = "PARTY_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["PORTAL_VIEW"] = "PORTAL_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_CREATE"] = "REPORT_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["TICKET_CREATE"] = "TICKET_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["TICKET_UPDATE_ASSIGN"] = "TICKET_UPDATE_ASSIGN";
    ApiUpdateRoleRemovePermissionsEnum["TICKET_UPDATE_CANCEL"] = "TICKET_UPDATE_CANCEL";
    ApiUpdateRoleRemovePermissionsEnum["TICKET_UPDATE_ESCALATE"] = "TICKET_UPDATE_ESCALATE";
    ApiUpdateRoleRemovePermissionsEnum["TICKET_UPDATE_COMMENT"] = "TICKET_UPDATE_COMMENT";
    ApiUpdateRoleRemovePermissionsEnum["TICKET_VIEW"] = "TICKET_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["TRANSACTIONS_VIEW"] = "TRANSACTIONS_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["USERS_CREATE"] = "USERS_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["USERS_UPDATE"] = "USERS_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["USERS_UPDATE_SYNC"] = "USERS_UPDATE_SYNC";
    ApiUpdateRoleRemovePermissionsEnum["USERS_VIEW"] = "USERS_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["USERS_VIEW_DETAIL"] = "USERS_VIEW_DETAIL";
    ApiUpdateRoleRemovePermissionsEnum["ACCOUNT_PREFUND_BLOCK"] = "ACCOUNT_PREFUND_BLOCK";
    ApiUpdateRoleRemovePermissionsEnum["ACCOUNT_PREFUND_UNBLOCK"] = "ACCOUNT_PREFUND_UNBLOCK";
    ApiUpdateRoleRemovePermissionsEnum["ACCOUNT_ACTIVITY_VIEW"] = "ACCOUNT_ACTIVITY_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["BAKKT_CARD_VIEW"] = "BAKKT_CARD_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["BAKKT_CARD_UPDATE"] = "BAKKT_CARD_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_CREDIT_ADD"] = "PARTY_CREDIT_ADD";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_PII_VIEW"] = "PARTY_PII_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["KYC_UPDATE"] = "KYC_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["CARD_ISSUER_KYC_UPDATE"] = "CARD_ISSUER_KYC_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["BANKACCOUNT_BLACKLIST_VIEW"] = "BANKACCOUNT_BLACKLIST_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["BANKACCOUNT_BLACKLIST_UPDATE"] = "BANKACCOUNT_BLACKLIST_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["DEBITCARD_BLACKLIST_UPDATE"] = "DEBITCARD_BLACKLIST_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_WHITELIST_UPDATE"] = "PARTY_WHITELIST_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["INCENTIVE_OFFER_VIEW"] = "INCENTIVE_OFFER_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["LOYALTY_REDEEM_CANCEL"] = "LOYALTY_REDEEM_CANCEL";
    ApiUpdateRoleRemovePermissionsEnum["LOYALTY_ACCOUNT_VIEW"] = "LOYALTY_ACCOUNT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["LOYALTY_ACCOUNT_UPDATE"] = "LOYALTY_ACCOUNT_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["INCENTIVE_OFFER_CREATE"] = "INCENTIVE_OFFER_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["INCENTIVE_OFFER_COMPLETION"] = "INCENTIVE_OFFER_COMPLETION";
    ApiUpdateRoleRemovePermissionsEnum["ACTIVITY_AUDIT_VIEW"] = "ACTIVITY_AUDIT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["DEBIT_CARD_VIEW"] = "DEBIT_CARD_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ASSET_ACTIVTY_VIEW"] = "REPORT_ASSET_ACTIVTY_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_PREFUNDING_VIEW"] = "REPORT_PREFUNDING_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_VIRTUAL_CURRENCY_VIEW"] = "REPORT_VIRTUAL_CURRENCY_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_MTL_VIEW"] = "REPORT_MTL_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ADOTMTL_VIEW"] = "REPORT_ADOTMTL_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_SIFT_VIEW"] = "REPORT_SIFT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_DISPUTE_CREDIT_VIEW"] = "REPORT_DISPUTE_CREDIT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_GIFT_CARD_RECON_VIEW"] = "REPORT_GIFT_CARD_RECON_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ACH_PROCESSING_VIEW"] = "REPORT_ACH_PROCESSING_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_SUSPEND_ACCOUNT_VIEW"] = "REPORT_SUSPEND_ACCOUNT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_P2P_TRANSFER_VIEW"] = "REPORT_P2P_TRANSFER_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ASSET_ACTIVTY_CREATE"] = "REPORT_ASSET_ACTIVTY_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_PREFUNDING_CREATE"] = "REPORT_PREFUNDING_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_VIRTUAL_CURRENCY_CREATE"] = "REPORT_VIRTUAL_CURRENCY_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_MTL_CREATE"] = "REPORT_MTL_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ADOTMTL_CREATE"] = "REPORT_ADOTMTL_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_SIFT_CREATE"] = "REPORT_SIFT_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_DISPUTE_CREDIT_CREATE"] = "REPORT_DISPUTE_CREDIT_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_GIFT_CARD_RECON_CREATE"] = "REPORT_GIFT_CARD_RECON_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE"] = "REPORT_ACCOUNT_BALANCE_SUMMARY_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ACH_PROCESSING_CREATE"] = "REPORT_ACH_PROCESSING_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_SUSPEND_ACCOUNT_CREATE"] = "REPORT_SUSPEND_ACCOUNT_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_P2P_TRANSFERS_CREATE"] = "REPORT_P2P_TRANSFERS_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_QUIZNOS_VIEW"] = "REPORT_QUIZNOS_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_QUIZNOS_CREATE"] = "REPORT_QUIZNOS_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_CHOICE_VIEW"] = "REPORT_CHOICE_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_CHOICE_CREATE"] = "REPORT_CHOICE_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["RISK_AUDIT_VIEW"] = "RISK_AUDIT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["RISK_AUDIT_VIEW_DETAIL"] = "RISK_AUDIT_VIEW_DETAIL";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_VIEW"] = "REPORT_ACCOUNT_BALANCE_V2_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_ACCOUNT_BALANCE_V2_CREATE"] = "REPORT_ACCOUNT_BALANCE_V2_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["PLAID_ACCOUNT_DETAIL_VIEW"] = "PLAID_ACCOUNT_DETAIL_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["UNMASK_PLAID_ACCOUNT_NUMBERS"] = "UNMASK_PLAID_ACCOUNT_NUMBERS";
    ApiUpdateRoleRemovePermissionsEnum["UNMASK_DEBIT_CARD_FIELDS"] = "UNMASK_DEBIT_CARD_FIELDS";
    ApiUpdateRoleRemovePermissionsEnum["TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW"] = "TRANSACTION_ACCOUNT_LINK_FAILURES_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_BTC_TRANSACTION_REPORT_CREATE"] = "REPORT_BTC_TRANSACTION_REPORT_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_CRYPTO_TRANSACTION_REPORT_CREATE"] = "REPORT_CRYPTO_TRANSACTION_REPORT_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["PARTNER_DETAIL_VIEW"] = "PARTNER_DETAIL_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["PARTNER_LINK_UNLINK"] = "PARTNER_LINK_UNLINK";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_NAME_UPDATE"] = "PARTY_NAME_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_SUSPEND"] = "PARTY_SUSPEND";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_UNSUSPEND"] = "PARTY_UNSUSPEND";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_LIQUIDATE"] = "PARTY_LIQUIDATE";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_ACTIVATE"] = "PARTY_ACTIVATE";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_CLOSE"] = "PARTY_CLOSE";
    ApiUpdateRoleRemovePermissionsEnum["PARTY_STATUS_UPDATE"] = "PARTY_STATUS_UPDATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_MERCHANT_INCENTIVE_CREATE"] = "REPORT_MERCHANT_INCENTIVE_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_MERCHANT_INCENTIVE_VIEW"] = "REPORT_MERCHANT_INCENTIVE_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_PARTNER_INCENTIVE_CREATE"] = "REPORT_PARTNER_INCENTIVE_CREATE";
    ApiUpdateRoleRemovePermissionsEnum["REPORT_PARTNER_INCENTIVE_VIEW"] = "REPORT_PARTNER_INCENTIVE_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["TAXID_LAST_4_DIGIT_VIEW"] = "TAXID_LAST_4_DIGIT_VIEW";
    ApiUpdateRoleRemovePermissionsEnum["TAXID_FULL_VIEW"] = "TAXID_FULL_VIEW";
})(ApiUpdateRoleRemovePermissionsEnum || (ApiUpdateRoleRemovePermissionsEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiWebLibraryFeatureNameEnum;
(function (ApiWebLibraryFeatureNameEnum) {
    ApiWebLibraryFeatureNameEnum["SIFT"] = "SIFT";
    ApiWebLibraryFeatureNameEnum["SARDINE"] = "SARDINE";
    ApiWebLibraryFeatureNameEnum["ARKOSE"] = "ARKOSE";
    ApiWebLibraryFeatureNameEnum["SEGMENT"] = "SEGMENT";
    ApiWebLibraryFeatureNameEnum["DATADOG"] = "DATADOG";
})(ApiWebLibraryFeatureNameEnum || (ApiWebLibraryFeatureNameEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ApiWhitelistPartyRequestReasonEnum;
(function (ApiWhitelistPartyRequestReasonEnum) {
    ApiWhitelistPartyRequestReasonEnum["ACCOUNT_CLOSURE_REQUESTED"] = "ACCOUNT_CLOSURE_REQUESTED";
    ApiWhitelistPartyRequestReasonEnum["ACH_MAX_RETURNS"] = "ACH_MAX_RETURNS";
    ApiWhitelistPartyRequestReasonEnum["ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_AND_TOTAL_AMOUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["ACH_RETURNS_COUNT_EXCEEDED"] = "ACH_RETURNS_COUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED"] = "ACH_RETURNS_TOTAL_AMOUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["ACH_RETURN_CODE"] = "ACH_RETURN_CODE";
    ApiWhitelistPartyRequestReasonEnum["ADMIN_UPDATE_IDENTIFIER"] = "ADMIN_UPDATE_IDENTIFIER";
    ApiWhitelistPartyRequestReasonEnum["COMPLIANCE_INVESTIGATION"] = "COMPLIANCE_INVESTIGATION";
    ApiWhitelistPartyRequestReasonEnum["COMPLIANCE_LOCKED"] = "COMPLIANCE_LOCKED";
    ApiWhitelistPartyRequestReasonEnum["COMPLIANCE_VERIFICATION"] = "COMPLIANCE_VERIFICATION";
    ApiWhitelistPartyRequestReasonEnum["CUSTOMER_GOOD_STANDING"] = "CUSTOMER_GOOD_STANDING";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AMOUNT_VERIFICATION_COUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_AVS_VERIFICATION_COUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED"] = "DEBIT_CARD_CVV_VERIFICATION_COUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_TRANSACTION_FRAUD_HOLD"] = "DEBIT_CARD_TRANSACTION_FRAUD_HOLD";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_LINKED_TO_OTHER_PARTY"] = "DEBIT_CARD_LINKED_TO_OTHER_PARTY";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_UNSUPPORTED_COUNTRY"] = "DEBIT_CARD_UNSUPPORTED_COUNTRY";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_INVALID"] = "DEBIT_CARD_INVALID";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED"] = "DEBIT_CARD_BLACKLIST_COUNT_EXCEEDED";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_SAME_CARD";
    ApiWhitelistPartyRequestReasonEnum["DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS"] = "DEBIT_CARD_FAILURE_COUNT_EXCEEDED_DIFF_CARDS";
    ApiWhitelistPartyRequestReasonEnum["LIQUIDATE_ACCOUNT"] = "LIQUIDATE_ACCOUNT";
    ApiWhitelistPartyRequestReasonEnum["MARQETA_KYC_VERIFICATION"] = "MARQETA_KYC_VERIFICATION";
    ApiWhitelistPartyRequestReasonEnum["NSF_BLOCKING"] = "NSF_BLOCKING";
    ApiWhitelistPartyRequestReasonEnum["OLD_OFAC_CHECK"] = "OLD_OFAC_CHECK";
    ApiWhitelistPartyRequestReasonEnum["OTHER"] = "OTHER";
    ApiWhitelistPartyRequestReasonEnum["PLAID_IDENTITY_CHECK"] = "PLAID_IDENTITY_CHECK";
    ApiWhitelistPartyRequestReasonEnum["PLAID_MISSING_NAMES"] = "PLAID_MISSING_NAMES";
    ApiWhitelistPartyRequestReasonEnum["RELINK"] = "RELINK";
    ApiWhitelistPartyRequestReasonEnum["RISK_DECISION"] = "RISK_DECISION";
    ApiWhitelistPartyRequestReasonEnum["RISK_KYC"] = "RISK_KYC";
    ApiWhitelistPartyRequestReasonEnum["RISK_OFAC"] = "RISK_OFAC";
    ApiWhitelistPartyRequestReasonEnum["RISK_SCREENING"] = "RISK_SCREENING";
    ApiWhitelistPartyRequestReasonEnum["TAX_ID_MAX_RETRIES"] = "TAX_ID_MAX_RETRIES";
    ApiWhitelistPartyRequestReasonEnum["CARD_TERMINATION_ERROR"] = "CARD_TERMINATION_ERROR";
    ApiWhitelistPartyRequestReasonEnum["CLOSED"] = "CLOSED";
    ApiWhitelistPartyRequestReasonEnum["TERMS_AND_CONDITIONS_ACCEPTED"] = "TERMS_AND_CONDITIONS_ACCEPTED";
    ApiWhitelistPartyRequestReasonEnum["PARTY_NAME_EMAIL_PHONE_UPDATE"] = "PARTY_NAME_EMAIL_PHONE_UPDATE";
    ApiWhitelistPartyRequestReasonEnum["PENDING_REVIEW"] = "PENDING_REVIEW";
    ApiWhitelistPartyRequestReasonEnum["DOCUMENT_VERIFICATION"] = "DOCUMENT_VERIFICATION";
    ApiWhitelistPartyRequestReasonEnum["PARTNER_PARTY_LINK_NAME_UPDATE"] = "PARTNER_PARTY_LINK_NAME_UPDATE";
    ApiWhitelistPartyRequestReasonEnum["PARTNER_UPDATE"] = "PARTNER_UPDATE";
    ApiWhitelistPartyRequestReasonEnum["ENROLLMENT"] = "ENROLLMENT";
    ApiWhitelistPartyRequestReasonEnum["OPEN"] = "OPEN";
    ApiWhitelistPartyRequestReasonEnum["UNSPECIFIED"] = "UNSPECIFIED";
})(ApiWhitelistPartyRequestReasonEnum || (ApiWhitelistPartyRequestReasonEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySearchRequestAssetActivityStatusEnum;
(function (AssetActivitySearchRequestAssetActivityStatusEnum) {
    AssetActivitySearchRequestAssetActivityStatusEnum["CANCELED"] = "CANCELED";
    AssetActivitySearchRequestAssetActivityStatusEnum["COMPLETE"] = "COMPLETE";
    AssetActivitySearchRequestAssetActivityStatusEnum["EXPIRED"] = "EXPIRED";
    AssetActivitySearchRequestAssetActivityStatusEnum["PENDING"] = "PENDING";
    AssetActivitySearchRequestAssetActivityStatusEnum["REJECTED"] = "REJECTED";
    AssetActivitySearchRequestAssetActivityStatusEnum["REPLACED"] = "REPLACED";
    AssetActivitySearchRequestAssetActivityStatusEnum["FAILED"] = "FAILED";
    AssetActivitySearchRequestAssetActivityStatusEnum["PROVISIONAL"] = "PROVISIONAL";
})(AssetActivitySearchRequestAssetActivityStatusEnum || (AssetActivitySearchRequestAssetActivityStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySearchRequestAssetActivityTypesEnum;
(function (AssetActivitySearchRequestAssetActivityTypesEnum) {
    AssetActivitySearchRequestAssetActivityTypesEnum["ADD"] = "ADD";
    AssetActivitySearchRequestAssetActivityTypesEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    AssetActivitySearchRequestAssetActivityTypesEnum["BUY"] = "BUY";
    AssetActivitySearchRequestAssetActivityTypesEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    AssetActivitySearchRequestAssetActivityTypesEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    AssetActivitySearchRequestAssetActivityTypesEnum["CARD_REFUND"] = "CARD_REFUND";
    AssetActivitySearchRequestAssetActivityTypesEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["CREDIT"] = "CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["CONVERT"] = "CONVERT";
    AssetActivitySearchRequestAssetActivityTypesEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    AssetActivitySearchRequestAssetActivityTypesEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    AssetActivitySearchRequestAssetActivityTypesEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    AssetActivitySearchRequestAssetActivityTypesEnum["DEPOSIT"] = "DEPOSIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    AssetActivitySearchRequestAssetActivityTypesEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    AssetActivitySearchRequestAssetActivityTypesEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    AssetActivitySearchRequestAssetActivityTypesEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    AssetActivitySearchRequestAssetActivityTypesEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    AssetActivitySearchRequestAssetActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["FREEZE"] = "FREEZE";
    AssetActivitySearchRequestAssetActivityTypesEnum["INCENTIVE"] = "INCENTIVE";
    AssetActivitySearchRequestAssetActivityTypesEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    AssetActivitySearchRequestAssetActivityTypesEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    AssetActivitySearchRequestAssetActivityTypesEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    AssetActivitySearchRequestAssetActivityTypesEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    AssetActivitySearchRequestAssetActivityTypesEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    AssetActivitySearchRequestAssetActivityTypesEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    AssetActivitySearchRequestAssetActivityTypesEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    AssetActivitySearchRequestAssetActivityTypesEnum["LINK"] = "LINK";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    AssetActivitySearchRequestAssetActivityTypesEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    AssetActivitySearchRequestAssetActivityTypesEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    AssetActivitySearchRequestAssetActivityTypesEnum["PAYOUT"] = "PAYOUT";
    AssetActivitySearchRequestAssetActivityTypesEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["RECEIVE"] = "RECEIVE";
    AssetActivitySearchRequestAssetActivityTypesEnum["REDEEM"] = "REDEEM";
    AssetActivitySearchRequestAssetActivityTypesEnum["REMOVE"] = "REMOVE";
    AssetActivitySearchRequestAssetActivityTypesEnum["RESTORE"] = "RESTORE";
    AssetActivitySearchRequestAssetActivityTypesEnum["REVERSE"] = "REVERSE";
    AssetActivitySearchRequestAssetActivityTypesEnum["SELL"] = "SELL";
    AssetActivitySearchRequestAssetActivityTypesEnum["SELL_ALL"] = "SELL_ALL";
    AssetActivitySearchRequestAssetActivityTypesEnum["SEND"] = "SEND";
    AssetActivitySearchRequestAssetActivityTypesEnum["TERMINATE"] = "TERMINATE";
    AssetActivitySearchRequestAssetActivityTypesEnum["UNFREEZE"] = "UNFREEZE";
    AssetActivitySearchRequestAssetActivityTypesEnum["UNLINK"] = "UNLINK";
    AssetActivitySearchRequestAssetActivityTypesEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    AssetActivitySearchRequestAssetActivityTypesEnum["WIRE_LINK"] = "WIRE_LINK";
    AssetActivitySearchRequestAssetActivityTypesEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    AssetActivitySearchRequestAssetActivityTypesEnum["WITHDRAW"] = "WITHDRAW";
    AssetActivitySearchRequestAssetActivityTypesEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    AssetActivitySearchRequestAssetActivityTypesEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    AssetActivitySearchRequestAssetActivityTypesEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    AssetActivitySearchRequestAssetActivityTypesEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    AssetActivitySearchRequestAssetActivityTypesEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(AssetActivitySearchRequestAssetActivityTypesEnum || (AssetActivitySearchRequestAssetActivityTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySearchRequestAssetTypesEnum;
(function (AssetActivitySearchRequestAssetTypesEnum) {
    AssetActivitySearchRequestAssetTypesEnum["CASH"] = "CASH";
    AssetActivitySearchRequestAssetTypesEnum["CRYPTO"] = "CRYPTO";
    AssetActivitySearchRequestAssetTypesEnum["REWARDS"] = "REWARDS";
    AssetActivitySearchRequestAssetTypesEnum["GIFT_CARD"] = "GIFT_CARD";
    AssetActivitySearchRequestAssetTypesEnum["BAKKT_CARD"] = "BAKKT_CARD";
    AssetActivitySearchRequestAssetTypesEnum["DEBIT_CARD"] = "DEBIT_CARD";
})(AssetActivitySearchRequestAssetTypesEnum || (AssetActivitySearchRequestAssetTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySearchRequestCurrencyEnum;
(function (AssetActivitySearchRequestCurrencyEnum) {
    AssetActivitySearchRequestCurrencyEnum["USD"] = "USD";
    AssetActivitySearchRequestCurrencyEnum["BCH"] = "BCH";
    AssetActivitySearchRequestCurrencyEnum["BTC"] = "BTC";
    AssetActivitySearchRequestCurrencyEnum["DOGE"] = "DOGE";
    AssetActivitySearchRequestCurrencyEnum["ETC"] = "ETC";
    AssetActivitySearchRequestCurrencyEnum["ETH"] = "ETH";
    AssetActivitySearchRequestCurrencyEnum["LTC"] = "LTC";
    AssetActivitySearchRequestCurrencyEnum["SHIB"] = "SHIB";
    AssetActivitySearchRequestCurrencyEnum["USDC"] = "USDC";
    AssetActivitySearchRequestCurrencyEnum["CET"] = "CET";
    AssetActivitySearchRequestCurrencyEnum["LXX"] = "LXX";
    AssetActivitySearchRequestCurrencyEnum["LCP"] = "LCP";
    AssetActivitySearchRequestCurrencyEnum["LWR"] = "LWR";
    AssetActivitySearchRequestCurrencyEnum["LMO"] = "LMO";
    AssetActivitySearchRequestCurrencyEnum["LKO"] = "LKO";
    AssetActivitySearchRequestCurrencyEnum["LED"] = "LED";
})(AssetActivitySearchRequestCurrencyEnum || (AssetActivitySearchRequestCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySearchRequestSystemEnum;
(function (AssetActivitySearchRequestSystemEnum) {
    AssetActivitySearchRequestSystemEnum["TANGO"] = "TANGO";
    AssetActivitySearchRequestSystemEnum["UNIT21"] = "UNIT21";
    AssetActivitySearchRequestSystemEnum["SIFT"] = "SIFT";
    AssetActivitySearchRequestSystemEnum["BRAZE"] = "BRAZE";
    AssetActivitySearchRequestSystemEnum["SARDINE"] = "SARDINE";
    AssetActivitySearchRequestSystemEnum["REWARD"] = "REWARD";
    AssetActivitySearchRequestSystemEnum["FUSIONAUTH"] = "FUSIONAUTH";
    AssetActivitySearchRequestSystemEnum["PARTNER_PARTY_LINK"] = "PARTNER_PARTY_LINK";
    AssetActivitySearchRequestSystemEnum["WIRE_REFERENCE_CODE"] = "WIRE_REFERENCE_CODE";
})(AssetActivitySearchRequestSystemEnum || (AssetActivitySearchRequestSystemEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySummarySearchRequestAssetActivityStatusEnum;
(function (AssetActivitySummarySearchRequestAssetActivityStatusEnum) {
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["CANCELED"] = "CANCELED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["COMPLETE"] = "COMPLETE";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["EXPIRED"] = "EXPIRED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["PENDING"] = "PENDING";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["REJECTED"] = "REJECTED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["REPLACED"] = "REPLACED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["REFUND"] = "REFUND";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["FAILED"] = "FAILED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["PROVISIONAL"] = "PROVISIONAL";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["CAPTURED"] = "CAPTURED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["PARTIAL_CAPTURED"] = "PARTIAL_CAPTURED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["REFUNDED"] = "REFUNDED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["REVERSED"] = "REVERSED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["PARTIAL_REVERSED"] = "PARTIAL_REVERSED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["AUTHORIZED"] = "AUTHORIZED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["SETTLED"] = "SETTLED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["DISPUTED"] = "DISPUTED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["DISPUTE_CLOSED"] = "DISPUTE_CLOSED";
    AssetActivitySummarySearchRequestAssetActivityStatusEnum["DISPUTE_OPEN"] = "DISPUTE_OPEN";
})(AssetActivitySummarySearchRequestAssetActivityStatusEnum || (AssetActivitySummarySearchRequestAssetActivityStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySummarySearchRequestAssetActivityTypesEnum;
(function (AssetActivitySummarySearchRequestAssetActivityTypesEnum) {
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["ADD"] = "ADD";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["BAKKT_WRITE_OFF"] = "BAKKT_WRITE_OFF";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["BUY"] = "BUY";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CARD_CHARGEBACK"] = "CARD_CHARGEBACK";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CARD_REFUND"] = "CARD_REFUND";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CONVENIENCE_CREDIT"] = "CONVENIENCE_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CREDIT"] = "CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CONVERT"] = "CONVERT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["CONVERT_CANCEL"] = "CONVERT_CANCEL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DECEASED_PARTY"] = "DECEASED_PARTY";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DEPOSIT_FROM_FAILED_TRANSACTION"] = "DEPOSIT_FROM_FAILED_TRANSACTION";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DEPOSIT"] = "DEPOSIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DEPOSIT_RETURN_FEE"] = "DEPOSIT_RETURN_FEE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DEPOSIT_RETURNED"] = "DEPOSIT_RETURNED";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DEPOSIT_REVERSE"] = "DEPOSIT_REVERSE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["DISPUTE_CREDIT_REVERSE"] = "DISPUTE_CREDIT_REVERSE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["FREEZE"] = "FREEZE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["INCENTIVE"] = "INCENTIVE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["INCENTIVE_CANCEL"] = "INCENTIVE_CANCEL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LOYALTY_CREDIT_CANCEL"] = "LOYALTY_CREDIT_CANCEL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LOYALTY_CRYPTO_REDEEM_FAILED"] = "LOYALTY_CRYPTO_REDEEM_FAILED";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LOYALTY_PAYMENT_REVERSAL"] = "LOYALTY_PAYMENT_REVERSAL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["LINK"] = "LINK";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_CREDIT"] = "MERCHANT_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_AUTHORIZE"] = "MERCHANT_PAYMENT_AUTHORIZE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_AUTHORIZE_DECLINED"] = "MERCHANT_PAYMENT_AUTHORIZE_DECLINED";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_CANCEL"] = "MERCHANT_PAYMENT_CANCEL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_CAPTURE"] = "MERCHANT_PAYMENT_CAPTURE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_CREDIT"] = "MERCHANT_PAYMENT_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_REFUND"] = "MERCHANT_PAYMENT_REFUND";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_PAYMENT_DISPUTE"] = "MERCHANT_PAYMENT_DISPUTE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["MERCHANT_REFUND"] = "MERCHANT_REFUND";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["OTHER_ADJUSTMENT"] = "OTHER_ADJUSTMENT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTNER_WRITE_OFF"] = "PARTNER_WRITE_OFF";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PAYOUT"] = "PAYOUT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PROMOTIONAL_CREDIT"] = "PROMOTIONAL_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["RECEIVE"] = "RECEIVE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["REDEEM"] = "REDEEM";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["REMOVE"] = "REMOVE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["RESTORE"] = "RESTORE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["REVERSE"] = "REVERSE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["SELL"] = "SELL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["SELL_ALL"] = "SELL_ALL";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["SEND"] = "SEND";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["TERMINATE"] = "TERMINATE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["UNFREEZE"] = "UNFREEZE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["UNLINK"] = "UNLINK";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WIRE_LINK"] = "WIRE_LINK";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WIRE_UNLINK"] = "WIRE_UNLINK";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WITHDRAW"] = "WITHDRAW";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WITHDRAW_REVERSE"] = "WITHDRAW_REVERSE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WITHDRAW_RETURN_FEE"] = "WITHDRAW_RETURN_FEE";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["WITHDRAW_RETURNED"] = "WITHDRAW_RETURNED";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTNER_REWARD_CRYPTO"] = "PARTNER_REWARD_CRYPTO";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTNER_REWARD_CASH"] = "PARTNER_REWARD_CASH";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTNER_TPJ_CREDIT"] = "PARTNER_TPJ_CREDIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTNER_TPJ_DEBIT"] = "PARTNER_TPJ_DEBIT";
    AssetActivitySummarySearchRequestAssetActivityTypesEnum["PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER"] = "PARTY_PARTNER_WALLET_ACCOUNT_TRANSFER";
})(AssetActivitySummarySearchRequestAssetActivityTypesEnum || (AssetActivitySummarySearchRequestAssetActivityTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySummarySearchRequestAssetTypesEnum;
(function (AssetActivitySummarySearchRequestAssetTypesEnum) {
    AssetActivitySummarySearchRequestAssetTypesEnum["CASH"] = "CASH";
    AssetActivitySummarySearchRequestAssetTypesEnum["CRYPTO"] = "CRYPTO";
    AssetActivitySummarySearchRequestAssetTypesEnum["REWARDS"] = "REWARDS";
    AssetActivitySummarySearchRequestAssetTypesEnum["GIFT_CARD"] = "GIFT_CARD";
    AssetActivitySummarySearchRequestAssetTypesEnum["BAKKT_CARD"] = "BAKKT_CARD";
    AssetActivitySummarySearchRequestAssetTypesEnum["DEBIT_CARD"] = "DEBIT_CARD";
})(AssetActivitySummarySearchRequestAssetTypesEnum || (AssetActivitySummarySearchRequestAssetTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySummarySearchRequestCurrenciesEnum;
(function (AssetActivitySummarySearchRequestCurrenciesEnum) {
    AssetActivitySummarySearchRequestCurrenciesEnum["USD"] = "USD";
    AssetActivitySummarySearchRequestCurrenciesEnum["BCH"] = "BCH";
    AssetActivitySummarySearchRequestCurrenciesEnum["BTC"] = "BTC";
    AssetActivitySummarySearchRequestCurrenciesEnum["DOGE"] = "DOGE";
    AssetActivitySummarySearchRequestCurrenciesEnum["ETC"] = "ETC";
    AssetActivitySummarySearchRequestCurrenciesEnum["ETH"] = "ETH";
    AssetActivitySummarySearchRequestCurrenciesEnum["LTC"] = "LTC";
    AssetActivitySummarySearchRequestCurrenciesEnum["SHIB"] = "SHIB";
    AssetActivitySummarySearchRequestCurrenciesEnum["USDC"] = "USDC";
    AssetActivitySummarySearchRequestCurrenciesEnum["CET"] = "CET";
    AssetActivitySummarySearchRequestCurrenciesEnum["LXX"] = "LXX";
    AssetActivitySummarySearchRequestCurrenciesEnum["LCP"] = "LCP";
    AssetActivitySummarySearchRequestCurrenciesEnum["LWR"] = "LWR";
    AssetActivitySummarySearchRequestCurrenciesEnum["LMO"] = "LMO";
    AssetActivitySummarySearchRequestCurrenciesEnum["LKO"] = "LKO";
    AssetActivitySummarySearchRequestCurrenciesEnum["LED"] = "LED";
})(AssetActivitySummarySearchRequestCurrenciesEnum || (AssetActivitySummarySearchRequestCurrenciesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var AssetActivitySummarySearchRequestSystemEnum;
(function (AssetActivitySummarySearchRequestSystemEnum) {
    AssetActivitySummarySearchRequestSystemEnum["TANGO"] = "TANGO";
    AssetActivitySummarySearchRequestSystemEnum["UNIT21"] = "UNIT21";
    AssetActivitySummarySearchRequestSystemEnum["SIFT"] = "SIFT";
    AssetActivitySummarySearchRequestSystemEnum["BRAZE"] = "BRAZE";
    AssetActivitySummarySearchRequestSystemEnum["SARDINE"] = "SARDINE";
    AssetActivitySummarySearchRequestSystemEnum["REWARD"] = "REWARD";
    AssetActivitySummarySearchRequestSystemEnum["FUSIONAUTH"] = "FUSIONAUTH";
    AssetActivitySummarySearchRequestSystemEnum["PARTNER_PARTY_LINK"] = "PARTNER_PARTY_LINK";
    AssetActivitySummarySearchRequestSystemEnum["WIRE_REFERENCE_CODE"] = "WIRE_REFERENCE_CODE";
})(AssetActivitySummarySearchRequestSystemEnum || (AssetActivitySummarySearchRequestSystemEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var CreditFileStatusEnum;
(function (CreditFileStatusEnum) {
    CreditFileStatusEnum["UNDEFINED"] = "UNDEFINED";
    CreditFileStatusEnum["CREATING"] = "CREATING";
    CreditFileStatusEnum["ERROR"] = "ERROR";
    CreditFileStatusEnum["NO_BATCH_FOUND"] = "NO_BATCH_FOUND";
    CreditFileStatusEnum["CREATED_CONSUMER_BATCH"] = "CREATED_CONSUMER_BATCH";
    CreditFileStatusEnum["CREATED_MERCHANT_BATCH"] = "CREATED_MERCHANT_BATCH";
    CreditFileStatusEnum["CREATED_MERCHANT_V1_BATCH"] = "CREATED_MERCHANT_V1_BATCH";
    CreditFileStatusEnum["CREATED_PARTNER_BATCH"] = "CREATED_PARTNER_BATCH";
    CreditFileStatusEnum["UPLOADED"] = "UPLOADED";
    CreditFileStatusEnum["PARTIALLY_UPLOADED"] = "PARTIALLY_UPLOADED";
})(CreditFileStatusEnum || (CreditFileStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var CryptoOrFiatAmountCurrencyEnum;
(function (CryptoOrFiatAmountCurrencyEnum) {
    CryptoOrFiatAmountCurrencyEnum["BCH"] = "BCH";
    CryptoOrFiatAmountCurrencyEnum["BTC"] = "BTC";
    CryptoOrFiatAmountCurrencyEnum["DOGE"] = "DOGE";
    CryptoOrFiatAmountCurrencyEnum["ETC"] = "ETC";
    CryptoOrFiatAmountCurrencyEnum["ETH"] = "ETH";
    CryptoOrFiatAmountCurrencyEnum["LTC"] = "LTC";
    CryptoOrFiatAmountCurrencyEnum["SHIB"] = "SHIB";
    CryptoOrFiatAmountCurrencyEnum["USDC"] = "USDC";
    CryptoOrFiatAmountCurrencyEnum["USD"] = "USD";
    CryptoOrFiatAmountCurrencyEnum["CET"] = "CET";
    CryptoOrFiatAmountCurrencyEnum["LCP"] = "LCP";
    CryptoOrFiatAmountCurrencyEnum["LKO"] = "LKO";
    CryptoOrFiatAmountCurrencyEnum["LMO"] = "LMO";
    CryptoOrFiatAmountCurrencyEnum["LWR"] = "LWR";
    CryptoOrFiatAmountCurrencyEnum["LXX"] = "LXX";
    CryptoOrFiatAmountCurrencyEnum["LED"] = "LED";
})(CryptoOrFiatAmountCurrencyEnum || (CryptoOrFiatAmountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var DebitFileStatusEnum;
(function (DebitFileStatusEnum) {
    DebitFileStatusEnum["UNDEFINED"] = "UNDEFINED";
    DebitFileStatusEnum["CREATING"] = "CREATING";
    DebitFileStatusEnum["ERROR"] = "ERROR";
    DebitFileStatusEnum["NO_BATCH_FOUND"] = "NO_BATCH_FOUND";
    DebitFileStatusEnum["CREATED_CONSUMER_BATCH"] = "CREATED_CONSUMER_BATCH";
    DebitFileStatusEnum["CREATED_MERCHANT_BATCH"] = "CREATED_MERCHANT_BATCH";
    DebitFileStatusEnum["CREATED_MERCHANT_V1_BATCH"] = "CREATED_MERCHANT_V1_BATCH";
    DebitFileStatusEnum["CREATED_PARTNER_BATCH"] = "CREATED_PARTNER_BATCH";
    DebitFileStatusEnum["UPLOADED"] = "UPLOADED";
    DebitFileStatusEnum["PARTIALLY_UPLOADED"] = "PARTIALLY_UPLOADED";
})(DebitFileStatusEnum || (DebitFileStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var DurationPeriodEnum;
(function (DurationPeriodEnum) {
    DurationPeriodEnum["DAILY"] = "DAILY";
    DurationPeriodEnum["MONTHLY"] = "MONTHLY";
})(DurationPeriodEnum || (DurationPeriodEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var FiatAmountCurrencyEnum;
(function (FiatAmountCurrencyEnum) {
    FiatAmountCurrencyEnum["USD"] = "USD";
})(FiatAmountCurrencyEnum || (FiatAmountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var FilterSearchCriteriaEnum;
(function (FilterSearchCriteriaEnum) {
    FilterSearchCriteriaEnum["STARTS_WITH"] = "STARTS_WITH";
    FilterSearchCriteriaEnum["ENDS_WITH"] = "ENDS_WITH";
    FilterSearchCriteriaEnum["CONTAINS"] = "CONTAINS";
    FilterSearchCriteriaEnum["NOT_CONTAINS"] = "NOT_CONTAINS";
    FilterSearchCriteriaEnum["EQUALS"] = "EQUALS";
    FilterSearchCriteriaEnum["NOT_EQUALS"] = "NOT_EQUALS";
    FilterSearchCriteriaEnum["IN"] = "IN";
    FilterSearchCriteriaEnum["BEFORE"] = "BEFORE";
    FilterSearchCriteriaEnum["BEFORE_OR_NULL"] = "BEFORE_OR_NULL";
    FilterSearchCriteriaEnum["AFTER"] = "AFTER";
    FilterSearchCriteriaEnum["AFTER_OR_NULL"] = "AFTER_OR_NULL";
    FilterSearchCriteriaEnum["BETWEEN"] = "BETWEEN";
    FilterSearchCriteriaEnum["RELATIVE_TIME"] = "RELATIVE_TIME";
    FilterSearchCriteriaEnum["LESS_THAN"] = "LESS_THAN";
    FilterSearchCriteriaEnum["LESS_THAN_OR_EQUAL"] = "LESS_THAN_OR_EQUAL";
    FilterSearchCriteriaEnum["LESS_THAN_OR_EQUAL_OR_NULL"] = "LESS_THAN_OR_EQUAL_OR_NULL";
    FilterSearchCriteriaEnum["GREATER_THAN"] = "GREATER_THAN";
    FilterSearchCriteriaEnum["GREATER_THAN_OR_EQUAL"] = "GREATER_THAN_OR_EQUAL";
    FilterSearchCriteriaEnum["GREATER_THAN_OR_EQUAL_OR_NULL"] = "GREATER_THAN_OR_EQUAL_OR_NULL";
    FilterSearchCriteriaEnum["IN_RANGE"] = "IN_RANGE";
    FilterSearchCriteriaEnum["NOT_NULL"] = "NOT_NULL";
    FilterSearchCriteriaEnum["NOT_IN"] = "NOT_IN";
})(FilterSearchCriteriaEnum || (FilterSearchCriteriaEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var IdentifierUserTypesEnum;
(function (IdentifierUserTypesEnum) {
    IdentifierUserTypesEnum["APP_USER"] = "APP_USER";
    IdentifierUserTypesEnum["PARTNER_ENROLLED_USER"] = "PARTNER_ENROLLED_USER";
})(IdentifierUserTypesEnum || (IdentifierUserTypesEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var LinkedTransactionPartnerTransactionStatusEnum;
(function (LinkedTransactionPartnerTransactionStatusEnum) {
    LinkedTransactionPartnerTransactionStatusEnum["CAPTURED"] = "CAPTURED";
    LinkedTransactionPartnerTransactionStatusEnum["DECLINED"] = "DECLINED";
    LinkedTransactionPartnerTransactionStatusEnum["DISPUTED"] = "DISPUTED";
    LinkedTransactionPartnerTransactionStatusEnum["CANCELLED"] = "CANCELLED";
    LinkedTransactionPartnerTransactionStatusEnum["PENDING"] = "PENDING";
    LinkedTransactionPartnerTransactionStatusEnum["COMPLETED"] = "COMPLETED";
})(LinkedTransactionPartnerTransactionStatusEnum || (LinkedTransactionPartnerTransactionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ModelFileStatusEnum;
(function (ModelFileStatusEnum) {
    ModelFileStatusEnum["UNDEFINED"] = "UNDEFINED";
    ModelFileStatusEnum["CREATING"] = "CREATING";
    ModelFileStatusEnum["ERROR"] = "ERROR";
    ModelFileStatusEnum["NO_BATCH_FOUND"] = "NO_BATCH_FOUND";
    ModelFileStatusEnum["CREATED_CONSUMER_BATCH"] = "CREATED_CONSUMER_BATCH";
    ModelFileStatusEnum["CREATED_MERCHANT_BATCH"] = "CREATED_MERCHANT_BATCH";
    ModelFileStatusEnum["CREATED_MERCHANT_V1_BATCH"] = "CREATED_MERCHANT_V1_BATCH";
    ModelFileStatusEnum["CREATED_PARTNER_BATCH"] = "CREATED_PARTNER_BATCH";
    ModelFileStatusEnum["UPLOADED"] = "UPLOADED";
    ModelFileStatusEnum["PARTIALLY_UPLOADED"] = "PARTIALLY_UPLOADED";
})(ModelFileStatusEnum || (ModelFileStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerBatchErrorCodeEnum;
(function (PartnerBatchErrorCodeEnum) {
    PartnerBatchErrorCodeEnum["GENERATE_REPORT_ERROR"] = "GENERATE_REPORT_ERROR";
    PartnerBatchErrorCodeEnum["MIGRATE_PARTNER_ERROR"] = "MIGRATE_PARTNER_ERROR";
})(PartnerBatchErrorCodeEnum || (PartnerBatchErrorCodeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerPartyKycResultPartyLevelEnum;
(function (PartnerPartyKycResultPartyLevelEnum) {
    PartnerPartyKycResultPartyLevelEnum["UNKNOWN"] = "UNKNOWN";
    PartnerPartyKycResultPartyLevelEnum["UNKNOWN_SUSPENDED"] = "UNKNOWN_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_1"] = "LEVEL_1";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_1_SUSPENDED"] = "LEVEL_1_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_1_LIQUIDATED"] = "LEVEL_1_LIQUIDATED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_2"] = "LEVEL_2";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_2_SUSPENDED"] = "LEVEL_2_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_2_LIQUIDATED"] = "LEVEL_2_LIQUIDATED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_3"] = "LEVEL_3";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_3_SUSPENDED"] = "LEVEL_3_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_3_LIQUIDATED"] = "LEVEL_3_LIQUIDATED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_4"] = "LEVEL_4";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_4_SUSPENDED"] = "LEVEL_4_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_4_LIQUIDATED"] = "LEVEL_4_LIQUIDATED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_5"] = "LEVEL_5";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_5_SUSPENDED"] = "LEVEL_5_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_5_LIQUIDATED"] = "LEVEL_5_LIQUIDATED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_99"] = "LEVEL_99";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_99_SUSPENDED"] = "LEVEL_99_SUSPENDED";
    PartnerPartyKycResultPartyLevelEnum["LEVEL_99_LIQUIDATED"] = "LEVEL_99_LIQUIDATED";
    PartnerPartyKycResultPartyLevelEnum["CLOSED"] = "CLOSED";
})(PartnerPartyKycResultPartyLevelEnum || (PartnerPartyKycResultPartyLevelEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerPartyKycResultTaxIdVerificationStatusEnum;
(function (PartnerPartyKycResultTaxIdVerificationStatusEnum) {
    PartnerPartyKycResultTaxIdVerificationStatusEnum["LOCKED"] = "LOCKED";
    PartnerPartyKycResultTaxIdVerificationStatusEnum["PENDING"] = "PENDING";
    PartnerPartyKycResultTaxIdVerificationStatusEnum["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    PartnerPartyKycResultTaxIdVerificationStatusEnum["VERIFIED"] = "VERIFIED";
    PartnerPartyKycResultTaxIdVerificationStatusEnum["SKIPPED"] = "SKIPPED";
})(PartnerPartyKycResultTaxIdVerificationStatusEnum || (PartnerPartyKycResultTaxIdVerificationStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerTransactionCryptoCurrencySourceEnum;
(function (PartnerTransactionCryptoCurrencySourceEnum) {
    PartnerTransactionCryptoCurrencySourceEnum["PARTNER"] = "PARTNER";
    PartnerTransactionCryptoCurrencySourceEnum["PARTY"] = "PARTY";
})(PartnerTransactionCryptoCurrencySourceEnum || (PartnerTransactionCryptoCurrencySourceEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerTransactionCryptoCurrencyTransactTypeEnum;
(function (PartnerTransactionCryptoCurrencyTransactTypeEnum) {
    PartnerTransactionCryptoCurrencyTransactTypeEnum["BUY"] = "BUY";
    PartnerTransactionCryptoCurrencyTransactTypeEnum["SELL"] = "SELL";
    PartnerTransactionCryptoCurrencyTransactTypeEnum["SELL_ALL"] = "SELL_ALL";
})(PartnerTransactionCryptoCurrencyTransactTypeEnum || (PartnerTransactionCryptoCurrencyTransactTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerTransactionOperationTypeEnum;
(function (PartnerTransactionOperationTypeEnum) {
    PartnerTransactionOperationTypeEnum["BUY"] = "BUY";
    PartnerTransactionOperationTypeEnum["SELL"] = "SELL";
    PartnerTransactionOperationTypeEnum["SELL_ALL"] = "SELL_ALL";
    PartnerTransactionOperationTypeEnum["PAYOUT"] = "PAYOUT";
    PartnerTransactionOperationTypeEnum["DEPOSIT"] = "DEPOSIT";
    PartnerTransactionOperationTypeEnum["WITHDRAW"] = "WITHDRAW";
    PartnerTransactionOperationTypeEnum["TPJ_DEPOSIT"] = "TPJ_DEPOSIT";
    PartnerTransactionOperationTypeEnum["TPJ_WITHDRAW"] = "TPJ_WITHDRAW";
    PartnerTransactionOperationTypeEnum["REWARD"] = "REWARD";
    PartnerTransactionOperationTypeEnum["INCENTIVE"] = "INCENTIVE";
    PartnerTransactionOperationTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    PartnerTransactionOperationTypeEnum["LOYALTY_CREDIT"] = "LOYALTY_CREDIT";
    PartnerTransactionOperationTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    PartnerTransactionOperationTypeEnum["LOYALTY_BUY_CANCEL"] = "LOYALTY_BUY_CANCEL";
    PartnerTransactionOperationTypeEnum["LOYALTY_REDEEM_CANCEL"] = "LOYALTY_REDEEM_CANCEL";
    PartnerTransactionOperationTypeEnum["DEPOSIT_RETURN"] = "DEPOSIT_RETURN";
    PartnerTransactionOperationTypeEnum["WITHDRAW_RETURN"] = "WITHDRAW_RETURN";
    PartnerTransactionOperationTypeEnum["WIRE_DEPOSIT"] = "WIRE_DEPOSIT";
    PartnerTransactionOperationTypeEnum["WIRE_WITHDRAW"] = "WIRE_WITHDRAW";
    PartnerTransactionOperationTypeEnum["ACH_RETURN_FEE"] = "ACH_RETURN_FEE";
})(PartnerTransactionOperationTypeEnum || (PartnerTransactionOperationTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerTransactionStatusEnum;
(function (PartnerTransactionStatusEnum) {
    PartnerTransactionStatusEnum["CAPTURED"] = "CAPTURED";
    PartnerTransactionStatusEnum["DECLINED"] = "DECLINED";
    PartnerTransactionStatusEnum["DISPUTED"] = "DISPUTED";
    PartnerTransactionStatusEnum["CANCELLED"] = "CANCELLED";
    PartnerTransactionStatusEnum["PENDING"] = "PENDING";
    PartnerTransactionStatusEnum["COMPLETED"] = "COMPLETED";
})(PartnerTransactionStatusEnum || (PartnerTransactionStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PartnerTransactionTransactionTypeEnum;
(function (PartnerTransactionTransactionTypeEnum) {
    PartnerTransactionTransactionTypeEnum["CRYPTO_CURRENCY"] = "CRYPTO_CURRENCY";
    PartnerTransactionTransactionTypeEnum["REWARD"] = "REWARD";
    PartnerTransactionTransactionTypeEnum["INCENTIVE"] = "INCENTIVE";
    PartnerTransactionTransactionTypeEnum["LOYALTY"] = "LOYALTY";
    PartnerTransactionTransactionTypeEnum["PAYOUT"] = "PAYOUT";
    PartnerTransactionTransactionTypeEnum["FUND_TRANSFER"] = "FUND_TRANSFER";
})(PartnerTransactionTransactionTypeEnum || (PartnerTransactionTransactionTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var PortfolioByAssetAssetTypeEnum;
(function (PortfolioByAssetAssetTypeEnum) {
    PortfolioByAssetAssetTypeEnum["CASH"] = "CASH";
    PortfolioByAssetAssetTypeEnum["CRYPTO"] = "CRYPTO";
    PortfolioByAssetAssetTypeEnum["REWARDS"] = "REWARDS";
    PortfolioByAssetAssetTypeEnum["GIFT_CARD"] = "GIFT_CARD";
    PortfolioByAssetAssetTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    PortfolioByAssetAssetTypeEnum["DEBIT_CARD"] = "DEBIT_CARD";
})(PortfolioByAssetAssetTypeEnum || (PortfolioByAssetAssetTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ReturnFileStatusEnum;
(function (ReturnFileStatusEnum) {
    ReturnFileStatusEnum["UNDEFINED"] = "UNDEFINED";
    ReturnFileStatusEnum["LOADING"] = "LOADING";
    ReturnFileStatusEnum["ERROR"] = "ERROR";
    ReturnFileStatusEnum["LOADED"] = "LOADED";
    ReturnFileStatusEnum["PROCESSED"] = "PROCESSED";
    ReturnFileStatusEnum["PROCESSED_ERROR"] = "PROCESSED_ERROR";
})(ReturnFileStatusEnum || (ReturnFileStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ServiceAddressCountryEnum;
(function (ServiceAddressCountryEnum) {
    ServiceAddressCountryEnum["AND"] = "AND";
    ServiceAddressCountryEnum["ARE"] = "ARE";
    ServiceAddressCountryEnum["AFG"] = "AFG";
    ServiceAddressCountryEnum["ATG"] = "ATG";
    ServiceAddressCountryEnum["AIA"] = "AIA";
    ServiceAddressCountryEnum["ALB"] = "ALB";
    ServiceAddressCountryEnum["ARM"] = "ARM";
    ServiceAddressCountryEnum["AGO"] = "AGO";
    ServiceAddressCountryEnum["ATA"] = "ATA";
    ServiceAddressCountryEnum["ARG"] = "ARG";
    ServiceAddressCountryEnum["ASM"] = "ASM";
    ServiceAddressCountryEnum["AUT"] = "AUT";
    ServiceAddressCountryEnum["AUS"] = "AUS";
    ServiceAddressCountryEnum["ABW"] = "ABW";
    ServiceAddressCountryEnum["ALA"] = "ALA";
    ServiceAddressCountryEnum["AZE"] = "AZE";
    ServiceAddressCountryEnum["BIH"] = "BIH";
    ServiceAddressCountryEnum["BRB"] = "BRB";
    ServiceAddressCountryEnum["BGD"] = "BGD";
    ServiceAddressCountryEnum["BEL"] = "BEL";
    ServiceAddressCountryEnum["BFA"] = "BFA";
    ServiceAddressCountryEnum["BGR"] = "BGR";
    ServiceAddressCountryEnum["BHR"] = "BHR";
    ServiceAddressCountryEnum["BDI"] = "BDI";
    ServiceAddressCountryEnum["BEN"] = "BEN";
    ServiceAddressCountryEnum["BLM"] = "BLM";
    ServiceAddressCountryEnum["BMU"] = "BMU";
    ServiceAddressCountryEnum["BRN"] = "BRN";
    ServiceAddressCountryEnum["BOL"] = "BOL";
    ServiceAddressCountryEnum["BES"] = "BES";
    ServiceAddressCountryEnum["BRA"] = "BRA";
    ServiceAddressCountryEnum["BHS"] = "BHS";
    ServiceAddressCountryEnum["BTN"] = "BTN";
    ServiceAddressCountryEnum["BVT"] = "BVT";
    ServiceAddressCountryEnum["BWA"] = "BWA";
    ServiceAddressCountryEnum["BLR"] = "BLR";
    ServiceAddressCountryEnum["BLZ"] = "BLZ";
    ServiceAddressCountryEnum["CAN"] = "CAN";
    ServiceAddressCountryEnum["CCK"] = "CCK";
    ServiceAddressCountryEnum["COD"] = "COD";
    ServiceAddressCountryEnum["CAF"] = "CAF";
    ServiceAddressCountryEnum["COG"] = "COG";
    ServiceAddressCountryEnum["CHE"] = "CHE";
    ServiceAddressCountryEnum["CIV"] = "CIV";
    ServiceAddressCountryEnum["COK"] = "COK";
    ServiceAddressCountryEnum["CHL"] = "CHL";
    ServiceAddressCountryEnum["CMR"] = "CMR";
    ServiceAddressCountryEnum["CHN"] = "CHN";
    ServiceAddressCountryEnum["COL"] = "COL";
    ServiceAddressCountryEnum["CRI"] = "CRI";
    ServiceAddressCountryEnum["CUB"] = "CUB";
    ServiceAddressCountryEnum["CPV"] = "CPV";
    ServiceAddressCountryEnum["CUW"] = "CUW";
    ServiceAddressCountryEnum["CXR"] = "CXR";
    ServiceAddressCountryEnum["CYP"] = "CYP";
    ServiceAddressCountryEnum["CZE"] = "CZE";
    ServiceAddressCountryEnum["DEU"] = "DEU";
    ServiceAddressCountryEnum["DJI"] = "DJI";
    ServiceAddressCountryEnum["DNK"] = "DNK";
    ServiceAddressCountryEnum["DMA"] = "DMA";
    ServiceAddressCountryEnum["DOM"] = "DOM";
    ServiceAddressCountryEnum["DZA"] = "DZA";
    ServiceAddressCountryEnum["ECU"] = "ECU";
    ServiceAddressCountryEnum["EST"] = "EST";
    ServiceAddressCountryEnum["EGY"] = "EGY";
    ServiceAddressCountryEnum["ESH"] = "ESH";
    ServiceAddressCountryEnum["ERI"] = "ERI";
    ServiceAddressCountryEnum["ESP"] = "ESP";
    ServiceAddressCountryEnum["ETH"] = "ETH";
    ServiceAddressCountryEnum["FIN"] = "FIN";
    ServiceAddressCountryEnum["FJI"] = "FJI";
    ServiceAddressCountryEnum["FLK"] = "FLK";
    ServiceAddressCountryEnum["FSM"] = "FSM";
    ServiceAddressCountryEnum["FRO"] = "FRO";
    ServiceAddressCountryEnum["FRA"] = "FRA";
    ServiceAddressCountryEnum["GAB"] = "GAB";
    ServiceAddressCountryEnum["GBR"] = "GBR";
    ServiceAddressCountryEnum["GRD"] = "GRD";
    ServiceAddressCountryEnum["GEO"] = "GEO";
    ServiceAddressCountryEnum["GUF"] = "GUF";
    ServiceAddressCountryEnum["GGY"] = "GGY";
    ServiceAddressCountryEnum["GHA"] = "GHA";
    ServiceAddressCountryEnum["GIB"] = "GIB";
    ServiceAddressCountryEnum["GRL"] = "GRL";
    ServiceAddressCountryEnum["GMB"] = "GMB";
    ServiceAddressCountryEnum["GIN"] = "GIN";
    ServiceAddressCountryEnum["GLP"] = "GLP";
    ServiceAddressCountryEnum["GNQ"] = "GNQ";
    ServiceAddressCountryEnum["GRC"] = "GRC";
    ServiceAddressCountryEnum["SGS"] = "SGS";
    ServiceAddressCountryEnum["GTM"] = "GTM";
    ServiceAddressCountryEnum["GUM"] = "GUM";
    ServiceAddressCountryEnum["GNB"] = "GNB";
    ServiceAddressCountryEnum["GUY"] = "GUY";
    ServiceAddressCountryEnum["HKG"] = "HKG";
    ServiceAddressCountryEnum["HMD"] = "HMD";
    ServiceAddressCountryEnum["HND"] = "HND";
    ServiceAddressCountryEnum["HRV"] = "HRV";
    ServiceAddressCountryEnum["HTI"] = "HTI";
    ServiceAddressCountryEnum["HUN"] = "HUN";
    ServiceAddressCountryEnum["IDN"] = "IDN";
    ServiceAddressCountryEnum["IRL"] = "IRL";
    ServiceAddressCountryEnum["ISR"] = "ISR";
    ServiceAddressCountryEnum["IMN"] = "IMN";
    ServiceAddressCountryEnum["IND"] = "IND";
    ServiceAddressCountryEnum["IOT"] = "IOT";
    ServiceAddressCountryEnum["IRQ"] = "IRQ";
    ServiceAddressCountryEnum["IRN"] = "IRN";
    ServiceAddressCountryEnum["ISL"] = "ISL";
    ServiceAddressCountryEnum["ITA"] = "ITA";
    ServiceAddressCountryEnum["JEY"] = "JEY";
    ServiceAddressCountryEnum["JAM"] = "JAM";
    ServiceAddressCountryEnum["JOR"] = "JOR";
    ServiceAddressCountryEnum["JPN"] = "JPN";
    ServiceAddressCountryEnum["KEN"] = "KEN";
    ServiceAddressCountryEnum["KGZ"] = "KGZ";
    ServiceAddressCountryEnum["KHM"] = "KHM";
    ServiceAddressCountryEnum["KIR"] = "KIR";
    ServiceAddressCountryEnum["COM"] = "COM";
    ServiceAddressCountryEnum["KNA"] = "KNA";
    ServiceAddressCountryEnum["PRK"] = "PRK";
    ServiceAddressCountryEnum["KOR"] = "KOR";
    ServiceAddressCountryEnum["KWT"] = "KWT";
    ServiceAddressCountryEnum["CYM"] = "CYM";
    ServiceAddressCountryEnum["KAZ"] = "KAZ";
    ServiceAddressCountryEnum["LAO"] = "LAO";
    ServiceAddressCountryEnum["LBN"] = "LBN";
    ServiceAddressCountryEnum["LCA"] = "LCA";
    ServiceAddressCountryEnum["LIE"] = "LIE";
    ServiceAddressCountryEnum["LKA"] = "LKA";
    ServiceAddressCountryEnum["LBR"] = "LBR";
    ServiceAddressCountryEnum["LSO"] = "LSO";
    ServiceAddressCountryEnum["LTU"] = "LTU";
    ServiceAddressCountryEnum["LUX"] = "LUX";
    ServiceAddressCountryEnum["LVA"] = "LVA";
    ServiceAddressCountryEnum["LBY"] = "LBY";
    ServiceAddressCountryEnum["MAR"] = "MAR";
    ServiceAddressCountryEnum["MCO"] = "MCO";
    ServiceAddressCountryEnum["MDA"] = "MDA";
    ServiceAddressCountryEnum["MNE"] = "MNE";
    ServiceAddressCountryEnum["MAF"] = "MAF";
    ServiceAddressCountryEnum["MDG"] = "MDG";
    ServiceAddressCountryEnum["MHL"] = "MHL";
    ServiceAddressCountryEnum["MKD"] = "MKD";
    ServiceAddressCountryEnum["MLI"] = "MLI";
    ServiceAddressCountryEnum["MMR"] = "MMR";
    ServiceAddressCountryEnum["MNG"] = "MNG";
    ServiceAddressCountryEnum["MAC"] = "MAC";
    ServiceAddressCountryEnum["MNP"] = "MNP";
    ServiceAddressCountryEnum["MTQ"] = "MTQ";
    ServiceAddressCountryEnum["MRT"] = "MRT";
    ServiceAddressCountryEnum["MSR"] = "MSR";
    ServiceAddressCountryEnum["MLT"] = "MLT";
    ServiceAddressCountryEnum["MUS"] = "MUS";
    ServiceAddressCountryEnum["MDV"] = "MDV";
    ServiceAddressCountryEnum["MWI"] = "MWI";
    ServiceAddressCountryEnum["MEX"] = "MEX";
    ServiceAddressCountryEnum["MYS"] = "MYS";
    ServiceAddressCountryEnum["MOZ"] = "MOZ";
    ServiceAddressCountryEnum["NAM"] = "NAM";
    ServiceAddressCountryEnum["NCL"] = "NCL";
    ServiceAddressCountryEnum["NER"] = "NER";
    ServiceAddressCountryEnum["NFK"] = "NFK";
    ServiceAddressCountryEnum["NGA"] = "NGA";
    ServiceAddressCountryEnum["NIC"] = "NIC";
    ServiceAddressCountryEnum["NLD"] = "NLD";
    ServiceAddressCountryEnum["NOR"] = "NOR";
    ServiceAddressCountryEnum["NPL"] = "NPL";
    ServiceAddressCountryEnum["NRU"] = "NRU";
    ServiceAddressCountryEnum["NIU"] = "NIU";
    ServiceAddressCountryEnum["NZL"] = "NZL";
    ServiceAddressCountryEnum["OMN"] = "OMN";
    ServiceAddressCountryEnum["PAN"] = "PAN";
    ServiceAddressCountryEnum["PER"] = "PER";
    ServiceAddressCountryEnum["PYF"] = "PYF";
    ServiceAddressCountryEnum["PNG"] = "PNG";
    ServiceAddressCountryEnum["PHL"] = "PHL";
    ServiceAddressCountryEnum["PAK"] = "PAK";
    ServiceAddressCountryEnum["POL"] = "POL";
    ServiceAddressCountryEnum["SPM"] = "SPM";
    ServiceAddressCountryEnum["PCN"] = "PCN";
    ServiceAddressCountryEnum["PRI"] = "PRI";
    ServiceAddressCountryEnum["PSE"] = "PSE";
    ServiceAddressCountryEnum["PRT"] = "PRT";
    ServiceAddressCountryEnum["PLW"] = "PLW";
    ServiceAddressCountryEnum["PRY"] = "PRY";
    ServiceAddressCountryEnum["QAT"] = "QAT";
    ServiceAddressCountryEnum["REU"] = "REU";
    ServiceAddressCountryEnum["ROU"] = "ROU";
    ServiceAddressCountryEnum["SRB"] = "SRB";
    ServiceAddressCountryEnum["RUS"] = "RUS";
    ServiceAddressCountryEnum["RWA"] = "RWA";
    ServiceAddressCountryEnum["SAU"] = "SAU";
    ServiceAddressCountryEnum["SLB"] = "SLB";
    ServiceAddressCountryEnum["SYC"] = "SYC";
    ServiceAddressCountryEnum["SDN"] = "SDN";
    ServiceAddressCountryEnum["SWE"] = "SWE";
    ServiceAddressCountryEnum["SGP"] = "SGP";
    ServiceAddressCountryEnum["SHN"] = "SHN";
    ServiceAddressCountryEnum["SVN"] = "SVN";
    ServiceAddressCountryEnum["SJM"] = "SJM";
    ServiceAddressCountryEnum["SVK"] = "SVK";
    ServiceAddressCountryEnum["SLE"] = "SLE";
    ServiceAddressCountryEnum["SMR"] = "SMR";
    ServiceAddressCountryEnum["SEN"] = "SEN";
    ServiceAddressCountryEnum["SOM"] = "SOM";
    ServiceAddressCountryEnum["SUR"] = "SUR";
    ServiceAddressCountryEnum["SSD"] = "SSD";
    ServiceAddressCountryEnum["STP"] = "STP";
    ServiceAddressCountryEnum["SLV"] = "SLV";
    ServiceAddressCountryEnum["SXM"] = "SXM";
    ServiceAddressCountryEnum["SYR"] = "SYR";
    ServiceAddressCountryEnum["SWZ"] = "SWZ";
    ServiceAddressCountryEnum["TCA"] = "TCA";
    ServiceAddressCountryEnum["TCD"] = "TCD";
    ServiceAddressCountryEnum["ATF"] = "ATF";
    ServiceAddressCountryEnum["TGO"] = "TGO";
    ServiceAddressCountryEnum["THA"] = "THA";
    ServiceAddressCountryEnum["TJK"] = "TJK";
    ServiceAddressCountryEnum["TKL"] = "TKL";
    ServiceAddressCountryEnum["TLS"] = "TLS";
    ServiceAddressCountryEnum["TKM"] = "TKM";
    ServiceAddressCountryEnum["TUN"] = "TUN";
    ServiceAddressCountryEnum["TON"] = "TON";
    ServiceAddressCountryEnum["TUR"] = "TUR";
    ServiceAddressCountryEnum["TTO"] = "TTO";
    ServiceAddressCountryEnum["TUV"] = "TUV";
    ServiceAddressCountryEnum["TWN"] = "TWN";
    ServiceAddressCountryEnum["TZA"] = "TZA";
    ServiceAddressCountryEnum["UKR"] = "UKR";
    ServiceAddressCountryEnum["UGA"] = "UGA";
    ServiceAddressCountryEnum["UMI"] = "UMI";
    ServiceAddressCountryEnum["USA"] = "USA";
    ServiceAddressCountryEnum["URY"] = "URY";
    ServiceAddressCountryEnum["UZB"] = "UZB";
    ServiceAddressCountryEnum["VAT"] = "VAT";
    ServiceAddressCountryEnum["VCT"] = "VCT";
    ServiceAddressCountryEnum["VEN"] = "VEN";
    ServiceAddressCountryEnum["VGB"] = "VGB";
    ServiceAddressCountryEnum["VIR"] = "VIR";
    ServiceAddressCountryEnum["VNM"] = "VNM";
    ServiceAddressCountryEnum["VUT"] = "VUT";
    ServiceAddressCountryEnum["WLF"] = "WLF";
    ServiceAddressCountryEnum["WSM"] = "WSM";
    ServiceAddressCountryEnum["YEM"] = "YEM";
    ServiceAddressCountryEnum["MYT"] = "MYT";
    ServiceAddressCountryEnum["ZAF"] = "ZAF";
    ServiceAddressCountryEnum["ZMB"] = "ZMB";
    ServiceAddressCountryEnum["ZWE"] = "ZWE";
})(ServiceAddressCountryEnum || (ServiceAddressCountryEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ServiceAmountCurrencyEnum;
(function (ServiceAmountCurrencyEnum) {
    ServiceAmountCurrencyEnum["USD"] = "USD";
    ServiceAmountCurrencyEnum["BCH"] = "BCH";
    ServiceAmountCurrencyEnum["BTC"] = "BTC";
    ServiceAmountCurrencyEnum["DOGE"] = "DOGE";
    ServiceAmountCurrencyEnum["ETC"] = "ETC";
    ServiceAmountCurrencyEnum["ETH"] = "ETH";
    ServiceAmountCurrencyEnum["LTC"] = "LTC";
    ServiceAmountCurrencyEnum["SHIB"] = "SHIB";
    ServiceAmountCurrencyEnum["USDC"] = "USDC";
    ServiceAmountCurrencyEnum["CET"] = "CET";
    ServiceAmountCurrencyEnum["LXX"] = "LXX";
    ServiceAmountCurrencyEnum["LCP"] = "LCP";
    ServiceAmountCurrencyEnum["LWR"] = "LWR";
    ServiceAmountCurrencyEnum["LMO"] = "LMO";
    ServiceAmountCurrencyEnum["LKO"] = "LKO";
    ServiceAmountCurrencyEnum["LED"] = "LED";
})(ServiceAmountCurrencyEnum || (ServiceAmountCurrencyEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var SortBySortOrderEnum;
(function (SortBySortOrderEnum) {
    SortBySortOrderEnum["ASC"] = "ASC";
    SortBySortOrderEnum["DESC"] = "DESC";
})(SortBySortOrderEnum || (SortBySortOrderEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum;
(function (TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum) {
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["ACTIVE"] = "ACTIVE";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["INACTIVE"] = "INACTIVE";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["OTP_REQUIRED"] = "OTP_REQUIRED";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["PENDING"] = "PENDING";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["TRANSFERRED"] = "TRANSFERRED";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["UNDER_REVIEW"] = "UNDER_REVIEW";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["LIQUIDATED"] = "LIQUIDATED";
    TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum["CLOSED"] = "CLOSED";
})(TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum || (TestAdminPartnerPartyLinkStatusRequestPartnerPartyLinkStatusEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var ThemeThemeNameEnum;
(function (ThemeThemeNameEnum) {
    ThemeThemeNameEnum["LIGHT"] = "LIGHT";
    ThemeThemeNameEnum["DARK"] = "DARK";
})(ThemeThemeNameEnum || (ThemeThemeNameEnum = {}));
/**
    * @export
    * @enum {string}
    */
export var UpdateBankAccountRequestAccountStatusEnum;
(function (UpdateBankAccountRequestAccountStatusEnum) {
    UpdateBankAccountRequestAccountStatusEnum["ACTIVE"] = "ACTIVE";
    UpdateBankAccountRequestAccountStatusEnum["PENDING"] = "PENDING";
    UpdateBankAccountRequestAccountStatusEnum["SUSPENDED"] = "SUSPENDED";
})(UpdateBankAccountRequestAccountStatusEnum || (UpdateBankAccountRequestAccountStatusEnum = {}));
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CheckLimitBalanceCurrencyEnum} currency
         * @param {CheckLimitBalanceAdjustmentTypeEnum} adjustmentType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLimitBalance: (currency, adjustmentType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('checkLimitBalance', 'currency', currency);
            // verify required parameter 'adjustmentType' is not null or undefined
            assertParamExists('checkLimitBalance', 'adjustmentType', adjustmentType);
            const localVarPath = `/aof/party/account/limit-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }
            if (adjustmentType !== undefined) {
                localVarQueryParameter['adjustmentType'] = adjustmentType;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieves all the accounts owned by a given party (retrieved from JWT).
         * @summary Retrieve Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAccounts: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/party/account/retrieve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function (configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CheckLimitBalanceCurrencyEnum} currency
         * @param {CheckLimitBalanceAdjustmentTypeEnum} adjustmentType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLimitBalance(currency, adjustmentType, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.checkLimitBalance(currency, adjustmentType, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AccountApi.checkLimitBalance']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Retrieves all the accounts owned by a given party (retrieved from JWT).
         * @summary Retrieve Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAccounts(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveAccounts(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AccountApi.retrieveAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AccountApiFp(configuration);
    return {
        /**
         *
         * @param {CheckLimitBalanceCurrencyEnum} currency
         * @param {CheckLimitBalanceAdjustmentTypeEnum} adjustmentType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLimitBalance(currency, adjustmentType, options) {
            return localVarFp.checkLimitBalance(currency, adjustmentType, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves all the accounts owned by a given party (retrieved from JWT).
         * @summary Retrieve Accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAccounts(options) {
            return localVarFp.retrieveAccounts(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     *
     * @param {CheckLimitBalanceCurrencyEnum} currency
     * @param {CheckLimitBalanceAdjustmentTypeEnum} adjustmentType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    checkLimitBalance(currency, adjustmentType, options) {
        return AccountApiFp(this.configuration).checkLimitBalance(currency, adjustmentType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieves all the accounts owned by a given party (retrieved from JWT).
     * @summary Retrieve Accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    retrieveAccounts(options) {
        return AccountApiFp(this.configuration).retrieveAccounts(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var CheckLimitBalanceCurrencyEnum;
(function (CheckLimitBalanceCurrencyEnum) {
    CheckLimitBalanceCurrencyEnum["USD"] = "USD";
    CheckLimitBalanceCurrencyEnum["BCH"] = "BCH";
    CheckLimitBalanceCurrencyEnum["BTC"] = "BTC";
    CheckLimitBalanceCurrencyEnum["DOGE"] = "DOGE";
    CheckLimitBalanceCurrencyEnum["ETC"] = "ETC";
    CheckLimitBalanceCurrencyEnum["ETH"] = "ETH";
    CheckLimitBalanceCurrencyEnum["LTC"] = "LTC";
    CheckLimitBalanceCurrencyEnum["SHIB"] = "SHIB";
    CheckLimitBalanceCurrencyEnum["USDC"] = "USDC";
    CheckLimitBalanceCurrencyEnum["CET"] = "CET";
    CheckLimitBalanceCurrencyEnum["LXX"] = "LXX";
    CheckLimitBalanceCurrencyEnum["LCP"] = "LCP";
    CheckLimitBalanceCurrencyEnum["LWR"] = "LWR";
    CheckLimitBalanceCurrencyEnum["LMO"] = "LMO";
    CheckLimitBalanceCurrencyEnum["LKO"] = "LKO";
    CheckLimitBalanceCurrencyEnum["LED"] = "LED";
})(CheckLimitBalanceCurrencyEnum || (CheckLimitBalanceCurrencyEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var CheckLimitBalanceAdjustmentTypeEnum;
(function (CheckLimitBalanceAdjustmentTypeEnum) {
    CheckLimitBalanceAdjustmentTypeEnum["ACH"] = "ACH";
    CheckLimitBalanceAdjustmentTypeEnum["ACH_CANCEL"] = "ACH_CANCEL";
    CheckLimitBalanceAdjustmentTypeEnum["ACH_RETURN"] = "ACH_RETURN";
    CheckLimitBalanceAdjustmentTypeEnum["WIRE"] = "WIRE";
    CheckLimitBalanceAdjustmentTypeEnum["WIRE_CANCEL"] = "WIRE_CANCEL";
    CheckLimitBalanceAdjustmentTypeEnum["BAKKT_CASH_CARD_REDEMPTION"] = "BAKKT_CASH_CARD_REDEMPTION";
    CheckLimitBalanceAdjustmentTypeEnum["BTC_BUY_SELL"] = "BTC_BUY_SELL";
    CheckLimitBalanceAdjustmentTypeEnum["CARD_PAYMENT"] = "CARD_PAYMENT";
    CheckLimitBalanceAdjustmentTypeEnum["CONVENIENCE"] = "CONVENIENCE";
    CheckLimitBalanceAdjustmentTypeEnum["CRYPTO_REWARD"] = "CRYPTO_REWARD";
    CheckLimitBalanceAdjustmentTypeEnum["DEBIT_CARD_DEPOSIT_TO_WALLET"] = "DEBIT_CARD_DEPOSIT_TO_WALLET";
    CheckLimitBalanceAdjustmentTypeEnum["DEBIT_CARD_WITHDRAWAL_FROM_WALLET"] = "DEBIT_CARD_WITHDRAWAL_FROM_WALLET";
    CheckLimitBalanceAdjustmentTypeEnum["DEPOSIT_DISPUTE_ACCEPT"] = "DEPOSIT_DISPUTE_ACCEPT";
    CheckLimitBalanceAdjustmentTypeEnum["DEPOSIT_DISPUTE_PROVISIONAL_ACCEPT"] = "DEPOSIT_DISPUTE_PROVISIONAL_ACCEPT";
    CheckLimitBalanceAdjustmentTypeEnum["DEPOSIT_DISPUTE_PROVISIONAL_REJECT"] = "DEPOSIT_DISPUTE_PROVISIONAL_REJECT";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_PAYOUT_CASH"] = "PARTNER_PAYOUT_CASH";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_PAYOUT_CRYPTO"] = "PARTNER_PAYOUT_CRYPTO";
    CheckLimitBalanceAdjustmentTypeEnum["ACH_RETURN_FEE"] = "ACH_RETURN_FEE";
    CheckLimitBalanceAdjustmentTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    CheckLimitBalanceAdjustmentTypeEnum["INCENTIVE"] = "INCENTIVE";
    CheckLimitBalanceAdjustmentTypeEnum["FIAT_LEDGER_ADJUSTMENT_CREDIT"] = "FIAT_LEDGER_ADJUSTMENT_CREDIT";
    CheckLimitBalanceAdjustmentTypeEnum["FIAT_LEDGER_ADJUSTMENT_DEBIT"] = "FIAT_LEDGER_ADJUSTMENT_DEBIT";
    CheckLimitBalanceAdjustmentTypeEnum["LOYALTY_BUY"] = "LOYALTY_BUY";
    CheckLimitBalanceAdjustmentTypeEnum["LOYALTY_CONVERSION"] = "LOYALTY_CONVERSION";
    CheckLimitBalanceAdjustmentTypeEnum["LOYALTY_PAYMENTS_REDEEM"] = "LOYALTY_PAYMENTS_REDEEM";
    CheckLimitBalanceAdjustmentTypeEnum["LOYALTY_REDEEM"] = "LOYALTY_REDEEM";
    CheckLimitBalanceAdjustmentTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_REWARDS_REDEEM"] = "PARTNER_REWARDS_REDEEM";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_PAYOUT"] = "PARTNER_PAYOUT";
    CheckLimitBalanceAdjustmentTypeEnum["ACCOUNT_TRANSFER"] = "ACCOUNT_TRANSFER";
    CheckLimitBalanceAdjustmentTypeEnum["PERSON_TO_PERSON_PAYMENT"] = "PERSON_TO_PERSON_PAYMENT";
    CheckLimitBalanceAdjustmentTypeEnum["PROMOTIONAL"] = "PROMOTIONAL";
    CheckLimitBalanceAdjustmentTypeEnum["VENDOR_PAYMENT"] = "VENDOR_PAYMENT";
    CheckLimitBalanceAdjustmentTypeEnum["WITHDRAW_DISPUTE_ACCEPT"] = "WITHDRAW_DISPUTE_ACCEPT";
    CheckLimitBalanceAdjustmentTypeEnum["WITHDRAW_DISPUTE_PROVISIONAL_ACCEPT"] = "WITHDRAW_DISPUTE_PROVISIONAL_ACCEPT";
    CheckLimitBalanceAdjustmentTypeEnum["WITHDRAW_DISPUTE_PROVISIONAL_REJECT"] = "WITHDRAW_DISPUTE_PROVISIONAL_REJECT";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_PAYMENT_CREDIT"] = "PARTNER_PAYMENT_CREDIT";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_PAYMENT_DEBIT"] = "PARTNER_PAYMENT_DEBIT";
    CheckLimitBalanceAdjustmentTypeEnum["PARTNER_CRYPTO_BUY_SELL"] = "PARTNER_CRYPTO_BUY_SELL";
})(CheckLimitBalanceAdjustmentTypeEnum || (CheckLimitBalanceAdjustmentTypeEnum = {}));
/**
 * AddIdentifierApi - axios parameter creator
 * @export
 */
export const AddIdentifierApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Designed for users without account created yet.
         * @summary Add Identifier to non-Account
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier1: (apiAddIdentifierRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiAddIdentifierRequest' is not null or undefined
            assertParamExists('addIdentifier1', 'apiAddIdentifierRequest', apiAddIdentifierRequest);
            const localVarPath = `/aof/party/add-identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAddIdentifierRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AddIdentifierApi - functional programming interface
 * @export
 */
export const AddIdentifierApiFp = function (configuration) {
    const localVarAxiosParamCreator = AddIdentifierApiAxiosParamCreator(configuration);
    return {
        /**
         * Designed for users without account created yet.
         * @summary Add Identifier to non-Account
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier1(apiAddIdentifierRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addIdentifier1(apiAddIdentifierRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AddIdentifierApi.addIdentifier1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * AddIdentifierApi - factory interface
 * @export
 */
export const AddIdentifierApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AddIdentifierApiFp(configuration);
    return {
        /**
         * Designed for users without account created yet.
         * @summary Add Identifier to non-Account
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier1(apiAddIdentifierRequest, options) {
            return localVarFp.addIdentifier1(apiAddIdentifierRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AddIdentifierApi - object-oriented interface
 * @export
 * @class AddIdentifierApi
 * @extends {BaseAPI}
 */
export class AddIdentifierApi extends BaseAPI {
    /**
     * Designed for users without account created yet.
     * @summary Add Identifier to non-Account
     * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddIdentifierApi
     */
    addIdentifier1(apiAddIdentifierRequest, options) {
        return AddIdentifierApiFp(this.configuration).addIdentifier1(apiAddIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAchFile: (apiGenerateAchFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiGenerateAchFileRequest' is not null or undefined
            assertParamExists('generateAchFile', 'apiGenerateAchFileRequest', apiGenerateAchFileRequest);
            const localVarPath = `/aof/admin/ach/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiGenerateAchFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAchFileByPartyId: (partyId, apiGenerateAchFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('generateAchFileByPartyId', 'partyId', partyId);
            // verify required parameter 'apiGenerateAchFileRequest' is not null or undefined
            assertParamExists('generateAchFileByPartyId', 'apiGenerateAchFileRequest', apiGenerateAchFileRequest);
            const localVarPath = `/aof/admin/ach/party/{partyId}/generate`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiGenerateAchFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} achFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: (achFileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achFileId' is not null or undefined
            assertParamExists('getTransactions', 'achFileId', achFileId);
            const localVarPath = `/aof/admin/ach/transactions/{achFileId}`
                .replace(`{${"achFileId"}}`, encodeURIComponent(String(achFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAchFileFromBlobStorage: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/admin/ach-returns/batch-load`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAchFileFromFileSystem: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/admin/ach-returns/batch-load/file-system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAchReturns: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/admin/ach-returns/batch-process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} achFileId
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAchFile: (achFileId, apiGenerateAchFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achFileId' is not null or undefined
            assertParamExists('regenerateAchFile', 'achFileId', achFileId);
            // verify required parameter 'apiGenerateAchFileRequest' is not null or undefined
            assertParamExists('regenerateAchFile', 'apiGenerateAchFileRequest', apiGenerateAchFileRequest);
            const localVarPath = `/aof/admin/ach/regenerate/{achFileId}`
                .replace(`{${"achFileId"}}`, encodeURIComponent(String(achFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiGenerateAchFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} achFileDetailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAchFile1: (achFileDetailId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achFileDetailId' is not null or undefined
            assertParamExists('regenerateAchFile1', 'achFileDetailId', achFileDetailId);
            const localVarPath = `/aof/admin/ach-return/{achFileDetailId}`
                .replace(`{${"achFileDetailId"}}`, encodeURIComponent(String(achFileDetailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFile: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchAchFile', 'searchParameters', searchParameters);
            const localVarPath = `/aof/admin/ach-file/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchAchFileDetail', 'searchParameters', searchParameters);
            const localVarPath = `/aof/admin/ach-file-detail/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} fromDate
         * @param {string} toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReturnTransactions: (fromDate, toDate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('sendReturnTransactions', 'fromDate', fromDate);
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('sendReturnTransactions', 'toDate', toDate);
            const localVarPath = `/aof/admin/ach-return/transactions/batch-process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate instanceof Date) ?
                    fromDate.toISOString() :
                    fromDate;
            }
            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate instanceof Date) ?
                    toDate.toISOString() :
                    toDate;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAchFile(apiGenerateAchFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateAchFile(apiGenerateAchFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.generateAchFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAchFileByPartyId(partyId, apiGenerateAchFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateAchFileByPartyId(partyId, apiGenerateAchFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.generateAchFileByPartyId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} achFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(achFileId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTransactions(achFileId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.getTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAchFileFromBlobStorage(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.loadAchFileFromBlobStorage(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.loadAchFileFromBlobStorage']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAchFileFromFileSystem(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.loadAchFileFromFileSystem(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.loadAchFileFromFileSystem']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAchReturns(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.processAchReturns(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.processAchReturns']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} achFileId
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAchFile(achFileId, apiGenerateAchFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.regenerateAchFile(achFileId, apiGenerateAchFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.regenerateAchFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} achFileDetailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAchFile1(achFileDetailId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.regenerateAchFile1(achFileDetailId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.regenerateAchFile1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFile(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAchFile(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.searchAchFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAchFileDetail(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.searchAchFileDetail']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} fromDate
         * @param {string} toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReturnTransactions(fromDate, toDate, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendReturnTransactions(fromDate, toDate, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AdminApi.sendReturnTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AdminApiFp(configuration);
    return {
        /**
         *
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAchFile(apiGenerateAchFileRequest, options) {
            return localVarFp.generateAchFile(apiGenerateAchFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateAchFileByPartyId(partyId, apiGenerateAchFileRequest, options) {
            return localVarFp.generateAchFileByPartyId(partyId, apiGenerateAchFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} achFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions(achFileId, options) {
            return localVarFp.getTransactions(achFileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAchFileFromBlobStorage(options) {
            return localVarFp.loadAchFileFromBlobStorage(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAchFileFromFileSystem(options) {
            return localVarFp.loadAchFileFromFileSystem(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAchReturns(options) {
            return localVarFp.processAchReturns(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} achFileId
         * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAchFile(achFileId, apiGenerateAchFileRequest, options) {
            return localVarFp.regenerateAchFile(achFileId, apiGenerateAchFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} achFileDetailId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerateAchFile1(achFileDetailId, options) {
            return localVarFp.regenerateAchFile1(achFileDetailId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFile(searchParameters, options) {
            return localVarFp.searchAchFile(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail(searchParameters, options) {
            return localVarFp.searchAchFileDetail(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} fromDate
         * @param {string} toDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendReturnTransactions(fromDate, toDate, options) {
            return localVarFp.sendReturnTransactions(fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     *
     * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    generateAchFile(apiGenerateAchFileRequest, options) {
        return AdminApiFp(this.configuration).generateAchFile(apiGenerateAchFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    generateAchFileByPartyId(partyId, apiGenerateAchFileRequest, options) {
        return AdminApiFp(this.configuration).generateAchFileByPartyId(partyId, apiGenerateAchFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} achFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    getTransactions(achFileId, options) {
        return AdminApiFp(this.configuration).getTransactions(achFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    loadAchFileFromBlobStorage(options) {
        return AdminApiFp(this.configuration).loadAchFileFromBlobStorage(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    loadAchFileFromFileSystem(options) {
        return AdminApiFp(this.configuration).loadAchFileFromFileSystem(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    processAchReturns(options) {
        return AdminApiFp(this.configuration).processAchReturns(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} achFileId
     * @param {ApiGenerateAchFileRequest} apiGenerateAchFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    regenerateAchFile(achFileId, apiGenerateAchFileRequest, options) {
        return AdminApiFp(this.configuration).regenerateAchFile(achFileId, apiGenerateAchFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} achFileDetailId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    regenerateAchFile1(achFileDetailId, options) {
        return AdminApiFp(this.configuration).regenerateAchFile1(achFileDetailId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    searchAchFile(searchParameters, options) {
        return AdminApiFp(this.configuration).searchAchFile(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    searchAchFileDetail(searchParameters, options) {
        return AdminApiFp(this.configuration).searchAchFileDetail(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} fromDate
     * @param {string} toDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    sendReturnTransactions(fromDate, toDate, options) {
        return AdminApiFp(this.configuration).sendReturnTransactions(fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * AllowListManagementApi - axios parameter creator
 * @export
 */
export const AllowListManagementApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create a new AllowListItem for a given Allow List type and sub-type
         * @summary Add to Allow List
         * @param {ApiAllowListItem} apiAllowListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllowListItem: (apiAllowListItem, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiAllowListItem' is not null or undefined
            assertParamExists('createAllowListItem', 'apiAllowListItem', apiAllowListItem);
            const localVarPath = `/aof/allow-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAllowListItem, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Remove a given allowed list item
         * @summary Remove from Allow List
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllowListItem: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAllowListItem', 'id', id);
            const localVarPath = `/aof/allow-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Lookup a single Allow List Item
         * @summary Fetch Allow List Item
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllowListItem: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('fetchAllowListItem', 'id', id);
            const localVarPath = `/aof/allow-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * List Allow List Items for a given type and sub-type
         * @summary Search Allow List
         * @param {FindAllowListItemsAllowListItemTypeEnum} allowListItemType
         * @param {FindAllowListItemsAllowListItemSubtypeEnum} allowListItemSubtype
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllowListItems: (allowListItemType, allowListItemSubtype, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'allowListItemType' is not null or undefined
            assertParamExists('findAllowListItems', 'allowListItemType', allowListItemType);
            // verify required parameter 'allowListItemSubtype' is not null or undefined
            assertParamExists('findAllowListItems', 'allowListItemSubtype', allowListItemSubtype);
            const localVarPath = `/aof/allow-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (allowListItemType !== undefined) {
                localVarQueryParameter['allowListItemType'] = allowListItemType;
            }
            if (allowListItemSubtype !== undefined) {
                localVarQueryParameter['allowListItemSubtype'] = allowListItemSubtype;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Import a list of Allow List Items from a file input
         * @summary Import Allow List File
         * @param {ImportAllowListItemFileRequest} [importAllowListItemFileRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAllowListItemFile: (importAllowListItemFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/allow-list/import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(importAllowListItemFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllowListItems: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchAllowListItems', 'searchParameters', searchParameters);
            const localVarPath = `/aof/allow-list/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update the value of a given allow list item
         * @summary Update Allow List Item
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {ApiAllowListItem} apiAllowListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllowListItem: (id, apiAllowListItem, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAllowListItem', 'id', id);
            // verify required parameter 'apiAllowListItem' is not null or undefined
            assertParamExists('updateAllowListItem', 'apiAllowListItem', apiAllowListItem);
            const localVarPath = `/aof/allow-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAllowListItem, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AllowListManagementApi - functional programming interface
 * @export
 */
export const AllowListManagementApiFp = function (configuration) {
    const localVarAxiosParamCreator = AllowListManagementApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a new AllowListItem for a given Allow List type and sub-type
         * @summary Add to Allow List
         * @param {ApiAllowListItem} apiAllowListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllowListItem(apiAllowListItem, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createAllowListItem(apiAllowListItem, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.createAllowListItem']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Remove a given allowed list item
         * @summary Remove from Allow List
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllowListItem(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAllowListItem(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.deleteAllowListItem']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Lookup a single Allow List Item
         * @summary Fetch Allow List Item
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllowListItem(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchAllowListItem(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.fetchAllowListItem']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * List Allow List Items for a given type and sub-type
         * @summary Search Allow List
         * @param {FindAllowListItemsAllowListItemTypeEnum} allowListItemType
         * @param {FindAllowListItemsAllowListItemSubtypeEnum} allowListItemSubtype
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllowListItems(allowListItemType, allowListItemSubtype, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.findAllowListItems(allowListItemType, allowListItemSubtype, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.findAllowListItems']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Import a list of Allow List Items from a file input
         * @summary Import Allow List File
         * @param {ImportAllowListItemFileRequest} [importAllowListItemFileRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAllowListItemFile(importAllowListItemFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.importAllowListItemFile(importAllowListItemFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.importAllowListItemFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllowListItems(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAllowListItems(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.searchAllowListItems']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Update the value of a given allow list item
         * @summary Update Allow List Item
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {ApiAllowListItem} apiAllowListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllowListItem(id, apiAllowListItem, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateAllowListItem(id, apiAllowListItem, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['AllowListManagementApi.updateAllowListItem']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * AllowListManagementApi - factory interface
 * @export
 */
export const AllowListManagementApiFactory = function (configuration, basePath, axios) {
    const localVarFp = AllowListManagementApiFp(configuration);
    return {
        /**
         * Create a new AllowListItem for a given Allow List type and sub-type
         * @summary Add to Allow List
         * @param {ApiAllowListItem} apiAllowListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAllowListItem(apiAllowListItem, options) {
            return localVarFp.createAllowListItem(apiAllowListItem, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove a given allowed list item
         * @summary Remove from Allow List
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllowListItem(id, options) {
            return localVarFp.deleteAllowListItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Lookup a single Allow List Item
         * @summary Fetch Allow List Item
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllowListItem(id, options) {
            return localVarFp.fetchAllowListItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List Allow List Items for a given type and sub-type
         * @summary Search Allow List
         * @param {FindAllowListItemsAllowListItemTypeEnum} allowListItemType
         * @param {FindAllowListItemsAllowListItemSubtypeEnum} allowListItemSubtype
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllowListItems(allowListItemType, allowListItemSubtype, options) {
            return localVarFp.findAllowListItems(allowListItemType, allowListItemSubtype, options).then((request) => request(axios, basePath));
        },
        /**
         * Import a list of Allow List Items from a file input
         * @summary Import Allow List File
         * @param {ImportAllowListItemFileRequest} [importAllowListItemFileRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importAllowListItemFile(importAllowListItemFileRequest, options) {
            return localVarFp.importAllowListItemFile(importAllowListItemFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAllowListItems(searchParameters, options) {
            return localVarFp.searchAllowListItems(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the value of a given allow list item
         * @summary Update Allow List Item
         * @param {string} id The unique identifier for the Allow List Item. Primary key.
         * @param {ApiAllowListItem} apiAllowListItem
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllowListItem(id, apiAllowListItem, options) {
            return localVarFp.updateAllowListItem(id, apiAllowListItem, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AllowListManagementApi - object-oriented interface
 * @export
 * @class AllowListManagementApi
 * @extends {BaseAPI}
 */
export class AllowListManagementApi extends BaseAPI {
    /**
     * Create a new AllowListItem for a given Allow List type and sub-type
     * @summary Add to Allow List
     * @param {ApiAllowListItem} apiAllowListItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    createAllowListItem(apiAllowListItem, options) {
        return AllowListManagementApiFp(this.configuration).createAllowListItem(apiAllowListItem, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Remove a given allowed list item
     * @summary Remove from Allow List
     * @param {string} id The unique identifier for the Allow List Item. Primary key.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    deleteAllowListItem(id, options) {
        return AllowListManagementApiFp(this.configuration).deleteAllowListItem(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Lookup a single Allow List Item
     * @summary Fetch Allow List Item
     * @param {string} id The unique identifier for the Allow List Item. Primary key.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    fetchAllowListItem(id, options) {
        return AllowListManagementApiFp(this.configuration).fetchAllowListItem(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * List Allow List Items for a given type and sub-type
     * @summary Search Allow List
     * @param {FindAllowListItemsAllowListItemTypeEnum} allowListItemType
     * @param {FindAllowListItemsAllowListItemSubtypeEnum} allowListItemSubtype
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    findAllowListItems(allowListItemType, allowListItemSubtype, options) {
        return AllowListManagementApiFp(this.configuration).findAllowListItems(allowListItemType, allowListItemSubtype, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Import a list of Allow List Items from a file input
     * @summary Import Allow List File
     * @param {ImportAllowListItemFileRequest} [importAllowListItemFileRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    importAllowListItemFile(importAllowListItemFileRequest, options) {
        return AllowListManagementApiFp(this.configuration).importAllowListItemFile(importAllowListItemFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    searchAllowListItems(searchParameters, options) {
        return AllowListManagementApiFp(this.configuration).searchAllowListItems(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the value of a given allow list item
     * @summary Update Allow List Item
     * @param {string} id The unique identifier for the Allow List Item. Primary key.
     * @param {ApiAllowListItem} apiAllowListItem
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllowListManagementApi
     */
    updateAllowListItem(id, apiAllowListItem, options) {
        return AllowListManagementApiFp(this.configuration).updateAllowListItem(id, apiAllowListItem, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var FindAllowListItemsAllowListItemTypeEnum;
(function (FindAllowListItemsAllowListItemTypeEnum) {
    FindAllowListItemsAllowListItemTypeEnum["IDENTIFIER"] = "IDENTIFIER";
    FindAllowListItemsAllowListItemTypeEnum["ADDRESS"] = "ADDRESS";
    FindAllowListItemsAllowListItemTypeEnum["BATCH_P2P"] = "BATCH_P2P";
})(FindAllowListItemsAllowListItemTypeEnum || (FindAllowListItemsAllowListItemTypeEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var FindAllowListItemsAllowListItemSubtypeEnum;
(function (FindAllowListItemsAllowListItemSubtypeEnum) {
    FindAllowListItemsAllowListItemSubtypeEnum["EMAIL"] = "EMAIL";
    FindAllowListItemsAllowListItemSubtypeEnum["PHONE"] = "PHONE";
    FindAllowListItemsAllowListItemSubtypeEnum["PARTY_ID"] = "PARTY_ID";
    FindAllowListItemsAllowListItemSubtypeEnum["REGION"] = "REGION";
})(FindAllowListItemsAllowListItemSubtypeEnum || (FindAllowListItemsAllowListItemSubtypeEnum = {}));
/**
 * BankAccountApi - axios parameter creator
 * @export
 */
export const BankAccountApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Links a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
         * @summary Create Bank Account
         * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccount: (apiCreateBankAccountRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCreateBankAccountRequest' is not null or undefined
            assertParamExists('createBankAccount', 'apiCreateBankAccountRequest', apiCreateBankAccountRequest);
            const localVarPath = `/aof/bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreateBankAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create Plaid Event
         * @param {ApiPlaidEvent} apiPlaidEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaidEvent: (apiPlaidEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPlaidEvent' is not null or undefined
            assertParamExists('createPlaidEvent', 'apiPlaidEvent', apiPlaidEvent);
            const localVarPath = `/aof/bank-account/plaid-event`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPlaidEvent, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete Plaid Account
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaidAccount: (plaidAccountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'plaidAccountId' is not null or undefined
            assertParamExists('deletePlaidAccount', 'plaidAccountId', plaidAccountId);
            const localVarPath = `/aof/bank-account/plaid-account/{plaidAccountId}`
                .replace(`{${"plaidAccountId"}}`, encodeURIComponent(String(plaidAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Expire the outstanding pending bank account if one exists for the selected party
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('expirePendingBankAccount', 'partyId', partyId);
            const localVarPath = `/aof/bank-account/verification/expiration/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount1: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/bank-account/pending-bank-account/expire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
         * @summary Get Bank Accounts
         * @param {GetBankAccountsStatusEnum} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccounts: (status, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
         * @summary Get Link Token
         * @param {ApiLinkTokenRequest} apiLinkTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken: (apiLinkTokenRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiLinkTokenRequest' is not null or undefined
            assertParamExists('getLinkToken', 'apiLinkTokenRequest', apiLinkTokenRequest);
            const localVarPath = `/aof/link-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiLinkTokenRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Token created by Plaid to initialize Link on client applications.
         * @summary Get Link Token
         * @param {boolean} [forUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken1: (forUpdate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/link-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (forUpdate !== undefined) {
                localVarQueryParameter['forUpdate'] = forUpdate;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get bank accounts pending verification.
         * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingBankAccounts: (verificationStatus, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/bank-account/pending-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (verificationStatus) {
                localVarQueryParameter['verificationStatus'] = Array.from(verificationStatus);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create Plaid link failure Event
         * @param {ApiLinkFailurePlaidEvent} apiLinkFailurePlaidEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkFailurePlaidEvent: (apiLinkFailurePlaidEvent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiLinkFailurePlaidEvent' is not null or undefined
            assertParamExists('linkFailurePlaidEvent', 'apiLinkFailurePlaidEvent', apiLinkFailurePlaidEvent);
            const localVarPath = `/aof/bank-account/plaid-event/link/failure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiLinkFailurePlaidEvent, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary This method to run bank kyc on existing bank account
         * @param {string} partyId
         * @param {string} [partnerId]
         * @param {PerformBankKycPartnerRiskProductEnum} [partnerRiskProduct]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performBankKyc: (partyId, partnerId, partnerRiskProduct, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('performBankKyc', 'partyId', partyId);
            const localVarPath = `/aof/bank-account/party/{partyId}/bank-kyc`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }
            if (partnerRiskProduct !== undefined) {
                localVarQueryParameter['partnerRiskProduct'] = partnerRiskProduct;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Initialize migration of bank/institution to OAuth
         * @param {ApiBankAuthorizationNotification} apiBankAuthorizationNotification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueBankMigration: (apiBankAuthorizationNotification, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBankAuthorizationNotification' is not null or undefined
            assertParamExists('queueBankMigration', 'apiBankAuthorizationNotification', apiBankAuthorizationNotification);
            const localVarPath = `/aof/bank-account/migration/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBankAuthorizationNotification, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove Bank Account
         * @param {ApiRemoveAccountRequest} apiRemoveAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBankAccount: (apiRemoveAccountRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiRemoveAccountRequest' is not null or undefined
            assertParamExists('removeBankAccount', 'apiRemoveAccountRequest', apiRemoveAccountRequest);
            const localVarPath = `/aof/bank-account/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiRemoveAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Search Plaid Account
         * @param {SearchParameters} searchParameters
         * @param {boolean} [includeEncrypted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaidAccount: (searchParameters, includeEncrypted, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchPlaidAccount', 'searchParameters', searchParameters);
            const localVarPath = `/aof/bank-account/plaid-account/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (includeEncrypted !== undefined) {
                localVarQueryParameter['includeEncrypted'] = includeEncrypted;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Search Plaid Events
         * @param {SearchParameters} searchParameters
         * @param {boolean} [includeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaidEvents: (searchParameters, includeRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchPlaidEvents', 'searchParameters', searchParameters);
            const localVarPath = `/aof/bank-account/plaid-event/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (includeRequest !== undefined) {
                localVarQueryParameter['includeRequest'] = includeRequest;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Transact Bank Account
         * @param {ApiTransactBankAccountRequest} apiTransactBankAccountRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount1: (apiTransactBankAccountRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiTransactBankAccountRequest' is not null or undefined
            assertParamExists('transactBankAccount1', 'apiTransactBankAccountRequest', apiTransactBankAccountRequest);
            const localVarPath = `/aof/bank-account/transact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTransactBankAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Unlink Bank Account for specified Party and Account
         * @param {string} partyId
         * @param {string} accountId
         * @param {string} [partnerId]
         * @param {boolean} [silent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkBankAccountAsAdministrator: (partyId, accountId, partnerId, silent, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('unlinkBankAccountAsAdministrator', 'partyId', partyId);
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('unlinkBankAccountAsAdministrator', 'accountId', accountId);
            const localVarPath = `/aof/bank-account/party/{partyId}/account/{accountId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }
            if (silent !== undefined) {
                localVarQueryParameter['silent'] = silent;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Updates the LinkStatus between the bank account and plaid
         * @summary Update LinkStatus for the BankAccount
         * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLinkStatus: (apiBankAccountLinkStatus, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBankAccountLinkStatus' is not null or undefined
            assertParamExists('updateAccountLinkStatus', 'apiBankAccountLinkStatus', apiBankAccountLinkStatus);
            const localVarPath = `/aof/bank-account/link-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBankAccountLinkStatus, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary This method to update bank information on existing bank account
         * @param {string} partnerId
         * @param {Array<string>} partyIds
         * @param {UpdateBankAccountRequest} updateBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankAccount: (partnerId, partyIds, updateBankAccountRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updateBankAccount', 'partnerId', partnerId);
            // verify required parameter 'partyIds' is not null or undefined
            assertParamExists('updateBankAccount', 'partyIds', partyIds);
            // verify required parameter 'updateBankAccountRequest' is not null or undefined
            assertParamExists('updateBankAccount', 'updateBankAccountRequest', updateBankAccountRequest);
            const localVarPath = `/aof/bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (partnerId !== undefined) {
                localVarQueryParameter['partnerId'] = partnerId;
            }
            if (partyIds) {
                localVarQueryParameter['partyIds'] = partyIds;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(updateBankAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update Plaid Account
         * @param {ApiPlaidAccount} apiPlaidAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaidAccount: (apiPlaidAccount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPlaidAccount' is not null or undefined
            assertParamExists('updatePlaidAccount', 'apiPlaidAccount', apiPlaidAccount);
            const localVarPath = `/aof/bank-account/plaid-account/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPlaidAccount, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Verify and Activate Pending Bank Account
         * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivatePendingBankAccount: (apiBankAccountLinkStatusUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBankAccountLinkStatusUpdateRequest' is not null or undefined
            assertParamExists('verifyAndActivatePendingBankAccount', 'apiBankAccountLinkStatusUpdateRequest', apiBankAccountLinkStatusUpdateRequest);
            const localVarPath = `/aof/bank-account/verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBankAccountLinkStatusUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BankAccountApi - functional programming interface
 * @export
 */
export const BankAccountApiFp = function (configuration) {
    const localVarAxiosParamCreator = BankAccountApiAxiosParamCreator(configuration);
    return {
        /**
         * Links a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
         * @summary Create Bank Account
         * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccount(apiCreateBankAccountRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createBankAccount(apiCreateBankAccountRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.createBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Create Plaid Event
         * @param {ApiPlaidEvent} apiPlaidEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaidEvent(apiPlaidEvent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createPlaidEvent(apiPlaidEvent, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.createPlaidEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Delete Plaid Account
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaidAccount(plaidAccountId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePlaidAccount(plaidAccountId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.deletePlaidAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Expire the outstanding pending bank account if one exists for the selected party
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.expirePendingBankAccount(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.expirePendingBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount1(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.expirePendingBankAccount1(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.expirePendingBankAccount1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
         * @summary Get Bank Accounts
         * @param {GetBankAccountsStatusEnum} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccounts(status, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getBankAccounts(status, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.getBankAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
         * @summary Get Link Token
         * @param {ApiLinkTokenRequest} apiLinkTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken(apiLinkTokenRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLinkToken(apiLinkTokenRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.getLinkToken']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Token created by Plaid to initialize Link on client applications.
         * @summary Get Link Token
         * @param {boolean} [forUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken1(forUpdate, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLinkToken1(forUpdate, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.getLinkToken1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get bank accounts pending verification.
         * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingBankAccounts(verificationStatus, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPendingBankAccounts(verificationStatus, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.getPendingBankAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Create Plaid link failure Event
         * @param {ApiLinkFailurePlaidEvent} apiLinkFailurePlaidEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkFailurePlaidEvent(apiLinkFailurePlaidEvent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.linkFailurePlaidEvent(apiLinkFailurePlaidEvent, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.linkFailurePlaidEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary This method to run bank kyc on existing bank account
         * @param {string} partyId
         * @param {string} [partnerId]
         * @param {PerformBankKycPartnerRiskProductEnum} [partnerRiskProduct]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performBankKyc(partyId, partnerId, partnerRiskProduct, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.performBankKyc(partyId, partnerId, partnerRiskProduct, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.performBankKyc']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Initialize migration of bank/institution to OAuth
         * @param {ApiBankAuthorizationNotification} apiBankAuthorizationNotification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueBankMigration(apiBankAuthorizationNotification, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.queueBankMigration(apiBankAuthorizationNotification, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.queueBankMigration']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Remove Bank Account
         * @param {ApiRemoveAccountRequest} apiRemoveAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBankAccount(apiRemoveAccountRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeBankAccount(apiRemoveAccountRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.removeBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Search Plaid Account
         * @param {SearchParameters} searchParameters
         * @param {boolean} [includeEncrypted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaidAccount(searchParameters, includeEncrypted, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchPlaidAccount(searchParameters, includeEncrypted, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.searchPlaidAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Search Plaid Events
         * @param {SearchParameters} searchParameters
         * @param {boolean} [includeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaidEvents(searchParameters, includeRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchPlaidEvents(searchParameters, includeRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.searchPlaidEvents']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Transact Bank Account
         * @param {ApiTransactBankAccountRequest} apiTransactBankAccountRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount1(apiTransactBankAccountRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.transactBankAccount1(apiTransactBankAccountRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.transactBankAccount1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Unlink Bank Account for specified Party and Account
         * @param {string} partyId
         * @param {string} accountId
         * @param {string} [partnerId]
         * @param {boolean} [silent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkBankAccountAsAdministrator(partyId, accountId, partnerId, silent, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unlinkBankAccountAsAdministrator(partyId, accountId, partnerId, silent, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.unlinkBankAccountAsAdministrator']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Updates the LinkStatus between the bank account and plaid
         * @summary Update LinkStatus for the BankAccount
         * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLinkStatus(apiBankAccountLinkStatus, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateAccountLinkStatus(apiBankAccountLinkStatus, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.updateAccountLinkStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary This method to update bank information on existing bank account
         * @param {string} partnerId
         * @param {Array<string>} partyIds
         * @param {UpdateBankAccountRequest} updateBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankAccount(partnerId, partyIds, updateBankAccountRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateBankAccount(partnerId, partyIds, updateBankAccountRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.updateBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update Plaid Account
         * @param {ApiPlaidAccount} apiPlaidAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaidAccount(apiPlaidAccount, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePlaidAccount(apiPlaidAccount, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.updatePlaidAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Verify and Activate Pending Bank Account
         * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountApi.verifyAndActivatePendingBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BankAccountApi - factory interface
 * @export
 */
export const BankAccountApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BankAccountApiFp(configuration);
    return {
        /**
         * Links a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
         * @summary Create Bank Account
         * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBankAccount(apiCreateBankAccountRequest, options) {
            return localVarFp.createBankAccount(apiCreateBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Plaid Event
         * @param {ApiPlaidEvent} apiPlaidEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlaidEvent(apiPlaidEvent, options) {
            return localVarFp.createPlaidEvent(apiPlaidEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete Plaid Account
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlaidAccount(plaidAccountId, options) {
            return localVarFp.deletePlaidAccount(plaidAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Expire the outstanding pending bank account if one exists for the selected party
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount(partyId, options) {
            return localVarFp.expirePendingBankAccount(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expirePendingBankAccount1(options) {
            return localVarFp.expirePendingBankAccount1(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
         * @summary Get Bank Accounts
         * @param {GetBankAccountsStatusEnum} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccounts(status, options) {
            return localVarFp.getBankAccounts(status, options).then((request) => request(axios, basePath));
        },
        /**
         * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
         * @summary Get Link Token
         * @param {ApiLinkTokenRequest} apiLinkTokenRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken(apiLinkTokenRequest, options) {
            return localVarFp.getLinkToken(apiLinkTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Token created by Plaid to initialize Link on client applications.
         * @summary Get Link Token
         * @param {boolean} [forUpdate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinkToken1(forUpdate, options) {
            return localVarFp.getLinkToken1(forUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get bank accounts pending verification.
         * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingBankAccounts(verificationStatus, options) {
            return localVarFp.getPendingBankAccounts(verificationStatus, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Plaid link failure Event
         * @param {ApiLinkFailurePlaidEvent} apiLinkFailurePlaidEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkFailurePlaidEvent(apiLinkFailurePlaidEvent, options) {
            return localVarFp.linkFailurePlaidEvent(apiLinkFailurePlaidEvent, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary This method to run bank kyc on existing bank account
         * @param {string} partyId
         * @param {string} [partnerId]
         * @param {PerformBankKycPartnerRiskProductEnum} [partnerRiskProduct]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performBankKyc(partyId, partnerId, partnerRiskProduct, options) {
            return localVarFp.performBankKyc(partyId, partnerId, partnerRiskProduct, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Initialize migration of bank/institution to OAuth
         * @param {ApiBankAuthorizationNotification} apiBankAuthorizationNotification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queueBankMigration(apiBankAuthorizationNotification, options) {
            return localVarFp.queueBankMigration(apiBankAuthorizationNotification, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove Bank Account
         * @param {ApiRemoveAccountRequest} apiRemoveAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBankAccount(apiRemoveAccountRequest, options) {
            return localVarFp.removeBankAccount(apiRemoveAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Plaid Account
         * @param {SearchParameters} searchParameters
         * @param {boolean} [includeEncrypted]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaidAccount(searchParameters, includeEncrypted, options) {
            return localVarFp.searchPlaidAccount(searchParameters, includeEncrypted, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Plaid Events
         * @param {SearchParameters} searchParameters
         * @param {boolean} [includeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPlaidEvents(searchParameters, includeRequest, options) {
            return localVarFp.searchPlaidEvents(searchParameters, includeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Transact Bank Account
         * @param {ApiTransactBankAccountRequest} apiTransactBankAccountRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount1(apiTransactBankAccountRequest, xTrackingId, options) {
            return localVarFp.transactBankAccount1(apiTransactBankAccountRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Unlink Bank Account for specified Party and Account
         * @param {string} partyId
         * @param {string} accountId
         * @param {string} [partnerId]
         * @param {boolean} [silent]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkBankAccountAsAdministrator(partyId, accountId, partnerId, silent, options) {
            return localVarFp.unlinkBankAccountAsAdministrator(partyId, accountId, partnerId, silent, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the LinkStatus between the bank account and plaid
         * @summary Update LinkStatus for the BankAccount
         * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccountLinkStatus(apiBankAccountLinkStatus, options) {
            return localVarFp.updateAccountLinkStatus(apiBankAccountLinkStatus, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary This method to update bank information on existing bank account
         * @param {string} partnerId
         * @param {Array<string>} partyIds
         * @param {UpdateBankAccountRequest} updateBankAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBankAccount(partnerId, partyIds, updateBankAccountRequest, options) {
            return localVarFp.updateBankAccount(partnerId, partyIds, updateBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Plaid Account
         * @param {ApiPlaidAccount} apiPlaidAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlaidAccount(apiPlaidAccount, options) {
            return localVarFp.updatePlaidAccount(apiPlaidAccount, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Verify and Activate Pending Bank Account
         * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options) {
            return localVarFp.verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BankAccountApi - object-oriented interface
 * @export
 * @class BankAccountApi
 * @extends {BaseAPI}
 */
export class BankAccountApi extends BaseAPI {
    /**
     * Links a new bank account via Plaid. Calls Plaid to get the account details, creates the account in AoF and fetches the Plaid Institution for the account if we don\'t have it already or if it\'s out of date.
     * @summary Create Bank Account
     * @param {ApiCreateBankAccountRequest} apiCreateBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    createBankAccount(apiCreateBankAccountRequest, options) {
        return BankAccountApiFp(this.configuration).createBankAccount(apiCreateBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create Plaid Event
     * @param {ApiPlaidEvent} apiPlaidEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    createPlaidEvent(apiPlaidEvent, options) {
        return BankAccountApiFp(this.configuration).createPlaidEvent(apiPlaidEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete Plaid Account
     * @param {string} plaidAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    deletePlaidAccount(plaidAccountId, options) {
        return BankAccountApiFp(this.configuration).deletePlaidAccount(plaidAccountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Expire the outstanding pending bank account if one exists for the selected party
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    expirePendingBankAccount(partyId, options) {
        return BankAccountApiFp(this.configuration).expirePendingBankAccount(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    expirePendingBankAccount1(options) {
        return BankAccountApiFp(this.configuration).expirePendingBankAccount1(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a list of accounts that have been linked in Plaid (i.e. account.plaid is non-null) for a given status, if any.  If status is not passed the active and all suspended Plaid accounts will be returned.
     * @summary Get Bank Accounts
     * @param {GetBankAccountsStatusEnum} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    getBankAccounts(status, options) {
        return BankAccountApiFp(this.configuration).getBankAccounts(status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * new type of token that is created by your app\'s server and passed to your app\'s client to initialize Link.
     * @summary Get Link Token
     * @param {ApiLinkTokenRequest} apiLinkTokenRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    getLinkToken(apiLinkTokenRequest, options) {
        return BankAccountApiFp(this.configuration).getLinkToken(apiLinkTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Token created by Plaid to initialize Link on client applications.
     * @summary Get Link Token
     * @param {boolean} [forUpdate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    getLinkToken1(forUpdate, options) {
        return BankAccountApiFp(this.configuration).getLinkToken1(forUpdate, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get bank accounts pending verification.
     * @param {Set<GetPendingBankAccountsVerificationStatusEnum>} [verificationStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    getPendingBankAccounts(verificationStatus, options) {
        return BankAccountApiFp(this.configuration).getPendingBankAccounts(verificationStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create Plaid link failure Event
     * @param {ApiLinkFailurePlaidEvent} apiLinkFailurePlaidEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    linkFailurePlaidEvent(apiLinkFailurePlaidEvent, options) {
        return BankAccountApiFp(this.configuration).linkFailurePlaidEvent(apiLinkFailurePlaidEvent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary This method to run bank kyc on existing bank account
     * @param {string} partyId
     * @param {string} [partnerId]
     * @param {PerformBankKycPartnerRiskProductEnum} [partnerRiskProduct]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    performBankKyc(partyId, partnerId, partnerRiskProduct, options) {
        return BankAccountApiFp(this.configuration).performBankKyc(partyId, partnerId, partnerRiskProduct, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Initialize migration of bank/institution to OAuth
     * @param {ApiBankAuthorizationNotification} apiBankAuthorizationNotification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    queueBankMigration(apiBankAuthorizationNotification, options) {
        return BankAccountApiFp(this.configuration).queueBankMigration(apiBankAuthorizationNotification, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove Bank Account
     * @param {ApiRemoveAccountRequest} apiRemoveAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    removeBankAccount(apiRemoveAccountRequest, options) {
        return BankAccountApiFp(this.configuration).removeBankAccount(apiRemoveAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Search Plaid Account
     * @param {SearchParameters} searchParameters
     * @param {boolean} [includeEncrypted]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    searchPlaidAccount(searchParameters, includeEncrypted, options) {
        return BankAccountApiFp(this.configuration).searchPlaidAccount(searchParameters, includeEncrypted, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Search Plaid Events
     * @param {SearchParameters} searchParameters
     * @param {boolean} [includeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    searchPlaidEvents(searchParameters, includeRequest, options) {
        return BankAccountApiFp(this.configuration).searchPlaidEvents(searchParameters, includeRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Transact Bank Account
     * @param {ApiTransactBankAccountRequest} apiTransactBankAccountRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    transactBankAccount1(apiTransactBankAccountRequest, xTrackingId, options) {
        return BankAccountApiFp(this.configuration).transactBankAccount1(apiTransactBankAccountRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Unlink Bank Account for specified Party and Account
     * @param {string} partyId
     * @param {string} accountId
     * @param {string} [partnerId]
     * @param {boolean} [silent]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    unlinkBankAccountAsAdministrator(partyId, accountId, partnerId, silent, options) {
        return BankAccountApiFp(this.configuration).unlinkBankAccountAsAdministrator(partyId, accountId, partnerId, silent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates the LinkStatus between the bank account and plaid
     * @summary Update LinkStatus for the BankAccount
     * @param {ApiBankAccountLinkStatus} apiBankAccountLinkStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    updateAccountLinkStatus(apiBankAccountLinkStatus, options) {
        return BankAccountApiFp(this.configuration).updateAccountLinkStatus(apiBankAccountLinkStatus, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary This method to update bank information on existing bank account
     * @param {string} partnerId
     * @param {Array<string>} partyIds
     * @param {UpdateBankAccountRequest} updateBankAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    updateBankAccount(partnerId, partyIds, updateBankAccountRequest, options) {
        return BankAccountApiFp(this.configuration).updateBankAccount(partnerId, partyIds, updateBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update Plaid Account
     * @param {ApiPlaidAccount} apiPlaidAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    updatePlaidAccount(apiPlaidAccount, options) {
        return BankAccountApiFp(this.configuration).updatePlaidAccount(apiPlaidAccount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Verify and Activate Pending Bank Account
     * @param {ApiBankAccountLinkStatusUpdateRequest} apiBankAccountLinkStatusUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountApi
     */
    verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options) {
        return BankAccountApiFp(this.configuration).verifyAndActivatePendingBankAccount(apiBankAccountLinkStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var GetBankAccountsStatusEnum;
(function (GetBankAccountsStatusEnum) {
    GetBankAccountsStatusEnum["ACTIVE"] = "ACTIVE";
    GetBankAccountsStatusEnum["PENDING"] = "PENDING";
    GetBankAccountsStatusEnum["SUSPENDED"] = "SUSPENDED";
})(GetBankAccountsStatusEnum || (GetBankAccountsStatusEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var GetPendingBankAccountsVerificationStatusEnum;
(function (GetPendingBankAccountsVerificationStatusEnum) {
    GetPendingBankAccountsVerificationStatusEnum["COMPLETE"] = "COMPLETE";
    GetPendingBankAccountsVerificationStatusEnum["EXPIRED"] = "EXPIRED";
    GetPendingBankAccountsVerificationStatusEnum["PENDING"] = "PENDING";
})(GetPendingBankAccountsVerificationStatusEnum || (GetPendingBankAccountsVerificationStatusEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var PerformBankKycPartnerRiskProductEnum;
(function (PerformBankKycPartnerRiskProductEnum) {
    PerformBankKycPartnerRiskProductEnum["DEFAULT"] = "DEFAULT";
    PerformBankKycPartnerRiskProductEnum["BAKKT_APP"] = "BAKKT_APP";
    PerformBankKycPartnerRiskProductEnum["CRYPTO_AS_SERVICE"] = "CRYPTO_AS_SERVICE";
    PerformBankKycPartnerRiskProductEnum["EMBEDDED_CRYPTO_AS_SERVICE"] = "EMBEDDED_CRYPTO_AS_SERVICE";
    PerformBankKycPartnerRiskProductEnum["CRYPTO_CARD"] = "CRYPTO_CARD";
    PerformBankKycPartnerRiskProductEnum["CRYPTO_REWARDS"] = "CRYPTO_REWARDS";
    PerformBankKycPartnerRiskProductEnum["DISBURSEMENTS"] = "DISBURSEMENTS";
    PerformBankKycPartnerRiskProductEnum["PARTY"] = "PARTY";
    PerformBankKycPartnerRiskProductEnum["PAYOUTS"] = "PAYOUTS";
    PerformBankKycPartnerRiskProductEnum["FIAT_FUNDING"] = "FIAT_FUNDING";
})(PerformBankKycPartnerRiskProductEnum || (PerformBankKycPartnerRiskProductEnum = {}));
/**
 * BankAccountPatchesApi - axios parameter creator
 * @export
 */
export const BankAccountPatchesApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Patch Transact Bank Account
         * @param {string} partyId
         * @param {boolean} applyChanges
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount: (partyId, applyChanges, fromDate, toDate, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('transactBankAccount', 'partyId', partyId);
            // verify required parameter 'applyChanges' is not null or undefined
            assertParamExists('transactBankAccount', 'applyChanges', applyChanges);
            const localVarPath = `/aof/patch/bank-account/transact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (partyId !== undefined) {
                localVarQueryParameter['partyId'] = partyId;
            }
            if (applyChanges !== undefined) {
                localVarQueryParameter['applyChanges'] = applyChanges;
            }
            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate instanceof Date) ?
                    fromDate.toISOString() :
                    fromDate;
            }
            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate instanceof Date) ?
                    toDate.toISOString() :
                    toDate;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BankAccountPatchesApi - functional programming interface
 * @export
 */
export const BankAccountPatchesApiFp = function (configuration) {
    const localVarAxiosParamCreator = BankAccountPatchesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Patch Transact Bank Account
         * @param {string} partyId
         * @param {boolean} applyChanges
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount(partyId, applyChanges, fromDate, toDate, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.transactBankAccount(partyId, applyChanges, fromDate, toDate, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankAccountPatchesApi.transactBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BankAccountPatchesApi - factory interface
 * @export
 */
export const BankAccountPatchesApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BankAccountPatchesApiFp(configuration);
    return {
        /**
         *
         * @summary Patch Transact Bank Account
         * @param {string} partyId
         * @param {boolean} applyChanges
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactBankAccount(partyId, applyChanges, fromDate, toDate, options) {
            return localVarFp.transactBankAccount(partyId, applyChanges, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BankAccountPatchesApi - object-oriented interface
 * @export
 * @class BankAccountPatchesApi
 * @extends {BaseAPI}
 */
export class BankAccountPatchesApi extends BaseAPI {
    /**
     *
     * @summary Patch Transact Bank Account
     * @param {string} partyId
     * @param {boolean} applyChanges
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountPatchesApi
     */
    transactBankAccount(partyId, applyChanges, fromDate, toDate, options) {
        return BankAccountPatchesApiFp(this.configuration).transactBankAccount(partyId, applyChanges, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BankRestrictionsApi - axios parameter creator
 * @export
 */
export const BankRestrictionsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add bank with restriction status
         * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankWithRestrictionStatus: (apiPlaidInstitutionRestrictionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPlaidInstitutionRestrictionRequest' is not null or undefined
            assertParamExists('addBankWithRestrictionStatus', 'apiPlaidInstitutionRestrictionRequest', apiPlaidInstitutionRestrictionRequest);
            const localVarPath = `/aof/bank-restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPlaidInstitutionRestrictionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Modify bank and/or restriction status
         * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBankAndOrRestrictionStatus: (apiPlaidInstitutionRestrictionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPlaidInstitutionRestrictionRequest' is not null or undefined
            assertParamExists('modifyBankAndOrRestrictionStatus', 'apiPlaidInstitutionRestrictionRequest', apiPlaidInstitutionRestrictionRequest);
            const localVarPath = `/aof/bank-restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPlaidInstitutionRestrictionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Remove bank
         * @param {string} institutionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBank: (institutionRef, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'institutionRef' is not null or undefined
            assertParamExists('removeBank', 'institutionRef', institutionRef);
            const localVarPath = `/aof/bank-restrictions/{institutionRef}`
                .replace(`{${"institutionRef"}}`, encodeURIComponent(String(institutionRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve banks with restriction status
         * @param {RetrieveAllBanksStatusEnum} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllBanks: (status, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/bank-restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve bank
         * @param {string} institutionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveBank: (institutionRef, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'institutionRef' is not null or undefined
            assertParamExists('retrieveBank', 'institutionRef', institutionRef);
            const localVarPath = `/aof/bank-restrictions/{institutionRef}`
                .replace(`{${"institutionRef"}}`, encodeURIComponent(String(institutionRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BankRestrictionsApi - functional programming interface
 * @export
 */
export const BankRestrictionsApiFp = function (configuration) {
    const localVarAxiosParamCreator = BankRestrictionsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add bank with restriction status
         * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankWithRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addBankWithRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankRestrictionsApi.addBankWithRestrictionStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Modify bank and/or restriction status
         * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBankAndOrRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.modifyBankAndOrRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankRestrictionsApi.modifyBankAndOrRestrictionStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Remove bank
         * @param {string} institutionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBank(institutionRef, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeBank(institutionRef, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankRestrictionsApi.removeBank']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve banks with restriction status
         * @param {RetrieveAllBanksStatusEnum} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllBanks(status, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveAllBanks(status, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankRestrictionsApi.retrieveAllBanks']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve bank
         * @param {string} institutionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveBank(institutionRef, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveBank(institutionRef, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BankRestrictionsApi.retrieveBank']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BankRestrictionsApi - factory interface
 * @export
 */
export const BankRestrictionsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BankRestrictionsApiFp(configuration);
    return {
        /**
         *
         * @summary Add bank with restriction status
         * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankWithRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options) {
            return localVarFp.addBankWithRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Modify bank and/or restriction status
         * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBankAndOrRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options) {
            return localVarFp.modifyBankAndOrRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Remove bank
         * @param {string} institutionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBank(institutionRef, options) {
            return localVarFp.removeBank(institutionRef, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve banks with restriction status
         * @param {RetrieveAllBanksStatusEnum} [status]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveAllBanks(status, options) {
            return localVarFp.retrieveAllBanks(status, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve bank
         * @param {string} institutionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveBank(institutionRef, options) {
            return localVarFp.retrieveBank(institutionRef, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BankRestrictionsApi - object-oriented interface
 * @export
 * @class BankRestrictionsApi
 * @extends {BaseAPI}
 */
export class BankRestrictionsApi extends BaseAPI {
    /**
     *
     * @summary Add bank with restriction status
     * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankRestrictionsApi
     */
    addBankWithRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options) {
        return BankRestrictionsApiFp(this.configuration).addBankWithRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Modify bank and/or restriction status
     * @param {ApiPlaidInstitutionRestrictionRequest} apiPlaidInstitutionRestrictionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankRestrictionsApi
     */
    modifyBankAndOrRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options) {
        return BankRestrictionsApiFp(this.configuration).modifyBankAndOrRestrictionStatus(apiPlaidInstitutionRestrictionRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Remove bank
     * @param {string} institutionRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankRestrictionsApi
     */
    removeBank(institutionRef, options) {
        return BankRestrictionsApiFp(this.configuration).removeBank(institutionRef, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve banks with restriction status
     * @param {RetrieveAllBanksStatusEnum} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankRestrictionsApi
     */
    retrieveAllBanks(status, options) {
        return BankRestrictionsApiFp(this.configuration).retrieveAllBanks(status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve bank
     * @param {string} institutionRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankRestrictionsApi
     */
    retrieveBank(institutionRef, options) {
        return BankRestrictionsApiFp(this.configuration).retrieveBank(institutionRef, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var RetrieveAllBanksStatusEnum;
(function (RetrieveAllBanksStatusEnum) {
    RetrieveAllBanksStatusEnum["ALLOWED"] = "ALLOWED";
    RetrieveAllBanksStatusEnum["DENIED"] = "DENIED";
    RetrieveAllBanksStatusEnum["IGNORED"] = "IGNORED";
})(RetrieveAllBanksStatusEnum || (RetrieveAllBanksStatusEnum = {}));
/**
 * BusinessPortalAccountActivityControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalAccountActivityControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AccountActivitySearchRequest} accountActivitySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountActivities: (accountActivitySearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'accountActivitySearchRequest' is not null or undefined
            assertParamExists('getAccountActivities', 'accountActivitySearchRequest', accountActivitySearchRequest);
            const localVarPath = `/business-portal/account/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(accountActivitySearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalAccountActivityControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalAccountActivityControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalAccountActivityControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AccountActivitySearchRequest} accountActivitySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountActivities(accountActivitySearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAccountActivities(accountActivitySearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAccountActivityControllerApi.getAccountActivities']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalAccountActivityControllerApi - factory interface
 * @export
 */
export const BusinessPortalAccountActivityControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalAccountActivityControllerApiFp(configuration);
    return {
        /**
         *
         * @param {AccountActivitySearchRequest} accountActivitySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountActivities(accountActivitySearchRequest, page, size, sort, options) {
            return localVarFp.getAccountActivities(accountActivitySearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalAccountActivityControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalAccountActivityControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalAccountActivityControllerApi extends BaseAPI {
    /**
     *
     * @param {AccountActivitySearchRequest} accountActivitySearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAccountActivityControllerApi
     */
    getAccountActivities(accountActivitySearchRequest, page, size, sort, options) {
        return BusinessPortalAccountActivityControllerApiFp(this.configuration).getAccountActivities(accountActivitySearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalAccountHistoryControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalAccountHistoryControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the history for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountHistory: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccountHistory', 'id', id);
            const localVarPath = `/business-portal/account/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the metadata history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountHistoryMetadata: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAccountHistoryMetadata', 'id', id);
            const localVarPath = `/business-portal/account/history/metadata/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalAccountHistoryControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalAccountHistoryControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalAccountHistoryControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the history for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountHistory(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAccountHistory(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAccountHistoryControllerApi.getAccountHistory']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the metadata history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountHistoryMetadata(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAccountHistoryMetadata(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAccountHistoryControllerApi.getAccountHistoryMetadata']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalAccountHistoryControllerApi - factory interface
 * @export
 */
export const BusinessPortalAccountHistoryControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalAccountHistoryControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the history for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountHistory(id, options) {
            return localVarFp.getAccountHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the metadata history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountHistoryMetadata(id, options) {
            return localVarFp.getAccountHistoryMetadata(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalAccountHistoryControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalAccountHistoryControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalAccountHistoryControllerApi extends BaseAPI {
    /**
     *
     * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the history for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAccountHistoryControllerApi
     */
    getAccountHistory(id, options) {
        return BusinessPortalAccountHistoryControllerApiFp(this.configuration).getAccountHistory(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id the PartnerPartyLinkId (individual account) or AccountEntityId (entity account) to retrieve the metadata history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAccountHistoryControllerApi
     */
    getAccountHistoryMetadata(id, options) {
        return BusinessPortalAccountHistoryControllerApiFp(this.configuration).getAccountHistoryMetadata(id, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalAchControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalAchControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} achFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAchFileSummary: (achFileId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achFileId' is not null or undefined
            assertParamExists('fetchAchFileSummary', 'achFileId', achFileId);
            const localVarPath = `/business-portal/ach-file/summary/{achFileId}`
                .replace(`{${"achFileId"}}`, encodeURIComponent(String(achFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {GenerateDownloadAchFileReportTypeEnum} reportType
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDownloadAchFile: (reportType, filename, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('generateDownloadAchFile', 'reportType', reportType);
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('generateDownloadAchFile', 'filename', filename);
            const localVarPath = `/business-portal/ach-file/download/{reportType}/{filename}`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)))
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchAchFileDetail', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/ach-file/enriched-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} achFileId
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail1: (achFileId, searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achFileId' is not null or undefined
            assertParamExists('searchAchFileDetail1', 'achFileId', achFileId);
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchAchFileDetail1', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/ach-file/enriched-details/{achFileId}`
                .replace(`{${"achFileId"}}`, encodeURIComponent(String(achFileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileSummaryView: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchAchFileSummaryView', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/ach-file/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalAchControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalAchControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalAchControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} achFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAchFileSummary(achFileId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchAchFileSummary(achFileId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAchControllerApi.fetchAchFileSummary']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {GenerateDownloadAchFileReportTypeEnum} reportType
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDownloadAchFile(reportType, filename, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateDownloadAchFile(reportType, filename, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAchControllerApi.generateDownloadAchFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAchFileDetail(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAchControllerApi.searchAchFileDetail']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} achFileId
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail1(achFileId, searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAchFileDetail1(achFileId, searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAchControllerApi.searchAchFileDetail1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileSummaryView(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAchFileSummaryView(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalAchControllerApi.searchAchFileSummaryView']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalAchControllerApi - factory interface
 * @export
 */
export const BusinessPortalAchControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalAchControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} achFileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAchFileSummary(achFileId, options) {
            return localVarFp.fetchAchFileSummary(achFileId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {GenerateDownloadAchFileReportTypeEnum} reportType
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDownloadAchFile(reportType, filename, options) {
            return localVarFp.generateDownloadAchFile(reportType, filename, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail(searchParameters, options) {
            return localVarFp.searchAchFileDetail(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} achFileId
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileDetail1(achFileId, searchParameters, options) {
            return localVarFp.searchAchFileDetail1(achFileId, searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAchFileSummaryView(searchParameters, options) {
            return localVarFp.searchAchFileSummaryView(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalAchControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalAchControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalAchControllerApi extends BaseAPI {
    /**
     *
     * @param {string} achFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAchControllerApi
     */
    fetchAchFileSummary(achFileId, options) {
        return BusinessPortalAchControllerApiFp(this.configuration).fetchAchFileSummary(achFileId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {GenerateDownloadAchFileReportTypeEnum} reportType
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAchControllerApi
     */
    generateDownloadAchFile(reportType, filename, options) {
        return BusinessPortalAchControllerApiFp(this.configuration).generateDownloadAchFile(reportType, filename, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAchControllerApi
     */
    searchAchFileDetail(searchParameters, options) {
        return BusinessPortalAchControllerApiFp(this.configuration).searchAchFileDetail(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} achFileId
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAchControllerApi
     */
    searchAchFileDetail1(achFileId, searchParameters, options) {
        return BusinessPortalAchControllerApiFp(this.configuration).searchAchFileDetail1(achFileId, searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalAchControllerApi
     */
    searchAchFileSummaryView(searchParameters, options) {
        return BusinessPortalAchControllerApiFp(this.configuration).searchAchFileSummaryView(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var GenerateDownloadAchFileReportTypeEnum;
(function (GenerateDownloadAchFileReportTypeEnum) {
    GenerateDownloadAchFileReportTypeEnum["ASSET_ACTIVTY"] = "ASSET_ACTIVTY";
    GenerateDownloadAchFileReportTypeEnum["CAESARS_ACTIVITY"] = "CAESARS_ACTIVITY";
    GenerateDownloadAchFileReportTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    GenerateDownloadAchFileReportTypeEnum["PREFUNDING"] = "PREFUNDING";
    GenerateDownloadAchFileReportTypeEnum["VIRTUAL_CURRENCY"] = "VIRTUAL_CURRENCY";
    GenerateDownloadAchFileReportTypeEnum["SIFT"] = "SIFT";
    GenerateDownloadAchFileReportTypeEnum["IIM_RECONCILATION_REPORT"] = "IIM_RECONCILATION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["MERCHANT_RECONCILIATION_REPORT"] = "MERCHANT_RECONCILIATION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["STARBUCKS_RECONCILIATION_REPORT"] = "STARBUCKS_RECONCILIATION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_SETTLEMENT_REPORT"] = "PARTNER_SETTLEMENT_REPORT";
    GenerateDownloadAchFileReportTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    GenerateDownloadAchFileReportTypeEnum["ACCOUNT_BALANCE"] = "ACCOUNT_BALANCE";
    GenerateDownloadAchFileReportTypeEnum["ACCOUNT_BALANCE_V2"] = "ACCOUNT_BALANCE_V2";
    GenerateDownloadAchFileReportTypeEnum["ACCOUNT_BALANCE_SUMMARY"] = "ACCOUNT_BALANCE_SUMMARY";
    GenerateDownloadAchFileReportTypeEnum["ALL_CURRENCY_MTL"] = "ALL_CURRENCY_MTL";
    GenerateDownloadAchFileReportTypeEnum["MTL"] = "MTL";
    GenerateDownloadAchFileReportTypeEnum["ADOTMTL"] = "ADOTMTL";
    GenerateDownloadAchFileReportTypeEnum["BTC_TRANSACTION_REPORT"] = "BTC_TRANSACTION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["CRYPTO_TRANSACTION_REPORT"] = "CRYPTO_TRANSACTION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT"] = "PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["SUSPEND_ACCOUNT"] = "SUSPEND_ACCOUNT";
    GenerateDownloadAchFileReportTypeEnum["GIFT_CARD_RECON"] = "GIFT_CARD_RECON";
    GenerateDownloadAchFileReportTypeEnum["GIFT_CARD_REDEEM_RECON"] = "GIFT_CARD_REDEEM_RECON";
    GenerateDownloadAchFileReportTypeEnum["GIFT_CARD_RECON_MONTH"] = "GIFT_CARD_RECON_MONTH";
    GenerateDownloadAchFileReportTypeEnum["GIFT_CARD_REDEEM_RECON_MONTH"] = "GIFT_CARD_REDEEM_RECON_MONTH";
    GenerateDownloadAchFileReportTypeEnum["ACH_FILE_RETURNS"] = "ACH_FILE_RETURNS";
    GenerateDownloadAchFileReportTypeEnum["ACH_FILE_OUTBOUND"] = "ACH_FILE_OUTBOUND";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_RECONCILIATION_REPORT"] = "PARTNER_RECONCILIATION_REPORT";
    GenerateDownloadAchFileReportTypeEnum["CHOICE"] = "CHOICE";
    GenerateDownloadAchFileReportTypeEnum["QUIZNOS"] = "QUIZNOS";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_INCENTIVE"] = "PARTNER_INCENTIVE";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_CASH_ACTIVITY"] = "PARTNER_CASH_ACTIVITY";
    GenerateDownloadAchFileReportTypeEnum["WIRE_TRANSFER_ACTIVITY_REVIEW"] = "WIRE_TRANSFER_ACTIVITY_REVIEW";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_CASH_ACTIVITY_APEX"] = "PARTNER_CASH_ACTIVITY_APEX";
    GenerateDownloadAchFileReportTypeEnum["PARTNER_CASH_BALANCE"] = "PARTNER_CASH_BALANCE";
    GenerateDownloadAchFileReportTypeEnum["PARTY_CSI_OFAC_CHECK"] = "PARTY_CSI_OFAC_CHECK";
    GenerateDownloadAchFileReportTypeEnum["FISERV_PAYMENT_STATUS"] = "FISERV_PAYMENT_STATUS";
    GenerateDownloadAchFileReportTypeEnum["DEPOSITE_BALANCE_CHECK_REPORT"] = "DEPOSITE_BALANCE_CHECK_REPORT";
    GenerateDownloadAchFileReportTypeEnum["NYDFS_CUSTOMER_REPORT"] = "NYDFS_CUSTOMER_REPORT";
})(GenerateDownloadAchFileReportTypeEnum || (GenerateDownloadAchFileReportTypeEnum = {}));
/**
 * BusinessPortalActivityAuditControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalActivityAuditControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ActivityAuditSearchRequest} activityAuditSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAudit: (activityAuditSearchRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'activityAuditSearchRequest' is not null or undefined
            assertParamExists('getActivityAudit', 'activityAuditSearchRequest', activityAuditSearchRequest);
            const localVarPath = `/business-portal/activityaudit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(activityAuditSearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAuditByEntityId: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityAuditByEntityId', 'id', id);
            const localVarPath = `/business-portal/activityaudit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalActivityAuditControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalActivityAuditControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalActivityAuditControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ActivityAuditSearchRequest} activityAuditSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAudit(activityAuditSearchRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getActivityAudit(activityAuditSearchRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalActivityAuditControllerApi.getActivityAudit']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAuditByEntityId(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getActivityAuditByEntityId(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalActivityAuditControllerApi.getActivityAuditByEntityId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalActivityAuditControllerApi - factory interface
 * @export
 */
export const BusinessPortalActivityAuditControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalActivityAuditControllerApiFp(configuration);
    return {
        /**
         *
         * @param {ActivityAuditSearchRequest} activityAuditSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAudit(activityAuditSearchRequest, options) {
            return localVarFp.getActivityAudit(activityAuditSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAuditByEntityId(id, options) {
            return localVarFp.getActivityAuditByEntityId(id, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalActivityAuditControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalActivityAuditControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalActivityAuditControllerApi extends BaseAPI {
    /**
     *
     * @param {ActivityAuditSearchRequest} activityAuditSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalActivityAuditControllerApi
     */
    getActivityAudit(activityAuditSearchRequest, options) {
        return BusinessPortalActivityAuditControllerApiFp(this.configuration).getActivityAudit(activityAuditSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalActivityAuditControllerApi
     */
    getActivityAuditByEntityId(id, options) {
        return BusinessPortalActivityAuditControllerApiFp(this.configuration).getActivityAuditByEntityId(id, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalBankAccountControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalBankAccountControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiTimeRange} apiTimeRange
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedBankAccount: (apiTimeRange, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiTimeRange' is not null or undefined
            assertParamExists('fetchBlacklistedBankAccount', 'apiTimeRange', apiTimeRange);
            const localVarPath = `/business-portal/bankaccount/blacklist/consumers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTimeRange, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedConsumerReportSummary: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/bankaccount/blacklist/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiTimeRange} apiTimeRange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedInstitutionAccount: (apiTimeRange, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiTimeRange' is not null or undefined
            assertParamExists('fetchBlacklistedInstitutionAccount', 'apiTimeRange', apiTimeRange);
            const localVarPath = `/business-portal/bankaccount/blacklist/institution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTimeRange, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} institutionId
         * @param {ApiTimeRange} apiTimeRange
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartiesByBlacklistInstitution: (institutionId, apiTimeRange, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'institutionId' is not null or undefined
            assertParamExists('fetchPartiesByBlacklistInstitution', 'institutionId', institutionId);
            // verify required parameter 'apiTimeRange' is not null or undefined
            assertParamExists('fetchPartiesByBlacklistInstitution', 'apiTimeRange', apiTimeRange);
            const localVarPath = `/business-portal/bankaccount/blacklist/institution/{institutionId}/parties`
                .replace(`{${"institutionId"}}`, encodeURIComponent(String(institutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTimeRange, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber1: (plaidAccountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'plaidAccountId' is not null or undefined
            assertParamExists('unmaskAccountNumber1', 'plaidAccountId', plaidAccountId);
            const localVarPath = `/business-portal/bankaccount/{plaidAccountId}/accountNumber/unmask`
                .replace(`{${"plaidAccountId"}}`, encodeURIComponent(String(plaidAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskRoutingNumber: (plaidAccountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'plaidAccountId' is not null or undefined
            assertParamExists('unmaskRoutingNumber', 'plaidAccountId', plaidAccountId);
            const localVarPath = `/business-portal/bankaccount/{plaidAccountId}/routingNumber/unmask`
                .replace(`{${"plaidAccountId"}}`, encodeURIComponent(String(plaidAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalBankAccountControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalBankAccountControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalBankAccountControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiTimeRange} apiTimeRange
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedBankAccount(apiTimeRange, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchBlacklistedBankAccount(apiTimeRange, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalBankAccountControllerApi.fetchBlacklistedBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedConsumerReportSummary(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchBlacklistedConsumerReportSummary(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalBankAccountControllerApi.fetchBlacklistedConsumerReportSummary']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiTimeRange} apiTimeRange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedInstitutionAccount(apiTimeRange, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchBlacklistedInstitutionAccount(apiTimeRange, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalBankAccountControllerApi.fetchBlacklistedInstitutionAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} institutionId
         * @param {ApiTimeRange} apiTimeRange
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartiesByBlacklistInstitution(institutionId, apiTimeRange, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchPartiesByBlacklistInstitution(institutionId, apiTimeRange, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalBankAccountControllerApi.fetchPartiesByBlacklistInstitution']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber1(plaidAccountId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskAccountNumber1(plaidAccountId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalBankAccountControllerApi.unmaskAccountNumber1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskRoutingNumber(plaidAccountId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskRoutingNumber(plaidAccountId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalBankAccountControllerApi.unmaskRoutingNumber']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalBankAccountControllerApi - factory interface
 * @export
 */
export const BusinessPortalBankAccountControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalBankAccountControllerApiFp(configuration);
    return {
        /**
         *
         * @param {ApiTimeRange} apiTimeRange
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedBankAccount(apiTimeRange, page, size, sort, options) {
            return localVarFp.fetchBlacklistedBankAccount(apiTimeRange, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedConsumerReportSummary(options) {
            return localVarFp.fetchBlacklistedConsumerReportSummary(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiTimeRange} apiTimeRange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchBlacklistedInstitutionAccount(apiTimeRange, options) {
            return localVarFp.fetchBlacklistedInstitutionAccount(apiTimeRange, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} institutionId
         * @param {ApiTimeRange} apiTimeRange
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchPartiesByBlacklistInstitution(institutionId, apiTimeRange, page, size, sort, options) {
            return localVarFp.fetchPartiesByBlacklistInstitution(institutionId, apiTimeRange, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber1(plaidAccountId, options) {
            return localVarFp.unmaskAccountNumber1(plaidAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} plaidAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskRoutingNumber(plaidAccountId, options) {
            return localVarFp.unmaskRoutingNumber(plaidAccountId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalBankAccountControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalBankAccountControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalBankAccountControllerApi extends BaseAPI {
    /**
     *
     * @param {ApiTimeRange} apiTimeRange
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalBankAccountControllerApi
     */
    fetchBlacklistedBankAccount(apiTimeRange, page, size, sort, options) {
        return BusinessPortalBankAccountControllerApiFp(this.configuration).fetchBlacklistedBankAccount(apiTimeRange, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalBankAccountControllerApi
     */
    fetchBlacklistedConsumerReportSummary(options) {
        return BusinessPortalBankAccountControllerApiFp(this.configuration).fetchBlacklistedConsumerReportSummary(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiTimeRange} apiTimeRange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalBankAccountControllerApi
     */
    fetchBlacklistedInstitutionAccount(apiTimeRange, options) {
        return BusinessPortalBankAccountControllerApiFp(this.configuration).fetchBlacklistedInstitutionAccount(apiTimeRange, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} institutionId
     * @param {ApiTimeRange} apiTimeRange
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalBankAccountControllerApi
     */
    fetchPartiesByBlacklistInstitution(institutionId, apiTimeRange, page, size, sort, options) {
        return BusinessPortalBankAccountControllerApiFp(this.configuration).fetchPartiesByBlacklistInstitution(institutionId, apiTimeRange, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} plaidAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalBankAccountControllerApi
     */
    unmaskAccountNumber1(plaidAccountId, options) {
        return BusinessPortalBankAccountControllerApiFp(this.configuration).unmaskAccountNumber1(plaidAccountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} plaidAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalBankAccountControllerApi
     */
    unmaskRoutingNumber(plaidAccountId, options) {
        return BusinessPortalBankAccountControllerApiFp(this.configuration).unmaskRoutingNumber(plaidAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalDisputeControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalDisputeControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiDisputeRejectionReason} apiDisputeRejectionReason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDisputeRejectionReason: (apiDisputeRejectionReason, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiDisputeRejectionReason' is not null or undefined
            assertParamExists('addDisputeRejectionReason', 'apiDisputeRejectionReason', apiDisputeRejectionReason);
            const localVarPath = `/business-portal/dispute/reject/reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeRejectionReason, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack: (disputeId, apiDisputeChargebackRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('approveChargeBack', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeChargebackRequest' is not null or undefined
            assertParamExists('approveChargeBack', 'apiDisputeChargebackRequest', apiDisputeChargebackRequest);
            const localVarPath = `/business-portal/dispute/merchant/{disputeId}/accept`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeChargebackRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeAcceptRequest} apiDisputeAcceptRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCredit: (disputeId, apiDisputeAcceptRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('approveCredit', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeAcceptRequest' is not null or undefined
            assertParamExists('approveCredit', 'apiDisputeAcceptRequest', apiDisputeAcceptRequest);
            const localVarPath = `/business-portal/dispute/{disputeId}/accept`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeAcceptRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeCancelRequest} apiDisputeCancelRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDispute: (disputeId, apiDisputeCancelRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('cancelDispute', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeCancelRequest' is not null or undefined
            assertParamExists('cancelDispute', 'apiDisputeCancelRequest', apiDisputeCancelRequest);
            const localVarPath = `/business-portal/dispute/{disputeId}/cancel`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeCancelRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiDisputeCreateRequest} apiDisputeCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDispute: (apiDisputeCreateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiDisputeCreateRequest' is not null or undefined
            assertParamExists('createDispute', 'apiDisputeCreateRequest', apiDisputeCreateRequest);
            const localVarPath = `/business-portal/dispute/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeCreateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} reasonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDisputeRejectionReasonById: (reasonId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reasonId' is not null or undefined
            assertParamExists('deleteDisputeRejectionReasonById', 'reasonId', reasonId);
            const localVarPath = `/business-portal/dispute/reject/reason/{reasonId}`
                .replace(`{${"reasonId"}}`, encodeURIComponent(String(reasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeEscalateRequest} apiDisputeEscalateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escalateDispute: (disputeId, apiDisputeEscalateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('escalateDispute', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeEscalateRequest' is not null or undefined
            assertParamExists('escalateDispute', 'apiDisputeEscalateRequest', apiDisputeEscalateRequest);
            const localVarPath = `/business-portal/dispute/{disputeId}/escalate`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeEscalateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeRejectRequest} apiDisputeRejectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectCredit: (disputeId, apiDisputeRejectRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('rejectCredit', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeRejectRequest' is not null or undefined
            assertParamExists('rejectCredit', 'apiDisputeRejectRequest', apiDisputeRejectRequest);
            const localVarPath = `/business-portal/dispute/{disputeId}/reject`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeRejectRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} reasonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDisputeRejectionReasonById: (reasonId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reasonId' is not null or undefined
            assertParamExists('retrieveDisputeRejectionReasonById', 'reasonId', reasonId);
            const localVarPath = `/business-portal/dispute/reject/reason/{reasonId}`
                .replace(`{${"reasonId"}}`, encodeURIComponent(String(reasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDisputeRejectionReasons: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/dispute/reject/reasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeRegulationERequest} apiDisputeRegulationERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulationEFlag: (disputeId, apiDisputeRegulationERequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('updateRegulationEFlag', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeRegulationERequest' is not null or undefined
            assertParamExists('updateRegulationEFlag', 'apiDisputeRegulationERequest', apiDisputeRegulationERequest);
            const localVarPath = `/business-portal/dispute/{disputeId}/regulation-e-flag`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeRegulationERequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalDisputeControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalDisputeControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalDisputeControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiDisputeRejectionReason} apiDisputeRejectionReason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDisputeRejectionReason(apiDisputeRejectionReason, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addDisputeRejectionReason(apiDisputeRejectionReason, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.addDisputeRejectionReason']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack(disputeId, apiDisputeChargebackRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.approveChargeBack(disputeId, apiDisputeChargebackRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.approveChargeBack']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeAcceptRequest} apiDisputeAcceptRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCredit(disputeId, apiDisputeAcceptRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.approveCredit(disputeId, apiDisputeAcceptRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.approveCredit']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeCancelRequest} apiDisputeCancelRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDispute(disputeId, apiDisputeCancelRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cancelDispute(disputeId, apiDisputeCancelRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.cancelDispute']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiDisputeCreateRequest} apiDisputeCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDispute(apiDisputeCreateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createDispute(apiDisputeCreateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.createDispute']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} reasonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDisputeRejectionReasonById(reasonId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteDisputeRejectionReasonById(reasonId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.deleteDisputeRejectionReasonById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeEscalateRequest} apiDisputeEscalateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escalateDispute(disputeId, apiDisputeEscalateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.escalateDispute(disputeId, apiDisputeEscalateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.escalateDispute']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeRejectRequest} apiDisputeRejectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectCredit(disputeId, apiDisputeRejectRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.rejectCredit(disputeId, apiDisputeRejectRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.rejectCredit']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} reasonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDisputeRejectionReasonById(reasonId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveDisputeRejectionReasonById(reasonId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.retrieveDisputeRejectionReasonById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDisputeRejectionReasons(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveDisputeRejectionReasons(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.retrieveDisputeRejectionReasons']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeRegulationERequest} apiDisputeRegulationERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulationEFlag(disputeId, apiDisputeRegulationERequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateRegulationEFlag(disputeId, apiDisputeRegulationERequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalDisputeControllerApi.updateRegulationEFlag']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalDisputeControllerApi - factory interface
 * @export
 */
export const BusinessPortalDisputeControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalDisputeControllerApiFp(configuration);
    return {
        /**
         *
         * @param {ApiDisputeRejectionReason} apiDisputeRejectionReason
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDisputeRejectionReason(apiDisputeRejectionReason, options) {
            return localVarFp.addDisputeRejectionReason(apiDisputeRejectionReason, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack(disputeId, apiDisputeChargebackRequest, options) {
            return localVarFp.approveChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeAcceptRequest} apiDisputeAcceptRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveCredit(disputeId, apiDisputeAcceptRequest, options) {
            return localVarFp.approveCredit(disputeId, apiDisputeAcceptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeCancelRequest} apiDisputeCancelRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelDispute(disputeId, apiDisputeCancelRequest, options) {
            return localVarFp.cancelDispute(disputeId, apiDisputeCancelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiDisputeCreateRequest} apiDisputeCreateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDispute(apiDisputeCreateRequest, options) {
            return localVarFp.createDispute(apiDisputeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} reasonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDisputeRejectionReasonById(reasonId, options) {
            return localVarFp.deleteDisputeRejectionReasonById(reasonId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeEscalateRequest} apiDisputeEscalateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escalateDispute(disputeId, apiDisputeEscalateRequest, options) {
            return localVarFp.escalateDispute(disputeId, apiDisputeEscalateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeRejectRequest} apiDisputeRejectRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectCredit(disputeId, apiDisputeRejectRequest, options) {
            return localVarFp.rejectCredit(disputeId, apiDisputeRejectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} reasonId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDisputeRejectionReasonById(reasonId, options) {
            return localVarFp.retrieveDisputeRejectionReasonById(reasonId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDisputeRejectionReasons(options) {
            return localVarFp.retrieveDisputeRejectionReasons(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeRegulationERequest} apiDisputeRegulationERequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRegulationEFlag(disputeId, apiDisputeRegulationERequest, options) {
            return localVarFp.updateRegulationEFlag(disputeId, apiDisputeRegulationERequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalDisputeControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalDisputeControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalDisputeControllerApi extends BaseAPI {
    /**
     *
     * @param {ApiDisputeRejectionReason} apiDisputeRejectionReason
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    addDisputeRejectionReason(apiDisputeRejectionReason, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).addDisputeRejectionReason(apiDisputeRejectionReason, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    approveChargeBack(disputeId, apiDisputeChargebackRequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).approveChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeAcceptRequest} apiDisputeAcceptRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    approveCredit(disputeId, apiDisputeAcceptRequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).approveCredit(disputeId, apiDisputeAcceptRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeCancelRequest} apiDisputeCancelRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    cancelDispute(disputeId, apiDisputeCancelRequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).cancelDispute(disputeId, apiDisputeCancelRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiDisputeCreateRequest} apiDisputeCreateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    createDispute(apiDisputeCreateRequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).createDispute(apiDisputeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} reasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    deleteDisputeRejectionReasonById(reasonId, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).deleteDisputeRejectionReasonById(reasonId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeEscalateRequest} apiDisputeEscalateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    escalateDispute(disputeId, apiDisputeEscalateRequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).escalateDispute(disputeId, apiDisputeEscalateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeRejectRequest} apiDisputeRejectRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    rejectCredit(disputeId, apiDisputeRejectRequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).rejectCredit(disputeId, apiDisputeRejectRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} reasonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    retrieveDisputeRejectionReasonById(reasonId, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).retrieveDisputeRejectionReasonById(reasonId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    retrieveDisputeRejectionReasons(options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).retrieveDisputeRejectionReasons(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeRegulationERequest} apiDisputeRegulationERequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalDisputeControllerApi
     */
    updateRegulationEFlag(disputeId, apiDisputeRegulationERequest, options) {
        return BusinessPortalDisputeControllerApiFp(this.configuration).updateRegulationEFlag(disputeId, apiDisputeRegulationERequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalIncentiveControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalIncentiveControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiIncentiveOfferCompletionRequest} apiIncentiveOfferCompletionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOffer: (apiIncentiveOfferCompletionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiIncentiveOfferCompletionRequest' is not null or undefined
            assertParamExists('completeOffer', 'apiIncentiveOfferCompletionRequest', apiIncentiveOfferCompletionRequest);
            const localVarPath = `/business-portal/incentive/complete-offer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiIncentiveOfferCompletionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchActiveIncentiveOfferSummaryList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/incentive/offer/group/active`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchExpiredIncentiveOfferSummaryList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/incentive/offer/group/expired`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIncentiveOffers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/incentive/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {boolean} isDraft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveOfferById: (id, isDraft, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIncentiveOfferById', 'id', id);
            // verify required parameter 'isDraft' is not null or undefined
            assertParamExists('getIncentiveOfferById', 'isDraft', isDraft);
            const localVarPath = `/business-portal/incentive/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (isDraft !== undefined) {
                localVarQueryParameter['isDraft'] = isDraft;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveOfferDrafts: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/incentive/offer/drafts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {GetIncentiveParticipatingOfferDetailsStatusEnum} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveParticipatingOfferDetails: (partyId, status, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getIncentiveParticipatingOfferDetails', 'partyId', partyId);
            // verify required parameter 'status' is not null or undefined
            assertParamExists('getIncentiveParticipatingOfferDetails', 'status', status);
            const localVarPath = `/business-portal/incentive/party/{partyId}/offer/{status}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)))
                .replace(`{${"status"}}`, encodeURIComponent(String(status)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} offerId
         * @param {string} comments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishIncentiveDraft: (offerId, comments, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'offerId' is not null or undefined
            assertParamExists('publishIncentiveDraft', 'offerId', offerId);
            // verify required parameter 'comments' is not null or undefined
            assertParamExists('publishIncentiveDraft', 'comments', comments);
            const localVarPath = `/business-portal/incentive/offer/publish/{offerId}`
                .replace(`{${"offerId"}}`, encodeURIComponent(String(offerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (comments !== undefined) {
                localVarQueryParameter['comments'] = comments;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIncentiveOfferDraft: (apiIncentiveOfferDetail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiIncentiveOfferDetail' is not null or undefined
            assertParamExists('saveIncentiveOfferDraft', 'apiIncentiveOfferDetail', apiIncentiveOfferDetail);
            const localVarPath = `/business-portal/incentive/offer/draft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiIncentiveOfferDetail, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {ApiIncentiveOfferUpdateRequest} apiIncentiveOfferUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncentiveOffer: (id, apiIncentiveOfferUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIncentiveOffer', 'id', id);
            // verify required parameter 'apiIncentiveOfferUpdateRequest' is not null or undefined
            assertParamExists('updateIncentiveOffer', 'apiIncentiveOfferUpdateRequest', apiIncentiveOfferUpdateRequest);
            const localVarPath = `/business-portal/incentive/offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiIncentiveOfferUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncentiveOfferDraft: (id, apiIncentiveOfferDetail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIncentiveOfferDraft', 'id', id);
            // verify required parameter 'apiIncentiveOfferDetail' is not null or undefined
            assertParamExists('updateIncentiveOfferDraft', 'apiIncentiveOfferDetail', apiIncentiveOfferDetail);
            const localVarPath = `/business-portal/incentive/offer/draft/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiIncentiveOfferDetail, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalIncentiveControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalIncentiveControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalIncentiveControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiIncentiveOfferCompletionRequest} apiIncentiveOfferCompletionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOffer(apiIncentiveOfferCompletionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.completeOffer(apiIncentiveOfferCompletionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.completeOffer']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchActiveIncentiveOfferSummaryList(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchActiveIncentiveOfferSummaryList(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.fetchActiveIncentiveOfferSummaryList']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchExpiredIncentiveOfferSummaryList(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchExpiredIncentiveOfferSummaryList(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.fetchExpiredIncentiveOfferSummaryList']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIncentiveOffers(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllIncentiveOffers(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.getAllIncentiveOffers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {boolean} isDraft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveOfferById(id, isDraft, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getIncentiveOfferById(id, isDraft, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.getIncentiveOfferById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveOfferDrafts(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getIncentiveOfferDrafts(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.getIncentiveOfferDrafts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {GetIncentiveParticipatingOfferDetailsStatusEnum} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveParticipatingOfferDetails(partyId, status, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getIncentiveParticipatingOfferDetails(partyId, status, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.getIncentiveParticipatingOfferDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} offerId
         * @param {string} comments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishIncentiveDraft(offerId, comments, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.publishIncentiveDraft(offerId, comments, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.publishIncentiveDraft']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIncentiveOfferDraft(apiIncentiveOfferDetail, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.saveIncentiveOfferDraft(apiIncentiveOfferDetail, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.saveIncentiveOfferDraft']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {ApiIncentiveOfferUpdateRequest} apiIncentiveOfferUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncentiveOffer(id, apiIncentiveOfferUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateIncentiveOffer(id, apiIncentiveOfferUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.updateIncentiveOffer']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncentiveOfferDraft(id, apiIncentiveOfferDetail, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateIncentiveOfferDraft(id, apiIncentiveOfferDetail, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalIncentiveControllerApi.updateIncentiveOfferDraft']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalIncentiveControllerApi - factory interface
 * @export
 */
export const BusinessPortalIncentiveControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalIncentiveControllerApiFp(configuration);
    return {
        /**
         *
         * @param {ApiIncentiveOfferCompletionRequest} apiIncentiveOfferCompletionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeOffer(apiIncentiveOfferCompletionRequest, options) {
            return localVarFp.completeOffer(apiIncentiveOfferCompletionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchActiveIncentiveOfferSummaryList(options) {
            return localVarFp.fetchActiveIncentiveOfferSummaryList(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchExpiredIncentiveOfferSummaryList(options) {
            return localVarFp.fetchExpiredIncentiveOfferSummaryList(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllIncentiveOffers(options) {
            return localVarFp.getAllIncentiveOffers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {boolean} isDraft
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveOfferById(id, isDraft, options) {
            return localVarFp.getIncentiveOfferById(id, isDraft, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveOfferDrafts(options) {
            return localVarFp.getIncentiveOfferDrafts(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {GetIncentiveParticipatingOfferDetailsStatusEnum} status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIncentiveParticipatingOfferDetails(partyId, status, options) {
            return localVarFp.getIncentiveParticipatingOfferDetails(partyId, status, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} offerId
         * @param {string} comments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishIncentiveDraft(offerId, comments, options) {
            return localVarFp.publishIncentiveDraft(offerId, comments, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIncentiveOfferDraft(apiIncentiveOfferDetail, options) {
            return localVarFp.saveIncentiveOfferDraft(apiIncentiveOfferDetail, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {ApiIncentiveOfferUpdateRequest} apiIncentiveOfferUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncentiveOffer(id, apiIncentiveOfferUpdateRequest, options) {
            return localVarFp.updateIncentiveOffer(id, apiIncentiveOfferUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIncentiveOfferDraft(id, apiIncentiveOfferDetail, options) {
            return localVarFp.updateIncentiveOfferDraft(id, apiIncentiveOfferDetail, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalIncentiveControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalIncentiveControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalIncentiveControllerApi extends BaseAPI {
    /**
     *
     * @param {ApiIncentiveOfferCompletionRequest} apiIncentiveOfferCompletionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    completeOffer(apiIncentiveOfferCompletionRequest, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).completeOffer(apiIncentiveOfferCompletionRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    fetchActiveIncentiveOfferSummaryList(options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).fetchActiveIncentiveOfferSummaryList(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    fetchExpiredIncentiveOfferSummaryList(options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).fetchExpiredIncentiveOfferSummaryList(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    getAllIncentiveOffers(options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).getAllIncentiveOffers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {boolean} isDraft
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    getIncentiveOfferById(id, isDraft, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).getIncentiveOfferById(id, isDraft, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    getIncentiveOfferDrafts(options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).getIncentiveOfferDrafts(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {GetIncentiveParticipatingOfferDetailsStatusEnum} status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    getIncentiveParticipatingOfferDetails(partyId, status, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).getIncentiveParticipatingOfferDetails(partyId, status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} offerId
     * @param {string} comments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    publishIncentiveDraft(offerId, comments, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).publishIncentiveDraft(offerId, comments, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    saveIncentiveOfferDraft(apiIncentiveOfferDetail, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).saveIncentiveOfferDraft(apiIncentiveOfferDetail, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {ApiIncentiveOfferUpdateRequest} apiIncentiveOfferUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    updateIncentiveOffer(id, apiIncentiveOfferUpdateRequest, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).updateIncentiveOffer(id, apiIncentiveOfferUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {ApiIncentiveOfferDetail} apiIncentiveOfferDetail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalIncentiveControllerApi
     */
    updateIncentiveOfferDraft(id, apiIncentiveOfferDetail, options) {
        return BusinessPortalIncentiveControllerApiFp(this.configuration).updateIncentiveOfferDraft(id, apiIncentiveOfferDetail, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var GetIncentiveParticipatingOfferDetailsStatusEnum;
(function (GetIncentiveParticipatingOfferDetailsStatusEnum) {
    GetIncentiveParticipatingOfferDetailsStatusEnum["PARTICIPATING"] = "PARTICIPATING";
    GetIncentiveParticipatingOfferDetailsStatusEnum["AVAILABLE"] = "AVAILABLE";
    GetIncentiveParticipatingOfferDetailsStatusEnum["EXPIRED"] = "EXPIRED";
})(GetIncentiveParticipatingOfferDetailsStatusEnum || (GetIncentiveParticipatingOfferDetailsStatusEnum = {}));
/**
 * BusinessPortalInstitutionControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalInstitutionControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {AddRoutingNumberToDenyListRequest} addRoutingNumberToDenyListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoutingNumberToDenyList: (id, addRoutingNumberToDenyListRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addRoutingNumberToDenyList', 'id', id);
            // verify required parameter 'addRoutingNumberToDenyListRequest' is not null or undefined
            assertParamExists('addRoutingNumberToDenyList', 'addRoutingNumberToDenyListRequest', addRoutingNumberToDenyListRequest);
            const localVarPath = `/business-portal/institution/{id}/denied-routing-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(addRoutingNumberToDenyListRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {CreateInstitutionRequest} createInstitutionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstitution: (createInstitutionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'createInstitutionRequest' is not null or undefined
            assertParamExists('createInstitution', 'createInstitutionRequest', createInstitutionRequest);
            const localVarPath = `/business-portal/institution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(createInstitutionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeniedRoutingNumbers: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeniedRoutingNumbers', 'id', id);
            const localVarPath = `/business-portal/institution/{id}/denied-routing-numbers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getInstitutionById', 'id', id);
            const localVarPath = `/business-portal/institution/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutions: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/institutions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {RemoveRoutingNumberFromDenyListRequest} removeRoutingNumberFromDenyListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoutingNumberFromInstitution: (id, removeRoutingNumberFromDenyListRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeRoutingNumberFromInstitution', 'id', id);
            // verify required parameter 'removeRoutingNumberFromDenyListRequest' is not null or undefined
            assertParamExists('removeRoutingNumberFromInstitution', 'removeRoutingNumberFromDenyListRequest', removeRoutingNumberFromDenyListRequest);
            const localVarPath = `/business-portal/institution/{id}/remove-denied-routing-number`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(removeRoutingNumberFromDenyListRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalInstitutionControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalInstitutionControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalInstitutionControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {AddRoutingNumberToDenyListRequest} addRoutingNumberToDenyListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoutingNumberToDenyList(id, addRoutingNumberToDenyListRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addRoutingNumberToDenyList(id, addRoutingNumberToDenyListRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalInstitutionControllerApi.addRoutingNumberToDenyList']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {CreateInstitutionRequest} createInstitutionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstitution(createInstitutionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createInstitution(createInstitutionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalInstitutionControllerApi.createInstitution']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeniedRoutingNumbers(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDeniedRoutingNumbers(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalInstitutionControllerApi.getDeniedRoutingNumbers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionById(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getInstitutionById(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalInstitutionControllerApi.getInstitutionById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutions(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getInstitutions(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalInstitutionControllerApi.getInstitutions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {RemoveRoutingNumberFromDenyListRequest} removeRoutingNumberFromDenyListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoutingNumberFromInstitution(id, removeRoutingNumberFromDenyListRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeRoutingNumberFromInstitution(id, removeRoutingNumberFromDenyListRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalInstitutionControllerApi.removeRoutingNumberFromInstitution']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalInstitutionControllerApi - factory interface
 * @export
 */
export const BusinessPortalInstitutionControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalInstitutionControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {AddRoutingNumberToDenyListRequest} addRoutingNumberToDenyListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoutingNumberToDenyList(id, addRoutingNumberToDenyListRequest, options) {
            return localVarFp.addRoutingNumberToDenyList(id, addRoutingNumberToDenyListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {CreateInstitutionRequest} createInstitutionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstitution(createInstitutionRequest, options) {
            return localVarFp.createInstitution(createInstitutionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeniedRoutingNumbers(id, options) {
            return localVarFp.getDeniedRoutingNumbers(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutionById(id, options) {
            return localVarFp.getInstitutionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutions(options) {
            return localVarFp.getInstitutions(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {RemoveRoutingNumberFromDenyListRequest} removeRoutingNumberFromDenyListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRoutingNumberFromInstitution(id, removeRoutingNumberFromDenyListRequest, options) {
            return localVarFp.removeRoutingNumberFromInstitution(id, removeRoutingNumberFromDenyListRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalInstitutionControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalInstitutionControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalInstitutionControllerApi extends BaseAPI {
    /**
     *
     * @param {string} id
     * @param {AddRoutingNumberToDenyListRequest} addRoutingNumberToDenyListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalInstitutionControllerApi
     */
    addRoutingNumberToDenyList(id, addRoutingNumberToDenyListRequest, options) {
        return BusinessPortalInstitutionControllerApiFp(this.configuration).addRoutingNumberToDenyList(id, addRoutingNumberToDenyListRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {CreateInstitutionRequest} createInstitutionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalInstitutionControllerApi
     */
    createInstitution(createInstitutionRequest, options) {
        return BusinessPortalInstitutionControllerApiFp(this.configuration).createInstitution(createInstitutionRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalInstitutionControllerApi
     */
    getDeniedRoutingNumbers(id, options) {
        return BusinessPortalInstitutionControllerApiFp(this.configuration).getDeniedRoutingNumbers(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalInstitutionControllerApi
     */
    getInstitutionById(id, options) {
        return BusinessPortalInstitutionControllerApiFp(this.configuration).getInstitutionById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalInstitutionControllerApi
     */
    getInstitutions(options) {
        return BusinessPortalInstitutionControllerApiFp(this.configuration).getInstitutions(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {RemoveRoutingNumberFromDenyListRequest} removeRoutingNumberFromDenyListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalInstitutionControllerApi
     */
    removeRoutingNumberFromInstitution(id, removeRoutingNumberFromDenyListRequest, options) {
        return BusinessPortalInstitutionControllerApiFp(this.configuration).removeRoutingNumberFromInstitution(id, removeRoutingNumberFromDenyListRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalLedgerAdjustmentControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalLedgerAdjustmentControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} ppl
         * @param {AdjustFiatLedgerRequest} adjustFiatLedgerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adjustFiatLedger: (ppl, adjustFiatLedgerRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'ppl' is not null or undefined
            assertParamExists('adjustFiatLedger', 'ppl', ppl);
            // verify required parameter 'adjustFiatLedgerRequest' is not null or undefined
            assertParamExists('adjustFiatLedger', 'adjustFiatLedgerRequest', adjustFiatLedgerRequest);
            const localVarPath = `/business-portal/partner-party-link/{ppl}/fiat-ledger-adjustment`
                .replace(`{${"ppl"}}`, encodeURIComponent(String(ppl)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(adjustFiatLedgerRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalLedgerAdjustmentControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalLedgerAdjustmentControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalLedgerAdjustmentControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} ppl
         * @param {AdjustFiatLedgerRequest} adjustFiatLedgerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adjustFiatLedger(ppl, adjustFiatLedgerRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adjustFiatLedger(ppl, adjustFiatLedgerRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalLedgerAdjustmentControllerApi.adjustFiatLedger']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalLedgerAdjustmentControllerApi - factory interface
 * @export
 */
export const BusinessPortalLedgerAdjustmentControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalLedgerAdjustmentControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} ppl
         * @param {AdjustFiatLedgerRequest} adjustFiatLedgerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adjustFiatLedger(ppl, adjustFiatLedgerRequest, options) {
            return localVarFp.adjustFiatLedger(ppl, adjustFiatLedgerRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalLedgerAdjustmentControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalLedgerAdjustmentControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalLedgerAdjustmentControllerApi extends BaseAPI {
    /**
     *
     * @param {string} ppl
     * @param {AdjustFiatLedgerRequest} adjustFiatLedgerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalLedgerAdjustmentControllerApi
     */
    adjustFiatLedger(ppl, adjustFiatLedgerRequest, options) {
        return BusinessPortalLedgerAdjustmentControllerApiFp(this.configuration).adjustFiatLedger(ppl, adjustFiatLedgerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalLoyaltyControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalLoyaltyControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiCancelRedemptionRequest} apiCancelRedemptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRedemption: (apiCancelRedemptionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCancelRedemptionRequest' is not null or undefined
            assertParamExists('cancelRedemption', 'apiCancelRedemptionRequest', apiCancelRedemptionRequest);
            const localVarPath = `/business-portal/loyalty/account/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCancelRedemptionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum} loyaltyIntegrationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyaltyAccountsDetails: (partyId, loyaltyIntegrationType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getLoyaltyAccountsDetails', 'partyId', partyId);
            // verify required parameter 'loyaltyIntegrationType' is not null or undefined
            assertParamExists('getLoyaltyAccountsDetails', 'loyaltyIntegrationType', loyaltyIntegrationType);
            const localVarPath = `/business-portal/loyalty/party/{partyId}/account/{loyaltyIntegrationType}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)))
                .replace(`{${"loyaltyIntegrationType"}}`, encodeURIComponent(String(loyaltyIntegrationType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {string} loyaltyAccountId
         * @param {string} comments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkLoyaltyAccount: (partyId, loyaltyAccountId, comments, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('unlinkLoyaltyAccount', 'partyId', partyId);
            // verify required parameter 'loyaltyAccountId' is not null or undefined
            assertParamExists('unlinkLoyaltyAccount', 'loyaltyAccountId', loyaltyAccountId);
            // verify required parameter 'comments' is not null or undefined
            assertParamExists('unlinkLoyaltyAccount', 'comments', comments);
            const localVarPath = `/business-portal/loyalty/party/{partyId}/account/{loyaltyAccountId}/unlink`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)))
                .replace(`{${"loyaltyAccountId"}}`, encodeURIComponent(String(loyaltyAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (comments !== undefined) {
                localVarQueryParameter['comments'] = comments;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {string} loyaltyAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber: (partyId, loyaltyAccountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('unmaskAccountNumber', 'partyId', partyId);
            // verify required parameter 'loyaltyAccountId' is not null or undefined
            assertParamExists('unmaskAccountNumber', 'loyaltyAccountId', loyaltyAccountId);
            const localVarPath = `/business-portal/loyalty/party/{partyId}/account/{loyaltyAccountId}/accountNumber/unmask`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)))
                .replace(`{${"loyaltyAccountId"}}`, encodeURIComponent(String(loyaltyAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalLoyaltyControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalLoyaltyControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalLoyaltyControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiCancelRedemptionRequest} apiCancelRedemptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRedemption(apiCancelRedemptionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.cancelRedemption(apiCancelRedemptionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalLoyaltyControllerApi.cancelRedemption']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum} loyaltyIntegrationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyaltyAccountsDetails(partyId, loyaltyIntegrationType, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLoyaltyAccountsDetails(partyId, loyaltyIntegrationType, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalLoyaltyControllerApi.getLoyaltyAccountsDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {string} loyaltyAccountId
         * @param {string} comments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkLoyaltyAccount(partyId, loyaltyAccountId, comments, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unlinkLoyaltyAccount(partyId, loyaltyAccountId, comments, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalLoyaltyControllerApi.unlinkLoyaltyAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {string} loyaltyAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber(partyId, loyaltyAccountId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskAccountNumber(partyId, loyaltyAccountId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalLoyaltyControllerApi.unmaskAccountNumber']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalLoyaltyControllerApi - factory interface
 * @export
 */
export const BusinessPortalLoyaltyControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalLoyaltyControllerApiFp(configuration);
    return {
        /**
         *
         * @param {ApiCancelRedemptionRequest} apiCancelRedemptionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelRedemption(apiCancelRedemptionRequest, options) {
            return localVarFp.cancelRedemption(apiCancelRedemptionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum} loyaltyIntegrationType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoyaltyAccountsDetails(partyId, loyaltyIntegrationType, options) {
            return localVarFp.getLoyaltyAccountsDetails(partyId, loyaltyIntegrationType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {string} loyaltyAccountId
         * @param {string} comments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkLoyaltyAccount(partyId, loyaltyAccountId, comments, options) {
            return localVarFp.unlinkLoyaltyAccount(partyId, loyaltyAccountId, comments, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {string} loyaltyAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskAccountNumber(partyId, loyaltyAccountId, options) {
            return localVarFp.unmaskAccountNumber(partyId, loyaltyAccountId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalLoyaltyControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalLoyaltyControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalLoyaltyControllerApi extends BaseAPI {
    /**
     *
     * @param {ApiCancelRedemptionRequest} apiCancelRedemptionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalLoyaltyControllerApi
     */
    cancelRedemption(apiCancelRedemptionRequest, options) {
        return BusinessPortalLoyaltyControllerApiFp(this.configuration).cancelRedemption(apiCancelRedemptionRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum} loyaltyIntegrationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalLoyaltyControllerApi
     */
    getLoyaltyAccountsDetails(partyId, loyaltyIntegrationType, options) {
        return BusinessPortalLoyaltyControllerApiFp(this.configuration).getLoyaltyAccountsDetails(partyId, loyaltyIntegrationType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {string} loyaltyAccountId
     * @param {string} comments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalLoyaltyControllerApi
     */
    unlinkLoyaltyAccount(partyId, loyaltyAccountId, comments, options) {
        return BusinessPortalLoyaltyControllerApiFp(this.configuration).unlinkLoyaltyAccount(partyId, loyaltyAccountId, comments, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {string} loyaltyAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalLoyaltyControllerApi
     */
    unmaskAccountNumber(partyId, loyaltyAccountId, options) {
        return BusinessPortalLoyaltyControllerApiFp(this.configuration).unmaskAccountNumber(partyId, loyaltyAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum;
(function (GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum) {
    GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum["UNKNOWN"] = "UNKNOWN";
    GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum["AWARD_WALLET"] = "AWARD_WALLET";
    GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum["REWARDS_GATEWAY"] = "REWARDS_GATEWAY";
})(GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum || (GetLoyaltyAccountsDetailsLoyaltyIntegrationTypeEnum = {}));
/**
 * BusinessPortalMultiownerControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalMultiownerControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} id
         * @param {ApiEnrollee} apiEnrollee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultiOwnerAccountOwner: (id, apiEnrollee, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addMultiOwnerAccountOwner', 'id', id);
            // verify required parameter 'apiEnrollee' is not null or undefined
            assertParamExists('addMultiOwnerAccountOwner', 'apiEnrollee', apiEnrollee);
            const localVarPath = `/business-portal/multiowner/{id}/owners`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiEnrollee, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiOwnerAccountById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMultiOwnerAccountById', 'id', id);
            const localVarPath = `/business-portal/multiowner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {string} externalRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultiOwnerAccountOwner: (id, externalRef, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeMultiOwnerAccountOwner', 'id', id);
            // verify required parameter 'externalRef' is not null or undefined
            assertParamExists('removeMultiOwnerAccountOwner', 'externalRef', externalRef);
            const localVarPath = `/business-portal/multiowner/{id}/owners/{externalRef}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"externalRef"}}`, encodeURIComponent(String(externalRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {MultiOwnerAccountSearchRequest} multiOwnerAccountSearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMultiOwnerAccounts: (multiOwnerAccountSearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'multiOwnerAccountSearchRequest' is not null or undefined
            assertParamExists('searchMultiOwnerAccounts', 'multiOwnerAccountSearchRequest', multiOwnerAccountSearchRequest);
            const localVarPath = `/business-portal/multiowner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(multiOwnerAccountSearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {MultiOwnerAccountUpdateRequest} multiOwnerAccountUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiOwnerAccount: (id, multiOwnerAccountUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMultiOwnerAccount', 'id', id);
            // verify required parameter 'multiOwnerAccountUpdateRequest' is not null or undefined
            assertParamExists('updateMultiOwnerAccount', 'multiOwnerAccountUpdateRequest', multiOwnerAccountUpdateRequest);
            const localVarPath = `/business-portal/multiowner/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(multiOwnerAccountUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {string} externalRef
         * @param {ApiEnrolleeUpdateRequest} apiEnrolleeUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiOwnerAccountOwner: (id, externalRef, apiEnrolleeUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMultiOwnerAccountOwner', 'id', id);
            // verify required parameter 'externalRef' is not null or undefined
            assertParamExists('updateMultiOwnerAccountOwner', 'externalRef', externalRef);
            // verify required parameter 'apiEnrolleeUpdateRequest' is not null or undefined
            assertParamExists('updateMultiOwnerAccountOwner', 'apiEnrolleeUpdateRequest', apiEnrolleeUpdateRequest);
            const localVarPath = `/business-portal/multiowner/{id}/owners/{externalRef}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"externalRef"}}`, encodeURIComponent(String(externalRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiEnrolleeUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalMultiownerControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalMultiownerControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalMultiownerControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {ApiEnrollee} apiEnrollee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultiOwnerAccountOwner(id, apiEnrollee, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addMultiOwnerAccountOwner(id, apiEnrollee, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalMultiownerControllerApi.addMultiOwnerAccountOwner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiOwnerAccountById(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMultiOwnerAccountById(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalMultiownerControllerApi.getMultiOwnerAccountById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} externalRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultiOwnerAccountOwner(id, externalRef, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeMultiOwnerAccountOwner(id, externalRef, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalMultiownerControllerApi.removeMultiOwnerAccountOwner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {MultiOwnerAccountSearchRequest} multiOwnerAccountSearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMultiOwnerAccounts(multiOwnerAccountSearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchMultiOwnerAccounts(multiOwnerAccountSearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalMultiownerControllerApi.searchMultiOwnerAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {MultiOwnerAccountUpdateRequest} multiOwnerAccountUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiOwnerAccount(id, multiOwnerAccountUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateMultiOwnerAccount(id, multiOwnerAccountUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalMultiownerControllerApi.updateMultiOwnerAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {string} externalRef
         * @param {ApiEnrolleeUpdateRequest} apiEnrolleeUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiOwnerAccountOwner(id, externalRef, apiEnrolleeUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateMultiOwnerAccountOwner(id, externalRef, apiEnrolleeUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalMultiownerControllerApi.updateMultiOwnerAccountOwner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalMultiownerControllerApi - factory interface
 * @export
 */
export const BusinessPortalMultiownerControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalMultiownerControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} id
         * @param {ApiEnrollee} apiEnrollee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMultiOwnerAccountOwner(id, apiEnrollee, options) {
            return localVarFp.addMultiOwnerAccountOwner(id, apiEnrollee, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultiOwnerAccountById(id, options) {
            return localVarFp.getMultiOwnerAccountById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {string} externalRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeMultiOwnerAccountOwner(id, externalRef, options) {
            return localVarFp.removeMultiOwnerAccountOwner(id, externalRef, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {MultiOwnerAccountSearchRequest} multiOwnerAccountSearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchMultiOwnerAccounts(multiOwnerAccountSearchRequest, page, size, sort, options) {
            return localVarFp.searchMultiOwnerAccounts(multiOwnerAccountSearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {MultiOwnerAccountUpdateRequest} multiOwnerAccountUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiOwnerAccount(id, multiOwnerAccountUpdateRequest, options) {
            return localVarFp.updateMultiOwnerAccount(id, multiOwnerAccountUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {string} externalRef
         * @param {ApiEnrolleeUpdateRequest} apiEnrolleeUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMultiOwnerAccountOwner(id, externalRef, apiEnrolleeUpdateRequest, options) {
            return localVarFp.updateMultiOwnerAccountOwner(id, externalRef, apiEnrolleeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalMultiownerControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalMultiownerControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalMultiownerControllerApi extends BaseAPI {
    /**
     *
     * @param {string} id
     * @param {ApiEnrollee} apiEnrollee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalMultiownerControllerApi
     */
    addMultiOwnerAccountOwner(id, apiEnrollee, options) {
        return BusinessPortalMultiownerControllerApiFp(this.configuration).addMultiOwnerAccountOwner(id, apiEnrollee, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalMultiownerControllerApi
     */
    getMultiOwnerAccountById(id, options) {
        return BusinessPortalMultiownerControllerApiFp(this.configuration).getMultiOwnerAccountById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {string} externalRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalMultiownerControllerApi
     */
    removeMultiOwnerAccountOwner(id, externalRef, options) {
        return BusinessPortalMultiownerControllerApiFp(this.configuration).removeMultiOwnerAccountOwner(id, externalRef, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {MultiOwnerAccountSearchRequest} multiOwnerAccountSearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalMultiownerControllerApi
     */
    searchMultiOwnerAccounts(multiOwnerAccountSearchRequest, page, size, sort, options) {
        return BusinessPortalMultiownerControllerApiFp(this.configuration).searchMultiOwnerAccounts(multiOwnerAccountSearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {MultiOwnerAccountUpdateRequest} multiOwnerAccountUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalMultiownerControllerApi
     */
    updateMultiOwnerAccount(id, multiOwnerAccountUpdateRequest, options) {
        return BusinessPortalMultiownerControllerApiFp(this.configuration).updateMultiOwnerAccount(id, multiOwnerAccountUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {string} externalRef
     * @param {ApiEnrolleeUpdateRequest} apiEnrolleeUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalMultiownerControllerApi
     */
    updateMultiOwnerAccountOwner(id, externalRef, apiEnrolleeUpdateRequest, options) {
        return BusinessPortalMultiownerControllerApiFp(this.configuration).updateMultiOwnerAccountOwner(id, externalRef, apiEnrolleeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalPartyControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalPartyControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiCreditActionRequest} apiCreditActionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCredit: (apiCreditActionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCreditActionRequest' is not null or undefined
            assertParamExists('addCredit', 'apiCreditActionRequest', apiCreditActionRequest);
            const localVarPath = `/business-portal/party/credit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreditActionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiBlacklistAccountRequest} apiBlacklistAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistBankAccount: (apiBlacklistAccountRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBlacklistAccountRequest' is not null or undefined
            assertParamExists('blacklistBankAccount', 'apiBlacklistAccountRequest', apiBlacklistAccountRequest);
            const localVarPath = `/business-portal/party/bank-account/blacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBlacklistAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistDebitCard: (apiBlacklistDebitCardRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBlacklistDebitCardRequest' is not null or undefined
            assertParamExists('blacklistDebitCard', 'apiBlacklistDebitCardRequest', apiBlacklistDebitCardRequest);
            const localVarPath = `/business-portal/party/debit-cards/blacklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBlacklistDebitCardRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Move party status from one state to another from Tango Portal
         * @param {ApiPartnerPartyLinkChangeStatusRequest} apiPartnerPartyLinkChangeStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePartnerPartyLinkStatus: (apiPartnerPartyLinkChangeStatusRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartnerPartyLinkChangeStatusRequest' is not null or undefined
            assertParamExists('changePartnerPartyLinkStatus', 'apiPartnerPartyLinkChangeStatusRequest', apiPartnerPartyLinkChangeStatusRequest);
            const localVarPath = `/business-portal/partner-party-link/change-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerPartyLinkChangeStatusRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchThirdPartyVerificationLogs: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('fetchThirdPartyVerificationLogs', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/verification-logs`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountActivityReasonMapping: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/account-activity/reason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConsumersAccount: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getAllConsumersAccount', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/bank-accounts`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} identityCheckId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetails: (identityCheckId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'identityCheckId' is not null or undefined
            assertParamExists('getAuditDetails', 'identityCheckId', identityCheckId);
            const localVarPath = `/business-portal/identity-check/audit/detail/{identityCheckId}`
                .replace(`{${"identityCheckId"}}`, encodeURIComponent(String(identityCheckId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebitCardsAndActivitiesByPartyId: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getDebitCardsAndActivitiesByPartyId', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/debit-cards`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {Array<GetDeviceSessionsSourceTypesEnum>} [sourceTypes]
         * @param {Array<GetDeviceSessionsFlowTypesEnum>} [flowTypes]
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceSessions: (partyId, sourceTypes, flowTypes, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getDeviceSessions', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/sessions`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (sourceTypes) {
                localVarQueryParameter['sourceTypes'] = sourceTypes;
            }
            if (flowTypes) {
                localVarQueryParameter['flowTypes'] = flowTypes;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerDetails: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getPartnerDetails', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/partners`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaidAccounts: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getPlaidAccounts', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/plaid-accounts`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} plaidAccountId
         * @param {GetPlaidEventEventTypeEnum} eventType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaidEvent: (plaidAccountId, eventType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'plaidAccountId' is not null or undefined
            assertParamExists('getPlaidEvent', 'plaidAccountId', plaidAccountId);
            // verify required parameter 'eventType' is not null or undefined
            assertParamExists('getPlaidEvent', 'eventType', eventType);
            const localVarPath = `/business-portal/party/{plaidAccountId}/plaid-event/{eventType}`
                .replace(`{${"plaidAccountId"}}`, encodeURIComponent(String(plaidAccountId)))
                .replace(`{${"eventType"}}`, encodeURIComponent(String(eventType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVerificationHistory: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getRiskVerificationHistory', 'partyId', partyId);
            const localVarPath = `/business-portal/identity-check/audit/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxDetails: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getTaxDetails', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/tax-details`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsAndAccountLinkFailures: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getTransactionsAndAccountLinkFailures', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/failures`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionInfo: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/party/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiLinkUnlinkPartnerStatusRequest} apiLinkUnlinkPartnerStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkUnlinkPartner: (apiLinkUnlinkPartnerStatusRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiLinkUnlinkPartnerStatusRequest' is not null or undefined
            assertParamExists('linkUnlinkPartner', 'apiLinkUnlinkPartnerStatusRequest', apiLinkUnlinkPartnerStatusRequest);
            const localVarPath = `/business-portal/party/partner/link-unlink`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiLinkUnlinkPartnerStatusRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Perform Kyc on an existing partner party link
         * @param {ApiPartnerPartyLinkPerformKycRequest} apiPartnerPartyLinkPerformKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performKyc: (apiPartnerPartyLinkPerformKycRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartnerPartyLinkPerformKycRequest' is not null or undefined
            assertParamExists('performKyc', 'apiPartnerPartyLinkPerformKycRequest', apiPartnerPartyLinkPerformKycRequest);
            const localVarPath = `/business-portal/partner-party-link/perform-kyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerPartyLinkPerformKycRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiBlacklistAccountRemovalRequest} apiBlacklistAccountRemovalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBlacklistFromBankAccount: (apiBlacklistAccountRemovalRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBlacklistAccountRemovalRequest' is not null or undefined
            assertParamExists('removeBlacklistFromBankAccount', 'apiBlacklistAccountRemovalRequest', apiBlacklistAccountRemovalRequest);
            const localVarPath = `/business-portal/party/bank-account/blacklist/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBlacklistAccountRemovalRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBlacklistFromDebitCards: (apiBlacklistDebitCardRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiBlacklistDebitCardRequest' is not null or undefined
            assertParamExists('removeBlacklistFromDebitCards', 'apiBlacklistDebitCardRequest', apiBlacklistDebitCardRequest);
            const localVarPath = `/business-portal/party/debit-cards/blacklist/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiBlacklistDebitCardRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWhitelistFromParty: (apiWhitelistPartyRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiWhitelistPartyRequest' is not null or undefined
            assertParamExists('removeWhitelistFromParty', 'apiWhitelistPartyRequest', apiWhitelistPartyRequest);
            const localVarPath = `/business-portal/party/whitelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiWhitelistPartyRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerPartyLinkDetails: (partnerPartyLinkId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('retrievePartnerPartyLinkDetails', 'partnerPartyLinkId', partnerPartyLinkId);
            const localVarPath = `/business-portal/partner-party-link/{partnerPartyLinkId}/details`
                .replace(`{${"partnerPartyLinkId"}}`, encodeURIComponent(String(partnerPartyLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerPartyLinkPortfolio: (partnerPartyLinkId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('retrievePartnerPartyLinkPortfolio', 'partnerPartyLinkId', partnerPartyLinkId);
            const localVarPath = `/business-portal/partner-party-link/{partnerPartyLinkId}/portfolio`
                .replace(`{${"partnerPartyLinkId"}}`, encodeURIComponent(String(partnerPartyLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrievePartyDetails: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrievePartyDetails', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/details`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartyPortfolio: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrievePartyPortfolio', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/portfolio`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartyRiskDetails: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrievePartyRiskDetails', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/partyriskdetails`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumers: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchConsumers', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/party/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId: (apiPartyDashboardSearch, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartyDashboardSearch' is not null or undefined
            assertParamExists('searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId', 'apiPartyDashboardSearch', apiPartyDashboardSearch);
            const localVarPath = `/business-portal/partner-party-link/dashboard/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartyDashboardSearch, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartyId: (apiPartyDashboardSearch, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartyDashboardSearch' is not null or undefined
            assertParamExists('searchConsumersUsingEmailOrPhoneOrNameOrPartyId', 'apiPartyDashboardSearch', apiPartyDashboardSearch);
            const localVarPath = `/business-portal/party/dashboard/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartyDashboardSearch, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiUnblockPrefundingRequest} apiUnblockPrefundingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockPrefunding: (apiUnblockPrefundingRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUnblockPrefundingRequest' is not null or undefined
            assertParamExists('unblockPrefunding', 'apiUnblockPrefundingRequest', apiUnblockPrefundingRequest);
            const localVarPath = `/business-portal/party/unblock-prefunding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUnblockPrefundingRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unmaskDateOfBirth: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('unmaskDateOfBirth', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/dob/unmask`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskPPLDateOfBirth: (partnerPartyLinkId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('unmaskPPLDateOfBirth', 'partnerPartyLinkId', partnerPartyLinkId);
            const localVarPath = `/business-portal/partner-party-link/{partnerPartyLinkId}/dob/unmask`
                .replace(`{${"partnerPartyLinkId"}}`, encodeURIComponent(String(partnerPartyLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskPPLTaxId: (partnerPartyLinkId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('unmaskPPLTaxId', 'partnerPartyLinkId', partnerPartyLinkId);
            const localVarPath = `/business-portal/partner-party-link/{partnerPartyLinkId}/tax-id/unmask`
                .replace(`{${"partnerPartyLinkId"}}`, encodeURIComponent(String(partnerPartyLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unmaskTaxId: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('unmaskTaxId', 'partyId', partyId);
            const localVarPath = `/business-portal/party/{partyId}/tax-id/unmask`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} detailsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskVerificationDateOfBirth: (detailsId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'detailsId' is not null or undefined
            assertParamExists('unmaskVerificationDateOfBirth', 'detailsId', detailsId);
            const localVarPath = `/business-portal/party/tax-details/{detailsId}/dob/unmask`
                .replace(`{${"detailsId"}}`, encodeURIComponent(String(detailsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} detailsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskVerificationTaxId: (detailsId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'detailsId' is not null or undefined
            assertParamExists('unmaskVerificationTaxId', 'detailsId', detailsId);
            const localVarPath = `/business-portal/party/tax-details/{detailsId}/tax-id/unmask`
                .replace(`{${"detailsId"}}`, encodeURIComponent(String(detailsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update Partner Party Link Name
         * @param {string} partnerPartyLinkId
         * @param {ApiUpdatePartyNameRequest} apiUpdatePartyNameRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyLinkName: (partnerPartyLinkId, apiUpdatePartyNameRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerPartyLinkId' is not null or undefined
            assertParamExists('updatePartnerPartyLinkName', 'partnerPartyLinkId', partnerPartyLinkId);
            // verify required parameter 'apiUpdatePartyNameRequest' is not null or undefined
            assertParamExists('updatePartnerPartyLinkName', 'apiUpdatePartyNameRequest', apiUpdatePartyNameRequest);
            const localVarPath = `/business-portal/partner-party-link/{partnerPartyLinkId}/name`
                .replace(`{${"partnerPartyLinkId"}}`, encodeURIComponent(String(partnerPartyLinkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdatePartyNameRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiUpdateTransunionVerificationRequest} apiUpdateTransunionVerificationRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransunionVerification: (apiUpdateTransunionVerificationRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateTransunionVerificationRequest' is not null or undefined
            assertParamExists('updateTransunionVerification', 'apiUpdateTransunionVerificationRequest', apiUpdateTransunionVerificationRequest);
            const localVarPath = `/business-portal/party/transunion-override`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateTransunionVerificationRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whitelistParty: (apiWhitelistPartyRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiWhitelistPartyRequest' is not null or undefined
            assertParamExists('whitelistParty', 'apiWhitelistPartyRequest', apiWhitelistPartyRequest);
            const localVarPath = `/business-portal/party/whitelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiWhitelistPartyRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalPartyControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalPartyControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalPartyControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiCreditActionRequest} apiCreditActionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCredit(apiCreditActionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addCredit(apiCreditActionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.addCredit']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiBlacklistAccountRequest} apiBlacklistAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistBankAccount(apiBlacklistAccountRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.blacklistBankAccount(apiBlacklistAccountRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.blacklistBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistDebitCard(apiBlacklistDebitCardRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.blacklistDebitCard(apiBlacklistDebitCardRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.blacklistDebitCard']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Move party status from one state to another from Tango Portal
         * @param {ApiPartnerPartyLinkChangeStatusRequest} apiPartnerPartyLinkChangeStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePartnerPartyLinkStatus(apiPartnerPartyLinkChangeStatusRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.changePartnerPartyLinkStatus(apiPartnerPartyLinkChangeStatusRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.changePartnerPartyLinkStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchThirdPartyVerificationLogs(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchThirdPartyVerificationLogs(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.fetchThirdPartyVerificationLogs']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountActivityReasonMapping(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAccountActivityReasonMapping(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getAccountActivityReasonMapping']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConsumersAccount(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAllConsumersAccount(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getAllConsumersAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} identityCheckId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetails(identityCheckId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAuditDetails(identityCheckId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getAuditDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebitCardsAndActivitiesByPartyId(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDebitCardsAndActivitiesByPartyId(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getDebitCardsAndActivitiesByPartyId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {Array<GetDeviceSessionsSourceTypesEnum>} [sourceTypes]
         * @param {Array<GetDeviceSessionsFlowTypesEnum>} [flowTypes]
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceSessions(partyId, sourceTypes, flowTypes, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDeviceSessions(partyId, sourceTypes, flowTypes, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getDeviceSessions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerDetails(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerDetails(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getPartnerDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaidAccounts(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlaidAccounts(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getPlaidAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} plaidAccountId
         * @param {GetPlaidEventEventTypeEnum} eventType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaidEvent(plaidAccountId, eventType, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlaidEvent(plaidAccountId, eventType, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getPlaidEvent']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVerificationHistory(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRiskVerificationHistory(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getRiskVerificationHistory']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxDetails(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTaxDetails(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getTaxDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsAndAccountLinkFailures(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTransactionsAndAccountLinkFailures(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getTransactionsAndAccountLinkFailures']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionInfo(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUserSessionInfo(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.getUserSessionInfo']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiLinkUnlinkPartnerStatusRequest} apiLinkUnlinkPartnerStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkUnlinkPartner(apiLinkUnlinkPartnerStatusRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.linkUnlinkPartner(apiLinkUnlinkPartnerStatusRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.linkUnlinkPartner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Perform Kyc on an existing partner party link
         * @param {ApiPartnerPartyLinkPerformKycRequest} apiPartnerPartyLinkPerformKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performKyc(apiPartnerPartyLinkPerformKycRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.performKyc(apiPartnerPartyLinkPerformKycRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.performKyc']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiBlacklistAccountRemovalRequest} apiBlacklistAccountRemovalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBlacklistFromBankAccount(apiBlacklistAccountRemovalRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeBlacklistFromBankAccount(apiBlacklistAccountRemovalRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.removeBlacklistFromBankAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBlacklistFromDebitCards(apiBlacklistDebitCardRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeBlacklistFromDebitCards(apiBlacklistDebitCardRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.removeBlacklistFromDebitCards']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWhitelistFromParty(apiWhitelistPartyRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.removeWhitelistFromParty(apiWhitelistPartyRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.removeWhitelistFromParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerPartyLinkDetails(partnerPartyLinkId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartnerPartyLinkDetails(partnerPartyLinkId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.retrievePartnerPartyLinkDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerPartyLinkPortfolio(partnerPartyLinkId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartnerPartyLinkPortfolio(partnerPartyLinkId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.retrievePartnerPartyLinkPortfolio']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrievePartyDetails(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartyDetails(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.retrievePartyDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartyPortfolio(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartyPortfolio(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.retrievePartyPortfolio']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartyRiskDetails(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartyRiskDetails(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.retrievePartyRiskDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumers(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchConsumers(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.searchConsumers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchConsumersUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.searchConsumersUsingEmailOrPhoneOrNameOrPartyId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiUnblockPrefundingRequest} apiUnblockPrefundingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockPrefunding(apiUnblockPrefundingRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unblockPrefunding(apiUnblockPrefundingRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unblockPrefunding']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unmaskDateOfBirth(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskDateOfBirth(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unmaskDateOfBirth']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskPPLDateOfBirth(partnerPartyLinkId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskPPLDateOfBirth(partnerPartyLinkId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unmaskPPLDateOfBirth']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskPPLTaxId(partnerPartyLinkId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskPPLTaxId(partnerPartyLinkId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unmaskPPLTaxId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unmaskTaxId(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskTaxId(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unmaskTaxId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} detailsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskVerificationDateOfBirth(detailsId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskVerificationDateOfBirth(detailsId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unmaskVerificationDateOfBirth']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} detailsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskVerificationTaxId(detailsId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskVerificationTaxId(detailsId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.unmaskVerificationTaxId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update Partner Party Link Name
         * @param {string} partnerPartyLinkId
         * @param {ApiUpdatePartyNameRequest} apiUpdatePartyNameRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyLinkName(partnerPartyLinkId, apiUpdatePartyNameRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerPartyLinkName(partnerPartyLinkId, apiUpdatePartyNameRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.updatePartnerPartyLinkName']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiUpdateTransunionVerificationRequest} apiUpdateTransunionVerificationRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransunionVerification(apiUpdateTransunionVerificationRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateTransunionVerification(apiUpdateTransunionVerificationRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.updateTransunionVerification']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whitelistParty(apiWhitelistPartyRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.whitelistParty(apiWhitelistPartyRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalPartyControllerApi.whitelistParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalPartyControllerApi - factory interface
 * @export
 */
export const BusinessPortalPartyControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalPartyControllerApiFp(configuration);
    return {
        /**
         *
         * @param {ApiCreditActionRequest} apiCreditActionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCredit(apiCreditActionRequest, options) {
            return localVarFp.addCredit(apiCreditActionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiBlacklistAccountRequest} apiBlacklistAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistBankAccount(apiBlacklistAccountRequest, options) {
            return localVarFp.blacklistBankAccount(apiBlacklistAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blacklistDebitCard(apiBlacklistDebitCardRequest, options) {
            return localVarFp.blacklistDebitCard(apiBlacklistDebitCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Move party status from one state to another from Tango Portal
         * @param {ApiPartnerPartyLinkChangeStatusRequest} apiPartnerPartyLinkChangeStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePartnerPartyLinkStatus(apiPartnerPartyLinkChangeStatusRequest, options) {
            return localVarFp.changePartnerPartyLinkStatus(apiPartnerPartyLinkChangeStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchThirdPartyVerificationLogs(partyId, options) {
            return localVarFp.fetchThirdPartyVerificationLogs(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountActivityReasonMapping(options) {
            return localVarFp.getAccountActivityReasonMapping(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConsumersAccount(partyId, options) {
            return localVarFp.getAllConsumersAccount(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} identityCheckId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditDetails(identityCheckId, options) {
            return localVarFp.getAuditDetails(identityCheckId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebitCardsAndActivitiesByPartyId(partyId, options) {
            return localVarFp.getDebitCardsAndActivitiesByPartyId(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {Array<GetDeviceSessionsSourceTypesEnum>} [sourceTypes]
         * @param {Array<GetDeviceSessionsFlowTypesEnum>} [flowTypes]
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceSessions(partyId, sourceTypes, flowTypes, page, size, sort, options) {
            return localVarFp.getDeviceSessions(partyId, sourceTypes, flowTypes, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerDetails(partyId, options) {
            return localVarFp.getPartnerDetails(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaidAccounts(partyId, options) {
            return localVarFp.getPlaidAccounts(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} plaidAccountId
         * @param {GetPlaidEventEventTypeEnum} eventType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlaidEvent(plaidAccountId, eventType, options) {
            return localVarFp.getPlaidEvent(plaidAccountId, eventType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRiskVerificationHistory(partyId, options) {
            return localVarFp.getRiskVerificationHistory(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxDetails(partyId, options) {
            return localVarFp.getTaxDetails(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsAndAccountLinkFailures(partyId, options) {
            return localVarFp.getTransactionsAndAccountLinkFailures(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSessionInfo(options) {
            return localVarFp.getUserSessionInfo(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiLinkUnlinkPartnerStatusRequest} apiLinkUnlinkPartnerStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkUnlinkPartner(apiLinkUnlinkPartnerStatusRequest, options) {
            return localVarFp.linkUnlinkPartner(apiLinkUnlinkPartnerStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Perform Kyc on an existing partner party link
         * @param {ApiPartnerPartyLinkPerformKycRequest} apiPartnerPartyLinkPerformKycRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performKyc(apiPartnerPartyLinkPerformKycRequest, options) {
            return localVarFp.performKyc(apiPartnerPartyLinkPerformKycRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiBlacklistAccountRemovalRequest} apiBlacklistAccountRemovalRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBlacklistFromBankAccount(apiBlacklistAccountRemovalRequest, options) {
            return localVarFp.removeBlacklistFromBankAccount(apiBlacklistAccountRemovalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeBlacklistFromDebitCards(apiBlacklistDebitCardRequest, options) {
            return localVarFp.removeBlacklistFromDebitCards(apiBlacklistDebitCardRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWhitelistFromParty(apiWhitelistPartyRequest, options) {
            return localVarFp.removeWhitelistFromParty(apiWhitelistPartyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerPartyLinkDetails(partnerPartyLinkId, options) {
            return localVarFp.retrievePartnerPartyLinkDetails(partnerPartyLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerPartyLinkPortfolio(partnerPartyLinkId, options) {
            return localVarFp.retrievePartnerPartyLinkPortfolio(partnerPartyLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        retrievePartyDetails(partyId, options) {
            return localVarFp.retrievePartyDetails(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartyPortfolio(partyId, options) {
            return localVarFp.retrievePartyPortfolio(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartyRiskDetails(partyId, options) {
            return localVarFp.retrievePartyRiskDetails(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumers(searchParameters, options) {
            return localVarFp.searchConsumers(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options) {
            return localVarFp.searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchConsumersUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options) {
            return localVarFp.searchConsumersUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiUnblockPrefundingRequest} apiUnblockPrefundingRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unblockPrefunding(apiUnblockPrefundingRequest, options) {
            return localVarFp.unblockPrefunding(apiUnblockPrefundingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unmaskDateOfBirth(partyId, options) {
            return localVarFp.unmaskDateOfBirth(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskPPLDateOfBirth(partnerPartyLinkId, options) {
            return localVarFp.unmaskPPLDateOfBirth(partnerPartyLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partnerPartyLinkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskPPLTaxId(partnerPartyLinkId, options) {
            return localVarFp.unmaskPPLTaxId(partnerPartyLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        unmaskTaxId(partyId, options) {
            return localVarFp.unmaskTaxId(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} detailsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskVerificationDateOfBirth(detailsId, options) {
            return localVarFp.unmaskVerificationDateOfBirth(detailsId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} detailsId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskVerificationTaxId(detailsId, options) {
            return localVarFp.unmaskVerificationTaxId(detailsId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Partner Party Link Name
         * @param {string} partnerPartyLinkId
         * @param {ApiUpdatePartyNameRequest} apiUpdatePartyNameRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyLinkName(partnerPartyLinkId, apiUpdatePartyNameRequest, options) {
            return localVarFp.updatePartnerPartyLinkName(partnerPartyLinkId, apiUpdatePartyNameRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiUpdateTransunionVerificationRequest} apiUpdateTransunionVerificationRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTransunionVerification(apiUpdateTransunionVerificationRequest, xTrackingId, options) {
            return localVarFp.updateTransunionVerification(apiUpdateTransunionVerificationRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whitelistParty(apiWhitelistPartyRequest, options) {
            return localVarFp.whitelistParty(apiWhitelistPartyRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalPartyControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalPartyControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalPartyControllerApi extends BaseAPI {
    /**
     *
     * @param {ApiCreditActionRequest} apiCreditActionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    addCredit(apiCreditActionRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).addCredit(apiCreditActionRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiBlacklistAccountRequest} apiBlacklistAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    blacklistBankAccount(apiBlacklistAccountRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).blacklistBankAccount(apiBlacklistAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    blacklistDebitCard(apiBlacklistDebitCardRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).blacklistDebitCard(apiBlacklistDebitCardRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Move party status from one state to another from Tango Portal
     * @param {ApiPartnerPartyLinkChangeStatusRequest} apiPartnerPartyLinkChangeStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    changePartnerPartyLinkStatus(apiPartnerPartyLinkChangeStatusRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).changePartnerPartyLinkStatus(apiPartnerPartyLinkChangeStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    fetchThirdPartyVerificationLogs(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).fetchThirdPartyVerificationLogs(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getAccountActivityReasonMapping(options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getAccountActivityReasonMapping(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getAllConsumersAccount(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getAllConsumersAccount(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} identityCheckId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getAuditDetails(identityCheckId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getAuditDetails(identityCheckId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getDebitCardsAndActivitiesByPartyId(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getDebitCardsAndActivitiesByPartyId(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {Array<GetDeviceSessionsSourceTypesEnum>} [sourceTypes]
     * @param {Array<GetDeviceSessionsFlowTypesEnum>} [flowTypes]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getDeviceSessions(partyId, sourceTypes, flowTypes, page, size, sort, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getDeviceSessions(partyId, sourceTypes, flowTypes, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getPartnerDetails(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getPartnerDetails(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getPlaidAccounts(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getPlaidAccounts(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} plaidAccountId
     * @param {GetPlaidEventEventTypeEnum} eventType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getPlaidEvent(plaidAccountId, eventType, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getPlaidEvent(plaidAccountId, eventType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getRiskVerificationHistory(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getRiskVerificationHistory(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getTaxDetails(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getTaxDetails(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getTransactionsAndAccountLinkFailures(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getTransactionsAndAccountLinkFailures(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    getUserSessionInfo(options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).getUserSessionInfo(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiLinkUnlinkPartnerStatusRequest} apiLinkUnlinkPartnerStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    linkUnlinkPartner(apiLinkUnlinkPartnerStatusRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).linkUnlinkPartner(apiLinkUnlinkPartnerStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Perform Kyc on an existing partner party link
     * @param {ApiPartnerPartyLinkPerformKycRequest} apiPartnerPartyLinkPerformKycRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    performKyc(apiPartnerPartyLinkPerformKycRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).performKyc(apiPartnerPartyLinkPerformKycRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiBlacklistAccountRemovalRequest} apiBlacklistAccountRemovalRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    removeBlacklistFromBankAccount(apiBlacklistAccountRemovalRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).removeBlacklistFromBankAccount(apiBlacklistAccountRemovalRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiBlacklistDebitCardRequest} apiBlacklistDebitCardRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    removeBlacklistFromDebitCards(apiBlacklistDebitCardRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).removeBlacklistFromDebitCards(apiBlacklistDebitCardRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    removeWhitelistFromParty(apiWhitelistPartyRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).removeWhitelistFromParty(apiWhitelistPartyRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partnerPartyLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    retrievePartnerPartyLinkDetails(partnerPartyLinkId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).retrievePartnerPartyLinkDetails(partnerPartyLinkId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partnerPartyLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    retrievePartnerPartyLinkPortfolio(partnerPartyLinkId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).retrievePartnerPartyLinkPortfolio(partnerPartyLinkId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    retrievePartyDetails(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).retrievePartyDetails(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    retrievePartyPortfolio(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).retrievePartyPortfolio(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    retrievePartyRiskDetails(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).retrievePartyRiskDetails(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    searchConsumers(searchParameters, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).searchConsumers(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).searchConsumersPPLUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiPartyDashboardSearch} apiPartyDashboardSearch
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    searchConsumersUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).searchConsumersUsingEmailOrPhoneOrNameOrPartyId(apiPartyDashboardSearch, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiUnblockPrefundingRequest} apiUnblockPrefundingRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unblockPrefunding(apiUnblockPrefundingRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unblockPrefunding(apiUnblockPrefundingRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unmaskDateOfBirth(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unmaskDateOfBirth(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partnerPartyLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unmaskPPLDateOfBirth(partnerPartyLinkId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unmaskPPLDateOfBirth(partnerPartyLinkId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partnerPartyLinkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unmaskPPLTaxId(partnerPartyLinkId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unmaskPPLTaxId(partnerPartyLinkId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unmaskTaxId(partyId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unmaskTaxId(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} detailsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unmaskVerificationDateOfBirth(detailsId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unmaskVerificationDateOfBirth(detailsId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} detailsId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    unmaskVerificationTaxId(detailsId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).unmaskVerificationTaxId(detailsId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update Partner Party Link Name
     * @param {string} partnerPartyLinkId
     * @param {ApiUpdatePartyNameRequest} apiUpdatePartyNameRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    updatePartnerPartyLinkName(partnerPartyLinkId, apiUpdatePartyNameRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).updatePartnerPartyLinkName(partnerPartyLinkId, apiUpdatePartyNameRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiUpdateTransunionVerificationRequest} apiUpdateTransunionVerificationRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    updateTransunionVerification(apiUpdateTransunionVerificationRequest, xTrackingId, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).updateTransunionVerification(apiUpdateTransunionVerificationRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiWhitelistPartyRequest} apiWhitelistPartyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalPartyControllerApi
     */
    whitelistParty(apiWhitelistPartyRequest, options) {
        return BusinessPortalPartyControllerApiFp(this.configuration).whitelistParty(apiWhitelistPartyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var GetDeviceSessionsSourceTypesEnum;
(function (GetDeviceSessionsSourceTypesEnum) {
    GetDeviceSessionsSourceTypesEnum["ARKOSE"] = "ARKOSE";
    GetDeviceSessionsSourceTypesEnum["SARDINE"] = "SARDINE";
    GetDeviceSessionsSourceTypesEnum["SIFT"] = "SIFT";
})(GetDeviceSessionsSourceTypesEnum || (GetDeviceSessionsSourceTypesEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var GetDeviceSessionsFlowTypesEnum;
(function (GetDeviceSessionsFlowTypesEnum) {
    GetDeviceSessionsFlowTypesEnum["L1_IDENTITY_CHECK_FINALIZE"] = "L1_IDENTITY_CHECK_FINALIZE";
    GetDeviceSessionsFlowTypesEnum["L2A_IDENTITY_CHECK_FINALIZE"] = "L2A_IDENTITY_CHECK_FINALIZE";
    GetDeviceSessionsFlowTypesEnum["L2A_IDENTITY_CHECK_ADMIN"] = "L2A_IDENTITY_CHECK_ADMIN";
    GetDeviceSessionsFlowTypesEnum["L2A_IDENTITY_CHECK_UPDATE_ADDRESS"] = "L2A_IDENTITY_CHECK_UPDATE_ADDRESS";
    GetDeviceSessionsFlowTypesEnum["L2B_IDENTITY_CHECK"] = "L2B_IDENTITY_CHECK";
    GetDeviceSessionsFlowTypesEnum["L2B_IDENTITY_CHECK_ADMIN"] = "L2B_IDENTITY_CHECK_ADMIN";
    GetDeviceSessionsFlowTypesEnum["L2C_IDENTITY_CHECK"] = "L2C_IDENTITY_CHECK";
    GetDeviceSessionsFlowTypesEnum["L3_IDENTITY_CHECK_ID_DOC_VERIFICATION"] = "L3_IDENTITY_CHECK_ID_DOC_VERIFICATION";
    GetDeviceSessionsFlowTypesEnum["PROFILE_UPDATE_ADDRESS"] = "PROFILE_UPDATE_ADDRESS";
    GetDeviceSessionsFlowTypesEnum["PROFILE_UPDATE_IDENTIFIER"] = "PROFILE_UPDATE_IDENTIFIER";
    GetDeviceSessionsFlowTypesEnum["BATCH_UPLOAD"] = "BATCH_UPLOAD";
    GetDeviceSessionsFlowTypesEnum["CSI_OFAC_BATCH"] = "CSI_OFAC_BATCH";
    GetDeviceSessionsFlowTypesEnum["CSI_OFAC"] = "CSI_OFAC";
    GetDeviceSessionsFlowTypesEnum["LOGIN"] = "LOGIN";
    GetDeviceSessionsFlowTypesEnum["LOGOUT"] = "LOGOUT";
    GetDeviceSessionsFlowTypesEnum["REGISTRATION"] = "REGISTRATION";
    GetDeviceSessionsFlowTypesEnum["TRANSACTION"] = "TRANSACTION";
    GetDeviceSessionsFlowTypesEnum["BANK_VERIFICATION"] = "BANK_VERIFICATION";
    GetDeviceSessionsFlowTypesEnum["PARTNER_PARTY_ENROLLMENT"] = "PARTNER_PARTY_ENROLLMENT";
    GetDeviceSessionsFlowTypesEnum["ACH_DEPOSIT"] = "ACH_DEPOSIT";
    GetDeviceSessionsFlowTypesEnum["ACH_WITHDRAW"] = "ACH_WITHDRAW";
    GetDeviceSessionsFlowTypesEnum["BUY_CRYPTO"] = "BUY_CRYPTO";
    GetDeviceSessionsFlowTypesEnum["SELL_CRYPTO"] = "SELL_CRYPTO";
    GetDeviceSessionsFlowTypesEnum["SEND_CRYPTO"] = "SEND_CRYPTO";
    GetDeviceSessionsFlowTypesEnum["CONVERT_REWARD"] = "CONVERT_REWARD";
    GetDeviceSessionsFlowTypesEnum["SEND_CASH"] = "SEND_CASH";
    GetDeviceSessionsFlowTypesEnum["BUY_GIFT_CARD"] = "BUY_GIFT_CARD";
    GetDeviceSessionsFlowTypesEnum["LINKING_BANK"] = "LINKING_BANK";
    GetDeviceSessionsFlowTypesEnum["LINKING_DEBIT_CARD"] = "LINKING_DEBIT_CARD";
    GetDeviceSessionsFlowTypesEnum["DEBIT_CARD_DEPOSIT_TO_WALLET"] = "DEBIT_CARD_DEPOSIT_TO_WALLET";
    GetDeviceSessionsFlowTypesEnum["DEBIT_CARD_WITHDRAWAL_FROM_WALLET"] = "DEBIT_CARD_WITHDRAWAL_FROM_WALLET";
    GetDeviceSessionsFlowTypesEnum["PAYMENT"] = "PAYMENT";
    GetDeviceSessionsFlowTypesEnum["DEBITCARD_VERIFICATION"] = "DEBITCARD_VERIFICATION";
    GetDeviceSessionsFlowTypesEnum["LOGIN_PIN"] = "LOGIN_PIN";
    GetDeviceSessionsFlowTypesEnum["LOGIN_BIOMETRIC"] = "LOGIN_BIOMETRIC";
    GetDeviceSessionsFlowTypesEnum["CARD_ADD"] = "CARD_ADD";
    GetDeviceSessionsFlowTypesEnum["L1_TO_L4_LEVEL_UP"] = "L1_TO_L4_LEVEL_UP";
    GetDeviceSessionsFlowTypesEnum["L2_TO_L4_LEVEL_UP"] = "L2_TO_L4_LEVEL_UP";
    GetDeviceSessionsFlowTypesEnum["L3_TO_L4_LEVEL_UP"] = "L3_TO_L4_LEVEL_UP";
})(GetDeviceSessionsFlowTypesEnum || (GetDeviceSessionsFlowTypesEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var GetPlaidEventEventTypeEnum;
(function (GetPlaidEventEventTypeEnum) {
    GetPlaidEventEventTypeEnum["AUTH_GET"] = "AUTH_GET";
    GetPlaidEventEventTypeEnum["INSTITUTION_GET_BY_ID"] = "INSTITUTION_GET_BY_ID";
    GetPlaidEventEventTypeEnum["ITEM_PUBLIC_TOKEN_EXCHANGE"] = "ITEM_PUBLIC_TOKEN_EXCHANGE";
    GetPlaidEventEventTypeEnum["LINK_TOKEN_CREATE"] = "LINK_TOKEN_CREATE";
    GetPlaidEventEventTypeEnum["LINK_TOKEN_REFRESH"] = "LINK_TOKEN_REFRESH";
    GetPlaidEventEventTypeEnum["ACCOUNT_BALANCE_GET"] = "ACCOUNT_BALANCE_GET";
    GetPlaidEventEventTypeEnum["IDENTITY_GET"] = "IDENTITY_GET";
    GetPlaidEventEventTypeEnum["TRANSACTIONS_GET"] = "TRANSACTIONS_GET";
    GetPlaidEventEventTypeEnum["PROCESSOR_AUTH_GET"] = "PROCESSOR_AUTH_GET";
    GetPlaidEventEventTypeEnum["PROCESSOR_BALANCE_GET"] = "PROCESSOR_BALANCE_GET";
})(GetPlaidEventEventTypeEnum || (GetPlaidEventEventTypeEnum = {}));
/**
 * BusinessPortalReportControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalReportControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} reportName
         * @param {DownloadReportReportTypeEnum} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport: (reportName, reportType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reportName' is not null or undefined
            assertParamExists('downloadReport', 'reportName', reportName);
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('downloadReport', 'reportType', reportType);
            const localVarPath = `/business-portal/report/download/{reportType}/{reportName}`
                .replace(`{${"reportName"}}`, encodeURIComponent(String(reportName)))
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllPartnerNameAndId: (reportType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('fetchAllPartnerNameAndId', 'reportType', reportType);
            const localVarPath = `/business-portal/report/{reportType}/partner/list`
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} reportName
         * @param {GenerateDownloadReportReportTypeEnum} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDownloadReport: (reportName, reportType, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'reportName' is not null or undefined
            assertParamExists('generateDownloadReport', 'reportName', reportName);
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('generateDownloadReport', 'reportType', reportType);
            const localVarPath = `/business-portal/report/downloadlink/{reportType}/{reportName}`
                .replace(`{${"reportName"}}`, encodeURIComponent(String(reportName)))
                .replace(`{${"reportType"}}`, encodeURIComponent(String(reportType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiDateRangeReportRequest} apiDateRangeReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport: (apiDateRangeReportRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiDateRangeReportRequest' is not null or undefined
            assertParamExists('generateReport', 'apiDateRangeReportRequest', apiDateRangeReportRequest);
            const localVarPath = `/business-portal/report/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDateRangeReportRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiReportListRequest} apiReportListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableReports: (apiReportListRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiReportListRequest' is not null or undefined
            assertParamExists('getAvailableReports', 'apiReportListRequest', apiReportListRequest);
            const localVarPath = `/business-portal/report/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiReportListRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalReportControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalReportControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalReportControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} reportName
         * @param {DownloadReportReportTypeEnum} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(reportName, reportType, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadReport(reportName, reportType, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalReportControllerApi.downloadReport']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllPartnerNameAndId(reportType, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchAllPartnerNameAndId(reportType, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalReportControllerApi.fetchAllPartnerNameAndId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} reportName
         * @param {GenerateDownloadReportReportTypeEnum} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDownloadReport(reportName, reportType, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateDownloadReport(reportName, reportType, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalReportControllerApi.generateDownloadReport']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiDateRangeReportRequest} apiDateRangeReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(apiDateRangeReportRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateReport(apiDateRangeReportRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalReportControllerApi.generateReport']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiReportListRequest} apiReportListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableReports(apiReportListRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAvailableReports(apiReportListRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalReportControllerApi.getAvailableReports']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalReportControllerApi - factory interface
 * @export
 */
export const BusinessPortalReportControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalReportControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} reportName
         * @param {DownloadReportReportTypeEnum} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReport(reportName, reportType, options) {
            return localVarFp.downloadReport(reportName, reportType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchAllPartnerNameAndId(reportType, options) {
            return localVarFp.fetchAllPartnerNameAndId(reportType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} reportName
         * @param {GenerateDownloadReportReportTypeEnum} reportType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateDownloadReport(reportName, reportType, options) {
            return localVarFp.generateDownloadReport(reportName, reportType, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiDateRangeReportRequest} apiDateRangeReportRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReport(apiDateRangeReportRequest, options) {
            return localVarFp.generateReport(apiDateRangeReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiReportListRequest} apiReportListRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableReports(apiReportListRequest, options) {
            return localVarFp.getAvailableReports(apiReportListRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalReportControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalReportControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalReportControllerApi extends BaseAPI {
    /**
     *
     * @param {string} reportName
     * @param {DownloadReportReportTypeEnum} reportType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalReportControllerApi
     */
    downloadReport(reportName, reportType, options) {
        return BusinessPortalReportControllerApiFp(this.configuration).downloadReport(reportName, reportType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} reportType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalReportControllerApi
     */
    fetchAllPartnerNameAndId(reportType, options) {
        return BusinessPortalReportControllerApiFp(this.configuration).fetchAllPartnerNameAndId(reportType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} reportName
     * @param {GenerateDownloadReportReportTypeEnum} reportType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalReportControllerApi
     */
    generateDownloadReport(reportName, reportType, options) {
        return BusinessPortalReportControllerApiFp(this.configuration).generateDownloadReport(reportName, reportType, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiDateRangeReportRequest} apiDateRangeReportRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalReportControllerApi
     */
    generateReport(apiDateRangeReportRequest, options) {
        return BusinessPortalReportControllerApiFp(this.configuration).generateReport(apiDateRangeReportRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiReportListRequest} apiReportListRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalReportControllerApi
     */
    getAvailableReports(apiReportListRequest, options) {
        return BusinessPortalReportControllerApiFp(this.configuration).getAvailableReports(apiReportListRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var DownloadReportReportTypeEnum;
(function (DownloadReportReportTypeEnum) {
    DownloadReportReportTypeEnum["ASSET_ACTIVTY"] = "ASSET_ACTIVTY";
    DownloadReportReportTypeEnum["CAESARS_ACTIVITY"] = "CAESARS_ACTIVITY";
    DownloadReportReportTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    DownloadReportReportTypeEnum["PREFUNDING"] = "PREFUNDING";
    DownloadReportReportTypeEnum["VIRTUAL_CURRENCY"] = "VIRTUAL_CURRENCY";
    DownloadReportReportTypeEnum["SIFT"] = "SIFT";
    DownloadReportReportTypeEnum["IIM_RECONCILATION_REPORT"] = "IIM_RECONCILATION_REPORT";
    DownloadReportReportTypeEnum["MERCHANT_RECONCILIATION_REPORT"] = "MERCHANT_RECONCILIATION_REPORT";
    DownloadReportReportTypeEnum["STARBUCKS_RECONCILIATION_REPORT"] = "STARBUCKS_RECONCILIATION_REPORT";
    DownloadReportReportTypeEnum["PARTNER_SETTLEMENT_REPORT"] = "PARTNER_SETTLEMENT_REPORT";
    DownloadReportReportTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    DownloadReportReportTypeEnum["ACCOUNT_BALANCE"] = "ACCOUNT_BALANCE";
    DownloadReportReportTypeEnum["ACCOUNT_BALANCE_V2"] = "ACCOUNT_BALANCE_V2";
    DownloadReportReportTypeEnum["ACCOUNT_BALANCE_SUMMARY"] = "ACCOUNT_BALANCE_SUMMARY";
    DownloadReportReportTypeEnum["ALL_CURRENCY_MTL"] = "ALL_CURRENCY_MTL";
    DownloadReportReportTypeEnum["MTL"] = "MTL";
    DownloadReportReportTypeEnum["ADOTMTL"] = "ADOTMTL";
    DownloadReportReportTypeEnum["BTC_TRANSACTION_REPORT"] = "BTC_TRANSACTION_REPORT";
    DownloadReportReportTypeEnum["CRYPTO_TRANSACTION_REPORT"] = "CRYPTO_TRANSACTION_REPORT";
    DownloadReportReportTypeEnum["PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT"] = "PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT";
    DownloadReportReportTypeEnum["SUSPEND_ACCOUNT"] = "SUSPEND_ACCOUNT";
    DownloadReportReportTypeEnum["GIFT_CARD_RECON"] = "GIFT_CARD_RECON";
    DownloadReportReportTypeEnum["GIFT_CARD_REDEEM_RECON"] = "GIFT_CARD_REDEEM_RECON";
    DownloadReportReportTypeEnum["GIFT_CARD_RECON_MONTH"] = "GIFT_CARD_RECON_MONTH";
    DownloadReportReportTypeEnum["GIFT_CARD_REDEEM_RECON_MONTH"] = "GIFT_CARD_REDEEM_RECON_MONTH";
    DownloadReportReportTypeEnum["ACH_FILE_RETURNS"] = "ACH_FILE_RETURNS";
    DownloadReportReportTypeEnum["ACH_FILE_OUTBOUND"] = "ACH_FILE_OUTBOUND";
    DownloadReportReportTypeEnum["PARTNER_RECONCILIATION_REPORT"] = "PARTNER_RECONCILIATION_REPORT";
    DownloadReportReportTypeEnum["CHOICE"] = "CHOICE";
    DownloadReportReportTypeEnum["QUIZNOS"] = "QUIZNOS";
    DownloadReportReportTypeEnum["PARTNER_INCENTIVE"] = "PARTNER_INCENTIVE";
    DownloadReportReportTypeEnum["PARTNER_CASH_ACTIVITY"] = "PARTNER_CASH_ACTIVITY";
    DownloadReportReportTypeEnum["WIRE_TRANSFER_ACTIVITY_REVIEW"] = "WIRE_TRANSFER_ACTIVITY_REVIEW";
    DownloadReportReportTypeEnum["PARTNER_CASH_ACTIVITY_APEX"] = "PARTNER_CASH_ACTIVITY_APEX";
    DownloadReportReportTypeEnum["PARTNER_CASH_BALANCE"] = "PARTNER_CASH_BALANCE";
    DownloadReportReportTypeEnum["PARTY_CSI_OFAC_CHECK"] = "PARTY_CSI_OFAC_CHECK";
    DownloadReportReportTypeEnum["FISERV_PAYMENT_STATUS"] = "FISERV_PAYMENT_STATUS";
    DownloadReportReportTypeEnum["DEPOSITE_BALANCE_CHECK_REPORT"] = "DEPOSITE_BALANCE_CHECK_REPORT";
    DownloadReportReportTypeEnum["NYDFS_CUSTOMER_REPORT"] = "NYDFS_CUSTOMER_REPORT";
})(DownloadReportReportTypeEnum || (DownloadReportReportTypeEnum = {}));
/**
  * @export
  * @enum {string}
  */
export var GenerateDownloadReportReportTypeEnum;
(function (GenerateDownloadReportReportTypeEnum) {
    GenerateDownloadReportReportTypeEnum["ASSET_ACTIVTY"] = "ASSET_ACTIVTY";
    GenerateDownloadReportReportTypeEnum["CAESARS_ACTIVITY"] = "CAESARS_ACTIVITY";
    GenerateDownloadReportReportTypeEnum["CHARGEBACK"] = "CHARGEBACK";
    GenerateDownloadReportReportTypeEnum["PREFUNDING"] = "PREFUNDING";
    GenerateDownloadReportReportTypeEnum["VIRTUAL_CURRENCY"] = "VIRTUAL_CURRENCY";
    GenerateDownloadReportReportTypeEnum["SIFT"] = "SIFT";
    GenerateDownloadReportReportTypeEnum["IIM_RECONCILATION_REPORT"] = "IIM_RECONCILATION_REPORT";
    GenerateDownloadReportReportTypeEnum["MERCHANT_RECONCILIATION_REPORT"] = "MERCHANT_RECONCILIATION_REPORT";
    GenerateDownloadReportReportTypeEnum["STARBUCKS_RECONCILIATION_REPORT"] = "STARBUCKS_RECONCILIATION_REPORT";
    GenerateDownloadReportReportTypeEnum["PARTNER_SETTLEMENT_REPORT"] = "PARTNER_SETTLEMENT_REPORT";
    GenerateDownloadReportReportTypeEnum["DISPUTE_CREDIT"] = "DISPUTE_CREDIT";
    GenerateDownloadReportReportTypeEnum["ACCOUNT_BALANCE"] = "ACCOUNT_BALANCE";
    GenerateDownloadReportReportTypeEnum["ACCOUNT_BALANCE_V2"] = "ACCOUNT_BALANCE_V2";
    GenerateDownloadReportReportTypeEnum["ACCOUNT_BALANCE_SUMMARY"] = "ACCOUNT_BALANCE_SUMMARY";
    GenerateDownloadReportReportTypeEnum["ALL_CURRENCY_MTL"] = "ALL_CURRENCY_MTL";
    GenerateDownloadReportReportTypeEnum["MTL"] = "MTL";
    GenerateDownloadReportReportTypeEnum["ADOTMTL"] = "ADOTMTL";
    GenerateDownloadReportReportTypeEnum["BTC_TRANSACTION_REPORT"] = "BTC_TRANSACTION_REPORT";
    GenerateDownloadReportReportTypeEnum["CRYPTO_TRANSACTION_REPORT"] = "CRYPTO_TRANSACTION_REPORT";
    GenerateDownloadReportReportTypeEnum["PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT"] = "PARTNER_PARTY_CRYPTO_TRANSACTION_REPORT";
    GenerateDownloadReportReportTypeEnum["SUSPEND_ACCOUNT"] = "SUSPEND_ACCOUNT";
    GenerateDownloadReportReportTypeEnum["GIFT_CARD_RECON"] = "GIFT_CARD_RECON";
    GenerateDownloadReportReportTypeEnum["GIFT_CARD_REDEEM_RECON"] = "GIFT_CARD_REDEEM_RECON";
    GenerateDownloadReportReportTypeEnum["GIFT_CARD_RECON_MONTH"] = "GIFT_CARD_RECON_MONTH";
    GenerateDownloadReportReportTypeEnum["GIFT_CARD_REDEEM_RECON_MONTH"] = "GIFT_CARD_REDEEM_RECON_MONTH";
    GenerateDownloadReportReportTypeEnum["ACH_FILE_RETURNS"] = "ACH_FILE_RETURNS";
    GenerateDownloadReportReportTypeEnum["ACH_FILE_OUTBOUND"] = "ACH_FILE_OUTBOUND";
    GenerateDownloadReportReportTypeEnum["PARTNER_RECONCILIATION_REPORT"] = "PARTNER_RECONCILIATION_REPORT";
    GenerateDownloadReportReportTypeEnum["CHOICE"] = "CHOICE";
    GenerateDownloadReportReportTypeEnum["QUIZNOS"] = "QUIZNOS";
    GenerateDownloadReportReportTypeEnum["PARTNER_INCENTIVE"] = "PARTNER_INCENTIVE";
    GenerateDownloadReportReportTypeEnum["PARTNER_CASH_ACTIVITY"] = "PARTNER_CASH_ACTIVITY";
    GenerateDownloadReportReportTypeEnum["WIRE_TRANSFER_ACTIVITY_REVIEW"] = "WIRE_TRANSFER_ACTIVITY_REVIEW";
    GenerateDownloadReportReportTypeEnum["PARTNER_CASH_ACTIVITY_APEX"] = "PARTNER_CASH_ACTIVITY_APEX";
    GenerateDownloadReportReportTypeEnum["PARTNER_CASH_BALANCE"] = "PARTNER_CASH_BALANCE";
    GenerateDownloadReportReportTypeEnum["PARTY_CSI_OFAC_CHECK"] = "PARTY_CSI_OFAC_CHECK";
    GenerateDownloadReportReportTypeEnum["FISERV_PAYMENT_STATUS"] = "FISERV_PAYMENT_STATUS";
    GenerateDownloadReportReportTypeEnum["DEPOSITE_BALANCE_CHECK_REPORT"] = "DEPOSITE_BALANCE_CHECK_REPORT";
    GenerateDownloadReportReportTypeEnum["NYDFS_CUSTOMER_REPORT"] = "NYDFS_CUSTOMER_REPORT";
})(GenerateDownloadReportReportTypeEnum || (GenerateDownloadReportReportTypeEnum = {}));
/**
 * BusinessPortalRiskControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalRiskControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelist: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('getWhitelist', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/whitelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalRiskControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalRiskControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalRiskControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelist(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getWhitelist(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalRiskControllerApi.getWhitelist']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalRiskControllerApi - factory interface
 * @export
 */
export const BusinessPortalRiskControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalRiskControllerApiFp(configuration);
    return {
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelist(searchParameters, options) {
            return localVarFp.getWhitelist(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalRiskControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalRiskControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalRiskControllerApi extends BaseAPI {
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalRiskControllerApi
     */
    getWhitelist(searchParameters, options) {
        return BusinessPortalRiskControllerApiFp(this.configuration).getWhitelist(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * BusinessPortalTicketControllerApi - axios parameter creator
 * @export
 */
export const BusinessPortalTicketControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} ticketId
         * @param {ApiCommentRequest} apiCommentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: (ticketId, apiCommentRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('addComment', 'ticketId', ticketId);
            // verify required parameter 'apiCommentRequest' is not null or undefined
            assertParamExists('addComment', 'apiCommentRequest', apiCommentRequest);
            const localVarPath = `/business-portal/ticket/{ticketId}/comment`
                .replace(`{${"ticketId"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCommentRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiTicketAssignRequest} apiTicketAssignRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTicket: (apiTicketAssignRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiTicketAssignRequest' is not null or undefined
            assertParamExists('assignTicket', 'apiTicketAssignRequest', apiTicketAssignRequest);
            const localVarPath = `/business-portal/ticket/assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTicketAssignRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('getComments', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/ticket/comment/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTickets: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchTickets', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/ticket/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * BusinessPortalTicketControllerApi - functional programming interface
 * @export
 */
export const BusinessPortalTicketControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = BusinessPortalTicketControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} ticketId
         * @param {ApiCommentRequest} apiCommentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(ticketId, apiCommentRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addComment(ticketId, apiCommentRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalTicketControllerApi.addComment']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiTicketAssignRequest} apiTicketAssignRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTicket(apiTicketAssignRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.assignTicket(apiTicketAssignRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalTicketControllerApi.assignTicket']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getComments(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalTicketControllerApi.getComments']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTickets(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchTickets(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['BusinessPortalTicketControllerApi.searchTickets']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * BusinessPortalTicketControllerApi - factory interface
 * @export
 */
export const BusinessPortalTicketControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = BusinessPortalTicketControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} ticketId
         * @param {ApiCommentRequest} apiCommentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(ticketId, apiCommentRequest, options) {
            return localVarFp.addComment(ticketId, apiCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiTicketAssignRequest} apiTicketAssignRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignTicket(apiTicketAssignRequest, options) {
            return localVarFp.assignTicket(apiTicketAssignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComments(searchParameters, options) {
            return localVarFp.getComments(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTickets(searchParameters, options) {
            return localVarFp.searchTickets(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * BusinessPortalTicketControllerApi - object-oriented interface
 * @export
 * @class BusinessPortalTicketControllerApi
 * @extends {BaseAPI}
 */
export class BusinessPortalTicketControllerApi extends BaseAPI {
    /**
     *
     * @param {string} ticketId
     * @param {ApiCommentRequest} apiCommentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalTicketControllerApi
     */
    addComment(ticketId, apiCommentRequest, options) {
        return BusinessPortalTicketControllerApiFp(this.configuration).addComment(ticketId, apiCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiTicketAssignRequest} apiTicketAssignRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalTicketControllerApi
     */
    assignTicket(apiTicketAssignRequest, options) {
        return BusinessPortalTicketControllerApiFp(this.configuration).assignTicket(apiTicketAssignRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalTicketControllerApi
     */
    getComments(searchParameters, options) {
        return BusinessPortalTicketControllerApiFp(this.configuration).getComments(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BusinessPortalTicketControllerApi
     */
    searchTickets(searchParameters, options) {
        return BusinessPortalTicketControllerApiFp(this.configuration).searchTickets(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * EvilAchTransactionControllerApi - axios parameter creator
 * @export
 */
export const EvilAchTransactionControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} achTransactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchTransaction: (achTransactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achTransactionId' is not null or undefined
            assertParamExists('getAchTransaction', 'achTransactionId', achTransactionId);
            const localVarPath = `/aof/evil/ach-transactions/{achTransactionId}`
                .replace(`{${"achTransactionId"}}`, encodeURIComponent(String(achTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} accountId
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchTransactions: (accountId, fromDate, toDate, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getAchTransactions', 'accountId', accountId);
            const localVarPath = `/aof/evil/ach-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }
            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate instanceof Date) ?
                    fromDate.toISOString() :
                    fromDate;
            }
            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate instanceof Date) ?
                    toDate.toISOString() :
                    toDate;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} achTransactionId
         * @param {ApiUpdateAchTransactionRequest} apiUpdateAchTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAchTransaction: (achTransactionId, apiUpdateAchTransactionRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'achTransactionId' is not null or undefined
            assertParamExists('updateAchTransaction', 'achTransactionId', achTransactionId);
            // verify required parameter 'apiUpdateAchTransactionRequest' is not null or undefined
            assertParamExists('updateAchTransaction', 'apiUpdateAchTransactionRequest', apiUpdateAchTransactionRequest);
            const localVarPath = `/aof/evil/ach-transactions/{achTransactionId}`
                .replace(`{${"achTransactionId"}}`, encodeURIComponent(String(achTransactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAchTransactionRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * EvilAchTransactionControllerApi - functional programming interface
 * @export
 */
export const EvilAchTransactionControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = EvilAchTransactionControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} achTransactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchTransaction(achTransactionId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAchTransaction(achTransactionId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['EvilAchTransactionControllerApi.getAchTransaction']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} accountId
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchTransactions(accountId, fromDate, toDate, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAchTransactions(accountId, fromDate, toDate, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['EvilAchTransactionControllerApi.getAchTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} achTransactionId
         * @param {ApiUpdateAchTransactionRequest} apiUpdateAchTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAchTransaction(achTransactionId, apiUpdateAchTransactionRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateAchTransaction(achTransactionId, apiUpdateAchTransactionRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['EvilAchTransactionControllerApi.updateAchTransaction']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * EvilAchTransactionControllerApi - factory interface
 * @export
 */
export const EvilAchTransactionControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = EvilAchTransactionControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} achTransactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchTransaction(achTransactionId, options) {
            return localVarFp.getAchTransaction(achTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} accountId
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAchTransactions(accountId, fromDate, toDate, page, size, sort, options) {
            return localVarFp.getAchTransactions(accountId, fromDate, toDate, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} achTransactionId
         * @param {ApiUpdateAchTransactionRequest} apiUpdateAchTransactionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAchTransaction(achTransactionId, apiUpdateAchTransactionRequest, options) {
            return localVarFp.updateAchTransaction(achTransactionId, apiUpdateAchTransactionRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * EvilAchTransactionControllerApi - object-oriented interface
 * @export
 * @class EvilAchTransactionControllerApi
 * @extends {BaseAPI}
 */
export class EvilAchTransactionControllerApi extends BaseAPI {
    /**
     *
     * @param {string} achTransactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvilAchTransactionControllerApi
     */
    getAchTransaction(achTransactionId, options) {
        return EvilAchTransactionControllerApiFp(this.configuration).getAchTransaction(achTransactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} accountId
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvilAchTransactionControllerApi
     */
    getAchTransactions(accountId, fromDate, toDate, page, size, sort, options) {
        return EvilAchTransactionControllerApiFp(this.configuration).getAchTransactions(accountId, fromDate, toDate, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} achTransactionId
     * @param {ApiUpdateAchTransactionRequest} apiUpdateAchTransactionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvilAchTransactionControllerApi
     */
    updateAchTransaction(achTransactionId, apiUpdateAchTransactionRequest, options) {
        return EvilAchTransactionControllerApiFp(this.configuration).updateAchTransaction(achTransactionId, apiUpdateAchTransactionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * NewPartySignUpFlowApi - axios parameter creator
 * @export
 */
export const NewPartySignUpFlowApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeParty: (apiFinalizeRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('finalizeParty', 'apiFinalizeRequest', apiFinalizeRequest);
            const localVarPath = `/aof/party/finalize-party`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * NewPartySignUpFlowApi - functional programming interface
 * @export
 */
export const NewPartySignUpFlowApiFp = function (configuration) {
    const localVarAxiosParamCreator = NewPartySignUpFlowApiAxiosParamCreator(configuration);
    return {
        /**
         * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeParty(apiFinalizeRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.finalizeParty(apiFinalizeRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['NewPartySignUpFlowApi.finalizeParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * NewPartySignUpFlowApi - factory interface
 * @export
 */
export const NewPartySignUpFlowApiFactory = function (configuration, basePath, axios) {
    const localVarFp = NewPartySignUpFlowApiFp(configuration);
    return {
        /**
         * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeParty(apiFinalizeRequest, xTrackingId, options) {
            return localVarFp.finalizeParty(apiFinalizeRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * NewPartySignUpFlowApi - object-oriented interface
 * @export
 * @class NewPartySignUpFlowApi
 * @extends {BaseAPI}
 */
export class NewPartySignUpFlowApi extends BaseAPI {
    /**
     * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
     * @summary Finalize Party Sign Up
     * @param {ApiFinalizeRequest} apiFinalizeRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewPartySignUpFlowApi
     */
    finalizeParty(apiFinalizeRequest, xTrackingId, options) {
        return NewPartySignUpFlowApiFp(this.configuration).finalizeParty(apiFinalizeRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OfficeOfForeignAssetsControlOFACApi - axios parameter creator
 * @export
 */
export const OfficeOfForeignAssetsControlOFACApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {ApiCreateOfacRuleRequest} apiCreateOfacRuleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfacRule: (apiCreateOfacRuleRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCreateOfacRuleRequest' is not null or undefined
            assertParamExists('createOfacRule', 'apiCreateOfacRuleRequest', apiCreateOfacRuleRequest);
            const localVarPath = `/aof/ofac/rule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreateOfacRuleRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfacRule: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOfacRule', 'id', id);
            const localVarPath = `/aof/ofac/rule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfacRules: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/ofac/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchedParties: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/ofac/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchedPartyAudits: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('listMatchedPartyAudits', 'partyId', partyId);
            const localVarPath = `/aof/ofac/matches/{partyId}/audits`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiOfacRequest} apiOfacRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markActedByCompliance: (apiOfacRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiOfacRequest' is not null or undefined
            assertParamExists('markActedByCompliance', 'apiOfacRequest', apiOfacRequest);
            const localVarPath = `/aof/ofac/match/acted-upon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiOfacRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSdnRecords: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchSdnRecords', 'searchParameters', searchParameters);
            const localVarPath = `/aof/ofac/sdn/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OfficeOfForeignAssetsControlOFACApi - functional programming interface
 * @export
 */
export const OfficeOfForeignAssetsControlOFACApiFp = function (configuration) {
    const localVarAxiosParamCreator = OfficeOfForeignAssetsControlOFACApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {ApiCreateOfacRuleRequest} apiCreateOfacRuleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfacRule(apiCreateOfacRuleRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOfacRule(apiCreateOfacRuleRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.createOfacRule']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfacRule(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteOfacRule(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.deleteOfacRule']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfacRules(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOfacRules(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.getOfacRules']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchedParties(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listMatchedParties(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.listMatchedParties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchedPartyAudits(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listMatchedPartyAudits(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.listMatchedPartyAudits']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiOfacRequest} apiOfacRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markActedByCompliance(apiOfacRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.markActedByCompliance(apiOfacRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.markActedByCompliance']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSdnRecords(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchSdnRecords(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OfficeOfForeignAssetsControlOFACApi.searchSdnRecords']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * OfficeOfForeignAssetsControlOFACApi - factory interface
 * @export
 */
export const OfficeOfForeignAssetsControlOFACApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OfficeOfForeignAssetsControlOFACApiFp(configuration);
    return {
        /**
         *
         * @param {ApiCreateOfacRuleRequest} apiCreateOfacRuleRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfacRule(apiCreateOfacRuleRequest, options) {
            return localVarFp.createOfacRule(apiCreateOfacRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfacRule(id, options) {
            return localVarFp.deleteOfacRule(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfacRules(options) {
            return localVarFp.getOfacRules(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchedParties(options) {
            return localVarFp.listMatchedParties(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchedPartyAudits(partyId, options) {
            return localVarFp.listMatchedPartyAudits(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiOfacRequest} apiOfacRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markActedByCompliance(apiOfacRequest, options) {
            return localVarFp.markActedByCompliance(apiOfacRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchSdnRecords(searchParameters, options) {
            return localVarFp.searchSdnRecords(searchParameters, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OfficeOfForeignAssetsControlOFACApi - object-oriented interface
 * @export
 * @class OfficeOfForeignAssetsControlOFACApi
 * @extends {BaseAPI}
 */
export class OfficeOfForeignAssetsControlOFACApi extends BaseAPI {
    /**
     *
     * @param {ApiCreateOfacRuleRequest} apiCreateOfacRuleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    createOfacRule(apiCreateOfacRuleRequest, options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).createOfacRule(apiCreateOfacRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    deleteOfacRule(id, options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).deleteOfacRule(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    getOfacRules(options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).getOfacRules(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    listMatchedParties(options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).listMatchedParties(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    listMatchedPartyAudits(partyId, options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).listMatchedPartyAudits(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiOfacRequest} apiOfacRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    markActedByCompliance(apiOfacRequest, options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).markActedByCompliance(apiOfacRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfficeOfForeignAssetsControlOFACApi
     */
    searchSdnRecords(searchParameters, options) {
        return OfficeOfForeignAssetsControlOFACApiFp(this.configuration).searchSdnRecords(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartnerAPIAdminApi - axios parameter creator
 * @export
 */
export const PartnerAPIAdminApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Generate Settlement file for a given date
         * @param {string} partnerId
         * @param {ApiPartnerBatchCompleteRequest} apiPartnerBatchCompleteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCompletion: (partnerId, apiPartnerBatchCompleteRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('batchCompletion', 'partnerId', partnerId);
            // verify required parameter 'apiPartnerBatchCompleteRequest' is not null or undefined
            assertParamExists('batchCompletion', 'apiPartnerBatchCompleteRequest', apiPartnerBatchCompleteRequest);
            const localVarPath = `/partner/report/{partnerId}/batchComplete`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerBatchCompleteRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Clear Partner Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/partner/cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Clear Partner config & hierarchy Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRiskConfigAndHierarchyCache: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/partner/clear/risk-config/cache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create Partner
         * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner: (apiCreatePartnerRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCreatePartnerRequest' is not null or undefined
            assertParamExists('createPartner', 'apiCreatePartnerRequest', apiCreatePartnerRequest);
            const localVarPath = `/partner/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreatePartnerRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Create Partner
         * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner1: (apiCreatePartnerRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCreatePartnerRequest' is not null or undefined
            assertParamExists('createPartner1', 'apiCreatePartnerRequest', apiCreatePartnerRequest);
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreatePartnerRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate Reconciliation file for a given date
         * @param {ApiPartnerReconciliationFileRequest} apiPartnerReconciliationFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReconciliationFile: (apiPartnerReconciliationFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartnerReconciliationFileRequest' is not null or undefined
            assertParamExists('generateReconciliationFile', 'apiPartnerReconciliationFileRequest', apiPartnerReconciliationFileRequest);
            const localVarPath = `/partner/report/reconciliation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerReconciliationFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate Settlement file for a given Partner and date
         * @param {string} partnerId
         * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSettlementFile: (partnerId, apiPartnerSettlementFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('generateSettlementFile', 'partnerId', partnerId);
            // verify required parameter 'apiPartnerSettlementFileRequest' is not null or undefined
            assertParamExists('generateSettlementFile', 'apiPartnerSettlementFileRequest', apiPartnerSettlementFileRequest);
            const localVarPath = `/partner/test-support/partner/{partnerId}/report/settlement`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerSettlementFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate Settlement file for a given date
         * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSettlementFile1: (apiPartnerSettlementFileRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartnerSettlementFileRequest' is not null or undefined
            assertParamExists('generateSettlementFile1', 'apiPartnerSettlementFileRequest', apiPartnerSettlementFileRequest);
            const localVarPath = `/partner/report/settlement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerSettlementFileRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/partner/admin/configuration/fiatConfigurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getById', 'partnerId', partnerId);
            const localVarPath = `/partner/admin/configuration/fiatConfigurations/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Cryptocurrency transactions for a given a Partner Id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCryptoCurrencyTransactions: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getCryptoCurrencyTransactions', 'partnerId', partnerId);
            const localVarPath = `/partner/test-support/partner/{partnerId}/cryptocurrency/transactions`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get a list of Enrollment logs for a given party id. If the id is unknown an empty list will be returned.
         * @summary Get Enrollment Logs
         * @param {string} partyId Target Party id. An unknown id will return an empty list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrollmentLogs: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getEnrollmentLogs', 'partyId', partyId);
            const localVarPath = `/partner/test-support/partner/{partyId}/enrollmentlog`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Cryptocurrency ledger postings
         * @param {string} partnerId
         * @param {Pageable} pageable
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLedgerPostings: (partnerId, pageable, from, to, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerLedgerPostings', 'partnerId', partnerId);
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getPartnerLedgerPostings', 'pageable', pageable);
            const localVarPath = `/partner/test-support/partner/{partnerId}/ledger`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] = (from instanceof Date) ?
                    from.toISOString() :
                    from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] = (to instanceof Date) ?
                    to.toISOString() :
                    to;
            }
            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Partner-Party details for a given Party Id
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyDetails: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('getPartnerPartyDetails', 'partyId', partyId);
            const localVarPath = `/partner/test-support/partner/party/{partyId}/details`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Cryptocurrency transaction details for a given transaction id
         * @param {string} transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerTransactionDetails: (transactionId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('getPartnerTransactionDetails', 'transactionId', transactionId);
            const localVarPath = `/partner/test-support/partner/transaction/{transactionId}/details`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate Report has completed processing
         * @param {string} id The id of the results of the target batch process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportCompletion: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReportCompletion', 'id', id);
            const localVarPath = `/partner/report/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get Migrate Partner Wallet Partner to Shared Wallet Partner Results
         * @param {string} partnerId The id of the partner being migrated
         * @param {string} id The id of the results of the target batch process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migratePartnerToSharedWalletComplete: (partnerId, id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('migratePartnerToSharedWalletComplete', 'partnerId', partnerId);
            // verify required parameter 'id' is not null or undefined
            assertParamExists('migratePartnerToSharedWalletComplete', 'id', id);
            const localVarPath = `/partner/migrate/{partnerId}/{id}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Commence Migrate Partner Wallet Partner to Shared Wallet Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migratePartnerToSharedWalletStart: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('migratePartnerToSharedWalletStart', 'partnerId', partnerId);
            const localVarPath = `/partner/migrate/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate Reconciliation file for a given date
         * @param {string} partnerId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performKyc: (partnerId, requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('performKyc', 'partnerId', partnerId);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('performKyc', 'requestBody', requestBody);
            const localVarPath = `/partner/admin/{partnerId}/performKyc`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Refresh IMM partner config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshConfig: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/partner/config-refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve Descendant Partners for a given partner id
         * @summary Retrieve Descendant Partners for a given partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDescendantPartners: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrieveDescendantPartners', 'partnerId', partnerId);
            const localVarPath = `/partner/{partnerId}/descendants`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartner: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrievePartner', 'partnerId', partnerId);
            const localVarPath = `/partner/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Search Partner(s)
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartner: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchPartner', 'searchParameters', searchParameters);
            const localVarPath = `/partner/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerRequests: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchPartnerRequests', 'searchParameters', searchParameters);
            const localVarPath = `/partner/admin/partnerRequest/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Unlink partner API for setting a partner party link to INACTIVE and transferring balances to the Bakkt wallet
         * @summary Unlink enrolled Party
         * @param {string} partnerId Partner that the Party is linked to. May be different to the Partner associated with the credentials.
         * @param {string} partnerPartyLinkIdOrPartnerPartyRef Bakkt\&#39;s Partner to Party Link ID or the Partner\&#39;s primary key for Party
         * @param {UnlinkPartyRequest} unlinkPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkParty: (partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('unlinkParty', 'partnerId', partnerId);
            // verify required parameter 'partnerPartyLinkIdOrPartnerPartyRef' is not null or undefined
            assertParamExists('unlinkParty', 'partnerPartyLinkIdOrPartnerPartyRef', partnerPartyLinkIdOrPartnerPartyRef);
            // verify required parameter 'unlinkPartyRequest' is not null or undefined
            assertParamExists('unlinkParty', 'unlinkPartyRequest', unlinkPartyRequest);
            const localVarPath = `/partner/{partnerId}/party/{partnerPartyLinkIdOrPartnerPartyRef}/unlink`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerPartyLinkIdOrPartnerPartyRef"}}`, encodeURIComponent(String(partnerPartyLinkIdOrPartnerPartyRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=UTF-8';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(unlinkPartyRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update Partner
         * @param {string} partnerId
         * @param {ApiPartner} apiPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner: (partnerId, apiPartner, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartner', 'partnerId', partnerId);
            // verify required parameter 'apiPartner' is not null or undefined
            assertParamExists('updatePartner', 'apiPartner', apiPartner);
            const localVarPath = `/partner/{partnerId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartner, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} partnerId
         * @param {ApiUpdatePartnerPartyEmailRequest} apiUpdatePartnerPartyEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyEmailOnParty: (partnerId, apiUpdatePartnerPartyEmailRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnerPartyEmailOnParty', 'partnerId', partnerId);
            // verify required parameter 'apiUpdatePartnerPartyEmailRequest' is not null or undefined
            assertParamExists('updatePartnerPartyEmailOnParty', 'apiUpdatePartnerPartyEmailRequest', apiUpdatePartnerPartyEmailRequest);
            const localVarPath = `/partner/admin/{partnerId}/updatePartyEmail`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdatePartnerPartyEmailRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update the partner party link status
         * @param {string} partnerId
         * @param {string} partyId
         * @param {TestAdminPartnerPartyLinkStatusRequest} testAdminPartnerPartyLinkStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyLinkStatus: (partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnerPartyLinkStatus', 'partnerId', partnerId);
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('updatePartnerPartyLinkStatus', 'partyId', partyId);
            // verify required parameter 'testAdminPartnerPartyLinkStatusRequest' is not null or undefined
            assertParamExists('updatePartnerPartyLinkStatus', 'testAdminPartnerPartyLinkStatusRequest', testAdminPartnerPartyLinkStatusRequest);
            const localVarPath = `/partner/test-support/partner/{partnerId}/party/{partyId}/link`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(testAdminPartnerPartyLinkStatusRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Calling this endpoint will create the configuration and approve it instantly.A Partner can be configured through this endpoint only when it has no approved configuration.
         * @summary Create Partner spread and commission configuration in IMM.
         * @param {string} partnerId Target Partner id. If unknown the call will fail.
         * @param {ApiPartnerImmConfigUpdateRequest} apiPartnerImmConfigUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerSpreadAndCommissionIMMConfig: (partnerId, apiPartnerImmConfigUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnerSpreadAndCommissionIMMConfig', 'partnerId', partnerId);
            // verify required parameter 'apiPartnerImmConfigUpdateRequest' is not null or undefined
            assertParamExists('updatePartnerSpreadAndCommissionIMMConfig', 'apiPartnerImmConfigUpdateRequest', apiPartnerImmConfigUpdateRequest);
            const localVarPath = `/partner/test-support/partner/{partnerId}/config/spread-and-commission`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerImmConfigUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update the properties of one or more PartnerTransactions in a non-production environment.
         * @summary Update PartnerTransactions
         * @param {ApiPartnerTransactionUpdateRequest} apiPartnerTransactionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerTransactions: (apiPartnerTransactionUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartnerTransactionUpdateRequest' is not null or undefined
            assertParamExists('updatePartnerTransactions', 'apiPartnerTransactionUpdateRequest', apiPartnerTransactionUpdateRequest);
            const localVarPath = `/partner/test-support/partner/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json;charset=UTF-8';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartnerTransactionUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartnerAPIAdminApi - functional programming interface
 * @export
 */
export const PartnerAPIAdminApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartnerAPIAdminApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Generate Settlement file for a given date
         * @param {string} partnerId
         * @param {ApiPartnerBatchCompleteRequest} apiPartnerBatchCompleteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCompletion(partnerId, apiPartnerBatchCompleteRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.batchCompletion(partnerId, apiPartnerBatchCompleteRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.batchCompletion']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Clear Partner Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.clearCache(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.clearCache']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Clear Partner config & hierarchy Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRiskConfigAndHierarchyCache(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.clearRiskConfigAndHierarchyCache(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.clearRiskConfigAndHierarchyCache']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Create Partner
         * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner(apiCreatePartnerRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createPartner(apiCreatePartnerRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.createPartner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Create Partner
         * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner1(apiCreatePartnerRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createPartner1(apiCreatePartnerRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.createPartner1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Generate Reconciliation file for a given date
         * @param {ApiPartnerReconciliationFileRequest} apiPartnerReconciliationFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReconciliationFile(apiPartnerReconciliationFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateReconciliationFile(apiPartnerReconciliationFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.generateReconciliationFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Generate Settlement file for a given Partner and date
         * @param {string} partnerId
         * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSettlementFile(partnerId, apiPartnerSettlementFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateSettlementFile(partnerId, apiPartnerSettlementFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.generateSettlementFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Generate Settlement file for a given date
         * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSettlementFile1(apiPartnerSettlementFileRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateSettlementFile1(apiPartnerSettlementFileRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.generateSettlementFile1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAll(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getAll']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getById(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getById']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get Cryptocurrency transactions for a given a Partner Id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCryptoCurrencyTransactions(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCryptoCurrencyTransactions(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getCryptoCurrencyTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Get a list of Enrollment logs for a given party id. If the id is unknown an empty list will be returned.
         * @summary Get Enrollment Logs
         * @param {string} partyId Target Party id. An unknown id will return an empty list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrollmentLogs(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getEnrollmentLogs(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getEnrollmentLogs']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get Cryptocurrency ledger postings
         * @param {string} partnerId
         * @param {Pageable} pageable
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLedgerPostings(partnerId, pageable, from, to, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerLedgerPostings(partnerId, pageable, from, to, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getPartnerLedgerPostings']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get Partner-Party details for a given Party Id
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyDetails(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerPartyDetails(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getPartnerPartyDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get Cryptocurrency transaction details for a given transaction id
         * @param {string} transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerTransactionDetails(transactionId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerTransactionDetails(transactionId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getPartnerTransactionDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Generate Report has completed processing
         * @param {string} id The id of the results of the target batch process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportCompletion(id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getReportCompletion(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.getReportCompletion']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get Migrate Partner Wallet Partner to Shared Wallet Partner Results
         * @param {string} partnerId The id of the partner being migrated
         * @param {string} id The id of the results of the target batch process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migratePartnerToSharedWalletComplete(partnerId, id, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.migratePartnerToSharedWalletComplete(partnerId, id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.migratePartnerToSharedWalletComplete']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Commence Migrate Partner Wallet Partner to Shared Wallet Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migratePartnerToSharedWalletStart(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.migratePartnerToSharedWalletStart(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.migratePartnerToSharedWalletStart']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Generate Reconciliation file for a given date
         * @param {string} partnerId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performKyc(partnerId, requestBody, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.performKyc(partnerId, requestBody, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.performKyc']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Refresh IMM partner config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshConfig(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.refreshConfig(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.refreshConfig']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Retrieve Descendant Partners for a given partner id
         * @summary Retrieve Descendant Partners for a given partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDescendantPartners(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveDescendantPartners(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.retrieveDescendantPartners']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartner(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartner(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.retrievePartner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Search Partner(s)
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartner(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchPartner(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.searchPartner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerRequests(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchPartnerRequests(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.searchPartnerRequests']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Unlink partner API for setting a partner party link to INACTIVE and transferring balances to the Bakkt wallet
         * @summary Unlink enrolled Party
         * @param {string} partnerId Partner that the Party is linked to. May be different to the Partner associated with the credentials.
         * @param {string} partnerPartyLinkIdOrPartnerPartyRef Bakkt\&#39;s Partner to Party Link ID or the Partner\&#39;s primary key for Party
         * @param {UnlinkPartyRequest} unlinkPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkParty(partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unlinkParty(partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.unlinkParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update Partner
         * @param {string} partnerId
         * @param {ApiPartner} apiPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner(partnerId, apiPartner, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartner(partnerId, apiPartner, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.updatePartner']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} partnerId
         * @param {ApiUpdatePartnerPartyEmailRequest} apiUpdatePartnerPartyEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyEmailOnParty(partnerId, apiUpdatePartnerPartyEmailRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerPartyEmailOnParty(partnerId, apiUpdatePartnerPartyEmailRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.updatePartnerPartyEmailOnParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update the partner party link status
         * @param {string} partnerId
         * @param {string} partyId
         * @param {TestAdminPartnerPartyLinkStatusRequest} testAdminPartnerPartyLinkStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyLinkStatus(partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerPartyLinkStatus(partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.updatePartnerPartyLinkStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Calling this endpoint will create the configuration and approve it instantly.A Partner can be configured through this endpoint only when it has no approved configuration.
         * @summary Create Partner spread and commission configuration in IMM.
         * @param {string} partnerId Target Partner id. If unknown the call will fail.
         * @param {ApiPartnerImmConfigUpdateRequest} apiPartnerImmConfigUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerSpreadAndCommissionIMMConfig(partnerId, apiPartnerImmConfigUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerSpreadAndCommissionIMMConfig(partnerId, apiPartnerImmConfigUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.updatePartnerSpreadAndCommissionIMMConfig']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Update the properties of one or more PartnerTransactions in a non-production environment.
         * @summary Update PartnerTransactions
         * @param {ApiPartnerTransactionUpdateRequest} apiPartnerTransactionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerTransactions(apiPartnerTransactionUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerTransactions(apiPartnerTransactionUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIAdminApi.updatePartnerTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartnerAPIAdminApi - factory interface
 * @export
 */
export const PartnerAPIAdminApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartnerAPIAdminApiFp(configuration);
    return {
        /**
         *
         * @summary Generate Settlement file for a given date
         * @param {string} partnerId
         * @param {ApiPartnerBatchCompleteRequest} apiPartnerBatchCompleteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchCompletion(partnerId, apiPartnerBatchCompleteRequest, options) {
            return localVarFp.batchCompletion(partnerId, apiPartnerBatchCompleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Clear Partner Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearCache(options) {
            return localVarFp.clearCache(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Clear Partner config & hierarchy Cache
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearRiskConfigAndHierarchyCache(options) {
            return localVarFp.clearRiskConfigAndHierarchyCache(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Partner
         * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner(apiCreatePartnerRequest, options) {
            return localVarFp.createPartner(apiCreatePartnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Create Partner
         * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartner1(apiCreatePartnerRequest, options) {
            return localVarFp.createPartner1(apiCreatePartnerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate Reconciliation file for a given date
         * @param {ApiPartnerReconciliationFileRequest} apiPartnerReconciliationFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateReconciliationFile(apiPartnerReconciliationFileRequest, options) {
            return localVarFp.generateReconciliationFile(apiPartnerReconciliationFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate Settlement file for a given Partner and date
         * @param {string} partnerId
         * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSettlementFile(partnerId, apiPartnerSettlementFileRequest, options) {
            return localVarFp.generateSettlementFile(partnerId, apiPartnerSettlementFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate Settlement file for a given date
         * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateSettlementFile1(apiPartnerSettlementFileRequest, options) {
            return localVarFp.generateSettlementFile1(apiPartnerSettlementFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options) {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getById(partnerId, options) {
            return localVarFp.getById(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Cryptocurrency transactions for a given a Partner Id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCryptoCurrencyTransactions(partnerId, options) {
            return localVarFp.getCryptoCurrencyTransactions(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of Enrollment logs for a given party id. If the id is unknown an empty list will be returned.
         * @summary Get Enrollment Logs
         * @param {string} partyId Target Party id. An unknown id will return an empty list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrollmentLogs(partyId, options) {
            return localVarFp.getEnrollmentLogs(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Cryptocurrency ledger postings
         * @param {string} partnerId
         * @param {Pageable} pageable
         * @param {string} [from]
         * @param {string} [to]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLedgerPostings(partnerId, pageable, from, to, options) {
            return localVarFp.getPartnerLedgerPostings(partnerId, pageable, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Partner-Party details for a given Party Id
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyDetails(partyId, options) {
            return localVarFp.getPartnerPartyDetails(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Cryptocurrency transaction details for a given transaction id
         * @param {string} transactionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerTransactionDetails(transactionId, options) {
            return localVarFp.getPartnerTransactionDetails(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate Report has completed processing
         * @param {string} id The id of the results of the target batch process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportCompletion(id, options) {
            return localVarFp.getReportCompletion(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get Migrate Partner Wallet Partner to Shared Wallet Partner Results
         * @param {string} partnerId The id of the partner being migrated
         * @param {string} id The id of the results of the target batch process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migratePartnerToSharedWalletComplete(partnerId, id, options) {
            return localVarFp.migratePartnerToSharedWalletComplete(partnerId, id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Commence Migrate Partner Wallet Partner to Shared Wallet Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migratePartnerToSharedWalletStart(partnerId, options) {
            return localVarFp.migratePartnerToSharedWalletStart(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate Reconciliation file for a given date
         * @param {string} partnerId
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        performKyc(partnerId, requestBody, options) {
            return localVarFp.performKyc(partnerId, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Refresh IMM partner config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshConfig(options) {
            return localVarFp.refreshConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Descendant Partners for a given partner id
         * @summary Retrieve Descendant Partners for a given partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDescendantPartners(partnerId, options) {
            return localVarFp.retrieveDescendantPartners(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartner(partnerId, options) {
            return localVarFp.retrievePartner(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Search Partner(s)
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartner(searchParameters, options) {
            return localVarFp.searchPartner(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPartnerRequests(searchParameters, options) {
            return localVarFp.searchPartnerRequests(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlink partner API for setting a partner party link to INACTIVE and transferring balances to the Bakkt wallet
         * @summary Unlink enrolled Party
         * @param {string} partnerId Partner that the Party is linked to. May be different to the Partner associated with the credentials.
         * @param {string} partnerPartyLinkIdOrPartnerPartyRef Bakkt\&#39;s Partner to Party Link ID or the Partner\&#39;s primary key for Party
         * @param {UnlinkPartyRequest} unlinkPartyRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkParty(partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options) {
            return localVarFp.unlinkParty(partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Partner
         * @param {string} partnerId
         * @param {ApiPartner} apiPartner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartner(partnerId, apiPartner, options) {
            return localVarFp.updatePartner(partnerId, apiPartner, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} partnerId
         * @param {ApiUpdatePartnerPartyEmailRequest} apiUpdatePartnerPartyEmailRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyEmailOnParty(partnerId, apiUpdatePartnerPartyEmailRequest, options) {
            return localVarFp.updatePartnerPartyEmailOnParty(partnerId, apiUpdatePartnerPartyEmailRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update the partner party link status
         * @param {string} partnerId
         * @param {string} partyId
         * @param {TestAdminPartnerPartyLinkStatusRequest} testAdminPartnerPartyLinkStatusRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerPartyLinkStatus(partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options) {
            return localVarFp.updatePartnerPartyLinkStatus(partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Calling this endpoint will create the configuration and approve it instantly.A Partner can be configured through this endpoint only when it has no approved configuration.
         * @summary Create Partner spread and commission configuration in IMM.
         * @param {string} partnerId Target Partner id. If unknown the call will fail.
         * @param {ApiPartnerImmConfigUpdateRequest} apiPartnerImmConfigUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerSpreadAndCommissionIMMConfig(partnerId, apiPartnerImmConfigUpdateRequest, options) {
            return localVarFp.updatePartnerSpreadAndCommissionIMMConfig(partnerId, apiPartnerImmConfigUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the properties of one or more PartnerTransactions in a non-production environment.
         * @summary Update PartnerTransactions
         * @param {ApiPartnerTransactionUpdateRequest} apiPartnerTransactionUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerTransactions(apiPartnerTransactionUpdateRequest, options) {
            return localVarFp.updatePartnerTransactions(apiPartnerTransactionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartnerAPIAdminApi - object-oriented interface
 * @export
 * @class PartnerAPIAdminApi
 * @extends {BaseAPI}
 */
export class PartnerAPIAdminApi extends BaseAPI {
    /**
     *
     * @summary Generate Settlement file for a given date
     * @param {string} partnerId
     * @param {ApiPartnerBatchCompleteRequest} apiPartnerBatchCompleteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    batchCompletion(partnerId, apiPartnerBatchCompleteRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).batchCompletion(partnerId, apiPartnerBatchCompleteRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Clear Partner Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    clearCache(options) {
        return PartnerAPIAdminApiFp(this.configuration).clearCache(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Clear Partner config & hierarchy Cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    clearRiskConfigAndHierarchyCache(options) {
        return PartnerAPIAdminApiFp(this.configuration).clearRiskConfigAndHierarchyCache(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create Partner
     * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    createPartner(apiCreatePartnerRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).createPartner(apiCreatePartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Create Partner
     * @param {ApiCreatePartnerRequest} apiCreatePartnerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    createPartner1(apiCreatePartnerRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).createPartner1(apiCreatePartnerRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate Reconciliation file for a given date
     * @param {ApiPartnerReconciliationFileRequest} apiPartnerReconciliationFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    generateReconciliationFile(apiPartnerReconciliationFileRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).generateReconciliationFile(apiPartnerReconciliationFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate Settlement file for a given Partner and date
     * @param {string} partnerId
     * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    generateSettlementFile(partnerId, apiPartnerSettlementFileRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).generateSettlementFile(partnerId, apiPartnerSettlementFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate Settlement file for a given date
     * @param {ApiPartnerSettlementFileRequest} apiPartnerSettlementFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    generateSettlementFile1(apiPartnerSettlementFileRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).generateSettlementFile1(apiPartnerSettlementFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getAll(options) {
        return PartnerAPIAdminApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getById(partnerId, options) {
        return PartnerAPIAdminApiFp(this.configuration).getById(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get Cryptocurrency transactions for a given a Partner Id
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getCryptoCurrencyTransactions(partnerId, options) {
        return PartnerAPIAdminApiFp(this.configuration).getCryptoCurrencyTransactions(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get a list of Enrollment logs for a given party id. If the id is unknown an empty list will be returned.
     * @summary Get Enrollment Logs
     * @param {string} partyId Target Party id. An unknown id will return an empty list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getEnrollmentLogs(partyId, options) {
        return PartnerAPIAdminApiFp(this.configuration).getEnrollmentLogs(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get Cryptocurrency ledger postings
     * @param {string} partnerId
     * @param {Pageable} pageable
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getPartnerLedgerPostings(partnerId, pageable, from, to, options) {
        return PartnerAPIAdminApiFp(this.configuration).getPartnerLedgerPostings(partnerId, pageable, from, to, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get Partner-Party details for a given Party Id
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getPartnerPartyDetails(partyId, options) {
        return PartnerAPIAdminApiFp(this.configuration).getPartnerPartyDetails(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get Cryptocurrency transaction details for a given transaction id
     * @param {string} transactionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getPartnerTransactionDetails(transactionId, options) {
        return PartnerAPIAdminApiFp(this.configuration).getPartnerTransactionDetails(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate Report has completed processing
     * @param {string} id The id of the results of the target batch process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    getReportCompletion(id, options) {
        return PartnerAPIAdminApiFp(this.configuration).getReportCompletion(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get Migrate Partner Wallet Partner to Shared Wallet Partner Results
     * @param {string} partnerId The id of the partner being migrated
     * @param {string} id The id of the results of the target batch process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    migratePartnerToSharedWalletComplete(partnerId, id, options) {
        return PartnerAPIAdminApiFp(this.configuration).migratePartnerToSharedWalletComplete(partnerId, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Commence Migrate Partner Wallet Partner to Shared Wallet Partner
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    migratePartnerToSharedWalletStart(partnerId, options) {
        return PartnerAPIAdminApiFp(this.configuration).migratePartnerToSharedWalletStart(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate Reconciliation file for a given date
     * @param {string} partnerId
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    performKyc(partnerId, requestBody, options) {
        return PartnerAPIAdminApiFp(this.configuration).performKyc(partnerId, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Refresh IMM partner config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    refreshConfig(options) {
        return PartnerAPIAdminApiFp(this.configuration).refreshConfig(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve Descendant Partners for a given partner id
     * @summary Retrieve Descendant Partners for a given partner id
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    retrieveDescendantPartners(partnerId, options) {
        return PartnerAPIAdminApiFp(this.configuration).retrieveDescendantPartners(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve Partner
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    retrievePartner(partnerId, options) {
        return PartnerAPIAdminApiFp(this.configuration).retrievePartner(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Search Partner(s)
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    searchPartner(searchParameters, options) {
        return PartnerAPIAdminApiFp(this.configuration).searchPartner(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    searchPartnerRequests(searchParameters, options) {
        return PartnerAPIAdminApiFp(this.configuration).searchPartnerRequests(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Unlink partner API for setting a partner party link to INACTIVE and transferring balances to the Bakkt wallet
     * @summary Unlink enrolled Party
     * @param {string} partnerId Partner that the Party is linked to. May be different to the Partner associated with the credentials.
     * @param {string} partnerPartyLinkIdOrPartnerPartyRef Bakkt\&#39;s Partner to Party Link ID or the Partner\&#39;s primary key for Party
     * @param {UnlinkPartyRequest} unlinkPartyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    unlinkParty(partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).unlinkParty(partnerId, partnerPartyLinkIdOrPartnerPartyRef, unlinkPartyRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update Partner
     * @param {string} partnerId
     * @param {ApiPartner} apiPartner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    updatePartner(partnerId, apiPartner, options) {
        return PartnerAPIAdminApiFp(this.configuration).updatePartner(partnerId, apiPartner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} partnerId
     * @param {ApiUpdatePartnerPartyEmailRequest} apiUpdatePartnerPartyEmailRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    updatePartnerPartyEmailOnParty(partnerId, apiUpdatePartnerPartyEmailRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).updatePartnerPartyEmailOnParty(partnerId, apiUpdatePartnerPartyEmailRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update the partner party link status
     * @param {string} partnerId
     * @param {string} partyId
     * @param {TestAdminPartnerPartyLinkStatusRequest} testAdminPartnerPartyLinkStatusRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    updatePartnerPartyLinkStatus(partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).updatePartnerPartyLinkStatus(partnerId, partyId, testAdminPartnerPartyLinkStatusRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Calling this endpoint will create the configuration and approve it instantly.A Partner can be configured through this endpoint only when it has no approved configuration.
     * @summary Create Partner spread and commission configuration in IMM.
     * @param {string} partnerId Target Partner id. If unknown the call will fail.
     * @param {ApiPartnerImmConfigUpdateRequest} apiPartnerImmConfigUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    updatePartnerSpreadAndCommissionIMMConfig(partnerId, apiPartnerImmConfigUpdateRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).updatePartnerSpreadAndCommissionIMMConfig(partnerId, apiPartnerImmConfigUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the properties of one or more PartnerTransactions in a non-production environment.
     * @summary Update PartnerTransactions
     * @param {ApiPartnerTransactionUpdateRequest} apiPartnerTransactionUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIAdminApi
     */
    updatePartnerTransactions(apiPartnerTransactionUpdateRequest, options) {
        return PartnerAPIAdminApiFp(this.configuration).updatePartnerTransactions(apiPartnerTransactionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartnerAPIWeblibApi - axios parameter creator
 * @export
 */
export const PartnerAPIWeblibApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get weblib features enabled
         * @param {string} partnerId The id of the partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeblibFeatures: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getWeblibFeatures', 'partnerId', partnerId);
            const localVarPath = `/partner/{partnerId}/weblib`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartnerAPIWeblibApi - functional programming interface
 * @export
 */
export const PartnerAPIWeblibApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartnerAPIWeblibApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Get weblib features enabled
         * @param {string} partnerId The id of the partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeblibFeatures(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getWeblibFeatures(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAPIWeblibApi.getWeblibFeatures']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartnerAPIWeblibApi - factory interface
 * @export
 */
export const PartnerAPIWeblibApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartnerAPIWeblibApiFp(configuration);
    return {
        /**
         *
         * @summary Get weblib features enabled
         * @param {string} partnerId The id of the partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeblibFeatures(partnerId, options) {
            return localVarFp.getWeblibFeatures(partnerId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartnerAPIWeblibApi - object-oriented interface
 * @export
 * @class PartnerAPIWeblibApi
 * @extends {BaseAPI}
 */
export class PartnerAPIWeblibApi extends BaseAPI {
    /**
     *
     * @summary Get weblib features enabled
     * @param {string} partnerId The id of the partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAPIWeblibApi
     */
    getWeblibFeatures(partnerId, options) {
        return PartnerAPIWeblibApiFp(this.configuration).getWeblibFeatures(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartnerAccountAPIAdminApi - axios parameter creator
 * @export
 */
export const PartnerAccountAPIAdminApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Creates Partner Account for a given partner id.
         * @summary Creates Partner Account for a given partner id.
         * @param {string} partnerId
         * @param {ApiProductAccount} apiProductAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerAccount: (partnerId, apiProductAccount, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('createPartnerAccount', 'partnerId', partnerId);
            // verify required parameter 'apiProductAccount' is not null or undefined
            assertParamExists('createPartnerAccount', 'apiProductAccount', apiProductAccount);
            const localVarPath = `/partner/{partnerId}/account`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiProductAccount, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete Partner Account
         * @summary Delete Partner Account
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerAccountDetails: (partnerId, partnerAccountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('deletePartnerAccountDetails', 'partnerId', partnerId);
            // verify required parameter 'partnerAccountId' is not null or undefined
            assertParamExists('deletePartnerAccountDetails', 'partnerAccountId', partnerAccountId);
            const localVarPath = `/partner/{partnerId}/account/{partnerAccountId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerAccountId"}}`, encodeURIComponent(String(partnerAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Replace Partner Account Bank Name, Account, Routing and Products
         * @summary Replace Partner Account Bank Name, Account, Routing and Products
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {ApiReplacePartnerAccountRequest} apiReplacePartnerAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replacePartnerAccountDetails: (partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('replacePartnerAccountDetails', 'partnerId', partnerId);
            // verify required parameter 'partnerAccountId' is not null or undefined
            assertParamExists('replacePartnerAccountDetails', 'partnerAccountId', partnerAccountId);
            // verify required parameter 'apiReplacePartnerAccountRequest' is not null or undefined
            assertParamExists('replacePartnerAccountDetails', 'apiReplacePartnerAccountRequest', apiReplacePartnerAccountRequest);
            const localVarPath = `/partner/{partnerId}/account/{partnerAccountId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerAccountId"}}`, encodeURIComponent(String(partnerAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiReplacePartnerAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve Partner Account for a given partner id and partner account id
         * @summary Retrieve Partner Account for a given partner id and partner account id
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerAccount: (partnerId, partnerAccountId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrievePartnerAccount', 'partnerId', partnerId);
            // verify required parameter 'partnerAccountId' is not null or undefined
            assertParamExists('retrievePartnerAccount', 'partnerAccountId', partnerAccountId);
            const localVarPath = `/partner/{partnerId}/account/{partnerAccountId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerAccountId"}}`, encodeURIComponent(String(partnerAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Retrieve Partner Accounts for a given partner id
         * @summary Retrieve Partner Accounts for a given partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerAccounts: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrievePartnerAccounts', 'partnerId', partnerId);
            const localVarPath = `/partner/{partnerId}/account`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update Partner Account Bank Name, Account or Routing
         * @summary Update Partner Account Bank Name, Account or Routing
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {ApiPatchPartnerAccountRequest} apiPatchPartnerAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAccountDetails: (partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnerAccountDetails', 'partnerId', partnerId);
            // verify required parameter 'partnerAccountId' is not null or undefined
            assertParamExists('updatePartnerAccountDetails', 'partnerAccountId', partnerAccountId);
            // verify required parameter 'apiPatchPartnerAccountRequest' is not null or undefined
            assertParamExists('updatePartnerAccountDetails', 'apiPatchPartnerAccountRequest', apiPatchPartnerAccountRequest);
            const localVarPath = `/partner/{partnerId}/account/{partnerAccountId}`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partnerAccountId"}}`, encodeURIComponent(String(partnerAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPatchPartnerAccountRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartnerAccountAPIAdminApi - functional programming interface
 * @export
 */
export const PartnerAccountAPIAdminApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartnerAccountAPIAdminApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates Partner Account for a given partner id.
         * @summary Creates Partner Account for a given partner id.
         * @param {string} partnerId
         * @param {ApiProductAccount} apiProductAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerAccount(partnerId, apiProductAccount, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createPartnerAccount(partnerId, apiProductAccount, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAccountAPIAdminApi.createPartnerAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Delete Partner Account
         * @summary Delete Partner Account
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerAccountDetails(partnerId, partnerAccountId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deletePartnerAccountDetails(partnerId, partnerAccountId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAccountAPIAdminApi.deletePartnerAccountDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Replace Partner Account Bank Name, Account, Routing and Products
         * @summary Replace Partner Account Bank Name, Account, Routing and Products
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {ApiReplacePartnerAccountRequest} apiReplacePartnerAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replacePartnerAccountDetails(partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.replacePartnerAccountDetails(partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAccountAPIAdminApi.replacePartnerAccountDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Retrieve Partner Account for a given partner id and partner account id
         * @summary Retrieve Partner Account for a given partner id and partner account id
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerAccount(partnerId, partnerAccountId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartnerAccount(partnerId, partnerAccountId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAccountAPIAdminApi.retrievePartnerAccount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Retrieve Partner Accounts for a given partner id
         * @summary Retrieve Partner Accounts for a given partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerAccounts(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartnerAccounts(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAccountAPIAdminApi.retrievePartnerAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Update Partner Account Bank Name, Account or Routing
         * @summary Update Partner Account Bank Name, Account or Routing
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {ApiPatchPartnerAccountRequest} apiPatchPartnerAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAccountDetails(partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePartnerAccountDetails(partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerAccountAPIAdminApi.updatePartnerAccountDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartnerAccountAPIAdminApi - factory interface
 * @export
 */
export const PartnerAccountAPIAdminApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartnerAccountAPIAdminApiFp(configuration);
    return {
        /**
         * Creates Partner Account for a given partner id.
         * @summary Creates Partner Account for a given partner id.
         * @param {string} partnerId
         * @param {ApiProductAccount} apiProductAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPartnerAccount(partnerId, apiProductAccount, options) {
            return localVarFp.createPartnerAccount(partnerId, apiProductAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Partner Account
         * @summary Delete Partner Account
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerAccountDetails(partnerId, partnerAccountId, options) {
            return localVarFp.deletePartnerAccountDetails(partnerId, partnerAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Replace Partner Account Bank Name, Account, Routing and Products
         * @summary Replace Partner Account Bank Name, Account, Routing and Products
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {ApiReplacePartnerAccountRequest} apiReplacePartnerAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replacePartnerAccountDetails(partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options) {
            return localVarFp.replacePartnerAccountDetails(partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Partner Account for a given partner id and partner account id
         * @summary Retrieve Partner Account for a given partner id and partner account id
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerAccount(partnerId, partnerAccountId, options) {
            return localVarFp.retrievePartnerAccount(partnerId, partnerAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve Partner Accounts for a given partner id
         * @summary Retrieve Partner Accounts for a given partner id
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerAccounts(partnerId, options) {
            return localVarFp.retrievePartnerAccounts(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Partner Account Bank Name, Account or Routing
         * @summary Update Partner Account Bank Name, Account or Routing
         * @param {string} partnerId
         * @param {string} partnerAccountId
         * @param {ApiPatchPartnerAccountRequest} apiPatchPartnerAccountRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerAccountDetails(partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options) {
            return localVarFp.updatePartnerAccountDetails(partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartnerAccountAPIAdminApi - object-oriented interface
 * @export
 * @class PartnerAccountAPIAdminApi
 * @extends {BaseAPI}
 */
export class PartnerAccountAPIAdminApi extends BaseAPI {
    /**
     * Creates Partner Account for a given partner id.
     * @summary Creates Partner Account for a given partner id.
     * @param {string} partnerId
     * @param {ApiProductAccount} apiProductAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountAPIAdminApi
     */
    createPartnerAccount(partnerId, apiProductAccount, options) {
        return PartnerAccountAPIAdminApiFp(this.configuration).createPartnerAccount(partnerId, apiProductAccount, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete Partner Account
     * @summary Delete Partner Account
     * @param {string} partnerId
     * @param {string} partnerAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountAPIAdminApi
     */
    deletePartnerAccountDetails(partnerId, partnerAccountId, options) {
        return PartnerAccountAPIAdminApiFp(this.configuration).deletePartnerAccountDetails(partnerId, partnerAccountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Replace Partner Account Bank Name, Account, Routing and Products
     * @summary Replace Partner Account Bank Name, Account, Routing and Products
     * @param {string} partnerId
     * @param {string} partnerAccountId
     * @param {ApiReplacePartnerAccountRequest} apiReplacePartnerAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountAPIAdminApi
     */
    replacePartnerAccountDetails(partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options) {
        return PartnerAccountAPIAdminApiFp(this.configuration).replacePartnerAccountDetails(partnerId, partnerAccountId, apiReplacePartnerAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve Partner Account for a given partner id and partner account id
     * @summary Retrieve Partner Account for a given partner id and partner account id
     * @param {string} partnerId
     * @param {string} partnerAccountId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountAPIAdminApi
     */
    retrievePartnerAccount(partnerId, partnerAccountId, options) {
        return PartnerAccountAPIAdminApiFp(this.configuration).retrievePartnerAccount(partnerId, partnerAccountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Retrieve Partner Accounts for a given partner id
     * @summary Retrieve Partner Accounts for a given partner id
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountAPIAdminApi
     */
    retrievePartnerAccounts(partnerId, options) {
        return PartnerAccountAPIAdminApiFp(this.configuration).retrievePartnerAccounts(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update Partner Account Bank Name, Account or Routing
     * @summary Update Partner Account Bank Name, Account or Routing
     * @param {string} partnerId
     * @param {string} partnerAccountId
     * @param {ApiPatchPartnerAccountRequest} apiPatchPartnerAccountRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerAccountAPIAdminApi
     */
    updatePartnerAccountDetails(partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options) {
        return PartnerAccountAPIAdminApiFp(this.configuration).updatePartnerAccountDetails(partnerId, partnerAccountId, apiPatchPartnerAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartnerBatchAPIAdminApi - axios parameter creator
 * @export
 */
export const PartnerBatchAPIAdminApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Import and Process CSI OFAC Result File
         * @param {string} batchFileNumber
         * @param {string} origBatchFileNumber
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processOFACResultFile: (batchFileNumber, origBatchFileNumber, partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'batchFileNumber' is not null or undefined
            assertParamExists('processOFACResultFile', 'batchFileNumber', batchFileNumber);
            // verify required parameter 'origBatchFileNumber' is not null or undefined
            assertParamExists('processOFACResultFile', 'origBatchFileNumber', origBatchFileNumber);
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('processOFACResultFile', 'partnerId', partnerId);
            const localVarPath = `/partner/csi/file/{batchFileNumber}/origfile/{origBatchFileNumber}/partnerId/{partnerId}/import`
                .replace(`{${"batchFileNumber"}}`, encodeURIComponent(String(batchFileNumber)))
                .replace(`{${"origBatchFileNumber"}}`, encodeURIComponent(String(origBatchFileNumber)))
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartnerBatchAPIAdminApi - functional programming interface
 * @export
 */
export const PartnerBatchAPIAdminApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartnerBatchAPIAdminApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Import and Process CSI OFAC Result File
         * @param {string} batchFileNumber
         * @param {string} origBatchFileNumber
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processOFACResultFile(batchFileNumber, origBatchFileNumber, partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.processOFACResultFile(batchFileNumber, origBatchFileNumber, partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerBatchAPIAdminApi.processOFACResultFile']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartnerBatchAPIAdminApi - factory interface
 * @export
 */
export const PartnerBatchAPIAdminApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartnerBatchAPIAdminApiFp(configuration);
    return {
        /**
         *
         * @summary Import and Process CSI OFAC Result File
         * @param {string} batchFileNumber
         * @param {string} origBatchFileNumber
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processOFACResultFile(batchFileNumber, origBatchFileNumber, partnerId, options) {
            return localVarFp.processOFACResultFile(batchFileNumber, origBatchFileNumber, partnerId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartnerBatchAPIAdminApi - object-oriented interface
 * @export
 * @class PartnerBatchAPIAdminApi
 * @extends {BaseAPI}
 */
export class PartnerBatchAPIAdminApi extends BaseAPI {
    /**
     *
     * @summary Import and Process CSI OFAC Result File
     * @param {string} batchFileNumber
     * @param {string} origBatchFileNumber
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerBatchAPIAdminApi
     */
    processOFACResultFile(batchFileNumber, origBatchFileNumber, partnerId, options) {
        return PartnerBatchAPIAdminApiFp(this.configuration).processOFACResultFile(batchFileNumber, origBatchFileNumber, partnerId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartnerPartyDetailsApi - axios parameter creator
 * @export
 */
export const PartnerPartyDetailsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} apiKey
         * @param {string} partnerPartyLinkOrRefId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyIds: (apiKey, partnerPartyLinkOrRefId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiKey' is not null or undefined
            assertParamExists('getPartnerPartyIds', 'apiKey', apiKey);
            // verify required parameter 'partnerPartyLinkOrRefId' is not null or undefined
            assertParamExists('getPartnerPartyIds', 'partnerPartyLinkOrRefId', partnerPartyLinkOrRefId);
            const localVarPath = `/partner/party/ids/{partnerPartyLinkOrRefId}`
                .replace(`{${"partnerPartyLinkOrRefId"}}`, encodeURIComponent(String(partnerPartyLinkOrRefId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (apiKey != null) {
                localVarHeaderParameter['apiKey'] = String(apiKey);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartnerPartyDetailsApi - functional programming interface
 * @export
 */
export const PartnerPartyDetailsApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartnerPartyDetailsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} apiKey
         * @param {string} partnerPartyLinkOrRefId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyIds(apiKey, partnerPartyLinkOrRefId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerPartyIds(apiKey, partnerPartyLinkOrRefId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPartyDetailsApi.getPartnerPartyIds']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartnerPartyDetailsApi - factory interface
 * @export
 */
export const PartnerPartyDetailsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartnerPartyDetailsApiFp(configuration);
    return {
        /**
         *
         * @param {string} apiKey
         * @param {string} partnerPartyLinkOrRefId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerPartyIds(apiKey, partnerPartyLinkOrRefId, options) {
            return localVarFp.getPartnerPartyIds(apiKey, partnerPartyLinkOrRefId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartnerPartyDetailsApi - object-oriented interface
 * @export
 * @class PartnerPartyDetailsApi
 * @extends {BaseAPI}
 */
export class PartnerPartyDetailsApi extends BaseAPI {
    /**
     *
     * @param {string} apiKey
     * @param {string} partnerPartyLinkOrRefId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPartyDetailsApi
     */
    getPartnerPartyIds(apiKey, partnerPartyLinkOrRefId, options) {
        return PartnerPartyDetailsApiFp(this.configuration).getPartnerPartyIds(apiKey, partnerPartyLinkOrRefId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartnerPortalApisApi - axios parameter creator
 * @export
 */
export const PartnerPortalApisApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} disputeId
         * @param {ApiCommentRequest} apiCommentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: (disputeId, apiCommentRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('addComment', 'disputeId', disputeId);
            // verify required parameter 'apiCommentRequest' is not null or undefined
            assertParamExists('addComment', 'apiCommentRequest', apiCommentRequest);
            const localVarPath = `/partner/ticket/{disputeId}/comment`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCommentRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack: (disputeId, apiDisputeChargebackRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('approveChargeBack', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeChargebackRequest' is not null or undefined
            assertParamExists('approveChargeBack', 'apiDisputeChargebackRequest', apiDisputeChargebackRequest);
            const localVarPath = `/partner/dispute/{disputeId}/accept`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeChargebackRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} bakktTransactionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDisputeDetails: (bakktTransactionRef, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'bakktTransactionRef' is not null or undefined
            assertParamExists('fetchDisputeDetails', 'bakktTransactionRef', bakktTransactionRef);
            const localVarPath = `/partner/dispute/{bakktTransactionRef}`
                .replace(`{${"bakktTransactionRef"}}`, encodeURIComponent(String(bakktTransactionRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSpreadAndCommission: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/partner/spread-and-commission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBack: (disputeId, apiDisputeChargebackRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'disputeId' is not null or undefined
            assertParamExists('rejectChargeBack', 'disputeId', disputeId);
            // verify required parameter 'apiDisputeChargebackRequest' is not null or undefined
            assertParamExists('rejectChargeBack', 'apiDisputeChargebackRequest', apiDisputeChargebackRequest);
            const localVarPath = `/partner/dispute/{disputeId}/reject`
                .replace(`{${"disputeId"}}`, encodeURIComponent(String(disputeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiDisputeChargebackRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerDetails: (partnerId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrievePartnerDetails', 'partnerId', partnerId);
            const localVarPath = `/partner/{partnerId}/details`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve Party for Admins
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrieveParty', 'partyId', partyId);
            const localVarPath = `/partner/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch the portfolio details for a party and list of partnerIds
         * @summary partner portal portfolio api
         * @param {string} partnerId
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePortfolioAccounts: (partnerId, partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('retrievePortfolioAccounts', 'partnerId', partnerId);
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrievePortfolioAccounts', 'partyId', partyId);
            const localVarPath = `/partner/{partnerId}/party/{partyId}/portfolio`
                .replace(`{${"partnerId"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartnerPortalApisApi - functional programming interface
 * @export
 */
export const PartnerPortalApisApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartnerPortalApisApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} disputeId
         * @param {ApiCommentRequest} apiCommentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(disputeId, apiCommentRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addComment(disputeId, apiCommentRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.addComment']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack(disputeId, apiDisputeChargebackRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.approveChargeBack(disputeId, apiDisputeChargebackRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.approveChargeBack']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} bakktTransactionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDisputeDetails(bakktTransactionRef, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchDisputeDetails(bakktTransactionRef, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.fetchDisputeDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSpreadAndCommission(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.fetchSpreadAndCommission(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.fetchSpreadAndCommission']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBack(disputeId, apiDisputeChargebackRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.rejectChargeBack(disputeId, apiDisputeChargebackRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.rejectChargeBack']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerDetails(partnerId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePartnerDetails(partnerId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.retrievePartnerDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve Party for Admins
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveParty(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.retrieveParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Fetch the portfolio details for a party and list of partnerIds
         * @summary partner portal portfolio api
         * @param {string} partnerId
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePortfolioAccounts(partnerId, partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrievePortfolioAccounts(partnerId, partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartnerPortalApisApi.retrievePortfolioAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartnerPortalApisApi - factory interface
 * @export
 */
export const PartnerPortalApisApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartnerPortalApisApiFp(configuration);
    return {
        /**
         *
         * @param {string} disputeId
         * @param {ApiCommentRequest} apiCommentRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(disputeId, apiCommentRequest, options) {
            return localVarFp.addComment(disputeId, apiCommentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveChargeBack(disputeId, apiDisputeChargebackRequest, options) {
            return localVarFp.approveChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} bakktTransactionRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchDisputeDetails(bakktTransactionRef, options) {
            return localVarFp.fetchDisputeDetails(bakktTransactionRef, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSpreadAndCommission(options) {
            return localVarFp.fetchSpreadAndCommission(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} disputeId
         * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectChargeBack(disputeId, apiDisputeChargebackRequest, options) {
            return localVarFp.rejectChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve Partner
         * @param {string} partnerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePartnerDetails(partnerId, options) {
            return localVarFp.retrievePartnerDetails(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve Party for Admins
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty(partyId, options) {
            return localVarFp.retrieveParty(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch the portfolio details for a party and list of partnerIds
         * @summary partner portal portfolio api
         * @param {string} partnerId
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrievePortfolioAccounts(partnerId, partyId, options) {
            return localVarFp.retrievePortfolioAccounts(partnerId, partyId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartnerPortalApisApi - object-oriented interface
 * @export
 * @class PartnerPortalApisApi
 * @extends {BaseAPI}
 */
export class PartnerPortalApisApi extends BaseAPI {
    /**
     *
     * @param {string} disputeId
     * @param {ApiCommentRequest} apiCommentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    addComment(disputeId, apiCommentRequest, options) {
        return PartnerPortalApisApiFp(this.configuration).addComment(disputeId, apiCommentRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    approveChargeBack(disputeId, apiDisputeChargebackRequest, options) {
        return PartnerPortalApisApiFp(this.configuration).approveChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} bakktTransactionRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    fetchDisputeDetails(bakktTransactionRef, options) {
        return PartnerPortalApisApiFp(this.configuration).fetchDisputeDetails(bakktTransactionRef, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    fetchSpreadAndCommission(options) {
        return PartnerPortalApisApiFp(this.configuration).fetchSpreadAndCommission(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} disputeId
     * @param {ApiDisputeChargebackRequest} apiDisputeChargebackRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    rejectChargeBack(disputeId, apiDisputeChargebackRequest, options) {
        return PartnerPortalApisApiFp(this.configuration).rejectChargeBack(disputeId, apiDisputeChargebackRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve Partner
     * @param {string} partnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    retrievePartnerDetails(partnerId, options) {
        return PartnerPortalApisApiFp(this.configuration).retrievePartnerDetails(partnerId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve Party for Admins
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    retrieveParty(partyId, options) {
        return PartnerPortalApisApiFp(this.configuration).retrieveParty(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch the portfolio details for a party and list of partnerIds
     * @summary partner portal portfolio api
     * @param {string} partnerId
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerPortalApisApi
     */
    retrievePortfolioAccounts(partnerId, partyId, options) {
        return PartnerPortalApisApiFp(this.configuration).retrievePortfolioAccounts(partnerId, partyId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartyApi - axios parameter creator
 * @export
 */
export const PartyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Add Identifier
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier: (apiAddIdentifierRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiAddIdentifierRequest' is not null or undefined
            assertParamExists('addIdentifier', 'apiAddIdentifierRequest', apiAddIdentifierRequest);
            const localVarPath = `/aof/party/identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAddIdentifierRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Designed for users without account created yet.
         * @summary Add Identifier to non-Account
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier1: (apiAddIdentifierRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiAddIdentifierRequest' is not null or undefined
            assertParamExists('addIdentifier1', 'apiAddIdentifierRequest', apiAddIdentifierRequest);
            const localVarPath = `/aof/party/add-identifier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAddIdentifierRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
         * @summary Party Search for Party Operations
         * @param {ApiPartySearchRequest} apiPartySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSearchParties: (apiPartySearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartySearchRequest' is not null or undefined
            assertParamExists('adminSearchParties', 'apiPartySearchRequest', apiPartySearchRequest);
            const localVarPath = `/aof/party/search/admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartySearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailable: (apiUpdateAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateAliasRequest' is not null or undefined
            assertParamExists('aliasAvailable', 'apiUpdateAliasRequest', apiUpdateAliasRequest);
            const localVarPath = `/aof/party/alias-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailablePreonboard: (apiUpdateAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateAliasRequest' is not null or undefined
            assertParamExists('aliasAvailablePreonboard', 'apiUpdateAliasRequest', apiUpdateAliasRequest);
            const localVarPath = `/aof/party/pre-onboard/alias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available: (apiAvailabilityRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiAvailabilityRequest' is not null or undefined
            assertParamExists('available', 'apiAvailabilityRequest', apiAvailabilityRequest);
            const localVarPath = `/aof/party/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAvailabilityRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Check Bakkt ID / alias availabilitity
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableAlias: (apiUpdateAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateAliasRequest' is not null or undefined
            assertParamExists('availableAlias', 'apiUpdateAliasRequest', apiUpdateAliasRequest);
            const localVarPath = `/aof/party/alias/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Change the password of the logged in user
         * @summary Change Password
         * @param {ApiChangePasswordRequest} apiChangePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: (apiChangePasswordRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiChangePasswordRequest' is not null or undefined
            assertParamExists('changePassword', 'apiChangePasswordRequest', apiChangePasswordRequest);
            const localVarPath = `/aof/party/password/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiChangePasswordRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
         * @summary Party Search for Consumers
         * @param {ApiPartySearchRequest} apiPartySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerSearchParties: (apiPartySearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartySearchRequest' is not null or undefined
            assertParamExists('consumerSearchParties', 'apiPartySearchRequest', apiPartySearchRequest);
            const localVarPath = `/aof/party/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartySearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize: (apiFinalizeRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('finalize', 'apiFinalizeRequest', apiFinalizeRequest);
            const localVarPath = `/aof/party/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeParty: (apiFinalizeRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('finalizeParty', 'apiFinalizeRequest', apiFinalizeRequest);
            const localVarPath = `/aof/party/finalize-party`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve Funding Preference by type
         * @param {GetInAppPaymentFundingSourceTypeEnum} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppPaymentFundingSource: (type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getInAppPaymentFundingSource', 'type', type);
            const localVarPath = `/aof/party/funding-sources/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLinks: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/party/partner-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/party/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Change Bakkt accounts to prepend + to email for Webull
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyLegacyEmailAccounts: (requestBody, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('modifyLegacyEmailAccounts', 'requestBody', requestBody);
            const localVarPath = `/aof/modifyLegacyEmailAccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp: (apiValidateOTPRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiValidateOTPRequest' is not null or undefined
            assertParamExists('otp', 'apiValidateOTPRequest', apiValidateOTPRequest);
            const localVarPath = `/aof/party/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateOTPRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Suspend and possibly liquidate a party
         * @summary Suspend party
         * @param {ApiPartyLiquidateRequest} apiPartyLiquidateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyLiquidate: (apiPartyLiquidateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartyLiquidateRequest' is not null or undefined
            assertParamExists('partyLiquidate', 'apiPartyLiquidateRequest', apiPartyLiquidateRequest);
            const localVarPath = `/aof/party/liquidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartyLiquidateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Persist EW9 Acceptance Time and Signature in Party
         * @summary Persist EW9 Acceptance Time and Signature
         * @param {ApiSignatureEw9Request} apiSignatureEw9Request
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        persistEW9AcceptanceDate: (apiSignatureEw9Request, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiSignatureEw9Request' is not null or undefined
            assertParamExists('persistEW9AcceptanceDate', 'apiSignatureEw9Request', apiSignatureEw9Request);
            const localVarPath = `/aof/party/updateEw9`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiSignatureEw9Request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
         * @summary Resend One Time Password (OTP)
         * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resend: (apiResendValidateIdentifierRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiResendValidateIdentifierRequest' is not null or undefined
            assertParamExists('resend', 'apiResendValidateIdentifierRequest', apiResendValidateIdentifierRequest);
            const localVarPath = `/aof/party/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiResendValidateIdentifierRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: (apiResetPasswordRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiResetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'apiResetPasswordRequest', apiResetPasswordRequest);
            const localVarPath = `/aof/party/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiResetPasswordRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize: (apiFinalizeRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('resetPasswordFinalize', 'apiFinalizeRequest', apiFinalizeRequest);
            const localVarPath = `/aof/party/password/reset/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Full details of a Party with the given Party ID
         * @summary Party Details
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFullPartyDetails: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrieveFullPartyDetails', 'partyId', partyId);
            const localVarPath = `/aof/party/{partyId}/full-details`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve Party for Consumers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/party`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Retrieve Party for Admins
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty1: (partyId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('retrieveParty1', 'partyId', partyId);
            const localVarPath = `/aof/party/{partyId}`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a list of static data to be used by the clients to help render user interfaces and validate user input.
         * @summary Retrieve Static Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStaticData: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/static-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update Bakkt ID / alias
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAlias: (apiUpdateAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateAliasRequest' is not null or undefined
            assertParamExists('setAlias', 'apiUpdateAliasRequest', apiUpdateAliasRequest);
            const localVarPath = `/aof/party/alias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Set the party\'s attribution
         * @param {ApiPartyAttributionData} apiPartyAttributionData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttribution: (apiPartyAttributionData, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPartyAttributionData' is not null or undefined
            assertParamExists('setAttribution', 'apiPartyAttributionData', apiPartyAttributionData);
            const localVarPath = `/aof/party/attribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPartyAttributionData, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update Bakkt ID / alias
         * @param {string} partyId
         * @param {ApiUpdateElevatedAliasRequest} apiUpdateElevatedAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlias: (partyId, apiUpdateElevatedAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'partyId' is not null or undefined
            assertParamExists('updateAlias', 'partyId', partyId);
            // verify required parameter 'apiUpdateElevatedAliasRequest' is not null or undefined
            assertParamExists('updateAlias', 'apiUpdateElevatedAliasRequest', apiUpdateElevatedAliasRequest);
            const localVarPath = `/aof/party/{partyId}/alias`
                .replace(`{${"partyId"}}`, encodeURIComponent(String(partyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateElevatedAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Updates the Cardholder Agreement publishing date and sets the acceptance date on the party
         * @summary Update Cardholder Agreement Acceptance Time
         * @param {ApiCardholderAgreementAcceptanceRequest} apiCardholderAgreementAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardHolderAgreementAcceptanceTime: (apiCardholderAgreementAcceptanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCardholderAgreementAcceptanceRequest' is not null or undefined
            assertParamExists('updateCardHolderAgreementAcceptanceTime', 'apiCardholderAgreementAcceptanceRequest', apiCardholderAgreementAcceptanceRequest);
            const localVarPath = `/aof/party/policy-update/cardholder-agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCardholderAgreementAcceptanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Update the first and last name along with address on a given party.
         * @summary Update Name and Address
         * @param {ApiUpdateNameAndAddressRequest} apiUpdateNameAndAddressRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNameAndAddress: (apiUpdateNameAndAddressRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateNameAndAddressRequest' is not null or undefined
            assertParamExists('updateNameAndAddress', 'apiUpdateNameAndAddressRequest', apiUpdateNameAndAddressRequest);
            const localVarPath = `/aof/party/name-and-address`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateNameAndAddressRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Updates the privacy policy publishing date and sets the acceptance date on the party
         * @summary Update Privacy Policy Acceptance Time
         * @param {ApiPrivacyPolicyAcceptanceRequest} apiPrivacyPolicyAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrivacyPolicyAcceptanceTime: (apiPrivacyPolicyAcceptanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiPrivacyPolicyAcceptanceRequest' is not null or undefined
            assertParamExists('updatePrivacyPolicyAcceptanceTime', 'apiPrivacyPolicyAcceptanceRequest', apiPrivacyPolicyAcceptanceRequest);
            const localVarPath = `/aof/party/policy-update/privacy-policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiPrivacyPolicyAcceptanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Updates the terms and conditions publishing date and sets the acceptance date on the party
         * @summary Update Terms and Conditions Acceptance Time
         * @param {ApiTermsAndConditionsAcceptanceRequest} apiTermsAndConditionsAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermsAndConditionsAcceptanceTime: (apiTermsAndConditionsAcceptanceRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiTermsAndConditionsAcceptanceRequest' is not null or undefined
            assertParamExists('updateTermsAndConditionsAcceptanceTime', 'apiTermsAndConditionsAcceptanceRequest', apiTermsAndConditionsAcceptanceRequest);
            const localVarPath = `/aof/party/policy-update/terms-and-conditions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTermsAndConditionsAcceptanceRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: (apiValidateIdentifierRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiValidateIdentifierRequest' is not null or undefined
            assertParamExists('validate', 'apiValidateIdentifierRequest', apiValidateIdentifierRequest);
            const localVarPath = `/aof/party/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateIdentifierRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Validate the password of the logged in user
         * @summary Validate Password
         * @param {ApiValidatePasswordRequest} apiValidatePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassword: (apiValidatePasswordRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiValidatePasswordRequest' is not null or undefined
            assertParamExists('validatePassword', 'apiValidatePasswordRequest', apiValidatePasswordRequest);
            const localVarPath = `/aof/party/password/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidatePasswordRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartyApi - functional programming interface
 * @export
 */
export const PartyApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartyApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Add Identifier
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier(apiAddIdentifierRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addIdentifier(apiAddIdentifierRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.addIdentifier']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Designed for users without account created yet.
         * @summary Add Identifier to non-Account
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier1(apiAddIdentifierRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addIdentifier1(apiAddIdentifierRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.addIdentifier1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
         * @summary Party Search for Party Operations
         * @param {ApiPartySearchRequest} apiPartySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSearchParties(apiPartySearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.adminSearchParties(apiPartySearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.adminSearchParties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailable(apiUpdateAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.aliasAvailable(apiUpdateAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.aliasAvailable']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailablePreonboard(apiUpdateAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.aliasAvailablePreonboard(apiUpdateAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.aliasAvailablePreonboard']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available(apiAvailabilityRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.available(apiAvailabilityRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.available']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Check Bakkt ID / alias availabilitity
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableAlias(apiUpdateAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.availableAlias(apiUpdateAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.availableAlias']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Change the password of the logged in user
         * @summary Change Password
         * @param {ApiChangePasswordRequest} apiChangePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(apiChangePasswordRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.changePassword(apiChangePasswordRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.changePassword']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
         * @summary Party Search for Consumers
         * @param {ApiPartySearchRequest} apiPartySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerSearchParties(apiPartySearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.consumerSearchParties(apiPartySearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.consumerSearchParties']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize(apiFinalizeRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.finalize(apiFinalizeRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.finalize']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeParty(apiFinalizeRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.finalizeParty(apiFinalizeRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.finalizeParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve Funding Preference by type
         * @param {GetInAppPaymentFundingSourceTypeEnum} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppPaymentFundingSource(type, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getInAppPaymentFundingSource(type, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.getInAppPaymentFundingSource']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLinks(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPartnerLinks(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.getPartnerLinks']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.logout(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.logout']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Change Bakkt accounts to prepend + to email for Webull
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyLegacyEmailAccounts(requestBody, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.modifyLegacyEmailAccounts(requestBody, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.modifyLegacyEmailAccounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp(apiValidateOTPRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.otp(apiValidateOTPRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.otp']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Suspend and possibly liquidate a party
         * @summary Suspend party
         * @param {ApiPartyLiquidateRequest} apiPartyLiquidateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyLiquidate(apiPartyLiquidateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.partyLiquidate(apiPartyLiquidateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.partyLiquidate']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Persist EW9 Acceptance Time and Signature in Party
         * @summary Persist EW9 Acceptance Time and Signature
         * @param {ApiSignatureEw9Request} apiSignatureEw9Request
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        persistEW9AcceptanceDate(apiSignatureEw9Request, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.persistEW9AcceptanceDate(apiSignatureEw9Request, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.persistEW9AcceptanceDate']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
         * @summary Resend One Time Password (OTP)
         * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resend(apiResendValidateIdentifierRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resend(apiResendValidateIdentifierRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.resend']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(apiResetPasswordRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resetPassword(apiResetPasswordRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.resetPassword']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize(apiFinalizeRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resetPasswordFinalize(apiFinalizeRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.resetPasswordFinalize']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Full details of a Party with the given Party ID
         * @summary Party Details
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFullPartyDetails(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveFullPartyDetails(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.retrieveFullPartyDetails']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve Party for Consumers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveParty(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.retrieveParty']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Retrieve Party for Admins
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty1(partyId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveParty1(partyId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.retrieveParty1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Returns a list of static data to be used by the clients to help render user interfaces and validate user input.
         * @summary Retrieve Static Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStaticData(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.retrieveStaticData(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.retrieveStaticData']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update Bakkt ID / alias
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAlias(apiUpdateAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setAlias(apiUpdateAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.setAlias']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Set the party\'s attribution
         * @param {ApiPartyAttributionData} apiPartyAttributionData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttribution(apiPartyAttributionData, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setAttribution(apiPartyAttributionData, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.setAttribution']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update Bakkt ID / alias
         * @param {string} partyId
         * @param {ApiUpdateElevatedAliasRequest} apiUpdateElevatedAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlias(partyId, apiUpdateElevatedAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateAlias(partyId, apiUpdateElevatedAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.updateAlias']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Updates the Cardholder Agreement publishing date and sets the acceptance date on the party
         * @summary Update Cardholder Agreement Acceptance Time
         * @param {ApiCardholderAgreementAcceptanceRequest} apiCardholderAgreementAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardHolderAgreementAcceptanceTime(apiCardholderAgreementAcceptanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateCardHolderAgreementAcceptanceTime(apiCardholderAgreementAcceptanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.updateCardHolderAgreementAcceptanceTime']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Update the first and last name along with address on a given party.
         * @summary Update Name and Address
         * @param {ApiUpdateNameAndAddressRequest} apiUpdateNameAndAddressRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNameAndAddress(apiUpdateNameAndAddressRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateNameAndAddress(apiUpdateNameAndAddressRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.updateNameAndAddress']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Updates the privacy policy publishing date and sets the acceptance date on the party
         * @summary Update Privacy Policy Acceptance Time
         * @param {ApiPrivacyPolicyAcceptanceRequest} apiPrivacyPolicyAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrivacyPolicyAcceptanceTime(apiPrivacyPolicyAcceptanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updatePrivacyPolicyAcceptanceTime(apiPrivacyPolicyAcceptanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.updatePrivacyPolicyAcceptanceTime']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Updates the terms and conditions publishing date and sets the acceptance date on the party
         * @summary Update Terms and Conditions Acceptance Time
         * @param {ApiTermsAndConditionsAcceptanceRequest} apiTermsAndConditionsAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermsAndConditionsAcceptanceTime(apiTermsAndConditionsAcceptanceRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateTermsAndConditionsAcceptanceTime(apiTermsAndConditionsAcceptanceRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.updateTermsAndConditionsAcceptanceTime']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(apiValidateIdentifierRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.validate(apiValidateIdentifierRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.validate']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Validate the password of the logged in user
         * @summary Validate Password
         * @param {ApiValidatePasswordRequest} apiValidatePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassword(apiValidatePasswordRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.validatePassword(apiValidatePasswordRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyApi.validatePassword']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartyApi - factory interface
 * @export
 */
export const PartyApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartyApiFp(configuration);
    return {
        /**
         *
         * @summary Add Identifier
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier(apiAddIdentifierRequest, options) {
            return localVarFp.addIdentifier(apiAddIdentifierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Designed for users without account created yet.
         * @summary Add Identifier to non-Account
         * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addIdentifier1(apiAddIdentifierRequest, options) {
            return localVarFp.addIdentifier1(apiAddIdentifierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
         * @summary Party Search for Party Operations
         * @param {ApiPartySearchRequest} apiPartySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSearchParties(apiPartySearchRequest, page, size, sort, options) {
            return localVarFp.adminSearchParties(apiPartySearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailable(apiUpdateAliasRequest, options) {
            return localVarFp.aliasAvailable(apiUpdateAliasRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailablePreonboard(apiUpdateAliasRequest, options) {
            return localVarFp.aliasAvailablePreonboard(apiUpdateAliasRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available(apiAvailabilityRequest, options) {
            return localVarFp.available(apiAvailabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Check Bakkt ID / alias availabilitity
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availableAlias(apiUpdateAliasRequest, options) {
            return localVarFp.availableAlias(apiUpdateAliasRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the password of the logged in user
         * @summary Change Password
         * @param {ApiChangePasswordRequest} apiChangePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(apiChangePasswordRequest, options) {
            return localVarFp.changePassword(apiChangePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
         * @summary Party Search for Consumers
         * @param {ApiPartySearchRequest} apiPartySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consumerSearchParties(apiPartySearchRequest, page, size, sort, options) {
            return localVarFp.consumerSearchParties(apiPartySearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize(apiFinalizeRequest, xTrackingId, options) {
            return localVarFp.finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalizeParty(apiFinalizeRequest, xTrackingId, options) {
            return localVarFp.finalizeParty(apiFinalizeRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve Funding Preference by type
         * @param {GetInAppPaymentFundingSourceTypeEnum} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppPaymentFundingSource(type, options) {
            return localVarFp.getInAppPaymentFundingSource(type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerLinks(options) {
            return localVarFp.getPartnerLinks(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Change Bakkt accounts to prepend + to email for Webull
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyLegacyEmailAccounts(requestBody, options) {
            return localVarFp.modifyLegacyEmailAccounts(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp(apiValidateOTPRequest, xTrackingId, options) {
            return localVarFp.otp(apiValidateOTPRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Suspend and possibly liquidate a party
         * @summary Suspend party
         * @param {ApiPartyLiquidateRequest} apiPartyLiquidateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partyLiquidate(apiPartyLiquidateRequest, options) {
            return localVarFp.partyLiquidate(apiPartyLiquidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Persist EW9 Acceptance Time and Signature in Party
         * @summary Persist EW9 Acceptance Time and Signature
         * @param {ApiSignatureEw9Request} apiSignatureEw9Request
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        persistEW9AcceptanceDate(apiSignatureEw9Request, xTrackingId, options) {
            return localVarFp.persistEW9AcceptanceDate(apiSignatureEw9Request, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
         * @summary Resend One Time Password (OTP)
         * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resend(apiResendValidateIdentifierRequest, options) {
            return localVarFp.resend(apiResendValidateIdentifierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(apiResetPasswordRequest, options) {
            return localVarFp.resetPassword(apiResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize(apiFinalizeRequest, options) {
            return localVarFp.resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Full details of a Party with the given Party ID
         * @summary Party Details
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveFullPartyDetails(partyId, options) {
            return localVarFp.retrieveFullPartyDetails(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve Party for Consumers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty(options) {
            return localVarFp.retrieveParty(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Retrieve Party for Admins
         * @param {string} partyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveParty1(partyId, options) {
            return localVarFp.retrieveParty1(partyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a list of static data to be used by the clients to help render user interfaces and validate user input.
         * @summary Retrieve Static Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveStaticData(options) {
            return localVarFp.retrieveStaticData(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Bakkt ID / alias
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAlias(apiUpdateAliasRequest, options) {
            return localVarFp.setAlias(apiUpdateAliasRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Set the party\'s attribution
         * @param {ApiPartyAttributionData} apiPartyAttributionData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttribution(apiPartyAttributionData, options) {
            return localVarFp.setAttribution(apiPartyAttributionData, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update Bakkt ID / alias
         * @param {string} partyId
         * @param {ApiUpdateElevatedAliasRequest} apiUpdateElevatedAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlias(partyId, apiUpdateElevatedAliasRequest, options) {
            return localVarFp.updateAlias(partyId, apiUpdateElevatedAliasRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Cardholder Agreement publishing date and sets the acceptance date on the party
         * @summary Update Cardholder Agreement Acceptance Time
         * @param {ApiCardholderAgreementAcceptanceRequest} apiCardholderAgreementAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCardHolderAgreementAcceptanceTime(apiCardholderAgreementAcceptanceRequest, options) {
            return localVarFp.updateCardHolderAgreementAcceptanceTime(apiCardholderAgreementAcceptanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the first and last name along with address on a given party.
         * @summary Update Name and Address
         * @param {ApiUpdateNameAndAddressRequest} apiUpdateNameAndAddressRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNameAndAddress(apiUpdateNameAndAddressRequest, xTrackingId, options) {
            return localVarFp.updateNameAndAddress(apiUpdateNameAndAddressRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the privacy policy publishing date and sets the acceptance date on the party
         * @summary Update Privacy Policy Acceptance Time
         * @param {ApiPrivacyPolicyAcceptanceRequest} apiPrivacyPolicyAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrivacyPolicyAcceptanceTime(apiPrivacyPolicyAcceptanceRequest, options) {
            return localVarFp.updatePrivacyPolicyAcceptanceTime(apiPrivacyPolicyAcceptanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the terms and conditions publishing date and sets the acceptance date on the party
         * @summary Update Terms and Conditions Acceptance Time
         * @param {ApiTermsAndConditionsAcceptanceRequest} apiTermsAndConditionsAcceptanceRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTermsAndConditionsAcceptanceTime(apiTermsAndConditionsAcceptanceRequest, options) {
            return localVarFp.updateTermsAndConditionsAcceptanceTime(apiTermsAndConditionsAcceptanceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(apiValidateIdentifierRequest, options) {
            return localVarFp.validate(apiValidateIdentifierRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate the password of the logged in user
         * @summary Validate Password
         * @param {ApiValidatePasswordRequest} apiValidatePasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePassword(apiValidatePasswordRequest, options) {
            return localVarFp.validatePassword(apiValidatePasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartyApi - object-oriented interface
 * @export
 * @class PartyApi
 * @extends {BaseAPI}
 */
export class PartyApi extends BaseAPI {
    /**
     *
     * @summary Add Identifier
     * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    addIdentifier(apiAddIdentifierRequest, options) {
        return PartyApiFp(this.configuration).addIdentifier(apiAddIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Designed for users without account created yet.
     * @summary Add Identifier to non-Account
     * @param {ApiAddIdentifierRequest} apiAddIdentifierRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    addIdentifier1(apiAddIdentifierRequest, options) {
        return PartyApiFp(this.configuration).addIdentifier1(apiAddIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
     * @summary Party Search for Party Operations
     * @param {ApiPartySearchRequest} apiPartySearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    adminSearchParties(apiPartySearchRequest, page, size, sort, options) {
        return PartyApiFp(this.configuration).adminSearchParties(apiPartySearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
     * @summary Add newAlias to customers Party
     * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    aliasAvailable(apiUpdateAliasRequest, options) {
        return PartyApiFp(this.configuration).aliasAvailable(apiUpdateAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
     * @summary Add newAlias to customers Party
     * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    aliasAvailablePreonboard(apiUpdateAliasRequest, options) {
        return PartyApiFp(this.configuration).aliasAvailablePreonboard(apiUpdateAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * First step in consumer sign-up. Verifies that the identifier in request is available for use
     * @summary Check Party identifier availability
     * @param {ApiAvailabilityRequest} apiAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    available(apiAvailabilityRequest, options) {
        return PartyApiFp(this.configuration).available(apiAvailabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Check Bakkt ID / alias availabilitity
     * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    availableAlias(apiUpdateAliasRequest, options) {
        return PartyApiFp(this.configuration).availableAlias(apiUpdateAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Change the password of the logged in user
     * @summary Change Password
     * @param {ApiChangePasswordRequest} apiChangePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    changePassword(apiChangePasswordRequest, options) {
        return PartyApiFp(this.configuration).changePassword(apiChangePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fetch a list of Parties that match all the given search criteria. All specified search criteria are combined.
     * @summary Party Search for Consumers
     * @param {ApiPartySearchRequest} apiPartySearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    consumerSearchParties(apiPartySearchRequest, page, size, sort, options) {
        return PartyApiFp(this.configuration).consumerSearchParties(apiPartySearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
     * @summary Finalize Party Sign Up
     * @param {ApiFinalizeRequest} apiFinalizeRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    finalize(apiFinalizeRequest, xTrackingId, options) {
        return PartyApiFp(this.configuration).finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Last step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP.  This is needed because the OTP resets need be secure.)
     * @summary Finalize Party Sign Up
     * @param {ApiFinalizeRequest} apiFinalizeRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    finalizeParty(apiFinalizeRequest, xTrackingId, options) {
        return PartyApiFp(this.configuration).finalizeParty(apiFinalizeRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve Funding Preference by type
     * @param {GetInAppPaymentFundingSourceTypeEnum} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    getInAppPaymentFundingSource(type, options) {
        return PartyApiFp(this.configuration).getInAppPaymentFundingSource(type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    getPartnerLinks(options) {
        return PartyApiFp(this.configuration).getPartnerLinks(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    logout(options) {
        return PartyApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Change Bakkt accounts to prepend + to email for Webull
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    modifyLegacyEmailAccounts(requestBody, options) {
        return PartyApiFp(this.configuration).modifyLegacyEmailAccounts(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
     * @summary Validate One Time Password (OTP)
     * @param {ApiValidateOTPRequest} apiValidateOTPRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    otp(apiValidateOTPRequest, xTrackingId, options) {
        return PartyApiFp(this.configuration).otp(apiValidateOTPRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Suspend and possibly liquidate a party
     * @summary Suspend party
     * @param {ApiPartyLiquidateRequest} apiPartyLiquidateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    partyLiquidate(apiPartyLiquidateRequest, options) {
        return PartyApiFp(this.configuration).partyLiquidate(apiPartyLiquidateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Persist EW9 Acceptance Time and Signature in Party
     * @summary Persist EW9 Acceptance Time and Signature
     * @param {ApiSignatureEw9Request} apiSignatureEw9Request
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    persistEW9AcceptanceDate(apiSignatureEw9Request, xTrackingId, options) {
        return PartyApiFp(this.configuration).persistEW9AcceptanceDate(apiSignatureEw9Request, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Resend an OTP for the identifier given. This function will reuse the party created during validate. This call will invalidate any other OTPs that have been sent for this particular identifier. If the client passes the encryptedIdentifier from POST /party/available the system will NOT send the otp and the system will return the same encryptedIdentifier to the client without an error.
     * @summary Resend One Time Password (OTP)
     * @param {ApiResendValidateIdentifierRequest} apiResendValidateIdentifierRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    resend(apiResendValidateIdentifierRequest, options) {
        return PartyApiFp(this.configuration).resend(apiResendValidateIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Start Reset Password
     * @param {ApiResetPasswordRequest} apiResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    resetPassword(apiResetPasswordRequest, options) {
        return PartyApiFp(this.configuration).resetPassword(apiResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Second and final step when resetting a password.
     * @summary Finalize Reset Password
     * @param {ApiFinalizeRequest} apiFinalizeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    resetPasswordFinalize(apiFinalizeRequest, options) {
        return PartyApiFp(this.configuration).resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Full details of a Party with the given Party ID
     * @summary Party Details
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    retrieveFullPartyDetails(partyId, options) {
        return PartyApiFp(this.configuration).retrieveFullPartyDetails(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve Party for Consumers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    retrieveParty(options) {
        return PartyApiFp(this.configuration).retrieveParty(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Retrieve Party for Admins
     * @param {string} partyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    retrieveParty1(partyId, options) {
        return PartyApiFp(this.configuration).retrieveParty1(partyId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a list of static data to be used by the clients to help render user interfaces and validate user input.
     * @summary Retrieve Static Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    retrieveStaticData(options) {
        return PartyApiFp(this.configuration).retrieveStaticData(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update Bakkt ID / alias
     * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    setAlias(apiUpdateAliasRequest, options) {
        return PartyApiFp(this.configuration).setAlias(apiUpdateAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Set the party\'s attribution
     * @param {ApiPartyAttributionData} apiPartyAttributionData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    setAttribution(apiPartyAttributionData, options) {
        return PartyApiFp(this.configuration).setAttribution(apiPartyAttributionData, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update Bakkt ID / alias
     * @param {string} partyId
     * @param {ApiUpdateElevatedAliasRequest} apiUpdateElevatedAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    updateAlias(partyId, apiUpdateElevatedAliasRequest, options) {
        return PartyApiFp(this.configuration).updateAlias(partyId, apiUpdateElevatedAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates the Cardholder Agreement publishing date and sets the acceptance date on the party
     * @summary Update Cardholder Agreement Acceptance Time
     * @param {ApiCardholderAgreementAcceptanceRequest} apiCardholderAgreementAcceptanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    updateCardHolderAgreementAcceptanceTime(apiCardholderAgreementAcceptanceRequest, options) {
        return PartyApiFp(this.configuration).updateCardHolderAgreementAcceptanceTime(apiCardholderAgreementAcceptanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update the first and last name along with address on a given party.
     * @summary Update Name and Address
     * @param {ApiUpdateNameAndAddressRequest} apiUpdateNameAndAddressRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    updateNameAndAddress(apiUpdateNameAndAddressRequest, xTrackingId, options) {
        return PartyApiFp(this.configuration).updateNameAndAddress(apiUpdateNameAndAddressRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates the privacy policy publishing date and sets the acceptance date on the party
     * @summary Update Privacy Policy Acceptance Time
     * @param {ApiPrivacyPolicyAcceptanceRequest} apiPrivacyPolicyAcceptanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    updatePrivacyPolicyAcceptanceTime(apiPrivacyPolicyAcceptanceRequest, options) {
        return PartyApiFp(this.configuration).updatePrivacyPolicyAcceptanceTime(apiPrivacyPolicyAcceptanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Updates the terms and conditions publishing date and sets the acceptance date on the party
     * @summary Update Terms and Conditions Acceptance Time
     * @param {ApiTermsAndConditionsAcceptanceRequest} apiTermsAndConditionsAcceptanceRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    updateTermsAndConditionsAcceptanceTime(apiTermsAndConditionsAcceptanceRequest, options) {
        return PartyApiFp(this.configuration).updateTermsAndConditionsAcceptanceTime(apiTermsAndConditionsAcceptanceRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Send One Time Password (OTP)
     * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    validate(apiValidateIdentifierRequest, options) {
        return PartyApiFp(this.configuration).validate(apiValidateIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Validate the password of the logged in user
     * @summary Validate Password
     * @param {ApiValidatePasswordRequest} apiValidatePasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyApi
     */
    validatePassword(apiValidatePasswordRequest, options) {
        return PartyApiFp(this.configuration).validatePassword(apiValidatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var GetInAppPaymentFundingSourceTypeEnum;
(function (GetInAppPaymentFundingSourceTypeEnum) {
    GetInAppPaymentFundingSourceTypeEnum["BUY_GIFTCARD"] = "BUY_GIFTCARD";
    GetInAppPaymentFundingSourceTypeEnum["BUY_CRYPTO"] = "BUY_CRYPTO";
    GetInAppPaymentFundingSourceTypeEnum["BAKKT_CARD"] = "BAKKT_CARD";
    GetInAppPaymentFundingSourceTypeEnum["MERCHANT_PAYMENT"] = "MERCHANT_PAYMENT";
})(GetInAppPaymentFundingSourceTypeEnum || (GetInAppPaymentFundingSourceTypeEnum = {}));
/**
 * PartyResetPasswordApi - axios parameter creator
 * @export
 */
export const PartyResetPasswordApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: (apiResetPasswordRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiResetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'apiResetPasswordRequest', apiResetPasswordRequest);
            const localVarPath = `/aof/party/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiResetPasswordRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize: (apiFinalizeRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('resetPasswordFinalize', 'apiFinalizeRequest', apiFinalizeRequest);
            const localVarPath = `/aof/party/password/reset/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartyResetPasswordApi - functional programming interface
 * @export
 */
export const PartyResetPasswordApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartyResetPasswordApiAxiosParamCreator(configuration);
    return {
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(apiResetPasswordRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resetPassword(apiResetPasswordRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyResetPasswordApi.resetPassword']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize(apiFinalizeRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.resetPasswordFinalize(apiFinalizeRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartyResetPasswordApi.resetPasswordFinalize']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartyResetPasswordApi - factory interface
 * @export
 */
export const PartyResetPasswordApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartyResetPasswordApiFp(configuration);
    return {
        /**
         * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Start Reset Password
         * @param {ApiResetPasswordRequest} apiResetPasswordRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(apiResetPasswordRequest, options) {
            return localVarFp.resetPassword(apiResetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Second and final step when resetting a password.
         * @summary Finalize Reset Password
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordFinalize(apiFinalizeRequest, options) {
            return localVarFp.resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartyResetPasswordApi - object-oriented interface
 * @export
 * @class PartyResetPasswordApi
 * @extends {BaseAPI}
 */
export class PartyResetPasswordApi extends BaseAPI {
    /**
     * First step in reset password process. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Start Reset Password
     * @param {ApiResetPasswordRequest} apiResetPasswordRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyResetPasswordApi
     */
    resetPassword(apiResetPasswordRequest, options) {
        return PartyResetPasswordApiFp(this.configuration).resetPassword(apiResetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Second and final step when resetting a password.
     * @summary Finalize Reset Password
     * @param {ApiFinalizeRequest} apiFinalizeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartyResetPasswordApi
     */
    resetPasswordFinalize(apiFinalizeRequest, options) {
        return PartyResetPasswordApiFp(this.configuration).resetPasswordFinalize(apiFinalizeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * PartySignUpApi - axios parameter creator
 * @export
 */
export const PartySignUpApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available: (apiAvailabilityRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiAvailabilityRequest' is not null or undefined
            assertParamExists('available', 'apiAvailabilityRequest', apiAvailabilityRequest);
            const localVarPath = `/aof/party/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiAvailabilityRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize: (apiFinalizeRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiFinalizeRequest' is not null or undefined
            assertParamExists('finalize', 'apiFinalizeRequest', apiFinalizeRequest);
            const localVarPath = `/aof/party/finalize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiFinalizeRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp: (apiValidateOTPRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiValidateOTPRequest' is not null or undefined
            assertParamExists('otp', 'apiValidateOTPRequest', apiValidateOTPRequest);
            const localVarPath = `/aof/party/otp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateOTPRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate: (apiValidateIdentifierRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiValidateIdentifierRequest' is not null or undefined
            assertParamExists('validate', 'apiValidateIdentifierRequest', apiValidateIdentifierRequest);
            const localVarPath = `/aof/party/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiValidateIdentifierRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * PartySignUpApi - functional programming interface
 * @export
 */
export const PartySignUpApiFp = function (configuration) {
    const localVarAxiosParamCreator = PartySignUpApiAxiosParamCreator(configuration);
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available(apiAvailabilityRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.available(apiAvailabilityRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartySignUpApi.available']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize(apiFinalizeRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.finalize(apiFinalizeRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartySignUpApi.finalize']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp(apiValidateOTPRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.otp(apiValidateOTPRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartySignUpApi.otp']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(apiValidateIdentifierRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.validate(apiValidateIdentifierRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['PartySignUpApi.validate']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * PartySignUpApi - factory interface
 * @export
 */
export const PartySignUpApiFactory = function (configuration, basePath, axios) {
    const localVarFp = PartySignUpApiFp(configuration);
    return {
        /**
         * First step in consumer sign-up. Verifies that the identifier in request is available for use
         * @summary Check Party identifier availability
         * @param {ApiAvailabilityRequest} apiAvailabilityRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        available(apiAvailabilityRequest, options) {
            return localVarFp.available(apiAvailabilityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
         * @summary Finalize Party Sign Up
         * @param {ApiFinalizeRequest} apiFinalizeRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        finalize(apiFinalizeRequest, xTrackingId, options) {
            return localVarFp.finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
         * @summary Validate One Time Password (OTP)
         * @param {ApiValidateOTPRequest} apiValidateOTPRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        otp(apiValidateOTPRequest, xTrackingId, options) {
            return localVarFp.otp(apiValidateOTPRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
         * @summary Send One Time Password (OTP)
         * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(apiValidateIdentifierRequest, options) {
            return localVarFp.validate(apiValidateIdentifierRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * PartySignUpApi - object-oriented interface
 * @export
 * @class PartySignUpApi
 * @extends {BaseAPI}
 */
export class PartySignUpApi extends BaseAPI {
    /**
     * First step in consumer sign-up. Verifies that the identifier in request is available for use
     * @summary Check Party identifier availability
     * @param {ApiAvailabilityRequest} apiAvailabilityRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    available(apiAvailabilityRequest, options) {
        return PartySignUpApiFp(this.configuration).available(apiAvailabilityRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Fourth and final step in consumer sign-up. This step completes the creation of the party by linking the AoF party to the user created in the IDP (currently FusionAuth) Linkage is via subjectRef (sub of the jwt or objectId in IDP)
     * @summary Finalize Party Sign Up
     * @param {ApiFinalizeRequest} apiFinalizeRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    finalize(apiFinalizeRequest, xTrackingId, options) {
        return PartySignUpApiFp(this.configuration).finalize(apiFinalizeRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Third step in consumer sign-up. This call is the validation step proving (theoretical) ownership of the identifier
     * @summary Validate One Time Password (OTP)
     * @param {ApiValidateOTPRequest} apiValidateOTPRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    otp(apiValidateOTPRequest, xTrackingId, options) {
        return PartySignUpApiFp(this.configuration).otp(apiValidateOTPRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Second step in consumer sign-up. This call results in an OTP sent to the identifier in the request (mobile phone or email)
     * @summary Send One Time Password (OTP)
     * @param {ApiValidateIdentifierRequest} apiValidateIdentifierRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartySignUpApi
     */
    validate(apiValidateIdentifierRequest, options) {
        return PartySignUpApiFp(this.configuration).validate(apiValidateIdentifierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * SettlementApi - axios parameter creator
 * @export
 */
export const SettlementApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {string} date
         * @param {ListConsumerSettlementTransactionsAchTypeEnum} achType
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConsumerSettlementTransactions: (date, achType, pageable, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('listConsumerSettlementTransactions', 'date', date);
            // verify required parameter 'achType' is not null or undefined
            assertParamExists('listConsumerSettlementTransactions', 'achType', achType);
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('listConsumerSettlementTransactions', 'pageable', pageable);
            const localVarPath = `/aof/settlement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (date !== undefined) {
                localVarQueryParameter['date'] = (date instanceof Date) ?
                    date.toISOString().substring(0, 10) :
                    date;
            }
            if (achType !== undefined) {
                localVarQueryParameter['achType'] = achType;
            }
            if (pageable !== undefined) {
                for (const [key, value] of Object.entries(pageable)) {
                    localVarQueryParameter[key] = value;
                }
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * SettlementApi - functional programming interface
 * @export
 */
export const SettlementApiFp = function (configuration) {
    const localVarAxiosParamCreator = SettlementApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} date
         * @param {ListConsumerSettlementTransactionsAchTypeEnum} achType
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConsumerSettlementTransactions(date, achType, pageable, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listConsumerSettlementTransactions(date, achType, pageable, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['SettlementApi.listConsumerSettlementTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * SettlementApi - factory interface
 * @export
 */
export const SettlementApiFactory = function (configuration, basePath, axios) {
    const localVarFp = SettlementApiFp(configuration);
    return {
        /**
         *
         * @param {string} date
         * @param {ListConsumerSettlementTransactionsAchTypeEnum} achType
         * @param {Pageable} pageable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listConsumerSettlementTransactions(date, achType, pageable, options) {
            return localVarFp.listConsumerSettlementTransactions(date, achType, pageable, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * SettlementApi - object-oriented interface
 * @export
 * @class SettlementApi
 * @extends {BaseAPI}
 */
export class SettlementApi extends BaseAPI {
    /**
     *
     * @param {string} date
     * @param {ListConsumerSettlementTransactionsAchTypeEnum} achType
     * @param {Pageable} pageable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettlementApi
     */
    listConsumerSettlementTransactions(date, achType, pageable, options) {
        return SettlementApiFp(this.configuration).listConsumerSettlementTransactions(date, achType, pageable, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
  * @export
  * @enum {string}
  */
export var ListConsumerSettlementTransactionsAchTypeEnum;
(function (ListConsumerSettlementTransactionsAchTypeEnum) {
    ListConsumerSettlementTransactionsAchTypeEnum["WITHDRAWAL"] = "WITHDRAWAL";
    ListConsumerSettlementTransactionsAchTypeEnum["DEPOSIT"] = "DEPOSIT";
})(ListConsumerSettlementTransactionsAchTypeEnum || (ListConsumerSettlementTransactionsAchTypeEnum = {}));
/**
 * TaxIdVerificationApi - axios parameter creator
 * @export
 */
export const TaxIdVerificationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceAvailability: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/aof/tax-id/check-service-availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} serviceProviderName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceAvailability1: (serviceProviderName, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'serviceProviderName' is not null or undefined
            assertParamExists('checkServiceAvailability1', 'serviceProviderName', serviceProviderName);
            const localVarPath = `/aof/tax-id/check-service-availability/{serviceProviderName}`
                .replace(`{${"serviceProviderName"}}`, encodeURIComponent(String(serviceProviderName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiVerifyTaxIdRequest} apiVerifyTaxIdRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTaxId: (apiVerifyTaxIdRequest, xTrackingId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiVerifyTaxIdRequest' is not null or undefined
            assertParamExists('verifyTaxId', 'apiVerifyTaxIdRequest', apiVerifyTaxIdRequest);
            const localVarPath = `/aof/tax-id/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (xTrackingId != null) {
                localVarHeaderParameter['x-tracking-id'] = String(xTrackingId);
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiVerifyTaxIdRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TaxIdVerificationApi - functional programming interface
 * @export
 */
export const TaxIdVerificationApiFp = function (configuration) {
    const localVarAxiosParamCreator = TaxIdVerificationApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceAvailability(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.checkServiceAvailability(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TaxIdVerificationApi.checkServiceAvailability']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} serviceProviderName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceAvailability1(serviceProviderName, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.checkServiceAvailability1(serviceProviderName, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TaxIdVerificationApi.checkServiceAvailability1']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiVerifyTaxIdRequest} apiVerifyTaxIdRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTaxId(apiVerifyTaxIdRequest, xTrackingId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyTaxId(apiVerifyTaxIdRequest, xTrackingId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TaxIdVerificationApi.verifyTaxId']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * TaxIdVerificationApi - factory interface
 * @export
 */
export const TaxIdVerificationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = TaxIdVerificationApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceAvailability(options) {
            return localVarFp.checkServiceAvailability(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceProviderName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceAvailability1(serviceProviderName, options) {
            return localVarFp.checkServiceAvailability1(serviceProviderName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiVerifyTaxIdRequest} apiVerifyTaxIdRequest
         * @param {string} [xTrackingId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyTaxId(apiVerifyTaxIdRequest, xTrackingId, options) {
            return localVarFp.verifyTaxId(apiVerifyTaxIdRequest, xTrackingId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TaxIdVerificationApi - object-oriented interface
 * @export
 * @class TaxIdVerificationApi
 * @extends {BaseAPI}
 */
export class TaxIdVerificationApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxIdVerificationApi
     */
    checkServiceAvailability(options) {
        return TaxIdVerificationApiFp(this.configuration).checkServiceAvailability(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} serviceProviderName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxIdVerificationApi
     */
    checkServiceAvailability1(serviceProviderName, options) {
        return TaxIdVerificationApiFp(this.configuration).checkServiceAvailability1(serviceProviderName, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiVerifyTaxIdRequest} apiVerifyTaxIdRequest
     * @param {string} [xTrackingId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxIdVerificationApi
     */
    verifyTaxId(apiVerifyTaxIdRequest, xTrackingId, options) {
        return TaxIdVerificationApiFp(this.configuration).verifyTaxId(apiVerifyTaxIdRequest, xTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * TransactionControllerApi - axios parameter creator
 * @export
 */
export const TransactionControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {AssetActivitySearchRequest} assetActivitySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetActivities: (assetActivitySearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'assetActivitySearchRequest' is not null or undefined
            assertParamExists('getAssetActivities', 'assetActivitySearchRequest', assetActivitySearchRequest);
            const localVarPath = `/business-portal/transactions/asset/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(assetActivitySearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiTransactionDetailsRequest} apiTransactionDetailsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerTransaction: (apiTransactionDetailsRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiTransactionDetailsRequest' is not null or undefined
            assertParamExists('getCustomerTransaction', 'apiTransactionDetailsRequest', apiTransactionDetailsRequest);
            const localVarPath = `/business-portal/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiTransactionDetailsRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssetActivitySummaryList: (assetActivitySummarySearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'assetActivitySummarySearchRequest' is not null or undefined
            assertParamExists('searchAssetActivitySummaryList', 'assetActivitySummarySearchRequest', assetActivitySummarySearchRequest);
            const localVarPath = `/business-portal/asset-activity/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(assetActivitySummarySearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactionSummary: (assetActivitySummarySearchRequest, page, size, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'assetActivitySummarySearchRequest' is not null or undefined
            assertParamExists('searchTransactionSummary', 'assetActivitySummarySearchRequest', assetActivitySummarySearchRequest);
            const localVarPath = `/business-portal/transaction/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(assetActivitySummarySearchRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactions: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('searchTransactions', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} debitCardTransactionId
         * @param {string} field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskDebitCardFields: (debitCardTransactionId, field, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'debitCardTransactionId' is not null or undefined
            assertParamExists('unmaskDebitCardFields', 'debitCardTransactionId', debitCardTransactionId);
            // verify required parameter 'field' is not null or undefined
            assertParamExists('unmaskDebitCardFields', 'field', field);
            const localVarPath = `/business-portal/transaction/{debitCardTransactionId}/debit-card/{field}/unmask`
                .replace(`{${"debitCardTransactionId"}}`, encodeURIComponent(String(debitCardTransactionId)))
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * TransactionControllerApi - functional programming interface
 * @export
 */
export const TransactionControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = TransactionControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {AssetActivitySearchRequest} assetActivitySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetActivities(assetActivitySearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAssetActivities(assetActivitySearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionControllerApi.getAssetActivities']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiTransactionDetailsRequest} apiTransactionDetailsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerTransaction(apiTransactionDetailsRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getCustomerTransaction(apiTransactionDetailsRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionControllerApi.getCustomerTransaction']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssetActivitySummaryList(assetActivitySummarySearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchAssetActivitySummaryList(assetActivitySummarySearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionControllerApi.searchAssetActivitySummaryList']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactionSummary(assetActivitySummarySearchRequest, page, size, sort, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchTransactionSummary(assetActivitySummarySearchRequest, page, size, sort, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionControllerApi.searchTransactionSummary']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactions(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchTransactions(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionControllerApi.searchTransactions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} debitCardTransactionId
         * @param {string} field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskDebitCardFields(debitCardTransactionId, field, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.unmaskDebitCardFields(debitCardTransactionId, field, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['TransactionControllerApi.unmaskDebitCardFields']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * TransactionControllerApi - factory interface
 * @export
 */
export const TransactionControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = TransactionControllerApiFp(configuration);
    return {
        /**
         *
         * @param {AssetActivitySearchRequest} assetActivitySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetActivities(assetActivitySearchRequest, page, size, sort, options) {
            return localVarFp.getAssetActivities(assetActivitySearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiTransactionDetailsRequest} apiTransactionDetailsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerTransaction(apiTransactionDetailsRequest, options) {
            return localVarFp.getCustomerTransaction(apiTransactionDetailsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchAssetActivitySummaryList(assetActivitySummarySearchRequest, page, size, sort, options) {
            return localVarFp.searchAssetActivitySummaryList(assetActivitySummarySearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactionSummary(assetActivitySummarySearchRequest, page, size, sort, options) {
            return localVarFp.searchTransactionSummary(assetActivitySummarySearchRequest, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchTransactions(searchParameters, options) {
            return localVarFp.searchTransactions(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} debitCardTransactionId
         * @param {string} field
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unmaskDebitCardFields(debitCardTransactionId, field, options) {
            return localVarFp.unmaskDebitCardFields(debitCardTransactionId, field, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * TransactionControllerApi - object-oriented interface
 * @export
 * @class TransactionControllerApi
 * @extends {BaseAPI}
 */
export class TransactionControllerApi extends BaseAPI {
    /**
     *
     * @param {AssetActivitySearchRequest} assetActivitySearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    getAssetActivities(assetActivitySearchRequest, page, size, sort, options) {
        return TransactionControllerApiFp(this.configuration).getAssetActivities(assetActivitySearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiTransactionDetailsRequest} apiTransactionDetailsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    getCustomerTransaction(apiTransactionDetailsRequest, options) {
        return TransactionControllerApiFp(this.configuration).getCustomerTransaction(apiTransactionDetailsRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    searchAssetActivitySummaryList(assetActivitySummarySearchRequest, page, size, sort, options) {
        return TransactionControllerApiFp(this.configuration).searchAssetActivitySummaryList(assetActivitySummarySearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {AssetActivitySummarySearchRequest} assetActivitySummarySearchRequest
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    searchTransactionSummary(assetActivitySummarySearchRequest, page, size, sort, options) {
        return TransactionControllerApiFp(this.configuration).searchTransactionSummary(assetActivitySummarySearchRequest, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    searchTransactions(searchParameters, options) {
        return TransactionControllerApiFp(this.configuration).searchTransactions(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} debitCardTransactionId
     * @param {string} field
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionControllerApi
     */
    unmaskDebitCardFields(debitCardTransactionId, field, options) {
        return TransactionControllerApiFp(this.configuration).unmaskDebitCardFields(debitCardTransactionId, field, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * UserRoleManagementControllerApi - axios parameter creator
 * @export
 */
export const UserRoleManagementControllerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {CreateUserRequest} createUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUser: (createUserRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('addOrUpdateUser', 'createUserRequest', createUserRequest);
            const localVarPath = `/business-portal/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiCreateRole} apiCreateRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissions: (apiCreateRole, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiCreateRole' is not null or undefined
            assertParamExists('createRoleWithPermissions', 'apiCreateRole', apiCreateRole);
            const localVarPath = `/business-portal/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiCreateRole, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetail: (fusionAuthRef, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('getPortalUserDetail', 'fusionAuthRef', fusionAuthRef);
            const localVarPath = `/business-portal/users/{fusionAuthRef}`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsers: (searchParameters, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'searchParameters' is not null or undefined
            assertParamExists('getPortalUsers', 'searchParameters', searchParameters);
            const localVarPath = `/business-portal/users/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(searchParameters, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/business-portal/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} role
         * @param {RoleUpdateRequest} roleUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyRoleUsers: (role, roleUpdateRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('modifyRoleUsers', 'role', role);
            // verify required parameter 'roleUpdateRequest' is not null or undefined
            assertParamExists('modifyRoleUsers', 'roleUpdateRequest', roleUpdateRequest);
            const localVarPath = `/business-portal/roles/{role}`
                .replace(`{${"role"}}`, encodeURIComponent(String(role)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(roleUpdateRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} fusionAuthRef
         * @param {UpdateUserRequest} updateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRoles: (fusionAuthRef, updateUserRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('modifyUserRoles', 'fusionAuthRef', fusionAuthRef);
            // verify required parameter 'updateUserRequest' is not null or undefined
            assertParamExists('modifyUserRoles', 'updateUserRequest', updateUserRequest);
            const localVarPath = `/business-portal/users/{fusionAuthRef}`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {string} fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUser: (fusionAuthRef, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'fusionAuthRef' is not null or undefined
            assertParamExists('reactivateUser', 'fusionAuthRef', fusionAuthRef);
            const localVarPath = `/business-portal/users/{fusionAuthRef}/reactivate`
                .replace(`{${"fusionAuthRef"}}`, encodeURIComponent(String(fusionAuthRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {InvitePortalUserRequest} invitePortalUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite: (invitePortalUserRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'invitePortalUserRequest' is not null or undefined
            assertParamExists('sendInvite', 'invitePortalUserRequest', invitePortalUserRequest);
            const localVarPath = `/business-portal/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(invitePortalUserRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {ApiUpdateRole} apiUpdateRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissions: (apiUpdateRole, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateRole' is not null or undefined
            assertParamExists('updateRoleWithPermissions', 'apiUpdateRole', apiUpdateRole);
            const localVarPath = `/business-portal/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateRole, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * UserRoleManagementControllerApi - functional programming interface
 * @export
 */
export const UserRoleManagementControllerApiFp = function (configuration) {
    const localVarAxiosParamCreator = UserRoleManagementControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {CreateUserRequest} createUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUser(createUserRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.addOrUpdateUser(createUserRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.addOrUpdateUser']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiCreateRole} apiCreateRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissions(apiCreateRole, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createRoleWithPermissions(apiCreateRole, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.createRoleWithPermissions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetail(fusionAuthRef, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPortalUserDetail(fusionAuthRef, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.getPortalUserDetail']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsers(searchParameters, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPortalUsers(searchParameters, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.getPortalUsers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleList(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRoleList(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.getRoleList']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} role
         * @param {RoleUpdateRequest} roleUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyRoleUsers(role, roleUpdateRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.modifyRoleUsers(role, roleUpdateRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.modifyRoleUsers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} fusionAuthRef
         * @param {UpdateUserRequest} updateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRoles(fusionAuthRef, updateUserRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.modifyUserRoles(fusionAuthRef, updateUserRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.modifyUserRoles']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {string} fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUser(fusionAuthRef, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reactivateUser(fusionAuthRef, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.reactivateUser']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {InvitePortalUserRequest} invitePortalUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(invitePortalUserRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendInvite(invitePortalUserRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.sendInvite']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @param {ApiUpdateRole} apiUpdateRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissions(apiUpdateRole, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateRoleWithPermissions(apiUpdateRole, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['UserRoleManagementControllerApi.updateRoleWithPermissions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * UserRoleManagementControllerApi - factory interface
 * @export
 */
export const UserRoleManagementControllerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = UserRoleManagementControllerApiFp(configuration);
    return {
        /**
         *
         * @param {CreateUserRequest} createUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUser(createUserRequest, options) {
            return localVarFp.addOrUpdateUser(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiCreateRole} apiCreateRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleWithPermissions(apiCreateRole, options) {
            return localVarFp.createRoleWithPermissions(apiCreateRole, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUserDetail(fusionAuthRef, options) {
            return localVarFp.getPortalUserDetail(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {SearchParameters} searchParameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPortalUsers(searchParameters, options) {
            return localVarFp.getPortalUsers(searchParameters, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleList(options) {
            return localVarFp.getRoleList(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} role
         * @param {RoleUpdateRequest} roleUpdateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyRoleUsers(role, roleUpdateRequest, options) {
            return localVarFp.modifyRoleUsers(role, roleUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} fusionAuthRef
         * @param {UpdateUserRequest} updateUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyUserRoles(fusionAuthRef, updateUserRequest, options) {
            return localVarFp.modifyUserRoles(fusionAuthRef, updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} fusionAuthRef
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reactivateUser(fusionAuthRef, options) {
            return localVarFp.reactivateUser(fusionAuthRef, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {InvitePortalUserRequest} invitePortalUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendInvite(invitePortalUserRequest, options) {
            return localVarFp.sendInvite(invitePortalUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {ApiUpdateRole} apiUpdateRole
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleWithPermissions(apiUpdateRole, options) {
            return localVarFp.updateRoleWithPermissions(apiUpdateRole, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * UserRoleManagementControllerApi - object-oriented interface
 * @export
 * @class UserRoleManagementControllerApi
 * @extends {BaseAPI}
 */
export class UserRoleManagementControllerApi extends BaseAPI {
    /**
     *
     * @param {CreateUserRequest} createUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    addOrUpdateUser(createUserRequest, options) {
        return UserRoleManagementControllerApiFp(this.configuration).addOrUpdateUser(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiCreateRole} apiCreateRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    createRoleWithPermissions(apiCreateRole, options) {
        return UserRoleManagementControllerApiFp(this.configuration).createRoleWithPermissions(apiCreateRole, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    getPortalUserDetail(fusionAuthRef, options) {
        return UserRoleManagementControllerApiFp(this.configuration).getPortalUserDetail(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {SearchParameters} searchParameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    getPortalUsers(searchParameters, options) {
        return UserRoleManagementControllerApiFp(this.configuration).getPortalUsers(searchParameters, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    getRoleList(options) {
        return UserRoleManagementControllerApiFp(this.configuration).getRoleList(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} role
     * @param {RoleUpdateRequest} roleUpdateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    modifyRoleUsers(role, roleUpdateRequest, options) {
        return UserRoleManagementControllerApiFp(this.configuration).modifyRoleUsers(role, roleUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} fusionAuthRef
     * @param {UpdateUserRequest} updateUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    modifyUserRoles(fusionAuthRef, updateUserRequest, options) {
        return UserRoleManagementControllerApiFp(this.configuration).modifyUserRoles(fusionAuthRef, updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {string} fusionAuthRef
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    reactivateUser(fusionAuthRef, options) {
        return UserRoleManagementControllerApiFp(this.configuration).reactivateUser(fusionAuthRef, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {InvitePortalUserRequest} invitePortalUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    sendInvite(invitePortalUserRequest, options) {
        return UserRoleManagementControllerApiFp(this.configuration).sendInvite(invitePortalUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {ApiUpdateRole} apiUpdateRole
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRoleManagementControllerApi
     */
    updateRoleWithPermissions(apiUpdateRole, options) {
        return UserRoleManagementControllerApiFp(this.configuration).updateRoleWithPermissions(apiUpdateRole, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * ValidatesAndStoresAliasApi - axios parameter creator
 * @export
 */
export const ValidatesAndStoresAliasApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailable: (apiUpdateAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateAliasRequest' is not null or undefined
            assertParamExists('aliasAvailable', 'apiUpdateAliasRequest', apiUpdateAliasRequest);
            const localVarPath = `/aof/party/alias-available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailablePreonboard: (apiUpdateAliasRequest, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'apiUpdateAliasRequest' is not null or undefined
            assertParamExists('aliasAvailablePreonboard', 'apiUpdateAliasRequest', apiUpdateAliasRequest);
            const localVarPath = `/aof/party/pre-onboard/alias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication auth required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "auth", [], configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(apiUpdateAliasRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ValidatesAndStoresAliasApi - functional programming interface
 * @export
 */
export const ValidatesAndStoresAliasApiFp = function (configuration) {
    const localVarAxiosParamCreator = ValidatesAndStoresAliasApiAxiosParamCreator(configuration);
    return {
        /**
         * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailable(apiUpdateAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.aliasAvailable(apiUpdateAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['ValidatesAndStoresAliasApi.aliasAvailable']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailablePreonboard(apiUpdateAliasRequest, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.aliasAvailablePreonboard(apiUpdateAliasRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['ValidatesAndStoresAliasApi.aliasAvailablePreonboard']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * ValidatesAndStoresAliasApi - factory interface
 * @export
 */
export const ValidatesAndStoresAliasApiFactory = function (configuration, basePath, axios) {
    const localVarFp = ValidatesAndStoresAliasApiFp(configuration);
    return {
        /**
         * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailable(apiUpdateAliasRequest, options) {
            return localVarFp.aliasAvailable(apiUpdateAliasRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
         * @summary Add newAlias to customers Party
         * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aliasAvailablePreonboard(apiUpdateAliasRequest, options) {
            return localVarFp.aliasAvailablePreonboard(apiUpdateAliasRequest, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ValidatesAndStoresAliasApi - object-oriented interface
 * @export
 * @class ValidatesAndStoresAliasApi
 * @extends {BaseAPI}
 */
export class ValidatesAndStoresAliasApi extends BaseAPI {
    /**
     * Designed for users without account created yet and without the addEmail flow.into newAlias.  Later in flow newAlias is stored into alias
     * @summary Add newAlias to customers Party
     * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidatesAndStoresAliasApi
     */
    aliasAvailable(apiUpdateAliasRequest, options) {
        return ValidatesAndStoresAliasApiFp(this.configuration).aliasAvailable(apiUpdateAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Designed for users without account created yet and addEmail identifier flow (New).It will store the chosen alias into newAlias.  Later in flow newAlias is stored into alias
     * @summary Add newAlias to customers Party
     * @param {ApiUpdateAliasRequest} apiUpdateAliasRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidatesAndStoresAliasApi
     */
    aliasAvailablePreonboard(apiUpdateAliasRequest, options) {
        return ValidatesAndStoresAliasApiFp(this.configuration).aliasAvailablePreonboard(apiUpdateAliasRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
