import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import alertReducer from "./alerts/reducer";
import appReducer from "./app/reducer";
const App = appReducer;
export const store = configureStore({
  reducer: {
    Alerts: alertReducer,
    App,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
