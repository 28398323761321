import { HBox, Icons } from "@bakkt/components";
import { logout } from "../../common/api";
import PermissionManager, { PortalPermission } from "../PermissionManager";
import SettingsView from "./Settings";

export default () => {
  return (
    <div style={{ marginLeft: 100, marginRight: 100, marginTop: 25, marginBottom: 25 }}>
      <HBox style={{ alignItems: "center" }}>
        <span style={{ fontSize: 20, fontFamily: "BrownPro" }}>The Tango Portal</span>
        <div style={{ display: "flex", flexGrow: 1 }}></div>
        <Icons.BakktLogo width={148} height={57} />
        <div style={{ display: "flex", flexGrow: 1 }}></div>
        {PermissionManager.userAllowed([PortalPermission.USERS_VIEW]) && <SettingsView />}
        <span style={{ fontSize: 16, cursor: "pointer", fontFamily: "BrownPro", height: 24 }} onClick={logout}>
          Sign Out
        </span>
      </HBox>
    </div>
  );
};
